import React, { useState } from "react";
import OptionsMentorshipPlan from "./OptionsMentorshipPlan";
import BrianPhoto from "../../assets/images/brian.png"
import "./index.scss";
import TermsModal from "./TermsModal";

const OptionsMentorship = () => {
  const [selected, setSelected] = useState('');
  const [open, setOpen] = useState(false);

  return (
    <div className="options-mentorship-page">
      <div className="options-mentorship-page-title">Forex Mentorship</div>
      <div className="options-mentorship-page-description">Launch your trading journey and execute your first profitable trade with
        MarketMakers Forex Mentorship.</div>
      <div className="options-mentorship-page-plans-title">Choose Your Forex Mentorship Plan</div>

      <div className="options-mentorship-plans">
        <OptionsMentorshipPlan
          type='novice'
          selected={selected}
          setSelected={setSelected}
        />
        <OptionsMentorshipPlan
          type='accelerator'
          selected={selected}
          setSelected={setSelected}
        />
        <OptionsMentorshipPlan
          type='master'
          selected={selected}
          setSelected={setSelected}
        />
      </div>

      {/* <div className="options-mentorship-terms" onClick={() => setOpen(true)}>
        * Terms and conditions Apply
      </div>

      <div className="options-mentorship-mentors">Our Mentors</div>
      <div className="options-mentorship-mentor">
        <img src={BrianPhoto} alt='mentor avatar' className="options-mentorship-mentor-avatar" />
        <div className="options-mentorship-mentor-info">
          <div className="options-mentorship-mentor-name">Brian Anderson</div>
          <div className="options-mentorship-mentor-job">MarketMakers Option Analyst</div>
        </div>
      </div>

      <TermsModal open={open} handleClose={() => setOpen(false)} /> */}
    </div>
  )
}

export default OptionsMentorship;