
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const CURRENCY_STRENGTH_LOAD = "CURRENCY_STRENGTH/LOAD/TOP";
export const CURRENCY_STRENGTH_LOAD_SUCCESS = "CURRENCY_STRENGTH/LOAD/TOP/SUCCESS";
export const CURRENCY_STRENGTH_LOAD_FAILURE = "CURRENCY_STRENGTH/LOAD/TOP/FAILURE";

// Action Creators
export const currencyStrengthLoad = (resolution,dropdown = 'default') => ({
  type: CURRENCY_STRENGTH_LOAD,
  resolution,
  dropdown
});
export const currencyStrengthLoadSuccess = (data, loading) => ({
  type: CURRENCY_STRENGTH_LOAD_SUCCESS,
  data,
  loading
});
export const currencyStrengthLoadFailure = (error) => ({
  type: CURRENCY_STRENGTH_LOAD_FAILURE,
  error,
  loading: false
});

// Sagas
function* fetchCurrencyStrength(action) {
  try {
    yield put(currencyStrengthLoadSuccess([], true));
    const { resolution, dropdown = 'default'} = action;

    let url = `${apiURL}/forex/currency-strengths/${resolution}/${dropdown}`;

    const response = yield axios.get(url, { withCredentials: true });

    const data = { ...response.data.data, date: response.data.date, time: response.data.time };
    yield put(currencyStrengthLoadSuccess(data, false));

  } catch (error) {
    console.log(error,' errorrr----')
    yield put(currencyStrengthLoadFailure(error));
  }
}

function* listenCurrencyStrengthLoad() {
  yield takeEvery(CURRENCY_STRENGTH_LOAD, fetchCurrencyStrength);
}

// Root Saga
export function* saga() {
  yield all([fork(listenCurrencyStrengthLoad)]);
}

const INIT_STATE = {
  currencyStrength: {
    currencyStrengths: [],
    time: "",
    date: "",
  },
  currencyStrengthLoading: true,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CURRENCY_STRENGTH_LOAD_SUCCESS:
      return {
        ...state,
        currencyStrength: action.data,
        currencyStrengthLoading: action.loading,
      };
    case CURRENCY_STRENGTH_LOAD_FAILURE:
      return {
        ...state,
        currencyStrength: {
          time: "",
          date: "",
          currencyStrengths: [],
        },
        currencyStrengthLoading: true
      }
    default:
      return state;
  }
};

export default reducer;
