import React, { useState, useEffect } from "react";

import { useAuth } from "../../contexts/Auth";
import Loader from "../common/Loader";

const SignOut = () => {
  const [seconds, setSeconds] = useState(1);
  const { signOut } = useAuth();

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    if (seconds === 0) {
      clearInterval(interval);
      signOut();
    }
    return () => clearInterval(interval);
  }, [seconds, signOut]);

  return (
    <Loader type="screen">
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: "13pt", marginTop: "10px", color: "#fff" }}>
          Signing Out.
        </p>
      </div>
    </Loader>
  );
};

export default SignOut;
