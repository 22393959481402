import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'grey',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '-webkit-linear-gradient(90deg, hsla(214, 100%, 54%, 1) 0%, hsla(175, 100%, 69%, 1) 56%, hsla(167, 100%, 76%, 1) 100%)',
  },
}));
