import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import moment from "moment";
// Action Types
export const OPTION_CHAIN_LOAD = "OC/LOAD/TOP";
export const OPTION_CHAIN_LOAD_SUCCESS = "OC/LOAD/TOP/SUCCESS";
export const OPTION_CHAIN_LOAD_FAILURE = "OC/LOAD/TOP/FAILURE";

export const optionChainLoad = (symbol, date) => ({
  type: OPTION_CHAIN_LOAD,
  symbol,
  date,
});
export const optionChainLoadSuccess = (data, loading) => ({
  type: OPTION_CHAIN_LOAD_SUCCESS,
  data,
  loading,
});
export const optionChainLoadFailure = (error) => ({
  type: OPTION_CHAIN_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchOptionChain(action) {
  try {
    // expects date as MM/DD/YYYY
    const { symbol, date } = action;
    yield put(optionChainLoadSuccess({ optionChain: [], loading: true }));
    const response = yield axios.get(
      `${apiURL}/option-chain/${symbol}?date=${moment(date).format('MM/DD/YYYY')}`
      , { withCredentials: true });
    yield put(optionChainLoadSuccess({ optionChain: response?.data, loading: false }));
  } catch (error) {
    yield put(optionChainLoadFailure(error));
  }
}

function* listenOptionChainLoad() {
  yield takeEvery(OPTION_CHAIN_LOAD, fetchOptionChain);
}

// Root Saga
export function* saga() {
  yield all([fork(listenOptionChainLoad)]);
}

const INIT_STATE = {
  optionChain: [],
  optionChainLoading: false
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPTION_CHAIN_LOAD_SUCCESS:
      return {
        ...state,
        optionChain: action.data.optionChain,
        loading: action.data.loading
      };
    default:
      return state;
  }
};

export default reducer;
