import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Collapse from "@mui/material/Collapse";

import RealTimeTradingAlertsWidget from '../AlphaOptions/RealTimeTradingAlertsWidget';
import SupportsWidget from '../AlphaOptions/SupportsWidget';
import PastVideos from '../AlphaOptions/PastVideos';
import RealTimeTradingAlertsComponent from '../AlphaOptions/RealTimeTradingAlertsComponent';
import SupportComponent from "../AlphaOptions/SupportComponent";
import TutorialVideoGroup from '../AlphaOptions/TutorialVideoGroup';

import AlphaYoutube from "../../assets/images/alpha_youtube.png";
import AuthorAvatar from "../../assets/images/brian.png";
import "./index.scss";

const HedgeFundMentors = () => {
  const [currentNav, setCurrentNav] = useState('livestream');
  const [isTutorialsToggle, setIsTutorialsToggle] = useState(false);
  const [currentVideoGroup, setCurrentVideoGroup] = useState('');
  const [isShowFAQ, setIsShowFAQ] = useState(false);

  const selectNavigation = (nav) => {
    setCurrentVideoGroup('')
    setCurrentNav(nav);
    if (nav === 'tutorials') {
      setIsTutorialsToggle(!isTutorialsToggle);
    }
    if (nav === 'livestream' && isTutorialsToggle) {
      setIsTutorialsToggle(!isTutorialsToggle);
    }

    setIsShowFAQ(false);
  }

  const showFAQ = (value) => {
    setIsShowFAQ(value);
  }

  return (
    <div className='hedgefund-mentors-page'>
      <div className='hedgefund-mentors-page-content-wrapper'>
        <div className='hedgefund-mentors-page-title'>The HedgeFund Mentorship</div>
        <div className='hedgefund-mentors-page-short-description'>Guided learning at your own pace to help you reach your goals.</div>
        <div className='hedgefund-mentor-page-body'>
          <div className='hedgefund-mentor-page-navigator'>
            <div className={`hedgefund-mentor-page-navItem ${currentNav === 'tutorials' ? 'active' : ''}`} onClick={() => selectNavigation('tutorials')}>Tutorials
              {isTutorialsToggle ? <ExpandMoreIcon className='expand-icon' /> : <ExpandLessIcon className='expand-icon' />}
            </div>
            <div className={`hedgefund-mentor-page-navItem ${currentNav === 'livestream' ? 'active' : ''}`} onClick={() => selectNavigation('livestream')}>Livestream Learning</div>
            <div className={`hedgefund-mentor-page-navItem ${currentNav === 'real-time' ? 'active' : ''}`} onClick={() => selectNavigation('real-time')}>Real-time Trading Alerts</div>
            <div className={`hedgefund-mentor-page-navItem ${currentNav === 'support' ? 'active' : ''}`} onClick={() => selectNavigation('support')}>Support</div>
          </div>
          <div className='hedgefund-mentor-page-main-view'>
            {
              currentVideoGroup === 'beginner' && <TutorialVideoGroup setCurrentVideoGroup={setCurrentVideoGroup} />
            }
            {
              !currentVideoGroup.length && (currentNav === 'tutorials' || currentNav === 'livestream') && (
                <>
                  <div className='hedgefund-mentors-page-content'>
                    <Collapse in={(currentNav === 'tutorials' && isTutorialsToggle)}>
                      {(currentNav === 'tutorials') && (
                        <>
                          {/* Tutorials section */}
                          <div className='hedgefund-mentors-page-tutorials-section' onClick={() => setCurrentVideoGroup('beginner')}>
                            <div className='hedgefund-mentors-page-tutorial-section'>
                              <div className='hedgefund-mentors-page-tutorial-section-header'>
                                <PlayCircleIcon className='play-circle-icon' />
                                <div className='hedgefund-mentors-page-tutorial-section-header-info'>
                                  <div className='hedgefund-mentors-page-tutorial-section-title'>Beginner Track</div>
                                  <div className='hedgefund-mentors-page-tutorial-section-video-info'>13 Videos • 5 hours 15 mins</div>
                                </div>
                              </div>

                              <div className='hedgefund-mentors-page-tutorial-section-body'>Understanding the basics is crucial for success. This course will cover all the essentials and give you the knowledge and skills you need to trade with confidence.</div>

                              <div className='hedgefund-mentors-page-tutorial-section-view-video'>View track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' /></div>
                            </div>

                            <div className='hedgefund-mentors-page-tutorial-section'>
                              <div className='hedgefund-mentors-page-tutorial-section-header'>
                                <PlayCircleIcon className='play-circle-icon' />
                                <div className='hedgefund-mentors-page-tutorial-section-header-info'>
                                  <div className='hedgefund-mentors-page-tutorial-section-title'>Intermediate Track</div>
                                  <div className='hedgefund-mentors-page-tutorial-section-video-info'>12 Videos • 4 hours</div>
                                </div>
                              </div>

                              <div className='hedgefund-mentors-page-tutorial-section-body'>If you've already learned the basics, this course is for you. We'll cover advanced strategies for finding and trading high probability options.</div>

                              <div className='hedgefund-mentors-page-tutorial-section-view-video'>View track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' /></div>
                            </div>

                            <div className='hedgefund-mentors-page-tutorial-section'>
                              <div className='hedgefund-mentors-page-tutorial-section-header'>
                                <PlayCircleIcon className='play-circle-icon' />
                                <div className='hedgefund-mentors-page-tutorial-section-header-info'>
                                  <div className='hedgefund-mentors-page-tutorial-section-title'>Advanced Track</div>
                                  <div className='hedgefund-mentors-page-tutorial-section-video-info'>16 Videos • 4 hours 45 mins</div>
                                </div>
                              </div>

                              <div className='hedgefund-mentors-page-tutorial-section-body'>The advanced course is designed to help you master the mechanics behind trading to build a robust trading system that consistently generates excess returns.</div>

                              <div className='hedgefund-mentors-page-tutorial-section-view-video'>View track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' /></div>
                            </div>

                            <div className='hedgefund-mentors-page-tutorial-section'>
                              <div className='hedgefund-mentors-page-tutorial-section-header'>
                                <PlayCircleIcon className='play-circle-icon' />
                                <div className='hedgefund-mentors-page-tutorial-section-header-info'>
                                  <div className='hedgefund-mentors-page-tutorial-section-title'>FAQ</div>
                                  <div className='hedgefund-mentors-page-tutorial-section-video-info'>12 Videos • 4 hours</div>
                                </div>
                              </div>

                              <div className='hedgefund-mentors-page-tutorial-section-body'>The FAQ section is a helpful resource for anyone seeking answers to common questions about the Alpha Options Method.</div>

                              <div className='hedgefund-mentors-page-tutorial-section-view-video'>View track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' /></div>
                            </div>
                          </div>
                        </>
                      )}
                    </Collapse>

                    {/* Live Streams section */}
                    <div className='hedgefund-mentors-page-live-streams-content' id="live-stream-section">
                      <div className='hedgefund-mentors-page-live-streams-header'>Livestream Learning</div>
                      <img src={AlphaYoutube} alt="live-stream-vidoe" className='alpha-youtube' />

                      <div className='hedgefund-mentors-page-live-streams-info'>
                        <div className='hedgefund-mentors-page-live-streams-info-header'>
                          <img src={AuthorAvatar} alt="Brian" className='live-streams-author' />
                          <div className='hedgefund-mentors-page-live-streams-author-info'>
                            <div className='hedgefund-mentors-page-live-streams-author-name'>Brian Anderson</div>
                            <div className='hedgefund-mentors-page-live-streams-author-job'>MarketMakers Option Analyst</div>
                          </div>
                        </div>

                        <div className='hedgefund-mentors-page-live-streams-info-content'>
                          <div className='hedgefund-mentors-page-live-streams-title'>The Alpha Options Daily Livestreams</div>
                          <div className='hedgefund-mentors-page-live-streams-description'>Join "Bronco" Brian Anderson for the Alpha Options Method daily livestream event. Brian will take you on a journey as he analyzes the markets and searches for profitable trades. This daily event is the perfect way to gain valuable insights, stay informed, and get profitable trade ideas.</div>
                        </div>

                        <div className='hedgefund-mentors-page-live-streams-schedule'>
                          <div className='hedgefund-mentors-page-live-streams-schedule-title'>Live Schedule</div>
                          <div className='hedgefund-mentors-page-live-streams-schedule-list'>
                            <div className='hedgefund-mentors-page-live-streams-schedule-day-item'>
                              <div className='hedgefund-mentors-page-live-streams-schedule-day'>Monday</div>
                              <div className='hedgefund-mentors-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>

                            <div className='hedgefund-mentors-page-live-streams-schedule-day-item'>
                              <div className='hedgefund-mentors-page-live-streams-schedule-day'>Tuesday</div>
                              <div className='hedgefund-mentors-page-live-streams-schedule-time'>10:00am EST</div>
                              <div className='hedgefund-mentors-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>

                            <div className='hedgefund-mentors-page-live-streams-schedule-day-item'>
                              <div className='hedgefund-mentors-page-live-streams-schedule-day'>Wednesday</div>
                              <div className='hedgefund-mentors-page-live-streams-schedule-time'>10:00am EST</div>
                              <div className='hedgefund-mentors-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>

                            <div className='hedgefund-mentors-page-live-streams-schedule-day-item'>
                              <div className='hedgefund-mentors-page-live-streams-schedule-day'>Thursday</div>
                              <div className='hedgefund-mentors-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>

                            <div className='hedgefund-mentors-page-live-streams-schedule-day-item'>
                              <div className='hedgefund-mentors-page-live-streams-schedule-day'>Friday</div>
                              <div className='hedgefund-mentors-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <RealTimeTradingAlertsWidget setCurrentNav={setCurrentNav} />

                    <SupportsWidget setCurrentNav={setCurrentNav} showFAQ={showFAQ} />
                  </div>
                </>

              )
            }

            {
              currentNav === 'real-time' && (
                <RealTimeTradingAlertsComponent setCurrentNav={setCurrentNav} />
              )
            }

            {
              currentNav === 'support' && (
                <SupportComponent setCurrentNav={setCurrentNav} isShowFAQ={isShowFAQ} />
              )
            }
          </div>
        </div>
      </div>

      <div className='past-videos-content'>
        <PastVideos />
      </div>
    </div>
  )
}

export default HedgeFundMentors;