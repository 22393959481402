
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const LAST_PRICE_LOAD = "LP/LOAD/TOP";
export const LAST_PRICE_LOAD_SUCCESS = "LP/LOAD/TOP/SUCCESS";
export const LAST_PRICE_LOAD_FAILURE = "LP/LOAD/TOP/FAILURE";

// Action Creators
export const lastPriceLoad = (symbol) => ({
  type: LAST_PRICE_LOAD,
  symbol
});
export const lastPriceLoadSuccess = (data) => ({
  type: LAST_PRICE_LOAD_SUCCESS,
  data,
});
export const lastPriceLoadFailure = (error) => ({
  type: LAST_PRICE_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchLastPrice(action) {
  try {
    const { symbol } = action
    if (!!symbol) {
      const response = yield axios.get(`${apiURL}/last-trade/${symbol}`, { withCredentials: true }
      );
      yield put(lastPriceLoadSuccess(response.data?.data[0] ? response?.data?.data[0]?.price : '' || []));
    }
  } catch (error) {
    yield put(lastPriceLoadFailure(error));
  }
}

function* listenLastPriceLoad() {
  yield takeEvery(LAST_PRICE_LOAD, fetchLastPrice);
}

// Root Saga
export function* saga() {
  yield all([fork(listenLastPriceLoad)]);
}

const INIT_STATE = {
  lastPrice: ''
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LAST_PRICE_LOAD_SUCCESS:
      return {
        ...state,
        lastPrice: action.data
      };
    default:
      return state;
  }
};

export default reducer;
