import React, { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./style.css";

const PastVideos = ({ selectNavigation }) => {
  const [selectedDropdowns, setSelectedDropdowns] = useState([]);

  const toggleDropdownItem = (value) => {
    let duplicated = [...selectedDropdowns];
    if (duplicated.includes(value)) {
      duplicated = duplicated.filter(item => item !== value);
    } else {
      duplicated.push(value);
    }

    setSelectedDropdowns(duplicated);
  }

  return (
    <div className="past-videos-wrapper">
      <div className="past-videos-dropdown">
        <div className="flex-row no-margin w-100" style={{ justifyContent: 'space-between' }}>
          <span className="past-videos-dropdown-text">Past Videos</span>
          <ExpandMoreIcon />
        </div>
        <div className="past-videos-list">
          <div className={`past-videos-dropdown-option ${selectedDropdowns.includes('recent') ? 'selected' : ''}`} onClick={() => toggleDropdownItem('recent')}>Recent Webinars</div>
          <div className={`past-videos-dropdown-option ${selectedDropdowns.includes('starter') ? 'selected' : ''}`} onClick={() => toggleDropdownItem('starter')}>For Starters</div>
          <div className={`past-videos-dropdown-option ${selectedDropdowns.includes('anaylsis') ? 'selected' : ''}`} onClick={() => toggleDropdownItem('anaylsis')}>Analysis</div>
        </div>
      </div>

      <div className="past-videos-big-title">The Alpha Options Method by Brian Anderson.</div>
      <div className="past-videos-small-title">
        The Alpha Options Method, led by Brian Anderson (also known as "Bronco" Brian), is a comprehensive options trading strategy designed to help traders make consistent profits in the stock market.
        <br /><br />
        This method is based on Brian Anderson's extensive experience and expertise in the options trading industry and focuses on identifying undervalued options and using technical analysis to make informed trading decisions.
        <br /><br />
        The Alpha Options Method emphasizes risk management and provides traders with a step-by-step process for selecting, entering, and exiting trades.
        <br /><br />
        The approach is designed to be flexible, allowing traders to adapt to changing market conditions and take advantage of opportunities as they arise. Brian Anderson provides ongoing support and guidance to traders who follow the Alpha Options Method, helping them to stay on track and achieve their investment goals.
      </div>

      <div className="past-videos-big-title">Key Resources</div>

      <div className="past-videos-blue-title" onClick={() => selectNavigation('real-time-trading-alerts')}>Telegram Trade Alerts</div>
      <div className="past-videos-small-title">Receive timely trade alerts directly on your phone through the Telegram app. Powerful alerts show you what we're buying and selling in real time.</div>

      <div className="past-videos-blue-title" onClick={() => selectNavigation('tutorials', true)}>Tutorials</div>
      <div className="past-videos-small-title">Access a library of tutorials covering a curriculum designed to take you from beginner to pro. Learn new strategies, techniques, and best practices from Brian himself.</div>

      <div className="past-videos-blue-title" onClick={() => selectNavigation('livestream')}>Daily Livestream</div>
      <div className="past-videos-small-title">Tune in to a daily livestream where Brian shares his insights and analysis on the markets. Get a real-time view of market trends and opportunities.</div>

      <div className="past-videos-blue-title" onClick={() => selectNavigation('support', true)}>FAQ</div>
      <div className="past-videos-small-title">Find answers to commonly asked questions about options trading. Get clarification on trading terms, mechanics, and procedures to help you navigate the options market with confidence.</div>
    </div>
  )
}

export default PastVideos;