export const optionsActivityData = [
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
  {
    pair: "USD/GBP",
    timePeriod: "09:34PM - 09:35PM",
    expiry: "01/20/23",
    strike: "233",
    cp: "Puts",
    spot: "181.75",
    details: "300 @ 119",
    premium: "$3.6M",
  },
]