import { all, put, takeEvery, fork, select } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const MINUTEAPI_LOAD = "MA/LOAD/TOP";
export const MINUTEAPI_LOAD_SUCCESS = "MA/LOAD/TOP/SUCCESS";
export const MINUTEAPI_LOAD_FAILURE = "MA/LOAD/TOP/FAILURE";

export const MINUTEAPI_TICKERS_LOAD = "MA/TICKERS/TOP";
export const MINUTEAPI_TICKERS_LOAD_SUCCESS = "MA/TICKERS/TOP/SUCCESS";
export const MINUTEAPI_TICKERS_LOAD_FAILURE = "MA/TICKERS/TOP/FAILURE";

// Action Creators
export const minuteApiLoad = (
  resolution,
  page,
  pageSize,
  activeTicker,
  concat,
  premium
) => ({
  type: MINUTEAPI_LOAD,
  activeTicker,
  resolution,
  page,
  pageSize,
  concat,
  premium
});
export const minuteApiLoadSuccess = (
  data,
  loading,
  page,
  pageSize,
  resolution,
  activeTicker,
  concat
) => ({
  type: MINUTEAPI_LOAD_SUCCESS,
  data,
  loading,
  page,
  pageSize,
  resolution,
  activeTicker,
  concat,
});
export const minuteApiLoadFailure = (error) => ({
  type: MINUTEAPI_LOAD_FAILURE,
  error,
  loading: false,
});

export const minuteApiTickersLoad = () => ({
  type: MINUTEAPI_TICKERS_LOAD,
  tickers: [],
});
export const minuteApiTickersLoadSuccess = (data, loading) => ({
  type: MINUTEAPI_TICKERS_LOAD_SUCCESS,
  data,
  loading,
});
export const minuteApiTickersLoadFailure = (error) => ({
  type: MINUTEAPI_TICKERS_LOAD_FAILURE,
  error,
  loading: false,
});

// Sagas
function* fetchMinuteApi(action) {
  try {
    // extract concat and data formatting to redux 
    // const existingResults = yield select((state) => state.minuteApi.results);
    yield put(
      minuteApiLoadSuccess(
        {
          page: "",
          pageSize: "",
          results: [],
        },
        true,
        1,
        30,
        "1m",
        "AUD/CAD:HSFX",
        false
      )
    );

    const { resolution, page, pageSize, activeTicker, concat, premium } = action;
    const tickers = !!activeTicker.length ? encodeURIComponent(activeTicker.join(",")) : 'all'
    let url = `${apiURL}/forex/minute-scanner`;
    const response = yield axios.get(url, {
      withCredentials: true,
      params: {
        tickers,
        resolution,
        premium,
        pagenumber: page,
        pagesize:pageSize,
      },
    })
    yield put(
      minuteApiLoadSuccess(
        response.data,
        false,
        page,
        pageSize,
        resolution,
        activeTicker,
        concat,
        premium
      )
    );
  } catch (error) {
    yield put(minuteApiLoadFailure(error));
  }
}

function* listenMinuteLoad() {
  yield takeEvery(MINUTEAPI_LOAD, fetchMinuteApi);
}
function* fetchMinuteApiTickers() {
  try {
    yield put(minuteApiTickersLoadSuccess([], true));

    const url = `${apiURL}/forex/scanner/tickers`;
    const response = yield axios.get(url, { withCredentials: true });

    yield put(minuteApiTickersLoadSuccess(response.data, false));
  } catch (error) {
    yield put(minuteApiTickersLoadFailure(error));
  }
}
function* listenMinuteTickersLoad() {
  yield takeEvery(MINUTEAPI_TICKERS_LOAD, fetchMinuteApiTickers);
}

// Root Saga
export function* saga() {
  yield all([fork(listenMinuteLoad), fork(listenMinuteTickersLoad)]);
}

const INIT_STATE = {
  minuteApi: [],
  minuteApiLoading: false,
  page: 1,
  pageSize: 30,
  resolution: "1m",
  activeTicker: "",
  concat: false,
  tickers: [],
  loadingTickers: false,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MINUTEAPI_LOAD_SUCCESS:
      if (action.concat === true) {
        return {
          ...state,
          // minuteApi: [...state.minuteApi, ...action.data.results],
          minuteApi: action.data.results,
          minuteApiLoading: action.loading,
          page: action.page,
          pageSize: action.pageSize,
          resolution: action.resolution,
          activeTicker: action.activeTicker,
          concat: true,
        };
      } else {
        return {
          ...state,
          minuteApi: action.data.results,
          minuteApiLoading: action.loading,
          page: action.page,
          pageSize: action.pageSize,
          resolution: action.resolution,
          activeTicker: action.activeTicker,
          concat: false,
        };
      }

    case MINUTEAPI_LOAD_FAILURE:
      return {
        ...state,
        minuteApi: {
          page: "",
          pageSize: "",
          results: [],
        },
        minuteApiLoading: false,
      };

    case MINUTEAPI_TICKERS_LOAD_SUCCESS:
      return {
        ...state,
        tickers: action.data,
        loadingTickers: action.loading,
      };
    case MINUTEAPI_TICKERS_LOAD_FAILURE:
      return {
        ...state,
        tickers: action.data,
        loadingTickers: false,
      };
    default:
      return state;
  }
};

export default reducer;
