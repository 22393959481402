import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import StoriesCard from "./stories-card/StoriesCard";
import { getStoryLoad } from "../../appRedux/ducks/getStories";
import { connect } from "react-redux";
import { approveStoryLoad } from "../../appRedux/ducks/approveStory";
import { StoryCardSkeleton } from "../common/Skeletons/StoryCardSkeleton";
import { useAuth } from "../../contexts/Auth";
import { makeStyles } from "@material-ui/core";
import "./index.scss";

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.innerBackground,
  },
  boxBackground: {
    background: theme.palette.primary.boxBackground,
  },
  headerTitle: {
    paddingBottom: 16,
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
  },
}));

function Stories({ stories, loading, fetchStories, approveStory }) {
  const navigate = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const approved = (params?.get('approved') || true);
  const { userData } = useAuth();
  const classes = useStyles();

  const hasApproverRole = useMemo(() => {
    return !!userData?.story_approver && approved === 'false'
  }, [userData])

  useEffect(() => {
    !!userData && fetchStories(!hasApproverRole)
  }, [hasApproverRole, userData])

  return (
    <Box
      className={classes.background}
      sx={{
        padding: "16px 16px 0 16px",
      }}
    >
      <Typography className={classes.headerTitle}>
        Success Stories
      </Typography>
      <Box
        className={classes.background}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          className={classes.boxBackground}
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "150px",
            marginBottom: "2rem",
            justifyContent: "space-around",
            padding: "15px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Circular Std'",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "25px",
              letterSpacing: "-0.04em",
              color: "#FFFFFF",
            }}
          >
            Post your stories and win prizes!
          </Typography>
          <Box className="action-button-container">
            <button
              onClick={() => navigate.push("/stories/add-story")}
              className="action-button active-button"
            >
              + Post Story
            </button>
            <button
              className="action-button"
            >
              Official Rules
            </button>
            <button
              className="action-button"
            >
              <span style={{ color: "#00DC0A" }}>New </span>September Winners
            </button>
          </Box>
        </Box>

        <div className={classes.background + ' no-side-padding'}>
          {
            loading ? (
              <StoryCardSkeleton />
            ) : (
              <div className={'row mm-success-cards'}>
                {
                  stories.map((story, idx) => (
                    <StoriesCard key={idx} approveStory={approveStory} approved={hasApproverRole} data={story} />
                  ))
                }
              </div>
            )
          }
        </div>
      </Box>
    </Box>
  );
}

const stateToProps = (state) => ({
  stories: state.getStories.stories,
  loading: state.getStories.loading,
});

const dispatchToProps = (dispatch) => ({
  fetchStories: (approved) => dispatch(getStoryLoad(approved)),
  approveStory: (id) => dispatch(approveStoryLoad(id))
});

export default connect(stateToProps, dispatchToProps)(Stories);