import React, { useMemo, useState } from 'react'
import AnimateOnChange from 'react-animate-on-change'
import './addedRowsAnimations.scss'
function CssPop({ comparator, midpointChange = false }) {
    const [price, setPrice] = useState(comparator)
    const animate = useMemo(() => {
        if(midpointChange) {
            setPrice(comparator)
            return false
        }         
        else return comparator !== price
    },[comparator,midpointChange, price])
    return (
        <AnimateOnChange
            baseClassName=""
            animationClassName={`new-item-options${comparator > price ? '-up' : '-down'}`}
            onAnimationEnd={() => setPrice(comparator)}
            animate={animate}>
            {price}
        </AnimateOnChange>
    )
}

export default CssPop