import React, { useRef, useState, useEffect, useMemo } from "react";
import axios from "axios";
import { AutoComplete } from "antd";
import { SearchOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { apiURL } from "../../../appRedux/actions/helpers";
import { symbolSetter } from "../../../appRedux/ducks/symbol";
import { getMatchBounds } from "../../utilities";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import useDebounce from "./useDebounce";
import { useMedia } from "react-media";
import { useAuth } from "../../../contexts/Auth";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  searchSkelly: {
    width: "312px",
    height: "72px !important"
  },
  backgroundBoxShadow: {
    backgroundColor: theme.palette.primary.contrastBackground,
    // backgroundColor: "rgba(25, 27, 28, 0.5)",
    borderRadius: "20px",

    "& .rc-virtual-list-holder": {
      backgroundColor: theme.palette.primary.contrastBackground,
      boxShadow: theme.palette.primary.boxShadow,
      // boxShadow: "0 -2px 10px rgb(0 0 0)",
      // backgroundColor: "rgba(25, 27, 28, 0.5)",
    },
    "& .ant-select-item-option:hover": {
      backgroundColor: theme.palette.primary.hover,
    },
    "& .ant-select-item-option-active": {
      backgroundColor: `${theme.palette.primary.hover} !important`,
    },
    "& .rc-virtual-list-holder-inner": {
      backgroundColor: theme.palette.primary.contrastBackground,
      opacity: 1,
      // backgroundColor: "#191b1c",
      // boxShadow: theme.palette.primary.boxShadow,
    },
    "& .ant-select-item-option-content": {
      // color: theme.palette.primary.text,
      color: "#FFF",
      //backgroundColor: theme.palette.primary.background
    },
    "& .mr-2 > svg": {
      backgroundColor: "transparent",
      // fill: theme.palette.primary.secondaryText,
      // stroke: theme.palette.primary.secondaryText,
      fill: "#79797B",
      stroke: "#79797B",
    },
  },
  input: {
    backgroundColor: theme.palette.primary.contrastBackground,
    color: theme.palette.primary.text,
  },
  background: {
    backgroundColor: theme.palette.primary.contrastBackground,
    color: theme.palette.primary.text,
  },
  text: {
    color: theme.palette.primary.text,
  },
}));
const CancelToken = axios.CancelToken;
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
const SearchBar = (props) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const { canSeeInternal, loading } = useAuth();
  const classes = useStyles();
  const { popupContainerId, guest = false, setSymbol, themeMode } = props;
  const [isMounted, setIsMounted] = useState(true);
  const [results, setResults] = useState([]);
  const lastAbortController = useRef();
  const searchRef = useRef(null);
  const hardHistory = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const savedSearch = localStorage.getItem("savedSearchMarketMakers");
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);
  const search = async (params, cancelToken) => {
    try {
      const url = `${apiURL}/search/${params}`;
      const response = await axios.get(url, {
        cancelToken: cancelToken.token,
        withCredentials: true,
      });

      if (isMounted) formatSearchResult(response.data, params);

      // if (response.status === 200) {
      // }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.log("error searching", error);
      }
    } finally {
    }
  };

  const formatSearchResult = async (data, value) => {
    value = value.toString().toLowerCase();
    let forex = data
      .filter((item) => item.ticker && item.name)
      .map((s) => {
        return {
          ...s,
          value: !!s.type ? s.ticker : s.site_symbol,
          type: !!s.type
            ? s.type === "common_stock"
              ? "Stock"
              : "ETF"
            : "Forex",
        };
      });

    let newResult = [...forex];
    newResult = newResult
      .map((security) => {
        security.score = getMatchScore(security, value.toLowerCase());
        return security;
      })
      .sort((a, b) => {
        return a.score - b.score;
      });

    // newResult = newResult.filter(
    //   (x) =>
    //     x.name?.toString().toLowerCase().includes(value) ||
    //     x.ticker?.toString().toLowerCase().includes(value)
    // );

    setResults(newResult);
  };

  const getMatchScore = (security, query) => {
    if (security.ticker && security.ticker.toLowerCase() === query) {
      return 0;
    }

    if (security.ticker && security.ticker.toLowerCase().startsWith(query)) {
      return 1;
    }

    if (security.ticker && security.ticker.toLowerCase().includes(query)) {
      return 2;
    }

    if (security.name && security.name.toLowerCase().startsWith(query)) {
      return 3;
    }

    return 4;
  };

  useEffect(() => {
    if (debouncedSearchValue === "") {
      return setResults([]);
    }
    if (lastAbortController.current) {
      lastAbortController.current.cancel("aborted");
    }
    const currentAbortController = CancelToken.source();
    lastAbortController.current = currentAbortController;

    search(debouncedSearchValue, currentAbortController);
  }, [debouncedSearchValue]);

  const handleSearch = (value) => {
    setSearchValue(value.toString().toLowerCase());
  };
  const handleSearchSelect = (value, type) => {
    setSymbol({
      type,
      value,
    });
    const url = `/company/${value}`;
    hardHistory.push(url);
  };
  const handleSelect = (value) => {
    let localOptions = JSON.parse(savedSearch);
    const option = !!results.length
      ? results.filter((item) => item.value === value || item.name === value)
      : localOptions?.filter(
        (item) => item.value === value || item.name === value
      ) || [];
    // check to see if there is a search history object
    if (savedSearch) {
      //if so check if this ticker has already been saved
      const safetyCheck = localOptions?.find(
        (item) => item.value === value || item.name === value
      );
      if (!safetyCheck) {
        // if not set the local storage flag on the obj
        option[0].localStorage = true;
        // add it to the beggining array
        localOptions?.unshift(option[0]);
        // if we already have 10 saved searches then remove the last entry
        if (localOptions?.length === 10) localOptions?.pop();
        // set local storage with the updated set
        localStorage.setItem(
          "savedSearchMarketMakers",
          JSON.stringify(localOptions)
        );
      }
    } else {
      // if there is no history arr then create it with the selected entity and apply the flag
      option[0].localStorage = true;
      localStorage.setItem("savedSearchMarketMakers", JSON.stringify(option));
    }
    const type = "company";
    handleSearchSelect(value.replace("/", "-"), type);
  };

  const getMatchValue = (value) => {
    const items = [];

    while (value) {
      const bounds = getMatchBounds(value, searchValue);

      if (!bounds) {
        items.push({
          value,
          isHighlighted: false,
        });
        break;
      }

      const nonMatch = value.slice(0, bounds.start);
      if (nonMatch) {
        items.push({
          value: nonMatch,
          isHighlighted: true,
        });
      }

      const match = value.slice(bounds.start, bounds.end);
      items.push({
        value: match,
        isHighlighted: true,
      });

      value = value.slice(bounds.end);
    }
    return items;
  };

  const renderHighlight = (value) => {
    return <span className="text-white">{value}</span>;
  };

  const renderName = (value) => {
    const items = getMatchValue(value);
    return items.map((item, index) => {
      return (
        <span key={index}>
          {item.isHighlighted ? renderHighlight(item.value) : item.value}
        </span>
      );
    });
  };

  const renderTicker = (value) => {
    const items = getMatchValue(value);
    return items.map((item, index) => {
      return (
        <span key={index}>
          {/* {item.isHighlighted ? renderHighlight(item.value) : item.value} */}
          {renderHighlight(item.value)}
        </span>
      );
    });
  };

  const renderItemDetails = (icon, ticker, name, type, localStorage) => {
    return (
      <div className="d-flex align-items-center space-between">
        <div className="d-flex space-between">
          <span className="search-dropdown-label justify-flex-start">
            <span className="mr-2 justify-center" style={{ fontSize: 22 }}>
              {icon}
            </span>

            {localStorage ? ticker : renderTicker(ticker)}
          </span>
          <span className="ml-2 mr-2 text-ellipsis space-between first-letter-capitalize">
            {/* {localStorage ? name : renderName(name)} */}
            {name}
          </span>
        </div>

        <div className="ml-auto">
          <span>{type}</span>
        </div>
      </div>
    );
  };

  const renderItem = (item) => {
    return {
      key: Math.random().toString(),
      value: item.value,
      label: renderItemDetails(
        <DollarCircleOutlined />,
        item.ticker,
        item.name,
        item.type,
        item?.localStorage
      ),
    };
  };

  const resultsMemo = useMemo(() => {
    let localOptions = JSON.parse(savedSearch);
    if (!!results.length) return results;
    else if (!!localOptions?.length && !searchValue.length) return localOptions;
    else return [];
  }, [results, savedSearch, searchValue]);
  const options = useMemo(() => {
    return resultsMemo.map((item) => {
      return renderItem(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsMemo]);

  //collapsed one
  if (loading) return <Skeleton className={classes.searchSkelly} />
  return !canSeeInternal ? <></> : (
    <div
      style={{ zIndex: isMobile ? "" : 1000000000 }}
      id={popupContainerId}
      className="search-bar position-relative"
    >
      <AutoComplete
        className={clsx(
          classes.backgroundBoxShadow,
          "search-wrapper border-radius-20"
        )}
        popupClassName={clsx(
          classes.backgroundBoxShadow,
          "search-bar-dropdown"
        )}
        ref={searchRef}
        style={{ width: "100%" }}
        options={options}
        onSelect={handleSelect}
        onSearch={handleSearch}
        getPopupContainer={() => document.getElementById(popupContainerId)}
        children={
          <input
            className={clsx("search-input", classes.input)}
            type="text"
            placeholder={"Search"}
            name="search"
          />
        }
      />
      <SearchOutlined className="search-icon" />
    </div>
  );
};

const dispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});
const stateToProps = (state) => ({
  symbol: state.symbol.symbol,
  themeMode: state.themeMode.themeMode,
});
export default connect(stateToProps, dispatchToProps)(SearchBar);
