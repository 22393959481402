import React, { useMemo, useState } from "react";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, TextField, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Button, IconButton, Input, Tooltip } from "@material-ui/core";
import CancelIcon from '@mui/icons-material/Cancel';
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { ConditionalWrapper } from "../common/Layout/PrivateLayout/SidebarContent";
import { submitStoryLoad } from "../../appRedux/ducks/submitStory";


const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.innerBackground,
  },
  boxBackground: {
    background: theme.palette.primary.boxBackground,
    backgroundColor: theme.palette.primary.boxBackground,
  },
  headerTitle: {
    paddingBottom: 16,
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
  },
  smallTitle: {
    color: theme.palette.primary.text + ' !important',
  },
}));

const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

function AddStory({ submitStory }) {
  const navigate = useHistory();
  const [header, setHeader] = useState("");
  const [main, setMain] = useState("");
  const [name, setName] = useState("")
  const [fileToUpload, setFileToUpload] = useState("");
  const classes = useStyles();

  const disabled = useMemo(() => {
    const headerVal = header.length < 50;
    const mainVal = main.length < 150;
    const fileVal = fileToUpload.length <= 0
    const nameVal = name.length <= 0;
    const buttonVal = headerVal || mainVal || fileVal
    return {
      headerVal, mainVal, fileVal, buttonVal, nameVal
    }
  }, [fileToUpload, header, main, name])

  function handleSubmit() {
    submitStory(fileToUpload, header, main, name)
    setHeader("");
    setMain("");
    setName("")
    setFileToUpload("")
    navigate.push("/stories")
  }
  return (
    <Box
      className={classes.background}
      sx={{
        width: "100%",
        height: "100%",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        onClick={() => navigate.push("/stories")}
      >
        <ChevronLeftIcon className={classes.smallTitle} />
        <Typography
          className={classes.smallTitle + ' curse-point'}
          sx={{
            fontFamily: "Circular Std",
            fontWeight: "450",
            fontSize: "14px",
            lineHeight: 1
          }}
        >
          Stories
        </Typography>
      </Box>
      <Typography
        className={classes.headerTitle}
      >
        Post a Success Story
      </Typography>
      <Box
        className={classes.background}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flexGrow: 1,
          paddingTop: '10vh',
          paddingBottom: "40px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "550px",
            justifyContent: "space-around",
            backgroundColor: "#1F1F21",
            minWidth: "700px",
            margin: "0px auto",
          }}
        >
          <Box
            className={classes.boxBackground}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: 'pointer'
            }}
          >
            {
              !!fileToUpload.length ? (
                <div className="parent-image">
                  <img style={{ height: '200px', width: '200px' }} src={fileToUpload} alt="fileUpload" />
                  <IconButton onClick={() => setFileToUpload("")} className="close-x" aria-label="delete">
                    <CancelIcon />
                  </IconButton>
                </div>
              ) : (
                <>
                  <Box
                    className={classes.background}
                    sx={{
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <Input id="select-image"
                        style={{ display: 'none' }}
                        type="file" inputProps={{ accept: 'image/*' }}
                        onChange={async (event) => {
                          const file = event.target.files[0];
                          const base64 = await getBase64(file)
                          setFileToUpload(base64)
                        }}
                      />
                      <label className="curse-point"
                        htmlFor="select-image" style={{ marginBottom: '0' }}>
                        <CameraAltOutlinedIcon fontSize="large" color="#00DC0A" />
                      </label>
                    </>

                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "'Circular Std'",
                      fontStyle: "normal",
                      fontWeight: "450",
                      fontSize: "14px",
                      lineHeight: "18px",
                      letterSpacing: "-0.02em",
                      color: "#00DC0A",
                      marginTop: "5px",
                    }}
                  >
                    Make your post stand out with a good picture!
                  </Typography>
                </>
              )
            }
          </Box>
          <Box
            className={classes.boxBackground}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                className={classes.background + ' ' + classes.smallTitle}
                sx={{
                  fontFamily: "'Circular Std'",
                  fontStyle: "normal",
                  fontWeight: "450",
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  width: "100%",
                  padding: "10px 0px",
                }}
              >
                Full Name
              </Typography>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="This is my header"
                sx={{
                  input: { color: 'white' },
                  fontFamily: "'Circular Std'",
                  fontStyle: "normal",
                  fontWeight: "450",
                  fontSize: "16px",
                  letterSpacing: "-0.02em",
                  color: "white",
                  width: "100%",
                }}
              />
              <Typography
                className={classes.background + ' ' + classes.smallTitle}
                sx={{
                  fontFamily: "'Circular Std'",
                  fontStyle: "normal",
                  fontWeight: "450",
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  width: "100%",
                  padding: "10px 0px",
                }}
              >
                Story Title
              </Typography>
              <TextField
                value={header}
                onChange={(e) => setHeader(e.target.value)}
                placeholder="This is my header"
                sx={{
                  input: { color: 'white' },
                  fontFamily: "'Circular Std'",
                  fontStyle: "normal",
                  fontWeight: "450",
                  fontSize: "16px",
                  // lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  color: "white",
                  width: "100%",
                  // padding: "10px 0px",
                }}
              />

              <Typography
                className={classes.background + ' ' + classes.smallTitle}
                sx={{
                  fontFamily: "'Circular Std'",
                  fontStyle: "normal",
                  fontWeight: "450",
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  width: "100%",
                  padding: "10px 0px",
                }}
              >
                Main Text
              </Typography>
              <TextField
                value={main}
                onChange={(e) => setMain(e.target.value)}
                placeholder="When I bought stocks in ..."
                sx={{
                  textarea: { color: 'white' },
                  fontFamily: "'Circular Std'",
                  fontStyle: "normal",
                  fontWeight: "450",
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  width: "100%",
                  padding: "10px 0px",
                }}
                multiline
                rows={5}
                maxRows={10}
              />
            </Box>
            <ConditionalWrapper
              condition={disabled.buttonVal}
              wrapper={(children) => (
                <Tooltip title={`${disabled.nameVal ? 'Must enter full name. ' : ''} ${disabled.headerVal ? 'Header must be a minimum of 50 characters. ' : ''} ${disabled.mainVal ? 'Main Text must be a minimum of 150 characters.' : ''} ${disabled.fileVal ? "Must have a picture uploaded to share story." : ""}`}>
                  {children}
                </Tooltip>
              )}
            >
              <Box
                className={classes.background}
                sx={{ cursor: disabled.buttonVal ? 'not-allowed' : 'pointer', zIndex: 2, width: "100%" }}
              >
                <Button
                  onClick={handleSubmit}
                  disabled={disabled.buttonVal}
                  style={{
                    padding: " 16px 24px",
                    background: "#00DC0A",
                    border: "none",
                    fontFamily: "'Circular Std'",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "23px",
                    letterSpacing: "-0.04em",
                    color: "#171917",
                    marginTop: "16px",
                  }}
                >
                  Share Story
                </Button>
              </Box>
            </ConditionalWrapper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const dispatchToProps = (dispatch) => ({
  submitStory: (image, title, description, name) => dispatch(submitStoryLoad(image, title, description, name)),
});

export default connect(null, dispatchToProps)(AddStory);