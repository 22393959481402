import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import { saga as lastPriceSaga } from "../ducks/polyLastPrice";
import { saga as searchSymbols } from "../ducks/search";
import { saga as companyData } from "../ducks/companyData";
import { saga as companyNews } from "../ducks/companyNews";
import { saga as news } from "../ducks/news";
import { saga as newsFilters } from "../ducks/newsFilters";
import { saga as dashboard } from "../ducks/dashboard";
import { saga as aiSentiment } from "../ducks/aiSentiment";
import { saga as userSettings } from "../ducks/userSettings";
import { saga as dxFeedSocket } from "../ducks/dxFeedSocket";
import { saga as forexActivity } from "../ducks/forexActivity";
import { saga as calendar } from "../ducks/calendar";
import { saga as minuteApi } from "../ducks/minuteApi"
import { saga as newLiveOptions } from "../ducks/newLiveOptions"
import { saga as socketSaga } from "../ducks/socket";
import { saga as minuteSnapshot } from "../ducks/minuteSnapshot";
import { saga as submitStory } from "../ducks/submitStory";
import { saga as getStory } from "../ducks/getStories";
import { saga as approveStory } from "../ducks/approveStory";
import { saga as historicDarkflowSaga } from "../ducks/historicalDarkflow"
import { saga as darkflowTableSaga } from "../ducks/darkflowTable"
import { saga as popularStocks } from "../ducks/popularStocks"
import { saga as equitiesSocket } from "../ducks/equitiesSocket"
import { saga as pastStreamsSaga } from "../ducks/pastStreams"
import { saga as addUsernameSaga } from "../ducks/addUsername"
import { saga as optionSparkline } from "../ducks/optionSparklines"
import { saga as equitiesSaga } from "../ducks/equities";
import { saga as optionChainSaga } from "../ducks/optionChain"
import { saga as optionExpiry } from "../ducks/optionExpirations"
import { saga as marketStatusSaga } from "../ducks/marketStatus";
import { saga as docusign } from "../ducks/docusigned"
import { saga as currencyStrength } from "../ducks/currencyStrength";
import { saga as updateFields } from "../ducks/updateFields"
import { saga as forexCompanyData } from "../ducks/forexCompanyData";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    lastPriceSaga(),
    searchSymbols(),
    companyData(),
    companyNews(),
    news(),
    newsFilters(),
    dashboard(),
    userSettings(),
    dxFeedSocket(),
    forexActivity(),
    calendar(),
    aiSentiment(),
    minuteApi(),
    newLiveOptions(),
    socketSaga(),
    minuteSnapshot(),
    submitStory(),
    getStory(),
    approveStory(),
    historicDarkflowSaga(),
    darkflowTableSaga(),
    popularStocks(),
    equitiesSocket(),
    pastStreamsSaga(),
    addUsernameSaga(),
    optionSparkline(),
    equitiesSaga(),
    optionChainSaga(),
    optionExpiry(),
    marketStatusSaga(),
    docusign(),
    currencyStrength(),
    updateFields(),
    forexCompanyData()
  ]);
}
