import React from "react";

import { Link } from "react-router-dom";
import { QuestionCircleOutlined, DoubleRightOutlined } from "@ant-design/icons";

import settings from "../../../config/settings";
import logoIcon from "../../../assets/images/logo.png";
import logoIconWithText from "../../../assets/images/logo-text.png";
import logoDarkIcon from "../../../assets/images/logo-black.png";
import logoWhiteIcon from "../../../assets/images/logo-white.png";
import Button from "../Button";
import { ArrowRightIcon, ChevronRightIcon } from "../Icon";

const resourcesGuide = settings.links.resourcesGuide;

export const Help = (props) => {
  return (
    <a
      href={resourcesGuide}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <QuestionCircleOutlined style={{ fontSize: "19px", color: "#007bff" }} />
    </a>
  );
};

export const TagLink = ({ className, label, to }) => {
  return (
    <a className={`tag-link ${className}`} href={to}>
      {label}
    </a>
  );
};

export const SeeMoreLink = (props) => {
  const { to, label, icon, className } = props;

  return (
    <Link to={to} className={`default-link ${className}`}>
      {label}
      {icon && (
        <DoubleRightOutlined
          style={{ fontSize: "12px", verticalAlign: "middle", marginLeft: 2 }}
        />
      )}
    </Link>
  );
};

SeeMoreLink.defaultProps = {
  icon: true,
  className: ""
};

export const ArrowLink = (props) => {
  const { to, label, className } = props;

  return (
    <Link to={to} className={`${className}`}>
      {label}→
    </Link>
  );
};

export const ChevronButton = (props) => {
  const {
    className,
    labelClassname,
    style,
    type,
    variant,
    size,
    label,
    iconPosition,
    icon,
    onClick
  } = props;

  return (
    <Button
      className={`chev-btn ${className}`}
      labelClassname={labelClassname}
      style={style}
      type={type}
      variant={variant}
      size={size}
      label={label}
      iconPosition={iconPosition}
      icon={!icon ? <ChevronRightIcon/> : icon}
      onClick={onClick}
    />
  );
};

ChevronButton.defaultProps = {
  className: "",
  style: null,
  size: "md"
};

export const ArrowButton = (props) => {
  const {
    className,
    labelClassname,
    style,
    variant,
    size,
    label,
    iconPosition,
    icon,
    onClick
  } = props;

  return (
    <Button
      className={`arrow-btn ${className}`}
      labelClassname={labelClassname}
      style={style}
      variant={variant}
      size={size}
      label={label}
      iconPosition={iconPosition}
      icon={!icon ? <ArrowRightIcon/> : icon}
      onClick={onClick}
    />
  );
};

ArrowButton.defaultProps = {
  className: "",
  style: null,
  size: "md"
};

export const LogoIcon = () => (
  <img src={logoIcon} alt="logo" className="logo-icon" />
);

export const LogoDarkIcon = () => (
  <img src={logoDarkIcon} alt="logo" className="logo-icon" />
);
export const LogoWhiteIcon = () => (
  <img src={logoWhiteIcon} alt="logo" className="logo-icon" />
);
export const LogoIconWithText = () => (
  <img src={logoIconWithText} alt="logo" className="logo-icon" />
);
