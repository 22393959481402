const retirementInsider = {
  siteName: "MarketMakers",
  domain: "",
  emails: {
    support: "",
    legal: ""
  },
  videos: {
    homepage: "s8jp23qt94"
  },
  links: {
    resourcesGuide: ""
  }
};

const portfolioInsider = {
  siteName: "MarketMakers",
  domain: "marketmakers.io",
  emails: {
    support: "",
    legal: ""
  },
  videos: {
    homepage: "s8jp23qt94"
  },
  links: {
    resourcesGuide: ""
  }
};

let currentApp = null;
const app = process.env.REACT_APP_SITE_DOMAIN;

if (app === "marketmakers.io") {
  currentApp = portfolioInsider;
} else {
  currentApp = retirementInsider;
}

export default currentApp;