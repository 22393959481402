  // Action Types
  export const SIDEBAR_SET = "SBO/SET";
  
  // Action Creators
  export const sidebarSetter = (open) => {
    return {
      type: SIDEBAR_SET,
      open
    };
  };
  
  
  const INIT_STATE = {
    open: true
  };
  // Reducer
  const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SIDEBAR_SET:
        return {
          ...state,
          open: action.open,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  