import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import { Button } from "@mui/material";

const MentorshipPromoBanner = ({ link }) => {
  return (
    <div className="mentorship-promo-banner-page">
      <div className="mentorship-promo-banner-content">
        <div className="mentorship-promo-banner-texts">
          <div className="mentorship-promo-banner-title">Market Makers Forex Mentorship Sessions</div>
          <div className="mentorship-promo-banner-info">Book your exclusive Market Makers Forex Mentorship session with our experts now and get 1 on 1 guidance on how to improve your profile!</div>
        </div>
        <Link to={link}>
          <Button className="promo-sign-up-button">Book Now</Button>
        </Link>
      </div>
    </div>
  )
}

export default MentorshipPromoBanner;