import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { forexActivityLoad } from "../../appRedux/ducks/forexActivity";
import { Typography } from "@mui/material";
import moment from "moment";
import MTZ from "moment-timezone";
import { TableSkeleton } from "../common/Skeletons/TableSkeleton";
import "./index.scss";
import MarketMakersTable from "../common/Tables/MarketMakersTable";
import InfiniteScroll from "react-infinite-scroll-component";
import { breadCrumbChange } from "../../appRedux/ducks/breadCrumb";

const createData = (
  pair,
  timePeriod,
  volume,
  totalTrades,
  totalPrice,
  avgPrice
) => {
  return {
    id: "id" + Math.random().toString(16).slice(2),
    pair,
    timePeriod,
    volume,
    totalTrades,
    totalPrice,
    avgPrice,
  };
};

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.innerBackground + ' !important',
  },
  headerTitle: {
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
  },
  text: {
    // color: theme.palette.primary.text + " !important",
    color: "#000000 !important",
  },
}));

const ForexActivity = ({ fetchForexActivity, forexActivity, loading, timezone, breadCrumbChanger }) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState([{
    field: 'timePeriod',
    sort: 'desc'
  }])
  const sortLookup = {
    "pair": (a, b) => (a.event_symbol.toUpperCase() < b.event_symbol.toUpperCase()) ? -1 : (a.event_symbol.toUpperCase() > b.event_symbol.toUpperCase()) ? 1 : 0,
    "volume": (a, b) => Number(a.volume) - Number(b.volume),
    "timePeriod": (a, b) => moment.tz(`${a.date.split('T')[0]} ${a.start_time}`, timezone) - moment.tz(`${b.date.split('T')[0]} ${b.start_time}`, timezone),
    "totalTrades": (a, b) => Number(a.total_trades) - Number(b.total_trades),
    "totalPrice": (a, b) => Number(a.total_price) - Number(b.total_price),
    "avgPrice": (a, b) => Number(a.avg_price) - Number(b.avg_price)
  }
  const columns = [
    {
      field: "pair",
      headerName: "PAIR",
      flex: 1,
      renderCell: (params) => (
        <div className="forex-activity-ticker">
          <Link
            to={`/company/${params.value.replace('/', '-')}`}
            onClick={() => {
              breadCrumbChanger({
                name: 'Forex Activity',
                path: pathname
              })
            }}
          >
            <span className={classes.text}>{params.value}</span>
          </Link>
        </div>
      ),
    },
    {
      field: "timePeriod",
      headerName: "TIME PERIOD",
      flex: 1,
      renderCell: (params) => (
        <span className={classes.text}>{params.value}</span>
      )
    },
    {
      field: "volume",
      headerName: "VOLUME",
      flex: 1,
      renderCell: (params) => (
        <div>
          <span
            style={{
              border: "2px solid #40e548",
              marginRight: "5px",
              padding: "5px",
              color: "#40e548",
            }}
          >
            {params.value.times}
          </span>
          <span className={classes.text}>
            {parseInt(params.value.value).toLocaleString()}
          </span>
        </div>
      ),
    },
    {
      field: "totalTrades",
      headerName: "TOTAL TRADES",
      flex: 1,
      filterable: false,
      renderCell: (params) => (
        <span style={{ position: 'relative', left: '33px' }} className={classes.text}>{params.value}</span>
      )
    },
    {
      field: "totalPrice",
      headerName: "TOTAL PREMIUM",
      flex: 1,
      filterable: false,
      renderCell: ({ value }) => (
        <span className={classes.text}>
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: value.raw_symbol.substring(0, 3),
          }).format(Number(value.total_price).toFixed(2))}
        </span>

      ),
    },
    {
      field: "avgPrice",
      headerName: "AVG. PRICE",
      flex: 1,
      renderCell: (params) => (
        <span style={{ position: 'relative', left: '18px' }} className={classes.text}>{params.value}</span>
      ),
      valueParser: (value, params) => {
        return value.value;
      },
    },
  ];

  useEffect(() => {
    fetchForexActivity(pageSize, true);
  }, [pageSize]);

  const getMoreRows = useCallback(() => {
    fetchForexActivity(pageSize, false)
  }, [fetchForexActivity]);
  useEffect(() => {
    let processedRows = [];
    forexActivity.sort(sortLookup[sortModel[0].field]).map((row) => {
      const startTimeEST = `${row.date.split('T')[0]} ${row.start_time.split(' ')[0]}`
      const utcTime = MTZ(startTimeEST, 'YYYY-MM-DD HH:mm:ss', 'America/New_York').utc();
      const timezoneConvertedStart = moment.tz(utcTime, timezone).format('HH:mm A')
      const endTimeEST = `${row.date.split('T')[0]} ${row.end_time.split(' ')[0]}`
      const utcTime2 = MTZ(endTimeEST, 'YYYY-MM-DD HH:mm:ss', 'America/New_York').utc();
      const timezoneConvertedEnd = moment.tz(utcTime2, timezone).format('HH:mm A')
      const timePeriod = `${timezoneConvertedStart} - ${timezoneConvertedEnd}`
      processedRows.push(
        createData(
          row.raw_symbol,
          timePeriod,
          {
            times: Number(row.multiplier).toFixed(2) + "x",
            value: row.volume,
          },
          row.total_trades,
          {
            total_price: row.total_price,
            raw_symbol: row.raw_symbol,
          },
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: row.raw_symbol.substring(0, 3),
          }).format(Math.round(row.avg_price * 100) / 100)
        )
      )
    }
    );
    if (sortModel[0].sort === 'desc') {
      processedRows = processedRows.reverse();
    }
    setData(processedRows)
  }, [forexActivity, sortModel, timezone]);

  return (
    <Box className={classes.background}>
      <InfiniteScroll
        dataLength={data.length}
        next={getMoreRows}
        hasMore={true}
        scrollableTarget="scrollable-target"
        style={{ overflow: 'none' }}
      >
        <Typography
          style={{ padding: '16px' }}
          className={classes.headerTitle}
        >
          Forex Unusual Activity
        </Typography>

        {
          loading && !data.length ? (
            <div style={{ padding: "0 16px" }}>
              <TableSkeleton />
            </div>
          )
            : <MarketMakersTable loading={false} sortModel={sortModel} setSortModel={setSortModel} rows={data} columns={columns} hasRowClassName={false} />
        }
      </InfiniteScroll>
    </Box>
  );
};

const stateToProps = (state) => ({
  forexActivity: state.forexActivity.data,
  loading: state.forexActivity.forexActivityLoading,
  timezone: state.userSettings.userSettings.timezone,
});

const dispatchToProps = (dispatch) => ({
  fetchForexActivity: (pageSize, firstLoad) => dispatch(forexActivityLoad(pageSize, firstLoad)),
  breadCrumbChanger: (change) => dispatch(breadCrumbChange(change)),
});

export default connect(stateToProps, dispatchToProps)(ForexActivity);
