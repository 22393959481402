import React, { } from "react";

import VerticalCard from './VerticalCard';

const CardGridViewer = ({ cards, timeStamp }) => {

  return (
    <div className="card-grid-viewer">
      {
        cards?.map((card, key) => (
          <div key={key} className='vertical-card-wrapper'>
            <VerticalCard card={card} timeStamp={timeStamp} />
          </div>

        ))
      }
    </div>
  );
};

export default CardGridViewer;
