import React, { useState } from "react";
import SignUpHint from "../AlphaOptions/SignUp/SignUpHint/SignUpHint";

import PreviousStreams from "../PreviousStreams";

import AlphaYoutube from "../../assets/images/mike-video.png";
import AuthorAvatar from "../../assets/images/mike.png";
import PreviousStreamImg from "../../assets/images/mike-previous-stream.png";

import "./style.scss";

const TradeWithMike = () => {

  const [streams, setStreams] = useState([
    {
      src: PreviousStreamImg,
      date: 'Tue, Feb 28, 2023 2:00 PM',
      duration: '56m',
    },
    {
      src: PreviousStreamImg,
      date: 'Tue, Feb 28, 2023 2:00 PM',
      duration: '56m',
    },
    {
      src: PreviousStreamImg,
      date: 'Tue, Feb 28, 2023 2:00 PM',
      duration: '56m',
    },
    {
      src: PreviousStreamImg,
      date: 'Tue, Feb 28, 2023 2:00 PM',
      duration: '56m',
    },
    {
      src: PreviousStreamImg,
      date: 'Tue, Feb 28, 2023 2:00 PM',
      duration: '56m',
    },
    {
      src: PreviousStreamImg,
      date: 'Tue, Feb 28, 2023 2:00 PM',
      duration: '56m',
    },
  ])
  return (
    <div className="trade-with-mike-page">
      <div className="w-70 left-part">
        <div className='trade-with-mike-page-title'>Trade With Mike Andrews</div>
        <div className='trade-with-mike-page-short-description'>Mike Andrews is an international Forex trading specialist. Because of his expertise in next-generation trading technologies, high-net-worth (HNW) and ultra-high-net-worth clients routinely seek his advice. His expertise includes algorithms, high-frequency trading, and machine learning – making him widely seen as one of the top investment minds in machine trading.</div>

        <div className='trade-with-mike-page-body'>
          <div className='trade-with-mike-page-main-view'>
            <div className='trade-with-mike-page-live-streams-content' id="live-stream-section">
              <img src={AlphaYoutube} alt="live-stream-vidoe" className='alpha-youtube' />

              <div className='trade-with-mike-page-live-streams-info'>
                <div className='trade-with-mike-page-live-streams-info-header'>
                  <img src={AuthorAvatar} alt="Brian" className='live-streams-author' />
                  <div className='trade-with-mike-page-live-streams-author-info'>
                    <div className='trade-with-mike-page-live-streams-author-name'>Mike Andrews</div>
                    <div className='trade-with-mike-page-live-streams-author-job'>MarketMakers Forex Trading Specialist</div>
                  </div>
                </div>

                <div className='trade-with-mike-page-live-streams-info-content'>
                  <div className='trade-with-mike-page-live-streams-title'>Live Trading Sessions</div>
                  <div className='trade-with-mike-page-live-streams-description'>
                    Watch and Learn how a professional trader thinks. Trades and reads the markets every day of the week and see how Mikes trades are placed in the marketplace. See his every set up and trade side by side with an FX veteran. Boost your learning curve and experience trading the financial markets with Mike Andrews.
                    <br /><br />
                    Password for the livestreams: MAPA
                  </div>
                </div>

                <div className='trade-with-mike-page-live-streams-schedule'>
                  <div className='trade-with-mike-page-live-streams-schedule-title'>Live Schedule</div>
                  <div className='trade-with-mike-page-live-streams-schedule-list'>
                    <div className='trade-with-mike-page-live-streams-schedule-day-item'>
                      <div className='trade-with-mike-page-live-streams-schedule-day'>Monday</div>
                      <div className='trade-with-mike-page-live-streams-schedule-time'>07:00pm EST</div>
                    </div>

                    <div className='trade-with-mike-page-live-streams-schedule-day-item'>
                      <div className='trade-with-mike-page-live-streams-schedule-day'>Tuesday</div>
                      <div className='trade-with-mike-page-live-streams-schedule-time'>02:00pm EST</div>
                    </div>

                    <div className='trade-with-mike-page-live-streams-schedule-day-item'>
                      <div className='trade-with-mike-page-live-streams-schedule-day'>Wednesday</div>
                      <div className='trade-with-mike-page-live-streams-schedule-time'>07:00pm ESTT</div>
                    </div>

                    <div className='trade-with-mike-page-live-streams-schedule-day-item'>
                      <div className='trade-with-mike-page-live-streams-schedule-day'>Thursday</div>
                      <div className='trade-with-mike-page-live-streams-schedule-time'>07:00pm EST</div>
                    </div>

                    <div className='trade-with-mike-page-live-streams-schedule-day-item'>
                      <div className='trade-with-mike-page-live-streams-schedule-day'>Friday</div>
                      <div className='trade-with-mike-page-live-streams-schedule-time'>07:00pm EST</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="trade-with-mike-page-previous-streams">
              <PreviousStreams streams={streams} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-30 right-part">
        <SignUpHint url="" />
      </div>
    </div>
  )
}

export default TradeWithMike;