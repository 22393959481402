import { useEffect, useMemo, useRef, useState } from "react";
import "../robinhood.css";
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  // on update of price, set previous Last Price array and value
  return ref.current;
}
export default function RobinhoodDigits({ lastPrice, ticker }) {
  // flag to change className of memo greenIntUp
  const [flag, setFlag] = useState(true);
  // flag to change className of memo redIntDown
  const [flag2, setFlag2] = useState(true);
  // create array of digits to compare with previous array of digits
  const currentArr = useMemo(
    () => lastPrice?.toString()?.split("") || [],
    // eslint-disable-next-line
    [lastPrice, ticker]
  );
  // previous price
  const prev = usePrevious(lastPrice) || "";
  // previous array of digits
  const prevArr = usePrevious(currentArr) || [];
  const className = useMemo(() => {
    // determine direction digit has moved
    if (lastPrice > prev) {
      if (flag) {
        setFlag(false);
        return "greenIntUp2";
      } else {
        setFlag(true);
        return "greenIntUp";
      }
    } else {
      if (flag2) {
        setFlag2(false);
        return "redIntDown2";
      } else {
        setFlag2(true);
        return "redIntDown";
      }
    }
    // eslint-disable-next-line
  }, [lastPrice, prev]);
  return currentArr.map((el, i) => {
    // apply className to el (digit) in array
    return (
      <p
        key={i}
        className={
          currentArr[i] !== prevArr[i]
            ? !!prevArr.length
              ? className
              : ""
            : ""
        }
      >
        {el}
      </p>
    );
  });
}
