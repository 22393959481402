import { styled } from "@mui/material/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Box, Slider, Typography } from "@mui/material";
import LiveOptionsCard from "./LiveOptionsCard";
import MarketMakersTable from "../common/Tables/MarketMakersTable";
import { liveOptionsLoad } from "../../appRedux/ducks/newLiveOptions";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { socketListen } from "../../appRedux/ducks/socket";
import { searchLoad } from "../../appRedux/ducks/search";
import { breadCrumbChange } from "../../appRedux/ducks/breadCrumb";
import { connect } from "react-redux";
import usePrevious from "../../hooks/usePrevious";
import BBi from "../../assets/images/liveOptions/BBi.png"
import AAi from "../../assets/images/liveOptions/AAi.png"
import LAi from "../../assets/images/liveOptions/LAi.png"
import LBi from "../../assets/images/liveOptions/LBi.png"
import { Link } from "react-router-dom";
import "../common/Tables/liveOptionsTable.css";
import "../common/Tables/animations/addedRowsAnimations.scss"
import { useMedia } from "react-media";
import clsx from "clsx";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import NumAbbr from "number-abbreviate";
import useDebounce from "../common/SearchBar/useDebounce";
import { RangePicker } from "./RangePicker";
import "./index.scss"
import AutoCompleteSearch from "./AutoCompleteSearch";
import { TableSkeleton } from "../common/Skeletons/TableSkeleton";
import { SnapshotSkeleton } from "../common/Skeletons/SnapshotSkeleton";
import BidAskAutoComplete from "./BidAskAutoComplete";
export const abbreviate = (x) =>
  new NumAbbr(["K", "M", "B", "T"]).abbreviate(x, 1);
const calcFlowSentimentLevel = (sentiment) => {
  // A) 0 ~ 0.48 = Bearish
  // B) 0.48 - 0.52 = Neutral
  // C) 0.52 - 1.0 = Bullish
  sentiment = sentiment ? sentiment : 0;

  if (sentiment >= 0 && sentiment < 0.48) {
    return "Bearish";
  }

  if (sentiment >= 0.48 && sentiment <= 0.52) {
    return "Neutral";
  }

  if (sentiment > 0.52 && sentiment <= 1) {
    return "Bullish";
  }
};
const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.innerBackground + ' !important',
    width: "100%",
    height: "100%"
  },
  LA: {
    backgroundColor: 'rgba(220, 0, 78, 0.8)',
  },
  LB: {
    backgroundColor: "#EA580C",
  },
  BB: {
    backgroundColor: "#2563EB",
  },
  AA: {
    backgroundColor: " #0D9488",
  },
  LAi: {
    color: 'rgba(220, 0, 78, 0.8)',
  },
  LBi: {
    color: "#EA580C",
  },
  BBi: {
    color: "#2563EB",
  },
  AAi: {
    color: " #0D9488",
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
  tableText: {
    // color: theme.palette.primary.text + " !important",
    color: "#000000 !important",
  },
  popper: {
    "& .MuiSelectUnstyled-root": {
      background: theme.palette.primary.select,
      color: theme.palette.primary.text
    },
    "& .MuiSelectUnstyled-listbox ": {
      "fontFamily": "IBM Plex Sans,sans-serif",
      "fontSize": "14px",
      boxSizing: "borderBox",
      padding: "5px",
      margin: "0px 0",
      minWidth: "256px",
      background: "#F1F5F9",
      borderRadius: "24px",
      color: "black",
      overflow: "auto",
      outline: "0px",
    }
  },
  headerTitle: {
    padding: 16,
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
  },
}));
const today = new Date();
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const styles = {
  sliderTitle: {
    fontFamily: "'Circular Std'",
    fontStyle: "normal",
    fontWeight: "450",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "-0.02em",
    color: "#8E8E93",
  },
};

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const marks = [
  {
    value: 0,
  },
  {
    value: 20,
  },
  {
    value: 37,
  },
  {
    value: 100,
  },
];

const CustomSliderPremium = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#00DC0A",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },

  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#2F2E31",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#2F2E31",
    height: 8,
    width: 8,
    borderRadius: 20,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#00DC0A",
    },
  },
}));
const CustomSliderCandle = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#00DC0A",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },

  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#2F2E31",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#2F2E31",
    height: 8,
    width: 8,
    borderRadius: 20,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#00DC0A",
    },
  },
}));
const valuetext = (value) => {
  return <span className="slider-tooltip"> {abbreviate(value)} </span>;
};
function LiveOptionsContainer({
  themeMode,
  listen,
  searchedSymbols,
  loading,
  searchAllSymbols,
  company = false,
  symbols,
  setSymbolRedux,
  liveFetchOptions,
  newLiveOptions,
  optionsLoading,
  summary,
  timezone,
  breadCrumbChanger
}) {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const classes = useStyles();
  const [bidAsk, setBidAsk] = useState([])

  const [show, setShow] = useState(false);
  const [rangeString, setRangeString] = useState('')
  const { pathname } = useLocation();
  const [dateRange, setDateRange] = useState({
    startDate: today,
    endDate: today,
    key: "selection",
  });
  const params = useParams();
  const all = params['0']?.split('/') || {}
  const allParams = useMemo(() => {
    //  live-options/35000/730/tickers/date
    if (!company) {
      if (!!all[3]) {
        let [prem, expiry, tickers, dateRange] = all
        return {
          prem,
          expiry,
          tickers,
          dateRange
        }
      } else if (!!all[2]) {
        let [prem, expiry, tickers] = all
        return {
          prem,
          expiry,
          tickers
        }
      } else {
        let [prem, expiry] = all
        return {
          prem,
          expiry
        }
      }
    }
  }, [all, company])
  const [minPremium, setMinPremium] = useState(!!allParams?.prem ? Number(allParams?.prem) : 35000);
  const [expiresWithin, setExpiresWithin] = useState(!!allParams?.expiry ? Number(allParams?.expiry) : 730);
  const debouncedExpire = useDebounce(expiresWithin, 400);
  const debouncedPrem = useDebounce(minPremium, 400);
  const isForex = useMemo(() => {
    return symbols[3] === '-' || symbols[3] === '/'
  }, [symbols])
  const cardData = useMemo(() => {
    if (!!summary?.call_count) {
      const { call_total_prem, put_to_call, put_total_prem } = summary
      const totalPremium =
        parseInt(put_total_prem) + parseInt(call_total_prem);
      const putPremiumRatio = parseInt(put_total_prem) / totalPremium;
      const callPremiumRatio = parseInt(call_total_prem) / totalPremium;
      return [
        { title: "Flow Sentiment", value: calcFlowSentimentLevel(callPremiumRatio), percentage: Number(callPremiumRatio * 100).toFixed(2) },
        {
          title: "Put to Call Flow", value: put_to_call === -1 || put_to_call === Infinity
            ?
            1900
            : put_to_call
              ? put_to_call.toFixed(3)
              : 0, percentage: put_to_call === -1 || put_to_call === Infinity
                ? 100
                : put_to_call
                  ? (put_to_call * 100).toFixed(1)
                  : 0
        },
        { title: "Call Premium", value: abbreviate(call_total_prem), percentage: (callPremiumRatio * 100).toFixed(1) },
        { title: "Put Premium", value: abbreviate(put_total_prem), percentage: (putPremiumRatio * 100).toFixed(1) },
      ];
    }
    return []
  }, [summary])
  const dateRangeString = useMemo(() => {
    const todayMoment = moment().format("YYYY-MM-DD");
    if (
      moment(dateRange.startDate).format("YYYY-MM-DD") === todayMoment &&
      moment(dateRange.endDate).format("YYYY-MM-DD") === todayMoment
    ) {
      return "Today's Contracts";
    } else {
      setBidAsk('1')
      return `${moment(dateRange.startDate).format("MMM Do")} - ${moment(
        dateRange.endDate
      ).format("MMM Do")} Contracts`;
    }
  }, [dateRange]);
  const prevRange = usePrevious(dateRangeString)
  const prevBidAsk = usePrevious(bidAsk)
  const [symbol, setSymbol] = useState("");
  const [tickerString, setTickerString] = useState('');
  const [ticker, setTicker] = useState([]);
  const [open, setOpen] = useState(false);
  const debouncedSymbol = useDebounce(symbol, 500);
  const hide = useMemo(() => {
    return company && !optionsLoading && minPremium === 35000 && expiresWithin === 730 && newLiveOptions.length === 0 && dateRangeString === `Today's Contracts` && bidAsk === "1"
  }, [company, debouncedPrem, debouncedExpire, newLiveOptions, dateRangeString, bidAsk])
  function rowClassName(params) {
    return `${params.row.newItem ? `new-item-${themeMode}` : ""}`
  }
  function IconAndTooltip(bid, ask, fillPrice, isMobile) {
    let text, Icon, tooltip
    if ((ask - fillPrice) < (fillPrice - bid)) {
      text = "LA"
      tooltip = "Leaning Ask, Price Filled leaning Ask"
      Icon = LAi
    }
    if ((ask - fillPrice) > (fillPrice - bid)) {
      text = "LB"
      tooltip = "Leaning Bid, Price Filled leaning Bid"
      Icon = LBi
    }
    if (ask <= fillPrice) {
      text = "AA"
      tooltip = "Above Ask, Price Filled at or Above Ask"
      Icon = AAi
    }
    if (bid >= fillPrice) {
      text = "BB"
      tooltip = "Below Bid, Price Filled at or Below Bid"
      Icon = BBi
    }
    if (Number(ask - fillPrice) === Number(fillPrice - bid)) return <> </>
    return (
      <Tooltip classes={{
        tooltip: classes[text],
        arrow: classes[(text + "i")]
      }} title={tooltip} placement="top" arrow>
        <span style={{ position: 'relative', left: isMobile ? '-6px' : '' }} className={`bid-ask-base ${text}`}>{text}<img src={Icon} className="BBi" alt="BBi" /></span>
      </Tooltip>
    )
  }
  const columns = useMemo(() => {
    let base = [
      {
        field: "time",
        headerName: "Time",
        renderHeader: () => <span className="table-headers2">Time</span>,
        renderCell: (params) => (
          <span className={clsx(classes.tableText, `time  ${!isMobile && 'large-table'}`)}>
            {moment(Number(params.value) * 1000).isSame(moment(), 'Day') ? moment.tz(Number(params.value) * 1000, timezone).format("hh:mm:ss A") : moment.tz(Number(params.value) * 1000, timezone).format("MM/DD hh:mm:ss A")}
          </span>
        ),
        flex: 1.5,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "ticker",
        headerName: "Ticker",
        renderHeader: (params) => <span className="table-headers">Ticker</span>,
        renderCell: (params) => (
          <span
            className="ticker"
            style={{ position: 'relative', left: '-3px' }}
            onClick={() => {
              setSymbolRedux({
                type: "",
                value: params.value,
              });

              breadCrumbChanger({
                name: 'Live Options',
                path: pathname
              })
            }}
          >
            <Link to={`/company/${params.value}`} className={clsx(classes.tableText, "ticker")}>
              <span
                // aria-id="option-ticker"
                className={clsx(classes.tableText, "ticker")}>
                {params.value}
              </span>
            </Link>
          </span>
        ),
        flex: 1,
      },
      {
        field: "exp",
        headerName: "Expiry",
        flex: 1,
        renderHeader: () => <span className="table-headers">Expiry</span>,
        renderCell: (params) => (
          <span className={clsx(classes.tableText, "strike")}>
            {moment.utc(params.value).format("MM/DD/YY")}
          </span>
        ),
      },
      {
        field: "strike",
        headerName: "Strike",
        renderHeader: () => <span className="table-headers">Strike</span>,
        renderCell: (params) => <span className={clsx(classes.tableText, "strike")}>{params.value}</span>,
        flex: 1,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },

      {
        field: "cp",
        headerName: "C/P",
        flex: 1,
        renderHeader: () => <span className="table-headers">C/P</span>,

        renderCell: (params) => (
          <span
            className={`cp ${params.value === "C" ? "green-call" : "red-put"}`}
          >
            {params.value === "C" ? "Calls" : "Puts"}
          </span>
        ),
      },
      {
        field: "spot",
        headerName: "Spot",
        flex: 1,
        renderHeader: () => <span className="table-headers">Spot</span>,
        renderCell: (params) => (
          <span className={classes.tableText} style={{ marginLeft: '3px' }}>{Number(params.value).toFixed(2)}</span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "bid_price",
        headerName: "Bid",
        flex: 1,
        renderHeader: () => <span className="table-headers">Bid</span>,
        renderCell: (params) => (
          <span className={classes.tableText} style={{ marginLeft: '3px' }}>{Number(params.value).toFixed(2)}</span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "contract_quantity",
        headerName: "Details",
        renderHeader: () => <span className="table-headers">Details</span>,
        renderCell: (params) => {

          return (
            <span style={{ position: 'relative', left: '-3px' }} className={clsx(classes.tableText, "ppc")}>
              {Math.round(params.row.contract_quantity) +
                " @ " +
                Number(params?.row?.price_per_contract || 0)?.toFixed(2) || ""}
              {isMobile && <br />}
              {dateRangeString.includes('Today') && (IconAndTooltip(Number(params.row.bid_price), Number(params.row.ask_price), Number(params.row.price_per_contract), isMobile))}
            </span>
          )
        },
        flex: 1.3,
      },
      {
        field: "ask_price",
        headerName: "Ask",
        flex: 1,
        renderHeader: () => <span className="table-headers">Ask</span>,
        renderCell: (params) => (
          <span className={classes.tableText} style={{ marginLeft: '3px' }}>{Number(params.value).toFixed(2)}</span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "type",
        headerName: "Type",
        renderHeader: () => <span className="table-headers">Type</span>,
        renderCell: (params) => <span className={clsx(classes.tableText, "type-cell")}>{params.value}</span>,
        flex: 1,
      },
      {
        field: "prem",
        headerName: "Premium",
        flex: 1,
        renderHeader: () => <span className="table-headers">Premium</span>,
        renderCell: ({ value }) => (
          <span
            style={{
              border: "2px solid #40e548",
              // marginRight: "5px",
              position: 'relative',
              left: '7px',
              top: '3px',
              padding: "5px",
              textAlign: "center",
              width: "70px",
              color: "#40e548",
            }}
          >
            {`${abbreviate(value)}`}
          </span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
    ];
    if (!dateRangeString.includes('Today')) {
      base.splice(6, 1)
      base.splice(7, 1)
    }
    return base
  }, [isMobile, dateRangeString, classes, timezone])
  const [sortModel, setSortModel] = useState([
    {
      field: "time",
      sort: "desc",
    },
  ]);
  useEffect(() => {
    company ? listen([`options;${symbols}`, 'CHAT']) : listen(["options", 'CHAT']);
    return () => listen([]);
  }, [symbols, company]);
  useEffect(() => {
    return () => {
      liveFetchOptions(35000, 730, false, '', '', true, sortModel[0], bidAsk)
    }
  }, [])
  const prevValues = useMemo(() => {
    return (prevBidAsk !== bidAsk || prevRange !== dateRangeString)
  }, [prevBidAsk, bidAsk, prevRange, dateRangeString])
  useEffect(() => {
    if (company) {
      !!symbols && liveFetchOptions(debouncedPrem, debouncedExpire, false, rangeString, symbols, prevValues, sortModel[0], bidAsk)
    } else {
      liveFetchOptions(debouncedPrem, debouncedExpire, false, rangeString, tickerString, prevValues, sortModel[0], bidAsk)
    }
  }, [debouncedPrem, debouncedExpire, rangeString, tickerString, company, symbols, sortModel, bidAsk, prevValues]);
  const getMoreRows = useCallback(() => {
    liveFetchOptions(debouncedPrem, debouncedExpire, true, rangeString, company ? symbols : tickerString, false, sortModel[0], bidAsk)
  }, [debouncedPrem, debouncedExpire, rangeString, tickerString, liveFetchOptions, company, symbols, sortModel, bidAsk]);

  useEffect(() => {
    searchAllSymbols(debouncedSymbol);
  }, [debouncedSymbol]);

  useEffect(() => {
    if (
      moment().format("YYYY-MM-DD") ===
      moment(dateRange.startDate).format("YYYY-MM-DD")
    ) {
      setRangeString("");
    } else {
      setRangeString(
        `${moment
          .tz(dateRange.startDate, "America/New_York")
          .format("YYYY-MM-DD")},${moment
            .tz(dateRange.endDate, "America/New_York")
            .format("YYYY-MM-DD")}`
      );
    }
  }, [JSON.stringify(dateRange)]);
  if (isForex && pathname.includes('company')) return null
  return (
    <Box className={classes.background}>
      <InfiniteScroll
        dataLength={newLiveOptions.length}
        next={getMoreRows}
        hasMore={true}
        scrollableTarget="scrollable-target"
        style={{ overflow: 'none' }}
      >
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Typography className={classes.headerTitle}>Live Options</Typography>
          <div
            className="live-options-inner-content"
          >
            <div
              className="live-options-sliders-wrapper"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CustomSliderPremium
                  size="small"
                  min={35000}
                  max={10000000}
                  valueLabelFormat={valuetext}
                  value={minPremium}
                  onChange={(e) => setMinPremium(e.target.value)}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  sx={{ color: "#00DC0A" }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={styles.sliderTitle}>
                    Minimum Premium
                  </Typography>
                  <Typography style={styles.sliderTitle}> 10M</Typography>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CustomSliderCandle
                  min={1}
                  max={730}
                  size="small"
                  value={expiresWithin}
                  onChange={(e) => setExpiresWithin(e.target.value)}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  sx={{ color: "#00DC0A" }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={styles.sliderTitle}>
                    Expires Within
                  </Typography>
                  <Typography style={styles.sliderTitle}> 730 days</Typography>
                </div>
              </div>
            </div>
            <div className="live-options-filter-container">

              <div
                style={{ zIndex: show ? 2 : 1 }}
                className={"live-option-date-filter"}
              >
                <span className={clsx(classes.text, "dates")}>
                  {dateRangeString}
                  <IconButton
                    className="calendar-button"
                    onClick={() => setShow(!show)}
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 4.98389H5C3.89543 4.98389 3 5.87932 3 6.98389V20.9839C3 22.0885 3.89543 22.9839 5 22.9839H19C20.1046 22.9839 21 22.0885 21 20.9839V6.98389C21 5.87932 20.1046 4.98389 19 4.98389Z"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 10.9839H21"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 2.98389V6.98389"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 2.98389V6.98389"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </IconButton>
                  <>
                    {show && (
                      <RangePicker
                        style={{ gridArea: "1 / 1 / 2 / 2" }}
                        selectedRange={dateRange}
                        onRangeSelect={(r) => {
                          setDateRange(r);
                          if (
                            moment(r.endDate).diff(
                              moment(r.startDate),
                              "days"
                            ) >= 1
                          ) {
                            setTimeout(() => {
                              setShow(!show);
                            }, 500);
                          }
                        }}
                      />
                    )}
                  </>
                </span>
              </div>
              <div className="live-option-search-inputs">
                {
                  !company && (
                    <AutoCompleteSearch
                      searchedSymbols={searchedSymbols}
                      value={symbol}
                      setSymbol={setSymbol}
                      symbol={symbol}
                      tickers={ticker}
                      loading={loading}
                      setTicker={setTicker}
                      setTickerString={setTickerString}
                    />
                  )
                }
                {
                  dateRangeString.includes("Today") && (
                    <BidAskAutoComplete bidAsk={bidAsk} setBidAsk={setBidAsk} />
                  )
                }
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                padding: "0px 15px",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginBottom: 24,
              }}
            >
              {
                optionsLoading ? (
                  <SnapshotSkeleton />
                ) : (
                  cardData.map((item, idx) => {
                    return <LiveOptionsCard key={idx} data={item} />;
                  })
                )
              }
            </div>
          </div>
        </div>
        {
          optionsLoading && !newLiveOptions.length ? <TableSkeleton /> : <MarketMakersTable sortModel={sortModel} setSortModel={setSortModel} rows={newLiveOptions} columns={columns} loading={false} hasRowClassName={true} rowClassName={rowClassName} />
        }
      </InfiniteScroll>
    </Box>
  );
}
const stateToProps = (state) => ({
  searchedSymbols: state.searchSymbols.searchSymbols,
  loading: state.searchSymbols.symbolsLoading,
  symbols: state.symbol.symbol.value,
  newLiveOptions: state.newLiveOptions.liveOptions,
  optionsLoading: state.newLiveOptions.loading,
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
  summary: state.newLiveOptions.summary,
  timezone: state.userSettings.userSettings.timezone,

});

const dispatchToProps = (dispatch) => ({
  searchAllSymbols: (symbol) => dispatch(searchLoad(symbol)),
  listen: (channels) => dispatch(socketListen(channels)),
  setSymbolRedux: (symbol) => dispatch(symbolSetter(symbol)),
  breadCrumbChanger: (change) => dispatch(breadCrumbChange(change)),
  liveFetchOptions: (tickers, prem_min, exp_within, last_time, date, clearCache, sortModel, bidAskFilter) => dispatch(liveOptionsLoad(tickers, prem_min, exp_within, last_time, date, clearCache, sortModel, bidAskFilter)),
});

export default connect(stateToProps, dispatchToProps)(LiveOptionsContainer);