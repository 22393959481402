import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { aiSentimentLoad } from "../../appRedux/ducks/aiSentiment";
import aiGauge from "../../assets/images/aiGauge.png"
import "./ai-sentiment.scss";
import union from "../../assets/images/Union.png"

const classLookup = {
  'Strong Buy': 'buy',
  "Buy": 'buy',
  "Neutral": 'neutral',
  "Sell": 'sell',
  'Strong Sell': 'sell'
}

const AISentiment = ({ ticker, fetchAISentiment, loading, sentiment }) => {
  const [active, setActive] = React.useState('4h');
  const value = useMemo(() => {
    if (sentiment !== undefined && sentiment !== null) {
      if (sentiment >= 25) return 'Strong Buy';
      if (sentiment < 25 && sentiment >= 5) return 'Buy';
      if (sentiment < 5 && sentiment >= -5) return 'Neutral';
      if (sentiment < -5 && sentiment > -25) return 'Sell';
      if (sentiment <= -25) return 'Strong Sell';
    } else {
      return 'Strong Sell'
    }
  }, [sentiment]);


  useEffect(() => {
    if (ticker) {
      fetchAISentiment(ticker, active);
    }
  }, [ticker, active, fetchAISentiment]);
  return (
    <div className="ai-sentiment-page">
      <div className="ai-sentiment-title">AI Sentiment</div>
      <div className="ai-sentiment-time-slots">
        <div className="ai-sentiment-time-slots-wrapper">
          <div className={`ai-sentiment-time-slot${active === '1h' ? ' active' : ''}`} onClick={() => setActive('1h')}>1 Hr</div>
          <div className={`ai-sentiment-time-slot${active === '4h' ? ' active' : ''}`} onClick={() => setActive('4h')}>4 Hrs</div>
          <div className={`ai-sentiment-time-slot${active === '1d' ? ' active' : ''}`} onClick={() => setActive('1d')}>Daily</div>
          <div className={`ai-sentiment-time-slot${active === '1w' ? ' active' : ''}`} onClick={() => setActive('1w')}>Weekly</div>
        </div>
      </div>

      <div className="meter-chart">
        <div>
          <img src={aiGauge} className="ai-gauge" />
          <img src={union} className={`union ${classLookup[value]}`} />
        </div>
      </div>
      <div className="curr-sentiment">Current Sentiment</div>
      <div className={`sentiment-value ${classLookup[value]}-sentiment`}>{value}</div>
    </div>
  );
};

const stateToProps = (state) => ({
  sentiment: state.aiSentiment.aiSentiment,
  loading: state.dashboard.dashboardLoading,
});

const dispatchToProps = (dispatch) => ({
  fetchAISentiment: (ticker, hours) => dispatch(aiSentimentLoad(ticker, hours)),
});

export default connect(stateToProps, dispatchToProps)(AISentiment);
