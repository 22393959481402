
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const NEWS_LOAD = "NEWS/LOAD/TOP";
export const NEWS_LOAD_SUCCESS = "NEWS/LOAD/TOP/SUCCESS";
export const NEWS_LOAD_FAILURE = "NEWS/LOAD/TOP/FAILURE";

// Action Creators
export const newsLoad = (topic, sentiment, sourceName) => ({
  type: NEWS_LOAD,
  topic,
  sentiment,
  sourceName
});
export const newsLoadSuccess = (data, loading) => ({
  type: NEWS_LOAD_SUCCESS,
  data,
  loading
});
export const newsLoadFailure = (error) => ({
  type: NEWS_LOAD_FAILURE,
  error,
  loading: false
});

// Sagas
function* fetchNews(action) {
  try {
    const { topic, sentiment, sourceName, startDate, endDate } = action;
    yield put(newsLoadSuccess([], true));

    let url = `${apiURL}/forex/general-news?`;

    if (topic && topic.toLowerCase() !== 'all') {
      url += `&topic=${topic}`;
    }
    if (sentiment && sentiment.toLowerCase() !== 'all') {
      url += `&sentiment=${sentiment}`;
    }
    if (sourceName && sourceName.toLowerCase() !== 'all') {
      url += `&source_name=${sourceName}`;
    }
    if (startDate) {
      url += `&start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }

    url = url.replace("?&", "?");

    const response = yield axios.get(url, { withCredentials: true });
    yield put(newsLoadSuccess(response.data, false));

  } catch (error) {
    yield put(newsLoadFailure(error));
  }
}

function* listenNewsLoad() {
  yield takeEvery(NEWS_LOAD, fetchNews);
}

// Root Saga
export function* saga() {
  yield all([fork(listenNewsLoad)]);
}

const INIT_STATE = {
  news: [],
  newsLoading: false,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NEWS_LOAD_SUCCESS:
      return {
        ...state,
        news: action.data,
        newsLoading: action.loading,
      };
    case NEWS_LOAD_FAILURE:
      return {
        ...state,
        news: [],
        newsLoading: false
      }
    default:
      return state;
  }
};

export default reducer;
