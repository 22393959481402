import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import { docusignLoad } from '../../../appRedux/ducks/docusigned'
import { connect } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import './docusignModal.css'
import { GradientLinearProgress } from './Progressbar'
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import docuSignLogo from "../../../assets/images/DocuSign-logo.png";
import LogoText from "../../../assets/images/logo-text.png";

const DocusignApproveModal = ({ modalVisible, userData, requestSignature }) => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const viewDocument = () => {
    setLoading(true)
    requestSignature(userData)
  }
  return (
    <Modal open={modalVisible} footer={null}>
      <div
        className="docusign-modal"
      >
        {page === 0 ? (
          <div>
            <GradientLinearProgress variant="determinate" value={50} />
            <br />
            <img src={LogoText} alt="logo-text" className='docusignModal-logo-text' />
            <div className='docusignModal-action-text'>
              <div className='docusignModal-action-text-title'>Welcome To MarketMakers</div>
              <div className='docusignModal-action-text-info'>Lets  get started with the product and start making profitable forex trades</div>
            </div>
            <div className='docusignModal-action-buttons'>
              <Button sx={{ visibility: "hidden" }} endIcon={<ArrowBackIosNewIcon className='icon-flipped' />}>Back</Button>

              <Button onClick={() => setPage(1)} endIcon={<ArrowBackIosNewIcon className='icon-flipped' />}>
                Next
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <GradientLinearProgress variant="determinate" value={100} />
            <br />
            <div className="docu-container">
              <div className='docu-container-header'>
                <span className='docu-container-status-text'>MarketMakers Services Agreement</span>
                <img src={docuSignLogo} alt="docu-sign-logo" className="docu-sign-logo" />
              </div>
              <div className='docu-container-body'>
                <div className='docu-container-body-top-big-text'>
                  We require your digital signature on MarketMakers Services Agreement
                </div>

                <div className='docu-container-body-button'>
                  <div className='docu-container-body-action-buttons'>
                    {
                      loading ? (
                        <Skeleton style={{ width: 230, height: 55 }} />
                      ) : (
                        <Button
                          className="docu-sign-pdf-view"
                          disabled={loading}
                          onClick={viewDocument}
                        >Review and Sign</Button>
                      )
                    }
                  </div>
                </div>

                <div className='docu-container-body-footer'>
                  <div className='docu-container-body-footer-main-text'>
                    Please click on the link above to complete the <strong style={{ fontWeight: 700 }}>MarketMakers Services Agreement</strong>.
                    <br />
                    If you need assistance, please contact MarketMakers Support (<a href="mailto:contact@marketmakers.com">contact@marketmakers.com</a>)
                  </div>
                  <div className='docu-container-body-footer-copyright'>
                  </div>
                </div>
              </div>
            </div>
            <div className='docusignModal-action-buttons'>
              <Button onClick={() => setPage(0)} startIcon={<ArrowBackIosNewIcon />}>Back</Button>
              {
                loading ? (
                  <Skeleton style={{ width: 130, height: 45, position: 'relative', left: '20px' }} />
                ) : (
                  <Button onClick={viewDocument}>View Document</Button>
                )
              }
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

const dispatchToProps = (dispatch) => ({
  requestSignature: (userData, name) => dispatch(docusignLoad(userData, name)),
})

export default connect(null, dispatchToProps)(DocusignApproveModal)