
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const COMPANY_NEWS_LOAD = "CN/LOAD/TOP";
export const COMPANY_NEWS_LOAD_SUCCESS = "CN/LOAD/TOP/SUCCESS";
export const COMPANY_NEWS_LOAD_FAILURE = "CN/LOAD/TOP/FAILURE";

// Action Creators
export const companyNewsLoad = (symbol) => ({
    type: COMPANY_NEWS_LOAD,
    symbol
});
export const companyNewsLoadSuccess = (data, loading) => ({
    type: COMPANY_NEWS_LOAD_SUCCESS,
    data,
    loading
});
export const companyNewsLoadFailure = (error) => ({
    type: COMPANY_NEWS_LOAD_FAILURE,
    error,
    loading: false
});

// Sagas
function* fetchCompanyNews(action) {
    try {
        const { symbol, startDate, endDate } = action;
        yield put(companyNewsLoadSuccess([], true));

        const url = `${apiURL}/forex/news-by-ticker?ticker=${symbol}`

        if (startDate) {
            url += `&start_date=${startDate}`;
        }
        if (endDate) {
            url += `&end_date=${endDate}`;
        }
        const response = yield axios.get(url, { withCredentials: true });
        yield put(companyNewsLoadSuccess(response.data, false));

    } catch (error) {
        yield put(companyNewsLoadFailure(error));
    }
}

function* listenCompanyNewsLoad() {
    yield takeEvery(COMPANY_NEWS_LOAD, fetchCompanyNews);
}

// Root Saga
export function* saga() {
    yield all([fork(listenCompanyNewsLoad)]);
}

const INIT_STATE = {
    companyNews: [],
    companyNewsLoading: false,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case COMPANY_NEWS_LOAD_SUCCESS:
            return {
                ...state,
                companyNews: action.data,
                companyNewsLoading: action.loading,
            };
        case COMPANY_NEWS_LOAD_FAILURE:
            return {
                ...state,
                companyNews: [],
                companyNewsLoading: false
            }
        default:
            return state;
    }
};

export default reducer;
