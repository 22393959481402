import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "antd/dist/antd.css";
import "./styles/index.css";

import App from "./App";

import ErrorBoundry from "./ErrorBoundry";    

import configureStore, { history } from "./store";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./contexts/Auth";



ReactDOM.render(
  <Provider store={configureStore()}>
    <ConnectedRouter history={history}>
        <AuthProvider>
            <ErrorBoundry>
              <App />
            </ErrorBoundry>
        </AuthProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
