
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const SUBMIT_STORY_LOAD = "SS/LOAD/TOP";
export const SUBMIT_STORY_LOAD_SUCCESS = "SS/LOAD/TOP/SUCCESS";
export const SUBMIT_STORY_LOAD_FAILURE = "SS/LOAD/TOP/FAILURE";

// Action Creators
export const submitStoryLoad = (image, title, description, name) => ({
    type: SUBMIT_STORY_LOAD,
    image,
    title,
    description,
    name
});
export const submitStoryLoadSuccess = (data) => ({
    type: SUBMIT_STORY_LOAD_SUCCESS,
    data,
});
export const submitStoryLoadFailure = (error) => ({
    type: SUBMIT_STORY_LOAD_FAILURE,
    error,
});

// Sagas
function* fetchSubmitStory(action) {
    try {
        const url = `${apiURL}/stories`;
        const response = yield axios.post(url, action, { withCredentials: true })
        yield put(submitStoryLoadSuccess(response?.data || []));
    } catch (error) {
        yield put(submitStoryLoadFailure(error));
    }
}

function* listenSubmitStroyLoad() {
    yield takeEvery(SUBMIT_STORY_LOAD, fetchSubmitStory);
}

// Root Saga
export function* saga() {
    yield all([fork(listenSubmitStroyLoad)]);
}

const INIT_STATE = {
    submitStory: ''
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBMIT_STORY_LOAD_SUCCESS:
            return {
                ...state,
                submitStory: action.data
            };
        default:
            return state;
    }
};

export default reducer;
