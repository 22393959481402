import React from "react";

import "./SignUpHint.scss";

const SignUpHint = () => {
  return (
    <div className="sign-up-hint">
      <div className="sign-up-hint-big-text">Get the Inside Scoop on Earnings Surprises Before Everyone Else.</div>
      <div className="sign-up-hint-small-text">
        Tired of guessing your way through the options market and coming up empty handed?
        <br /><br />
        Say hello to the <span className="alpha-options-method">Alpha Options Method</span>, the cutting-edge trading system designed to give you an edge in the options market.
        <br /><br />
        With this method, you'll learn how to generate consistent profits by understanding market trends and identifying high-probability trade opportunities.
        <br /><br />
        Our step-by-step approach takes the guesswork out of options trading, making it easier than ever to achieve financial success.
        <br /><br />
        Whether you're a beginner or an experienced trader, the <span className="alpha-options-method">Alpha Options Method</span> is the solution you've been searching for. Say goodbye to uncertainty and hello to consistent profits with the <span className="alpha-options-method">Alpha Options Method</span>.
      </div>

      <div className="sign-up-hint-big-text">Key Benefits</div>
      <a className="sign-up-hint-anchor" >What You Will Learn</a>
      <ul className="sign-up-hint-ul">
        <li className="sign-up-hint-li">Comprehensive training program</li>
        <li className="sign-up-hint-li">Daily live streams with options trading expert Bronco Brian</li>
        <li className="sign-up-hint-li">Live Q&A section for clarifying doubts and getting answers to questions</li>
        <li className="sign-up-hint-li">Daily trade alerts for valuable insights and recommendations</li>
        <li className="sign-up-hint-li">Step-by-step approach to options trading that takes the guesswork out of the process</li>
        <li className="sign-up-hint-li">Opportunities to learn from a seasoned options trader in real-time</li>
        <li className="sign-up-hint-li">Access to a supportive community of like-minded traders</li>
        <li className="sign-up-hint-li">Improved understanding of market trends and high-probability trade opportunities</li>
        <li className="sign-up-hint-li">Increased confidence and consistency in options trading</li>
        <li className="sign-up-hint-li">Potential for generating consistent profits in the options market.</li>
      </ul>
    </div>
  )
}

export default SignUpHint;