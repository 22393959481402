import React from "react";
import Button from "@mui/material/Button";
import CheckIcon from '@mui/icons-material/Check';
import image from "../../../assets/images/trading.png";
import footerImg from "../../../assets/images/alpha-signup-footer.png";
import SignUpHint from "./SignUpHint/SignUpHint";
import AlphaOptionsSignUpResult from "./AlphaOptionsSignUpResult";
import "./AlphaOptionsSignUp.scss";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";

const AlphaOptionsSignUp = ({ userSettings }) => {
  const submit = () => {
    window.location.href =  "https://checkout.marketmakers.com/ICS_aB3GB4v_SrY6CX_LIgQ8P0TOittEl1sqMZSS__QnvyeHgZRU5_WpOI2aCjMDl-qiWpft5kLvedEIFmHdtMUCBQ=="
  }
  return (
    !!userSettings?.alpha_sign_up ? (
      <AlphaOptionsSignUpResult />
    ) : (
      <div className="alpha-options-sign-up">
        <div className="alpha-options-sign-up-left">
          <div className="alpha-options-sign-up-header">
            <div className="alpha-options-sign-up-title">The Alpha Options Method</div>
            <div className="alpha-options-sign-up-info">Profitable Option Trading</div>
            <div className="alpha-options-sign-up-info-1">Made Easy.</div>
            <Button className="alpha-options-sign-up-button" onClick={submit.bind(this)}>Sign me up</Button>
          </div>

          <div className="alpha-options-sign-up-trading-section">
            <div className="alpha-options-sign-up-unlock-text">Unlock the Secrets to Successful Trading</div>
            <img src={image} alt='trading' className="alpha-options-sign-up-trading" />
          </div>

          <div className="alpha-options-sign-up-items">
            <div className="alpha-options-sign-up-item-row">
              <div className="alpha-options-sign-up-item">
                <span className="alpha-options-sign-up-item-icon"><CheckIcon /></span> Short Interest
              </div>
              <div className="alpha-options-sign-up-item">
                <span className="alpha-options-sign-up-item-icon"><CheckIcon /></span> Artificial Intelligence
              </div>
            </div>
            <div className="alpha-options-sign-up-item-row">
              <div className="alpha-options-sign-up-item">
                <span className="alpha-options-sign-up-item-icon"><CheckIcon /></span> Real-Time Alerts for Trading
              </div>
              <div className="alpha-options-sign-up-item">
                <span className="alpha-options-sign-up-item-icon"><CheckIcon /></span> Premium Support
              </div>
            </div>
          </div>

          <div className="alpha-options-sign-up-price-row">
            <div className="alpha-options-sign-up-price-row-inner">
              <div className="alpha-options-sign-up-price">$2,995</div>
              <div className="alpha-options-sign-up-year">/yr</div>
              <Button className="alpha-options-sign-up-button-small" onClick={submit.bind(this)}>Sign me up</Button>
            </div>
          </div>

          <div className="alpha-options-sign-up-footer">
            <div className="alpha-options-sign-up-footer-left">
              <div className="alpha-options-sign-up-footer-title">Access To Cutting Edge Option Strategies</div>
              <div className="alpha-options-sign-up-footer-description">
                This page is exclusively designed for our registered members and provides access to a wealth of information and resources that are not available to the general public.
                <br /><br />
                Don't miss out on this incredible opportunity to access our private content. Sign up now to start exploring everything that what our <span style={{ color: "#00DC0A" }}>Alpha Options Method</span> has to offer.
              </div>
              <Button className="alpha-options-sign-up-button-outline" onClick={submit.bind(this)}>Sign me up</Button>
            </div>
            <img src={footerImg} alt="footer" className="alpha-options-sign-up-footer-img" />
          </div>

        </div>

        <div className="alpha-options-sign-up-right">
          <SignUpHint />
        </div>

      </div>
    )
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(AlphaOptionsSignUp);
