
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const APPROVE_STORY_LOAD = "AS/LOAD/TOP";
export const APPROVE_STORY_LOAD_SUCCESS = "AS/LOAD/TOP/SUCCESS";
export const APPROVE_STORY_LOAD_FAILURE = "AS/LOAD/TOP/FAILURE";

// Action Creators
export const approveStoryLoad = (id) => ({
    type: APPROVE_STORY_LOAD,
    id
});
export const approveStoryLoadSuccess = (data) => ({
    type: APPROVE_STORY_LOAD_SUCCESS,
    data,
});
export const approveStoryLoadFailure = (error) => ({
    type: APPROVE_STORY_LOAD_FAILURE,
    error,
});

// Sagas
function* fetchApproveStory(action) {
    try {
        const { id } = action
        const url = `${apiURL}/stories/${id}/approve`;
        const response = yield axios.put(url, {}, { withCredentials: true })
        yield put(approveStoryLoadSuccess(response?.data || []));
    } catch (error) {
        yield put(approveStoryLoadFailure(error));
    }
}

function* listenApproveStroyLoad() {
    yield takeEvery(APPROVE_STORY_LOAD, fetchApproveStory);
}

// Root Saga
export function* saga() {
    yield all([fork(listenApproveStroyLoad)]);
}

const INIT_STATE = {
    approveStory: ''
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case APPROVE_STORY_LOAD_SUCCESS:
            return {
                ...state,
                approveStory: action.data
            };
        default:
            return state;
    }
};

export default reducer;
