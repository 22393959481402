import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMedia } from "react-media";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";

import { useAuth } from "../../contexts/Auth";
import "./SignIn.scss";
import logo from "./logoTa.png";

// const url = process.env["REACT_APP_RELEASE_STAGE"] === 'staging' ? 'https://dev.marketmakers.com' : 'https://marketmakers.com';
const url = 'http://www.marketmakers.com';

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const SignIn = () => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const { signIn, signInLoading } = useAuth();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  function onSubmit() {
    signIn(values);
  }
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  return (
    <>
      <div className="background-glow" />
      <div className="signin-container">
        <div style={{ borderBottom: "1px solid hsla(0deg, 0%, 100%, 0.12)", position: "sticky", top: 0, background: "#060203", zIndex: 4 }}>
          <div className="signin-navbar">
            <a style={{ cursor: 'pointer' }} href={url}>
              <img src={logo} className="logo-MM" alt="logo" />
            </a>
          </div>
        </div>

        <div className="signin-content">
          <div className="signin-content-tiny">
            <div className="signin-caption">Sign in</div>

            <form onKeyDown={onKeyDownHandler} onSubmit={onSubmit}>
              <div className={"input-row"}>
                <input
                  // aria-id="name"
                  value={values.email}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      email: e.target.value.replace(/\s+/g, ''),
                    })
                  }
                  autoComplete="username"
                  type={"text"}
                  className={"input-field full-width"}
                  placeholder={"Your email"}
                />
              </div>
              <div className={"input-row"}>
                <input
                  // aria-id="password"
                  value={values.password}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      password: e.target.value,
                    })
                  }
                  type={"password"}
                  autoComplete="current-password"
                  className={"input-field full-width"}
                  placeholder={"Password"}
                />
              </div>

              <div className={"input-row border-button"}>
                <Button
                  className='login-button'
                  onClick={onSubmit}
                  disabled={signInLoading}
                >
                  {signInLoading ? <CircularProgress color="success" size={24} /> : 'Submit'}
                </Button>
              </div>

              <div className="input-row small-text">
                <Link to="/reset-password" style={{ marginBottom: isMobile ? '10px' : '0px', fontSize: 14 }} >
                  <span className={"sign-up"} >Forgot Password?</span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>

  );
};

export default SignIn;
