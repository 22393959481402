import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


export const CardSkeleton = ({ width = '450px', margin = '15px', borderRadius = "16px" }) => {
  const useStyles = makeStyles((theme) => ({
    skeleton: {
      backgroundColor: theme.palette.primary.skeleton,
      minWidth: width,
      maxWidth: width,
      minHeight: "468px",
      maxHeight: "464px",
      borderRadius: borderRadius,
      margin: margin
    }
  }));
  const classes = useStyles();
  return (
    <Skeleton className={classes.skeleton} variant="rect" />
  );
};
