import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const MINUTE_SCANNER_SNAPSHOT_LOAD = "MSS/LOAD/TOP";
export const MINUTE_SCANNER_SNAPSHOT_LOAD_SUCCESS = "MSS/LOAD/TOP/SUCCESS";
export const MINUTE_SCANNER_SNAPSHOT_LOAD_FAILURE = "MSS/LOAD/TOP/FAILURE";

// Action Creators
export const minuteScannerSnapshotLoad = (pairs) => ({
    type: MINUTE_SCANNER_SNAPSHOT_LOAD,
    pairs
});
export const minuteScannerSnapshotLoadSuccess = (data, loading) => ({
    type: MINUTE_SCANNER_SNAPSHOT_LOAD_SUCCESS,
    data,
    loading
});
export const minuteScannerSnapshotLoadFailure = (error) => ({
    type: MINUTE_SCANNER_SNAPSHOT_LOAD_FAILURE,
    error,
    loading: false
});
const INIT_STATE = {
    minuteScannerSnapshot: {
        "buyVsSellRatio": 0,
        "buyVolume": 0,
        "sellVolume": 0
    },
    loading: false,
};
// Sagas
function* fetchMinuteScannerSnapshot(action) {
    try {
        const { pairs } = action
        const tickers = !!pairs.length ? encodeURIComponent(pairs.join(",")) : ''
        yield put(minuteScannerSnapshotLoadSuccess(INIT_STATE, true));
        const url = `${apiURL}/forex/general-scanner-data${!!tickers ? `?tickers=${tickers}` : ''}`;
        const response = yield axios.get(url, { withCredentials: true });
        yield put(minuteScannerSnapshotLoadSuccess(response.data, false));
    } catch (error) {
        yield put(minuteScannerSnapshotLoadFailure(error));
    }
}

function* listenMinuteScannerSnapshotLoad() {
    yield takeEvery(MINUTE_SCANNER_SNAPSHOT_LOAD, fetchMinuteScannerSnapshot);
}

// Root Saga
export function* saga() {
    yield all([fork(listenMinuteScannerSnapshotLoad)]);
}

// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MINUTE_SCANNER_SNAPSHOT_LOAD_SUCCESS:
            return {
                ...state,
                minuteScannerSnapshot: action.data,
                loading: action.loading,
            };
        case MINUTE_SCANNER_SNAPSHOT_LOAD_FAILURE:
            return {
                ...state,
                minuteScannerSnapshot: {
                    "buyVsSellRatio": 0,
                    "buyVolume": 0,
                    "sellVolume": 0
                },
                loading: false
            }
        default:
            return state;
    }
};

export default reducer;
