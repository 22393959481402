const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const today = new Date();

const dateOptions = {
  weekday: "long",
  year: "2-digit",
  month: "short",
  day: "numeric"
};

const timeOptions = {
  hour: "numeric",
  minute: "numeric"
};

export const date = today.toLocaleDateString(undefined, dateOptions);
export const time = today.toLocaleTimeString(undefined, timeOptions);

export const formatDate = (date) => {
  const newDate = new Date(date);

  const newDateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };

  return newDate.toLocaleDateString(undefined, newDateOptions);
};

export const formatDateTime = (date) => {
  const newDate = new Date(date);

  const dateTimeOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };

  return newDate.toLocaleDateString(undefined, dateTimeOptions);
};

export const formatDateObject = (date) => {
  // const dateObject = new Date(date);
  // return dateObject.toDateString();

  const dateObject = new Date(date);
  const dateString = dateObject.toDateString();
  return dateString.slice(dateString.indexOf(" "), dateString.length);
};

const convertLocalDateToUTCDate = (date) => {
  // date = new Date(date);

  // const localOffset = date.getTimezoneOffset() * 60000;
  // const localTime = date.getTime();
  // date = localTime - localOffset;

  date = new Date(date);
  return date;
};

const calculateTimeDifference = (date, dateNow) => {
  let diffInMilliSeconds = Math.abs(dateNow - date) / 1000;

  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  const months = Math.floor(days / 31);

  const weeks = Math.floor(days / 7);

  const years = Math.floor(months / 12);

  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (years > 0) {
    difference += years === 1 ? `${years} year ago` : `${years} years ago`;
  } else if (months > 0) {
    difference += months === 1 ? `${months} month ago` : `${months} month ago`;
  } else if (weeks > 0) {
    difference += weeks === 1 ? `${weeks} week ago` : `${weeks} weeks ago`;
  } else if (days > 0) {
    difference += days === 1 ? `${days} day ago` : `${days} days ago`;
  } else if (hours > 0) {
    difference +=
      hours === 0 || hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
  } else {
    difference += minutes === 0 ? `now` : `${minutes} minutes ago`;
  }

  return difference;
};

export const timeDifference = (date) => {
  date = convertLocalDateToUTCDate(date);
  return calculateTimeDifference(date, new Date());
};

timeDifference("2020-05-05T12:30:00.000Z");

export const sortDate = (a, b, order, dataField, rowA, rowB) => {
  // const ad = new Date();
  // const bd = new Date();

  const months = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  };

  const as = a.split(" "),
    bs = b.split(" ");

  const m1 = months[as[0]];
  const d1 = as[1];
  const m2 = months[bs[0]];
  const d2 = bs[1];

  if (order === "asc") {
    return m2 - m1;
  }

  return m1 - m2;
};

export const formatUTCDate = (date) => {
  date = new Date(date);

  let day = date.getUTCDate();
  const year = date.getFullYear();
  const month = date.getUTCMonth();

  if (day < 10) {
    day = `0${day}`;
  }

  return `${months[month]} ${day} ${year}`;
};
