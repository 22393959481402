import React from "react";

import { Button as BootstrapButton } from "react-bootstrap";

const Button = (props) => {
  const {
    type,
    active,
    className,
    labelClassname,
    iconClassname,
    disabled,
    variant,
    size,
    block,
    label,
    onClick,
    icon,
    iconPosition,
    style,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave
  } = props;

  const btnTypeClassname =
    icon && !label ? "btn-icon-only" : icon && label ? "btn-with-icon" : "";

  return (
    <BootstrapButton
      style={style}
      className={`${className} ${
        active ? "active-btn" : ""
      } ${btnTypeClassname}`}
      active={active}
      type={type}
      variant={variant && variant}
      size={size}
      block={block}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
    >
      {icon && iconPosition === "left" && (
        <>
          <span className={`btn-icon icon-left ${iconClassname}`}>{icon}</span>
          {label && (
            <span className={`btn-label btn-label-right ${labelClassname} `}>
              {label}
            </span>
          )}
        </>
      )}

      {label && !icon && (
        <>
          <span className={`btn-label ${labelClassname} `}>{label}</span>
        </>
      )}

      {icon && !iconPosition && (
        <>
          <span className={`btn-icon no-text ${iconClassname}`}>{icon}</span>
        </>
      )}

      {icon && iconPosition === "right" && (
        <>
          {label && (
            <span className={`btn-label btn-label-left ${labelClassname} `}>
              {label}
            </span>
          )}
          <span className={`btn-icon icon-right ${iconClassname}`}>{icon}</span>
        </>
      )}
    </BootstrapButton>
  );
};

export default Button;

Button.defaultProps = {
  disabled: false,
  type: "button",
  active: false,
  className: "",
  labelClassname: "",
  iconClassname: "",
  size: "md",
  variant: "light",
  block: false,
  iconPosition: ""
};
