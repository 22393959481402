import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import settings from "../../../config/settings";

const Logo = (props) => {
  const { type, className, linkable, url, external, icon, logo, from, noText, header, hide, themeMode } = props;
  let element;
  let text = useMemo(() => {
    return themeMode === "light" ? "black-logo-text" : 'white-logo-text';
  }, [themeMode]);
  if (linkable && external) {
    element = (
      <Link to={`${url}`} className={"text-white market-maker-logo-wrapper"}>
        {icon && icon}
        {!noText && <span className={`${from === 'dashboard' ? text : ''}`}>{settings.siteName}</span>}
      </Link>
    );
  } else if (linkable && !external) {
    element = (
      <Link to={`${url}`} className={"text-white market-maker-logo-wrapper"}>
        {icon && icon}
        {!noText && <span style={{ fontSize: header ? '22px' : '26px', visibility: hide ? 'hidden' : 'visible' }} className={`${from === 'dashboard' ? text : ''}`}>{settings.siteName}</span>}
      </Link>
    );
  } else {
    element = (
      <div className="d-inline-flex">
        {icon && icon}
        {!noText && <span className={`${from === 'dashboard' ? `${text} market-maker-logo-wrapper` : "text-white market-maker-logo-wrapper"}`}><span>{settings.siteName}</span></span>}
      </div>
    );
  }

  if (logo) {
    return (
      <Link to={`${url}`} className={className}>
        {logo}
      </Link>
    );
  } else {
    if (type === "h1") {
      return (
        <>
          <h1
            className={`text-capitalize d-inline-block logo ${className} ${icon ? "with-icon" : ""
              }`}
          >
            {element}
          </h1>
        </>
      );
    }

    return (
      <>
        <h4
          className={`text-capitalize d-inline-block logo ${className} ${icon ? "with-icon" : ""
            }`}
        >
          {element}
        </h4>
      </>
    );
  }
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode
});
export default connect(stateToProps, null)(Logo);

Logo.defaultProps = {
  type: "",
  className: "",
  url: "",
  external: false,
  linkable: true,
  logo: null,
  icon: null
};
