import Auth from "./Auth";
import User from "./user";
import themeMode from "../ducks/themeMode";
import symbol from "../ducks/symbol";
import sidebarOpen from "../ducks/sidebarOpen";
import lastPrice from "../ducks/polyLastPrice";
import searchSymbols from "../ducks/search";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import companyData from "../ducks/companyData";
import companyNews from "../ducks/companyNews";
import news from "../ducks/news";
import newsFilters from "../ducks/newsFilters";
import dashboard from "../ducks/dashboard";
import aiSentiment from "../ducks/aiSentiment";
import dxFeedSocket from "../ducks/dxFeedSocket";
import userSettings from "../ducks/userSettings";
import forexActivity from "../ducks/forexActivity";
import calendar from "../ducks/calendar";
import minuteApi from "../ducks/minuteApi"
import newLiveOptions from "../ducks/newLiveOptions"
import Socket from "../ducks/socket";
import minuteSnapshot from "../ducks/minuteSnapshot"
import getStories from "../ducks/getStories"
import approveStory from "../ducks/approveStory"
import submitStory from "../ducks/submitStory"
import historicalDarkflow from "../ducks/historicalDarkflow"
import darkflowTable from "../ducks/darkflowTable"
import popularStocks from "../ducks/popularStocks";
import EquitiesSocket from "../ducks/equitiesSocket"
import streams from "../ducks/pastStreams";
import nameChange from "../ducks/changeName";
import addUsername from "../ducks/addUsername";
import breadCrumb from "../ducks/breadCrumb"
import optionSparkline from "../ducks/optionSparklines";
import equities from '../ducks/equities'
import optionChain from "../ducks/optionChain"
import optionExpiry from "../ducks/optionExpirations"
import marketStatus from "../ducks/marketStatus";
import docusign from "../ducks/docusigned";
import currencyStrength from "../ducks/currencyStrength";
import updateFields from "../ducks/updateFields";
import forexCompanyData from "../ducks/forexCompanyData";
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: Auth,
    user: User,
    themeMode,
    symbol,
    sidebarOpen,
    lastPrice,
    searchSymbols,
    companyData,
    companyNews,
    news,
    newsFilters,
    dashboard,
    dxFeedSocket,
    userSettings,
    forexActivity,
    calendar,
    aiSentiment,
    minuteApi,
    newLiveOptions,
    Socket,
    minuteSnapshot,
    getStories,
    submitStory,
    approveStory,
    historicalDarkflow,
    darkflowTable,
    popularStocks,
    streams,
    nameChange,
    addUsername,
    breadCrumb,
    EquitiesSocket,
    optionSparkline,
    equities,
    optionChain,
    optionExpiry,
    marketStatus,
    docusign,
    currencyStrength,
    updateFields,
    forexCompanyData
  });
export default createRootReducer;
