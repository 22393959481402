import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { useHistory, useParams } from "react-router-dom";
import CurrencyStrengthTable from "./CurrencyStrengthTable";
import { currenciesData } from "./currenciesData.js";
import "./style.scss";
import TrendingArea from "../homePage/TrendingArea";
import TimeStamp from "../homePage/TimeStamp";
import CurrencyStrengthCards from "./CurrencyStrengthCards";
import { currencyStrengthLoad } from "../../appRedux/ducks/currencyStrength";

const CurrencyStrength = ({ fetchCurrencyStrengthData, currencyStrength, loading }) => {
  const [type, setType] = useState('default')
  const [hours, setHours] = useState('4h');

  const hardHistory = useHistory();
  const goHours = (value) => {
    hardHistory.push(`/currency-strength/${value}`);
  }

  const { time } = useParams();
  useEffect(() => {
    fetchCurrencyStrengthData(hours, type);
  }, [hours, type])

  useEffect(() => {
    if (time) {
      setHours(time)
    }
  }, [time])

  const filteredData = useMemo(() => {
    let currenciesData = currencyStrength.currencyStrengths;
    if (currenciesData) {
      let duplicated = [...currenciesData];
      duplicated = duplicated.sort((a, b) => b.score - a.score);

      duplicated.forEach(item => {
        let greenList = item.pairs.filter(obj => obj.performance > 0);
        let redList = item.pairs.filter(obj => obj.performance < 0);

        greenList = greenList.sort((a, b) => b.performance - a.performance);
        redList = redList.sort((a, b) => b.performance - a.performance);

        item.greenList = greenList;
        item.redList = redList;
      });

      let maxGreenListItemsCount = [];

      duplicated.forEach(item => {
        maxGreenListItemsCount.push(item.greenList.length);
      });

      const maxNumber = Math.max.apply(null, maxGreenListItemsCount);

      duplicated.forEach(item => {
        if (maxNumber > item.greenList.length) {
          const missingCount = maxNumber - item.greenList.length;
          for (let i = 0; i < missingCount; i++) {
            item.greenList.unshift({ currency: '', percentage: '' });
          }
        }
      });

      return duplicated;
    } else {
      return [];
    }
  }, [currencyStrength.currencyStrengths]);

  const trendingUp = useMemo(() => {
    let result = [];
    if (currencyStrength.currencyStrengths && currencyStrength.currencyStrengths.length > 0) {
      currencyStrength.currencyStrengths.forEach(score => {
        if (score.score > 0) {
          result.push(score);
        }
      })
    }

    return result.slice(0, 5);
  }, [currencyStrength.currencyStrengths]);

  const trendingDown = useMemo(() => {
    let result = [];
    if (currencyStrength.currencyStrengths && currencyStrength.currencyStrengths.length > 0) {
      currencyStrength.currencyStrengths.forEach(score => {
        if (score.score < 0) {
          result.push(score);
        }
      })
    }

    result = result.sort((a, b) => a.score - b.score);

    return result.slice(0, 5);
  }, [currencyStrength.currencyStrengths]);

  return (
    <div className="currency-strength-page">
      <div className="currency-strength-page-header">
        <div className="currecy-strength-page-title">Currency Strength</div>
        <TimeStamp hours={hours} goHours={goHours} />
      </div>

      <div className="currency-strength-page-caption">
        <div className="currency-strength-page-info">Trade Scorecard</div>
        <div className="currency-strength-page-datetime">
          <span>{currencyStrength.date && moment(currencyStrength.date).format('MMMM D, YYYY')}</span>
          {
            currencyStrength.time && <span style={{ marginLeft: 8 }}>{moment(currencyStrength.time, "H:mm").format("h:mm A")}</span>
          }
        </div>
      </div>

      <TrendingArea
        loading={loading}
        trendingUp={trendingUp}
        trendingDown={trendingDown}
      />
      <div style={{ float: 'right' }}>
        <select className="api-type-selector"
          value={type} onChange={(e) => setType(e.target.value)}
        >
          <option value='default'>All</option>
          <option value='popular'>Popular</option>
          <option value='g7'>G7 Pairs</option>
        </select>
      </div>

      <div className="currency-strength-page-content">
        <CurrencyStrengthTable loading={loading} data={filteredData} />
      </div>
      {/* 
      <div className="currency-strength-page-cards-wrapper">
        <CurrencyStrengthCards />
      </div> */}
    </div>
  )
}

const stateToProps = (state) => ({
  currencyStrength: state.currencyStrength.currencyStrength,
  loading: state.currencyStrength.currencyStrengthLoading,
});

const dispatchToProps = (dispatch) => ({
  fetchCurrencyStrengthData: (hours, type) => dispatch(currencyStrengthLoad(hours, type)),
});

export default connect(stateToProps, dispatchToProps)(CurrencyStrength);
