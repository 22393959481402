import { Typography } from "@mui/material";
import React from "react";
import "./index.scss";

const LiveOptionsCard = ({ data }) => {
  return (
    <div
      className="live-option-card-box"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: "'Circular Std'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "15px",
            lineHeight: "30px",
            letterSpacing: "-0.04em",
            color: "#000000",
            wordSpacing: "-0.2em",
          }}
        >
          {data.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Circular Std'",
            fontStyle: "normal",
            fontWeight: "450",
            fontSize: "16px",
            lineHeight: "23px",
            letterSpacing: "-0.04em",
            color: "#1F1F21",
          }}
        >
          {data.value}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
        }}
      >
        <CustomPercentageBar percentage={data.percentage >= 100 ? 100 : data.percentage} />
      </div>
    </div>
  );
};

export default LiveOptionsCard;

const CustomPercentageBar = ({ percentage }) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#ecfbed",
        height: "20px",
      }}
    >
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: `${percentage}%`,
            textAlign: 'center',
            height: "100%",
            backgroundColor: "#00DC0A",
            height: "20px",
          }}
        >
        </div>
        <Typography
          sx={{
            fontFamily: "'Circular Std'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20px",
            letterSpacing: "-0.04em",
            position: 'relative',
            left: '37%',
            top: '-20px'
          }}
        >
          {`${percentage}%`}
        </Typography>
      </div>
    </div>
  );
};
