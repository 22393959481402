import React, { useEffect, useState } from "react";
import "./index.css";
import { widget } from "../../../src/charting_library/charting_library.esm.js";
import Datafeed, { closeSocket } from "./datafeed";
import DatafeedDX from "./datafeedDx";
import useWindowSize from "../../hooks/useWindowSize";
import { connect } from "react-redux";
import { isInternalWS, isPolyREST } from "../../appRedux/actions/helpers";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
function OptionTVContainer({ symbol, themeMode, candleDefaults }) {
  const [widgetRef, setWidgetRef] = useState({});
  const resolution = candleDefaults.defaultResolution;
  const candleType = candleDefaults.candleType;
  const indicators = candleDefaults?.defaultIndicators || [];
  const windowSize = useWindowSize();
  const disabled_features = [
    "use_localstorage_for_settings",
    "display_market_status",
    "popup_hints",
    "header_screenshot",
    "header_symbol_search",
    "header_compare"
    // "widget_logo",
  ];
  useEffect(() => {
    const widgetOptions = {
      symbol: isPolyREST ? `O:${symbol.replace('OPTION_CONTRACT', '')}` : symbol, 
      interval: resolution,
      datafeed:isPolyREST ?  Datafeed : DatafeedDX,
      container: "tv_chart_container",
      library_path: "/charting_library/",
      locale: getLanguageFromURL() || "en",
      time_frames: [
        { text: "5Y", resolution: "1D", description: "5 Years" },
        { text: "1Y", resolution: "1D", description: "1 Year" },
        { text: "6M", resolution: "1D", description: "6 Months" },
        { text: "3M", resolution: "1D", description: "3 Months" },
        { text: "1M", resolution: "1", description: "1 Month" },
        { text: "5D", resolution: "1", description: "5 Days" },
        { text: "1D", resolution: "1", description: "1 Day" },
      ].reverse(),
      header_widget_buttons_mode: "adaptive",
      disabled_features,
      enabled_features: [
        "side_toolbar_in_fullscreen_mode",
        "chart_style_hilo",
        // "extended_hours",
        "secondary_series_extend_time_scale",
        // "high_density_bars",
        "header_in_fullscreen_mode",
        "hide_last_na_study_output",
        "seconds_resolution",
      ],
      load_last_chart: false,
      fullscreen: false,
      autosize: true,
      width: "100%",
      timezone: "America/New_York",
      client_id: "patternscanner",
      theme: themeMode,
      overrides: {
        "mainSeriesProperties.showCountdown": true,
      },
      loading_screen: {
        backgroundColor: "#222222",
        foregroundColor: "#229712",
      },
    };
    if (symbol) {
      const tvWidget = new widget(widgetOptions);
      tvWidget.onChartReady(() => {
        setWidgetRef(tvWidget);
      });
    }
  }, [symbol, themeMode]);
  useEffect(() => {
    if (Object.keys(widgetRef).length) {
      widgetRef.activeChart().removeAllStudies();
      widgetRef.activeChart().setChartType(Number(candleType));
      widgetRef.activeChart().setResolution(resolution);
      indicators?.forEach((indicator) => widgetRef.activeChart().createStudy(indicator))
    }
  }, [resolution, candleType, widgetRef, candleDefaults])
  useEffect(() => {
    if (document.getElementById("tv_chart_container")) {
      const currentWidth =
        document.getElementById("tv_chart_container").clientWidth;
      const maxHeight = currentWidth * 0.1;
      document.getElementById(
        "tv_chart_container"
      ).style.maxHeight = `${maxHeight}px`;
    }
  }, [windowSize]);
  useEffect(() => {
    return () => {
      if (isInternalWS) {
        closeSocket()
      } else {
        setTimeout(() => {
          Datafeed.unsubscribeBars('full-unsub')
        }, 1200)
      }
    }
  }, [])

  return (
    <>
      {symbol && <div id="tv_chart_container" className={"TVChartContainerOptions"} />}
    </>
  );
}


const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  candleDefaults: state.userSettings.userSettings,

});

export default connect(stateToProps, null)(OptionTVContainer);
