import MTZ from "moment-timezone";
import axios from "axios";
import { apiURL, isInternalWS, wsUrl } from "../../appRedux/actions/helpers";
import moment from "moment";
import Feed, { EventType } from "@dxfeed/api";
// Setup config
const supportedResolutions = ["1S", "5S", "15S", "30S", "1", "3", "5", "15", "30", "60", "120", "240", "360", "D", "2D", "3D", "W"];
const config = {
  supported_resolutions: supportedResolutions,
};
let allBars = [];
const history = {};
var sub;
let feed;
let unsub;
let properRes;
let pricescale = 10000
let token
const connect = async () => {
  //get Token
  feed = new Feed();
  feed.connect(wsUrl);
  return new Promise((resolve) => {
    resolve(feed);
  });
};
// Trading View JS Datafeed
const datafeedDx = {
  onReady: (cb) => {
    if (!feed) connect()
    setTimeout(() => cb(config), 0);
  },

  searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
    try {
      const results = [];
      const { data } = await axios.get(`${apiURL}/search/${userInput.replace("/", "")}`, { withCredentials: true })
      const availableSymbols = data;
      if (availableSymbols.length) {
        for (let i = 0; i <= availableSymbols.length; i++) {
          const data = availableSymbols[i];
          const symbol = data?.type ? data?.ticker : data?.symbol || '';
          const full_name = data?.name || '';
          const description = data?.type ? data?.name : data?.description || '';
          const ticker = data?.ticker || '';
          let type = "Forex";
          if (!!data?.type) {
            if (data?.type?.includes("fund")) {
              type = "Fund";
            } else {
              if (data?.type === "etf") {
                type = "Etf";
              } else {
                if (data?.type === "common_stock") {
                  type = "Stock";
                }
              }
            }
          }
          const formatted = {
            symbol,
            full_name,
            description,
            ticker,
            exchange: symbol,
            type,
          };
          if (!!symbol.length) {
            results.push(formatted);
          }
        }
        onResultReadyCallback(results);
      }
    } catch (error) {
      console.log(error, "err searching symbols")
    }
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    const isForex = symbolName.includes('/') || symbolName.includes('-')
    let symbolExchange = '{mm=COMP}';
    if (isForex) {
      pricescale = 100000
    } else {
      pricescale = 100
    }
    var symbol_stub = {
      isForex,
      symbolExchange,
      isOption: false,
      name: symbolName,
      description: symbolName,
      type: isForex ? 'forex' : 'stock',
      timezone: "Etc/UTC",
      ticker: symbolName,
      // exchange: 'NYSE',
      minmov: 1,
      pricescale,
      has_weekly_and_monthly: false,
      has_seconds: true,
      seconds_multipliers: ["1", "5", "15", "30"],
      has_intraday: true,
      intraday_multipliers: ["1", "5", "15", "30", "60", "120", "240", "360"],
      supported_resolution: supportedResolutions,
      volume_precision: 1,
      data_status: "streaming",
    };
    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);
  },

  async getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    try {
      let { from, to, firstDataRequest } = periodParams;
      let qs = {
        // firstDataRequest,
        symbol: symbolInfo.name,
        resolution: resolution.replace("1D", "D"),
        fromTime: moment(from * 1000).subtract(resolution.includes("S") ? firstDataRequest ? 5 : 12 : 5, resolution.includes("S") ? "hours" : 'days').valueOf(),
        toTime: to * 1000,
        timespan: "m"
      };
      const sunday = moment().startOf('week').format("YYYY-MM-DD");
      let fromTime = moment(qs.fromTime).format("YYYY-MM-DD");
      if (moment(sunday).isSame(fromTime, 'day') && resolution.includes('S')) {
        qs.fromTime = moment(qs.fromTime).subtract(2, 'days').startOf("day").valueOf();
      }
      if (qs.resolution === "D") {
        // day
        qs.timespan = "d";
        qs.resolution = 1;
      } else if (resolution.includes("S")) {
        qs.timespan = ''
        qs.resolution = resolution.replace("S", "")
      } else {
        //minute || hour
        qs.timespan = 'm';
        qs.resolution = qs.resolution;
        if (qs.resolution >= 60) {
          qs.timespan = 'h'
          qs.resolution = qs.resolution / 60;
        }
      }
      const symbol = (symbolInfo.isForex ? qs.symbol + symbolInfo.symbolExchange : qs.symbol).replace("OPTION_CONTRACT", "");
      const params = encodeURIComponent(`${symbol}{=${qs.resolution}${qs.timespan},price=mark}`)
      let uri = `https://ws.marketmakers.com/rest/events.json?events=Candle&symbols=${params}&fromTime=${qs.fromTime}&toTime=${qs.toTime}&indent`;
      if (!symbolInfo.isForex) {
        uri = `https://ws.marketmakers.com/rest/events.json?events=Candle&symbols=${encodeURIComponent(`${symbol}{=${resolution}${qs.timespan}}`)}&fromTime=${qs.fromTime}&toTime=${qs.toTime}&indent`;
      }
      let bars = [];
      let { data } = await axios.get(uri, { withCredentials: true })
      if (!!data?.Candle) {
        let keys = Object.keys(data.Candle);
        if (keys[0].includes(symbol)) {
          bars = data.Candle[keys[0]];
        }
      }
      if (bars.length) {
        if (firstDataRequest) {
          var lastBar = bars[bars.length - 1];
          history[symbolInfo.name] = { lastBar: lastBar };
        }
        allBars = allBars.concat(bars).sort((a, b) => a.time - b.time);
        onHistoryCallback(bars, { noData: false });
      } else {
        onHistoryCallback([], { noData: true });
      }
    } catch (error) {
      console.log({ error });
      onErrorCallback(error);
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    let symbol = symbolInfo.name.replace("OPTION_CONTRACT", "");
    const { isForex } = symbolInfo
    const oneMinAhead = moment().add(1, 'minute').valueOf() / 1000
    unsub && unsub()
    properRes = resolution.includes('S') ? resolution.replace('S', 's') : Number(resolution) >= 60 ? Number(resolution / 60) + 'h' : resolution.includes('D') ? resolution.replace('D', 'd') : resolution + 'm';
    const symbolSuffix = `{mm=COMP}{=${properRes},price=mark}`
    if (isForex) {
      unsub = feed.subscribeTimeSeries([EventType.Candle], [`${symbol}${symbolSuffix}`], oneMinAhead, (event) => {
        let _lastBar = updateBar(event, sub, symbolSuffix, isForex)
        if (!!_lastBar) {
          if (allBars[allBars.length - 1]) {
            allBars[allBars.length - 1].close = Number(_lastBar.close);
            _lastBar.close = Number(_lastBar.close);
          } else {
            _lastBar = allBars[allBars.length - 1]
          }
          sub.listener(_lastBar);
          // update our own record of lastBar
          sub.lastBar = _lastBar;
        }
      });
    } else {
      unsub = feed.subscribe([EventType.Candle], [symbol], (event) => {
        const { eventSymbol } = event;
        if (symbol === eventSymbol) {
          var _lastBar = updateBar(event, sub, '', false);
          if (!!_lastBar) {
            if (allBars[allBars.length - 1]) {
              allBars[allBars.length - 1].close = Number(_lastBar.close);
            }
            _lastBar.close = Number(_lastBar.close);
          } else {
            _lastBar = allBars[allBars.length - 1]
          }
          sub.listener(_lastBar);
          // update our own record of lastBar
          sub.lastBar = _lastBar;
        }
      });
    }
    if (history[symbolInfo.name]) {
      const newSub = {
        symbol,
        subscribeUID,
        resolution,
        symbolInfo,
        lastBar: history[symbolInfo.name].lastBar,
        listener: onRealtimeCallback,
      };
      sub = newSub;
    }
    onResetCacheNeededCallback()
  },

  unsubscribeBars: (flag) => {
    allBars = [];
    if (!isInternalWS && flag === 'full-unsub' && !!feed) {
      feed.disconnect()
      feed = undefined
    }
  },
};

// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub, symbolSuffix = '', isForex = false) {
  var lastBar = sub?.lastBar;
  let resolution = sub?.resolution;
  var coeff = resolution * 60;
  if (resolution && resolution?.includes('S')) {
    coeff = resolution.replace("S", "")
  }
  var lastBarSec = lastBar?.time;
  var _lastBar;
  const lastBarEndMinute = MTZ(lastBarSec).valueOf() / 1000;
  const dataTime = MTZ(data.time).valueOf() / 1000;
  const diff = dataTime - lastBarEndMinute;
  if (diff >= coeff || isForex) {
    // create a new candle
    _lastBar = {
      time: data.time,
      open: Number(data.open),
      high: Number(data.high),
      low: Number(data.low),
      close: Number(data.close),
      volume: Number(data.volume),
      symbol: data.eventSymbol.replace(symbolSuffix, '')
    };
  } else {
    if (Number(data.low) < Number(lastBar.low)) {
      lastBar.low = Number(data.low);
    } else if (data.high > lastBar.high) {
      lastBar.high = Number(data.high);
    }
    lastBar.volume = data.volume
    lastBar.close = Number(data.close);
    // lastBar.time = data.time
    _lastBar = lastBar;
  }
  return _lastBar;
}

export function getSub() {
  if (!!sub?.lastBar) {
    return sub.lastBar
  } else {
    return {
      isLastBar: false,
      close: '',
      symbol: "",
    }
  }
}
export function getUnsub() {
  if (!!unsub) {
    return unsub
  }
}
export default datafeedDx;