import { styled } from "@mui/material/styles";
import { Box, Slider, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment";
import { TradeAlgoTable } from "../../components/common/Tables/TradeAlgoTable";
import {
  minuteApiLoad,
  minuteApiTickersLoad,
} from "../../appRedux/ducks/minuteApi";
import { connect } from "react-redux";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import tickerList from "../../helpers/tickerList";
import CssPop from "../common/Tables/animations/CssPop";
import MinuteCard from "./MinuteCard";
import { minuteScannerSnapshotLoad } from "../../appRedux/ducks/minuteSnapshot";
import { breadCrumbChange } from "../../appRedux/ducks/breadCrumb";
import { abbreviate } from "../LiveOptions";
import useDebounce from "../common/SearchBar/useDebounce";
import { SnapshotSkeleton } from "../common/Skeletons/SnapshotSkeleton";
import { equtiesSocketListen } from "../../appRedux/ducks/equitiesSocket";
import { isInternalWS } from "../../appRedux/actions/helpers";
const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const styles = {
  activeTag: {
    fontFamily: "'SF Pro Text'",
    fontStyle: "normal",
    fontWeight: "bolder",
    fontSize: "13px",
    lineHeight: "14px",
    textAlign: "center",
    letterSpacing: "-0.4px",
    color: "#FFFFFF",
    padding: "4px 10px",
    background: "#171917",
    border: "1px solid #00DC0A",
    marginRight: "5px",
    marginBottom: "5px",
    minHeight: 45,
  },
  inactiveTag: {
    fontFamily: "'SF Pro Text'",
    fontStyle: "normal",
    fontWeight: "bolder",
    fontSize: "13px",
    lineHeight: "14px",
    textAlign: "center",
    letterSpacing: "-0.4px",
    color: "#FFFFFF",
    padding: "4px 10px",
    background: "#2F2E31",
    marginRight: "5px",
    marginBottom: "5px",
    minHeight: 45,
  },
  sliderTitle: {
    fontFamily: "'Circular Std'",
    fontStyle: "normal",
    fontWeight: "450",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "-0.02em",
    color: "#8E8E93",
  },
};

const CustomSliderPremium = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "16px 0",
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#00DC0A",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",

      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },

  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#2F2E31",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#2F2E31",
    height: 8,
    width: 8,
    borderRadius: 20,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#00DC0A",
    },
  },
}));
const CustomSliderCandle = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "16px 0",
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#00DC0A",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",

      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },

  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#2F2E31",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#2F2E31",
    height: 8,
    width: 8,
    borderRadius: 20,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#00DC0A",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.innerBackground,
  },
  headerTitle: {
    padding: 16,
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
    marginBottom: "16px!important",
  },
}));

function MinuteScanner({
  minuteScannerData,
  loading,
  fetchMinuteScanner,
  tickers,
  loadingTickers,
  fetchMinuteScannerTickers,
  listen,
  currentPrices,
  fetchSnapshot,
  snapshotLoading,
  snapshot,
  timezone,
  breadCrumbChanger
}) {
  const { pathname } = useLocation();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [resolution, setResolution] = useState("1m");
  const [minPremium, setMinPremium] = useState(0);
  const debouncedPrem = useDebounce(minPremium, 400)
  const [concat, setConcat] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [tickerQuery, setTickerQuery] = useState("");
  const [activeTicker, setActiveTicker] = useState([]);
  const [upperHeight, setUpperHeight] = useState(0);
  const upperDiv = useRef();
  const [sortModel, setSortModel] = useState([
    {
      field: "timePeriod",
      sort: "desc",
    },
  ]);
  const allTickersList = useMemo(() => {
    return !!data.length ? [
      ...new Set(data.map((each) => each.event_symbol)),
    ] : [];
  }, [data])

  const tickersToSubscribe = useMemo(() => {
    return !!allTickersList.length
      ? tickerList
        .filter((t) => allTickersList.includes(t.slice(0, 7)))
        .filter((i) => i.includes("COMP") || i.includes("HSFX"))
        .map((i) => (i.includes("COMP") ? i + "{=1m,price=mark}" : i))
      : [];
  }, [allTickersList]);

  const cardData = useMemo(() => {
    if (snapshot.buyVolume) {
      const { buyVolume, sellVolume, buyVsSellRatio } = snapshot
      const trend = buyVolume > sellVolume ? "Bullish" : "Bearish"
      const sellPercentage = 100 - buyVsSellRatio

      return [
        { title: "Trend", value: trend, percentage: Number(trend === "Bullish" ? buyVsSellRatio : sellPercentage).toFixed(2) },
        { title: "Buy vs Sell Ratio", value: Number(buyVsSellRatio).toFixed(2), percentage: Number(buyVsSellRatio).toFixed(2) },
        { title: "Buy Volume", value: abbreviate(buyVolume), percentage: Number(buyVsSellRatio).toFixed(2) },
        { title: "Sell Volume", value: abbreviate(sellVolume), percentage: Number(sellPercentage).toFixed(2) },
      ];
    }
    return []
  }, [snapshot])

  useEffect(() => {
    if (!!allTickersList.length) {
      listen([...new Set(isInternalWS ? allTickersList.map((el) => `forex_1m_dx;${el}`) : tickersToSubscribe)], false, false);
    }
    return () => listen([], false, true);
  }, [tickersToSubscribe]);

  const generateTickerLink = (ticker) => {
    if (ticker) {
      return (
        <Link
          className="minute-scanner-ticker"
          to={`/company/${ticker.replace("/", "-")}`}
          onClick={() => {
            breadCrumbChanger({
              name: 'Minute Scanner',
              path: pathname
            })
          }}
        >
          {ticker}
        </Link>
      )
    }
  }

  const getData = useCallback(async () => {
    try {
      let localRows = [];
      let premiumArr = [];
      !!minuteScannerData.length && minuteScannerData.map((each) => {
        const decimals = each.current_price > 0.09 ? 4 : 6
        localRows.push({
          ...each,
          id: "id" + Math.random().toString(16).slice(2),
          date: formatTime(each.converted_time, each.date),
          timePeriod: formatTime(each.converted_time, each.date),
          avgPrice: Math.round(each.weighted_avg_price * 100) / 100,
          currency: generateTickerLink(each.event_symbol.split(":")[0]),
          current: Number(each.current_price).toFixed(decimals),
          change: Number(each.price_change).toFixed(decimals),
          // spread: Number(each.spread).toFixed(decimals),
          vsAverage: Math.round(each.volume_multiplier * 100) / 100,
          premium: Math.round(each.dollar_volume * 100) / 100,
        });
        premiumArr.push(Math.round(each.dollar_volume * 100) / 100);
      });
      if (!concat) {
        setData(localRows);
      }
      if (concat) {
        setData((oldData) => [...oldData, ...localRows]);
      }
    } catch (err) {
      console.log("Error Occured", err);
    }
  }, [minuteScannerData]);

  useEffect(() => {
    getData();
  }, [minuteScannerData]);

  useEffect(() => {
    fetchMinuteScanner(resolution, page, pageSize, activeTicker, concat, debouncedPrem);
  }, [resolution, page, pageSize, activeTicker, debouncedPrem]);

  let premiumArr = [];
  data.map((item) => {
    premiumArr.push(item.premium);
  });
  useLayoutEffect(() => {
    setUpperHeight(upperDiv.current.clientHeight);
  });
  useEffect(() => {
    fetchMinuteScannerTickers();
  }, []);
  useEffect(() => {
    fetchSnapshot(activeTicker)
  }, [activeTicker]);
  function nFormatter(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).toString().replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).toString().replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).toString().replace(/\.0$/, "") + "K";
    }
    return num;
  }

  function valuetext(value) {
    return `${value}m`;
  }
  const handleResolutionChange = (event, newValue) => {
    if (newValue === 60) {
      setResolution("1h");
      setPage(1);
      setConcat(false);
    } else {
      setResolution(`${newValue}m`);
      setPage(1);
      setConcat(false);
    }
  };
  const handlePremiumChange = (event, newValue) => {
    setMinPremium(newValue);
  };

  const handleQueryChange = (event) => {
    setTickerQuery(event.target.value);
  };
  const handelTickerChange = (ticker) => {
    if (!activeTicker.includes(ticker)) {
      setConcat(false);
      setActiveTicker([...activeTicker, ticker]);
      setPage(1);
      setResolution("1m");
    } else {
      const filtered = [...activeTicker].filter((i) => i !== ticker)
      setActiveTicker(filtered)
    }
  };
  function formatTime(time, date) {
    const twentyHourTime = moment(time, ["h:mm A"]).format("HH:mm");

    const preparedDateTime = date.split("T")[0] + " " + twentyHourTime;

    return moment(preparedDateTime).format();
  }
  function formatTimeGrid(str) {
    const startTime = moment.tz(str, timezone);
    let timeToAdd = "1m";
    if (resolution === "1m") {
      timeToAdd = 60;
    }
    if (resolution === "5m") {
      timeToAdd = 60 * 5;
    }
    if (resolution === "15m") {
      timeToAdd = 60 * 15;
    }
    if (resolution === "30m") {
      timeToAdd = 60 * 30;
    }
    if (resolution === "1h") {
      timeToAdd = 60 * 60;
    }
    const endTime = moment.tz(startTime, timezone).add(timeToAdd, "seconds");
    return `${startTime.format("h:mm a")} - ${endTime.format("h:mm a")}`;
  }
  function formatDate(str) {
    const datePart = moment(str).format("YYYY-MM-DD");

    return datePart;
  }

  const columns = [
    {
      field: "date",
      headerName: "DATE",
      // flex: 1,
      maxWidth: 100,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "timePeriod",
      headerName: "TIME PERIOD",
      // flex: 1,
      minWidth: 220,
      valueFormatter: ({ value }) => formatTimeGrid(value),
    },
    {
      field: "weighted_avg_price",
      headerName: "AVG.PRICE",
      flex: 1,
      filterable: false,
      minWidth: 100,
      renderCell: ({ value }) => <span style={{ position: 'relative', left: '20px' }}>{(Math.round(value * 100) / 100).toFixed(2)}</span>
    },
    {
      field: "currency",
      headerName: "CURRENCY",
      flex: 1,
      filterable: false,
      renderCell: ({ value }) => <span style={{ position: 'relative', left: '10px' }}>{value}</span>
    },
    {
      field: "current",
      headerName: "CURRENT",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        const symbol = params.row.event_symbol
        const decimals = params.value > .09 ? 4 : 6
        const value = Number(currentPrices.get(symbol)?.price || params?.value || 0)?.toFixed(decimals) || 0
        return (
          <span style={{ position: 'relative', left: '14px' }}>
            <CssPop comparator={Number(value).toFixed(decimals)} />
          </span>
        )
      }
    },
    {
      field: "change",
      headerName: "CHANGE",
      flex: 1,
      filterable: false,
      renderCell: ({ value }) => <span style={{ position: 'relative', left: '12px' }}>{value == 0 ? 0 : nFormatter(value)}</span>,
    },
    // {
    //   field: "spread",
    //   headerName: "SPREAD",
    //   flex: 1,
    //   filterable: false,
    //   renderCell: ({ value }) => value == 0 ? 0 : `${nFormatter(value)}`,
    // },
    {
      field: "vsAverage",
      headerName: "VS AVERAGE",
      flex: 1,
      filterable: false,
      renderCell: ({ value }) => (
        <span
          style={{
            padding: "5px",
            color: "#40e548",
            position: 'relative',
            left: '19px'
          }}
        >
          {`${(Math.round(value * 100) / 100).toFixed(2)} X`}
        </span>
      ),
    },
    {
      field: "premium",
      headerName: "PREMIUM",
      flex: 1,
      filterable: false,
      renderCell: ({ value }) => (
        <span
          style={{
            border: "2px solid #40e548",
            // marginRight: "5px",
            padding: "5px",
            textAlign: "center",
            width: "80px",
            color: "#40e548",
          }}
        >
          {`${nFormatter(value)}`}
        </span>
      ),
    },
  ];

  function fetchMore() {
    const nextPage = page + 1;
    setConcat(true);
    setPage(nextPage);
  }

  return (
    <Box className={classes.background + ' minuteContainer'}>
      <div ref={upperDiv} className={'upperContainer'}>
        <Typography className={classes.headerTitle}>Minute Scanner</Typography>
        {!loadingTickers ? (
          <div className="filtersContainer">
            {tickerQuery.length !== 0 ? (
              <div className="filters">
                {tickers
                  .filter((item) => {
                    const searchTerm = tickerQuery.toLowerCase();
                    const fullName = item.symbol.toLowerCase();

                    return searchTerm && fullName.includes(searchTerm);
                  })
                  .slice(0, 8)
                  .map((ticker, idx) => {
                    return (
                      <button
                        key={idx}
                        value={ticker.symbol}
                        style={
                          activeTicker.includes(ticker.symbol)
                            ? styles.activeTag
                            : styles.inactiveTag
                        }
                        onClick={() => handelTickerChange(ticker.symbol)}
                      >
                        {ticker.symbol.split(":")[0]}
                      </button>
                    );
                  })}
              </div>
            ) : (
              <div className="filters">
                {tickers.slice(0, 8).map((ticker, idx) => {
                  return (
                    <button
                      key={idx}
                      value={ticker.symbol}
                      style={
                        activeTicker.includes(ticker.symbol)
                          ? styles.activeTag
                          : styles.inactiveTag
                      }
                      onClick={() => handelTickerChange(ticker.symbol)}
                    >
                      {ticker.symbol.split(":")[0]}
                    </button>
                  );
                })}
              </div>
            )}
            <input
              className="pairsInput"
              placeholder="Search Pairs"
              value={tickerQuery}
              onChange={handleQueryChange}
            />
          </div>
        ) : null}

        <div className="candleContainer">
          <div className="paddedContainer">
            <div className="leftContainer">
              <div className="slider1">
                <CustomSliderPremium
                  valueLabelFormat={(value) => nFormatter(value)}
                  size="small"
                  value={minPremium}
                  max={1000000000}
                  aria-label="Small"
                  onChange={handlePremiumChange}
                  valueLabelDisplay="auto"
                  sx={{ color: "#00DC0A" }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={styles.sliderTitle}>
                    Minimum Premium
                  </Typography>
                  <Typography style={styles.sliderTitle}>
                    1 G
                  </Typography>
                </div>
              </div>

              <div className="slider2">
                <CustomSliderCandle
                  valueLabelFormat={(value) => {
                    if (value === 60) {
                      return "1h";
                    } else {
                      return `${value}m`;
                    }
                  }}
                  size="small"
                  defaultValue={1}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  onChange={handleResolutionChange}
                  step={null}
                  max={60}
                  sx={{ color: "#00DC0A" }}
                  marks={[
                    {
                      value: 1,
                    },
                    {
                      value: 5,
                    },
                    {
                      value: 15,
                    },
                    {
                      value: 30,
                    },
                    {
                      value: 60,
                    },
                  ]}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={styles.sliderTitle}>
                    Candle Size
                  </Typography>
                  <Typography style={styles.sliderTitle}> 1 Hour</Typography>
                </div>
              </div>
            </div>
            <div className="rightContainer"></div>
          </div>
        </div>
        <div className="minute-card-wrapper">
          {
            snapshotLoading ? (
              <SnapshotSkeleton />
            ) : (
              cardData.map((i, idx) => {
                return (
                  <MinuteCard data={i} key={idx} />
                )
              })
            )
          }
        </div>
      </div>
      <TradeAlgoTable
        concat={concat}
        rows={data}
        // rows={data}
        columns={columns}
        page={page}
        setPage={setPage}
        setConcat={setConcat}
        loading={loading}
        themeMode="dark"
        sortModel={sortModel}
        setSortModel={setSortModel}
        fetchMore={fetchMore}
        upperHeight={upperHeight}
        resolution={resolution}
      />
    </Box>
  );
}

const stateToProps = (state) => ({
  minuteScannerData: state.minuteApi.minuteApi,
  loading: state.minuteApi.minuteApiLoading,
  tickers: state.minuteApi.tickers,
  loadingTickers: state.minuteApi.loadingTickers,
  currentPrices: isInternalWS ? state.EquitiesSocket.currentPrices : state.dxFeedSocket.socketData,
  snapshot: state.minuteSnapshot.minuteScannerSnapshot,
  snapshotLoading: state.minuteSnapshot.loading,
  timezone: state.userSettings.userSettings.timezone,
});
const dispatchToProps = (dispatch) => ({
  fetchMinuteScanner: (resolution, page, pageSize, activeTicker, concat, premium) =>
    dispatch(minuteApiLoad(resolution, page, pageSize, activeTicker, concat, premium)),
  fetchMinuteScannerTickers: () => dispatch(minuteApiTickersLoad()),
  listen: (symbols, options, disconnect) => dispatch(isInternalWS ? equtiesSocketListen(symbols) : dxFeedSocketListen(symbols, options, disconnect)),
  fetchSnapshot: (tickers) => dispatch(minuteScannerSnapshotLoad(tickers)),
  breadCrumbChanger: (change) => dispatch(breadCrumbChange(change)),
});

export default connect(stateToProps, dispatchToProps)(MinuteScanner);
