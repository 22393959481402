import { makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useMedia } from "react-media";

const mediaQuery = {
  isBigScreen: "screen and (min-width: 1301px)",
  isMediumScreen: "screen and (min-width: 801px) and (max-width: 1300px)",
  isSmallScreen: "screen and (max-width: 800px)",
};

export const StoryCardSkeleton = ({ width = '450px' }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      // width: 'auto', // allow table to stretch full width
      flexGrow: 1, // allow table to stretch full width
      backgroundColor: theme.palette.primary.innerBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      '& > div': {
        height: 'auto !important', // override zero height from DataGrid
        width: 'auto !important', // override zero width from DataGrid
      },
      '& .MuiDataGrid-root': {
        overflow: 'auto', // set table to be responsive
        fontSize: '0.9rem',
      },
    },
    skeleton: {
      backgroundColor: theme.palette.primary.skeleton,
      minHeight: "468px",
      maxHeight: "464px",
      width: "calc((100% - 32px) / 3)",

      "@media (max-width: 1300px)": {
        width: "calc(50% - 8px)",
      },

      "@media (max-width: 800px)": {
        width: "100%",
      }
    }
  }));
  const classes = useStyles();

  const { isBigScreen, isMediumScreen, isSmallScreen } = useMedia({
    queries: mediaQuery,
  });

  return (
    <Paper className={classes.paper} elevation={0}>
      {
        isBigScreen && (
          <>
            <Skeleton className={classes.skeleton} variant="rect" />
            <Skeleton className={classes.skeleton} variant="rect" />
            <Skeleton className={classes.skeleton} variant="rect" />
          </>
        )
      }

      {
        isMediumScreen && (
          <>
            <Skeleton className={classes.skeleton} variant="rect" />
            <Skeleton className={classes.skeleton} variant="rect" />
          </>
        )
      }

      {
        isSmallScreen && (
          <>
            <Skeleton className={classes.skeleton} variant="rect" />
          </>
        )
      }

    </Paper>
  );
};
