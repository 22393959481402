import React from "react";

import "./style.scss";

const PreviousStreams = ({ streams }) => {
  return (
    <div className="previous-streams-page">
      <div className="previous-streams-page-title">Previous Streams</div>

      <div className="previous-streams-page-content">
        {
          streams.map((stream, key) => (
            <div className="previous-stream-element" key={key}>
              <img src={stream.src} alt='video-screenshot' className="previous-stream-element-video" />
              <div className="previous-stream-date">{stream.date}</div>
              <div className="previous-stream-duration">Duration: {stream.duration}</div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default PreviousStreams;