
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const FOREX_COMPANY_DATA_LOAD = "FCD/LOAD/TOP";
export const FOREX_COMPANY_DATA_LOAD_SUCCESS = "FCD/LOAD/TOP/SUCCESS";
export const FOREX_COMPANY_DATA_LOAD_FAILURE = "FCD/LOAD/TOP/FAILURE";

// Action Creators
export const forexCompanyDataLoad = (symbol) => ({
    type: FOREX_COMPANY_DATA_LOAD,
    symbol,
});
export const forexCompanyDataLoadSuccess = (data, loading = true) => ({
    type: FOREX_COMPANY_DATA_LOAD_SUCCESS,
    data,
    loading
});
export const forexCompanyDataLoadFailure = (error) => ({
    type: FOREX_COMPANY_DATA_LOAD_FAILURE,
    error,
});

// Sagas
function* fetchForexCompanyData(action) {
    try {
        yield put(forexCompanyDataLoadSuccess({
            sparklinesByTicker: {},
            tickerBidAsk: {},
            tickerData: [],
            tickerPairs: [],
            tickerPrice: []
        }, true));
        const { symbol } = action
        if (!!symbol) {
            const response = yield axios.get(`${apiURL}/forex/ticker/${encodeURIComponent(symbol.replace('-', '/'))}`, { withCredentials: true }
            );
            yield put(forexCompanyDataLoadSuccess(response?.data || {}, false));
        }
    } catch (error) {
        yield put(forexCompanyDataLoadFailure(error));
    }
}

function* listenForexCompanyDataLoad() {
    yield takeEvery(FOREX_COMPANY_DATA_LOAD, fetchForexCompanyData);
}

// Root Saga
export function* saga() {
    yield all([fork(listenForexCompanyDataLoad)]);
}

const INIT_STATE = {
    forexCompanyData: {
        sparklinesByTicker: {},
        tickerBidAsk: {},
        tickerData: [],
        tickerPairs: [],
        tickerPrice: []
    },
    loading: true
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FOREX_COMPANY_DATA_LOAD_SUCCESS:
            return {
                ...state,
                forexCompanyData: action.data,
                loading: action.loading
            };
        default:
            return state;
    }
};

export default reducer;
