import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/core";
import MainChart from "../mainChart";
import "./company.scss";
import { connect } from "react-redux";
import AISentiment from "./AISentiment";
import { calendarLoad } from "../../appRedux/ducks/calendar";
import moment from "moment";
import IconButton from '@mui/material/IconButton';
import leftArrow from "../../assets/images/arrow-left.png"
import rightArrow from "../../assets/images/arrow-right.png"
import { forexCompanyDataLoad } from "../../appRedux/ducks/forexCompanyData";
import { forexActivityLoad } from "../../appRedux/ducks/forexActivity";
import { historicalDarkflowLoad } from "../../appRedux/ducks/historicalDarkflow";
import { TableSkeleton } from "../common/Skeletons/TableSkeleton";
import MarketMakersTable from "../common/Tables/MarketMakersTable";
import MTZ from "moment-timezone";
import { breadCrumbChange } from "../../appRedux/ducks/breadCrumb";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { getAISentimentClassname } from "../common/Tables/HistoricalActivity";
import SparkLines from "../common/SparkLines";
import { equtiesSocketListen } from "../../appRedux/ducks/equitiesSocket";
import { isInternalWS } from "../../appRedux/actions/helpers";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import CssPop from "../common/Tables/animations/CssPop";
function calculatePerformance(initialPrice, finalPrice) {
  var returnPercentage = ((finalPrice - initialPrice) / initialPrice) * 100;
  return returnPercentage;
}
const createData = (
  pair,
  timePeriod,
  volume,
  totalTrades,
  totalPrice,
  avgPrice
) => {
  return {
    id: "id" + Math.random().toString(16).slice(2),
    pair,
    timePeriod,
    volume,
    totalTrades,
    totalPrice,
    avgPrice,
  };
};
const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.innerBackground,
  },
  headerTitle: {
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
  },
  text: {
    // color: theme.palette.primary.text + " !important",
    color: "#000000 !important",
  },
}));
const flex = 1;

const Company = ({ symbols, breadCrumb, fetchCalendarData, calendar, fetchCompanyData, forexCompanyData,
  getHistoricDarkFlow, currentPrices, listen, fetchForexActivity, forexActivity, historicalDarkflow, timezone, loading, breadCrumbChanger, setSymbol, themeMode }) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const history = useHistory()
  const [sortModel, setSortModel] = useState([
    {
      field: "date_modified",
      sort: "desc",
    },
  ]);
  const sparklineData = useMemo(() => {
    let highestPrice;
    let lowestPrice;
    let sparklines = []
    if (!!forexCompanyData?.sparklinesByTicker && !!symbols.value && !!forexCompanyData?.sparklinesByTicker[symbols.value.replace('-', '/')]) {
      sparklines = forexCompanyData?.sparklinesByTicker[symbols.value.replace('-', '/')].sort((a, b) => a.eventTime - b.eventTime).map((item, i) => {
        if (i === 0) {
          highestPrice = item.high
          lowestPrice = item.low
        }
        if (item.high > highestPrice) {
          highestPrice = item.high
        }
        if (item.low < lowestPrice) {
          lowestPrice = item.low
        }
        return [item.eventTime, Number(item.close)]
      })
    }
    return { sparklines, highestPrice, lowestPrice }
  }, [forexCompanyData, symbols])
  const lastPrice = useMemo(() => {
    return currentPrices?.get(symbols?.value.replace('-', '/') + "{=1m,price=mark}")?.price 
  }, [currentPrices, symbols, forexCompanyData])

  const performance = useMemo(() => {
    const initialPrice = !!forexCompanyData?.tickerPrice[0] ? forexCompanyData?.tickerPrice[0]?.close : 0
    return calculatePerformance(initialPrice, lastPrice)
  }, [lastPrice, symbols, forexCompanyData])
  const SparklineComponent = useMemo(() => {
    const dummyOptions = {
      colors: [true ? '#00DC0A' : "#FF5757"],
      chart: {
        type: "area",
        width: "100%",
        height: 200,
        sparkline: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          show: false
        },
      },
      xaxis: {
        labels: {
          show: false
        }
      },
      grid: {
        row: {
          colors: ["transparent"],
          opacity: 0.5,
        },
        yaxis: {
          lines: {
            show: false,
            colors: ["transparent"],
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },

      tooltip: {
        theme: "dark",
        fixed: {
          enabled: false,
        },
        y: {
          title: {
            // eslint-disable-next-line no-unused-vars
            formatter(seriesName, s) {
              return "";
            },
          },
        },
        x: {
          title: {
            // eslint-disable-next-line no-unused-vars
            formatter(seriesName, s) {
              return "";
            },
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 1.5,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.5,
          opacityFrom: 0.7,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
    };
    return (
      <SparkLines
        series={[
          {
            data: sparklineData.sparklines,
          },
        ]}
        options={dummyOptions}
        type={dummyOptions.chart.type}
        height={dummyOptions.chart.height}
        width={dummyOptions.chart.width}
      />
    )
  }, [sparklineData, symbols.value, forexCompanyData]);
  const tickerPairs = useMemo(() => {
    if (!!forexCompanyData?.tickerPairs) {
      return forexCompanyData?.tickerPairs?.map((item) => item.default_symbol).filter(el => el != null);
    }
    return []
  }, [forexCompanyData, symbols])
  const [currDate, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [startDate, setStartDate] = useState(moment(currDate).startOf('month').format('YYYY-MM-DD'))
  const [trigger, setTrigger] = useState(0)
  const [activityTab, setActivityTab] = useState('historical');
  const isForexPage = useMemo(() => {
    if ((symbols && symbols.value && symbols.value.includes('/')) || (symbols && symbols.value && symbols.value.includes('-'))) {
      return true;
    } else {
      return false;
    }
  }, [symbols]);
  const sortLookup = {
    "pair": (a, b) => (a.event_symbol.toUpperCase() < b.event_symbol.toUpperCase()) ? -1 : (a.event_symbol.toUpperCase() > b.event_symbol.toUpperCase()) ? 1 : 0,
    "volume": (a, b) => Number(a.volume) - Number(b.volume),
    "timePeriod": (a, b) => moment.tz(`${a.date.split('T')[0]} ${a.start_time}`, timezone) - moment.tz(`${b.date.split('T')[0]} ${b.start_time}`, timezone),
    "totalTrades": (a, b) => Number(a.total_trades) - Number(b.total_trades),
    "totalPrice": (a, b) => Number(a.total_price) - Number(b.total_price),
    "avgPrice": (a, b) => Number(a.avg_price) - Number(b.avg_price)
  }
  const endDate = useMemo(() => {
    return moment(startDate).add(1, 'month').format('YYYY-MM-DD')
  }, [startDate]);

  const calendarData = useMemo(() => {
    return !!calendar[currDate] ? calendar[currDate] : []
  }, [calendar, currDate])

  useEffect(() => {
    fetchCompanyData(symbols.value)
    getHistoricDarkFlow('4h', 100, 'popular', false, symbols.value)
    fetchForexActivity(100, true, symbols.value)
  }, [symbols])

  useEffect(() => {
    if (!isForexPage) {
      setActivityTab('historical')
    }
  }, [isForexPage])

  useEffect(() => {
    const dateUsed = moment(currDate)
    if (dateUsed.diff(endDate) >= 0) {
      setStartDate(currDate)
      setTrigger((prev) => prev + 1)
    }
  }, [currDate, startDate])

  useEffect(() => {
    fetchCalendarData(startDate, endDate)
  }, [trigger])

  const goBackToHome = () => {
    history.push(breadCrumb.path);
  };
  const rows = useMemo(() => {
    if (activityTab === 'historical') {
      return historicalDarkflow.length
        ? historicalDarkflow.map((item, i) => {
          return {
            ...item,
            id: i + 1,
            symbol: item.symbol,
            price_detected: item.price_detected,
            ai_sentiment: item.ai_sentiment,
          };
        })
        : [];
    } else {
      let processedRows = [];
      forexActivity.sort(sortLookup[sortModel[0].field]).map((row) => {
        const startTimeEST = `${row.date.split('T')[0]} ${row.start_time.split(' ')[0]}`
        const utcTime = MTZ(startTimeEST, 'YYYY-MM-DD HH:mm:ss', 'America/New_York').utc();
        const timezoneConvertedStart = moment.tz(utcTime, timezone).format('HH:mm A')
        const endTimeEST = `${row.date.split('T')[0]} ${row.end_time.split(' ')[0]}`
        const utcTime2 = MTZ(endTimeEST, 'YYYY-MM-DD HH:mm:ss', 'America/New_York').utc();
        const timezoneConvertedEnd = moment.tz(utcTime2, timezone).format('HH:mm A')
        const timePeriod = `${timezoneConvertedStart} - ${timezoneConvertedEnd}`
        processedRows.push(
          createData(
            row.raw_symbol,
            timePeriod,
            {
              times: Number(row.multiplier).toFixed(2) + "x",
              value: row.volume,
            },
            row.total_trades,
            {
              total_price: row.total_price,
              raw_symbol: row.raw_symbol,
            },
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: row.raw_symbol.substring(0, 3),
            }).format(Math.round(row.avg_price * 100) / 100)
          )
        )
      }
      );
      if (sortModel[0].sort === 'desc') {
        processedRows = processedRows.reverse();
      }
      return processedRows
    }
  }, [activityTab, forexActivity, historicalDarkflow, timezone, sortModel])

  const columns = useMemo(() => {
    if (activityTab === 'historical') {
      return [
        {
          field: "timePeriod",
          headerName: "TIME PERIOD",
          hide: true,
          flex: 1
        },
        {
          field: "date_modified",
          headerName: "TIME ALERTED",
          flex,
          renderCell: (params) => {
            return (
              <div
                style={{
                  position: "relative",
                  left: "3px",
                }}
              >
                <span className={"item-from"}>
                  <div className={clsx(classes.text, "history-item-time")}>
                    {moment.tz(params.value, timezone)
                      .format("MMM Do h:mm:ss A")}
                  </div>
                </span>
              </div>
            );
          },
        },
        {
          field: "symbol",
          headerName: "CURRENCY PAIR",
          flex,
          renderCell: (params) => {
            return (
              <div
                onClick={() => {
                  setSymbol({
                    type: "",
                    value: params.value,
                  });
                  breadCrumbChanger({
                    name: 'Historical Activity',
                    path: pathname
                  })
                }}
                style={{
                  position: "relative",
                  left: "8px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                <Link className={classes.text}
                  style={{ color: "black" }} to={`/company/${params.value.replace("/", "-")}`}>
                  {params.value}
                </Link>
              </div>
            );
          },
        },
        {
          field: "price_detected",
          headerName: "PRICE DETECTED",
          flex,
          renderCell: (params) => {
            return (
              <div
                className={clsx(classes.text)}
                style={{
                  position: "relative",
                  left: "8px",
                }}
              >
                {params.value && parseFloat(params.value).toFixed(8)}
              </div>
            );
          },
          sortComparator: (v1, v2, param1, param2) =>
            parseFloat(Math.round(v1 * 100) / 100) -
            parseFloat(Math.round(v2 * 100) / 100),
        },
        {
          field: "ai_sentiment",
          headerName: "SENTIMENT",
          width: 200,
          renderCell: (params) => {
            return (
              <div
                className={getAISentimentClassname(params.value, themeMode)}
                style={{
                  position: "relative",
                  left: "5px",
                }}
              >
                {params.value}
              </div>
            );
          },
          sortComparator: (v1, v2, param1, param2) => {
            if (v1 < v2)
              return -1;
            if (v1 > v2)
              return 1;
            return 0;
          }
        },
      ];
    } else {
      return [
        {
          field: "date_modified",
          headerName: "TIME ALERTED",
          flex,
          hide: true
        },
        {
          field: "pair",
          headerName: "PAIR",
          flex: 1,
          renderCell: (params) => (
            <div className="forex-activity-ticker">
              <Link
                to={`/company/${params.value.replace('/', '-')}`}
                onClick={() => {
                  breadCrumbChanger({
                    name: 'Forex Activity',
                    path: pathname
                  })
                }}
              >
                <span className={classes.text}>{params.value}</span>
              </Link>
            </div>
          ),
        },
        {
          field: "timePeriod",
          headerName: "TIME PERIOD",
          flex: 1,
          renderCell: (params) => (
            <span className={classes.text}>{params.value}</span>
          )
        },
        {
          field: "volume",
          headerName: "VOLUME",
          flex: 1,
          renderCell: (params) => (
            <div>
              <span
                style={{
                  border: "2px solid #40e548",
                  marginRight: "5px",
                  padding: "5px",
                  color: "#40e548",
                }}
              >
                {params.value.times}
              </span>
              <span className={classes.text}>
                {parseInt(params.value.value).toLocaleString()}
              </span>
            </div>
          ),
        },
        {
          field: "totalTrades",
          headerName: "TOTAL TRADES",
          flex: 1,
          filterable: false,
          renderCell: (params) => (
            <span style={{ position: 'relative', left: '33px' }} className={classes.text}>{params.value}</span>
          )
        },
        {
          field: "totalPrice",
          headerName: "TOTAL PREMIUM",
          flex: 1,
          filterable: false,
          renderCell: ({ value }) => (
            <span className={classes.text}>
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: value.raw_symbol.substring(0, 3),
              }).format(Number(value.total_price).toFixed(2))}
            </span>

          ),
        },
        {
          field: "avgPrice",
          headerName: "AVG. PRICE",
          flex: 1,
          renderCell: (params) => (
            <span style={{ position: 'relative', left: '18px' }} className={classes.text}>{params.value}</span>
          ),
          valueParser: (value, params) => {
            return value.value;
          },
        },
      ];
    }
  }, [activityTab, forexActivity, historicalDarkflow, timezone, sortModel])
  const bidAsk = useMemo(() => {
    let bid, ask
    if (!!(forexCompanyData?.tickerBidAsk[symbols.value.replace('-', '/')])?.length) {
      const item = forexCompanyData?.tickerBidAsk[symbols.value.replace('-', '/')][0];
      bid = item.bidPrice;
      ask = item.askPrice
    }
    return { bid, ask }
  }, [symbols, forexCompanyData])
  useEffect(() => {
    if (isForexPage) {
      listen([...new Set(isInternalWS ? [`forex_1m_dx;${symbols.value}`] : [symbols.value, symbols.value + "{=1m,price=mark}"])], false, false);
    }
    return () => listen([], false, true);
  }, [symbols, isForexPage]);
  return (
    <div className={classes.background + ' company-page'}>
      <div className="company-nav-header">
        <div className="company-nav-header-back-button" onClick={goBackToHome}>
          <LeftOutlined /> {breadCrumb.name}
        </div>
        <div className={classes.headerTitle}>
          {symbols?.value?.replace("-", "/")}
        </div>
      </div>
      {
        isForexPage && (
          <div className="flex justify-between p-15">
            <div className="e-rate-block">
              <div className="w-50 e-content">
                <div className="e-rate">Exchange Rate</div>
                <div className="prices-performance">
                  <span className="price-in"><CssPop comparator={lastPrice} /></span> <span className={`performance-in ${performance >= 0 ? "positive" : "negative"}`}>{performance >= 0 ? "+" : "-"}(<CssPop comparator={Number(performance).toFixed(2)} />)%</span>
                </div>
                <div className="hi-lo-container">
                  <div className="hi-lo">
                    <span className="high-title">
                      Highest Price
                    </span>
                    <span className="high-value">
                      <CssPop comparator={lastPrice > sparklineData.highestPrice ? lastPrice : sparklineData.highestPrice} />
                    </span>
                  </div>

                  <div className="hi-lo">
                    <span className="high-title">
                      Lowest Price
                    </span>
                    <span className="high-value">
                      <CssPop comparator={lastPrice < sparklineData.lowestPrice ? lastPrice : sparklineData.lowestPrice} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-50">
                {SparklineComponent}
              </div>
            </div>

            <div className="b-a-block flex flex-col">
              <div className="flex flex-col">
                <span className="b-a-title">Bid Price</span>
                <span className="b-a-value">
                  <CssPop comparator={Number(currentPrices?.get(symbols.value.replace('-', '/'))?.bidPrice || bidAsk?.bid)} />
                </span>
              </div>
              <div className="flex flex-col">
                <span className="b-a-title">Ask Price</span>
                <span className="b-a-value">
                  <CssPop comparator={Number(currentPrices?.get(symbols.value.replace('-', '/'))?.askPrice || bidAsk?.ask)} />
                </span>
              </div>
            </div>
            <div className="flex flex-col additional-block">
              <div className="additional-info">
                Related Currencies
              </div>
              <div className="content-inner-wrapper">
                {
                  tickerPairs.map((item, index) => (
                    <Link onClick={() => setSymbol({ value: item })} to={item.replace('/', '-')} className="ohlc-row">
                      <div key={index} className="ohlc-row">
                        {item}
                      </div>
                    </Link>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
      <div className="company-container">
        <div className={`full-width flex-start${isForexPage ? '' : ' non-forex-page'}`}>
          <div className={`company-chart-wrapper`}><MainChart /></div>
          {
            isForexPage && (
              <div className={`company-news-wrapper`}>
                <AISentiment ticker={symbols.value.replace("/", "-")} />
                <div className="calendar-container">
                  <div className="e-rate">Upcoming Events</div>
                  <div className="date-wrapper">
                    <span className="num-date"> {moment(currDate).format('DD')}
                      <span className="mm-yy">{moment(currDate).format("MMM YYYY")}</span>
                    </span>
                    <div className="cal-button-wrapper">
                      <IconButton onClick={() => setDate(moment(currDate).subtract(1, 'd').format('YYYY-MM-DD'))} className="arrow-wrapper" color="primary" component="label">
                        <img className="arrow-cal" src={leftArrow} />
                      </IconButton>
                      <IconButton onClick={() => setDate(moment(currDate).add(1, 'd').format('YYYY-MM-DD'))} className="right-arrow arrow-wrapper" color="primary" component="label">
                        <img className="arrow-cal" src={rightArrow} />
                      </IconButton>
                    </div>
                  </div>
                  <div className="cal-wrapper">
                    {
                      calendarData.map((i, index) => (
                        <div key={index} className="calender-data-row">
                          <span> ({i.country}) {i.event}</span>
                          <span> {i.previous} </span>
                        </div>
                      ))
                    }
                  </div>
                  <div onClick={() => history.push('/calendar')} className="cal-view">
                    View Full Calendar
                  </div>
                </div>
                {/* <CompanyNews notifyNewsStatus={notifyNewsStatus} /> */}
              </div>
            )
          }
        </div>
      </div>
      {
        isForexPage && (
          <div className="activity-container">
            <div className="tab-wrapper">
              <span onClick={() => {
                setSortModel([
                  {
                    field: "date_modified",
                    sort: "desc",
                  },
                ]);
                setActivityTab("historical")
              }} className={`activity-tab ${(activityTab === 'historical' || !isForexPage) && 'active-activity-tab'}`}>Historical Activity</span>
              {isForexPage && <span onClick={() => {
                setActivityTab("forex")
              }} className={`activity-tab ${activityTab === 'forex' && 'active-activity-tab'}`}>Forex Activity</span>}
            </div>
            {
              loading ? (
                <div style={{ padding: "0 16px" }}>
                  <TableSkeleton />
                </div>
              )
                : <MarketMakersTable sortModel={sortModel} setSortModel={setSortModel} rows={rows} columns={columns} loading={false} />
            }
          </div>
        )
      }
    </div>
  );
};

const stateToProps = (state) => ({
  symbols: state.symbol.symbol,
  breadCrumb: state.breadCrumb.breadCrumb,
  calendar: state.calendar.calendar,
  forexCompanyData: state.forexCompanyData.forexCompanyData,
  forexActivity: state.forexActivity.data,
  historicalDarkflow: state.historicalDarkflow.historicalDarkflow,
  timezone: state.userSettings.userSettings.timezone,
  loading: state.forexActivity.forexActivityLoading,
  themeMode: state.themeMode.themeMode,
  currentPrices: isInternalWS ? state.EquitiesSocket.currentPrices : state.dxFeedSocket.socketData,
});

const dispatchToProps = (dispatch) => ({
  fetchCalendarData: (startingDate, endingDate) =>
    dispatch(calendarLoad(startingDate, endingDate)),
  fetchCompanyData: (symbol) => dispatch(forexCompanyDataLoad(symbol)),
  getHistoricDarkFlow: (timeStamp, pageSize, type, firstLoad, ticker) =>
    dispatch(historicalDarkflowLoad(timeStamp, pageSize, type, firstLoad, ticker)),
  fetchForexActivity: (pageSize, firstLoad, ticker) => dispatch(forexActivityLoad(pageSize, firstLoad, ticker)),
  breadCrumbChanger: (change) => dispatch(breadCrumbChange(change)),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  listen: (symbols, options, disconnect) => dispatch(isInternalWS ? equtiesSocketListen(symbols) : dxFeedSocketListen(symbols, options, disconnect)),

});


export default connect(stateToProps, dispatchToProps)(Company);
