
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const GET_STORY_LOAD = "GS/LOAD/TOP";
export const GET_STORY_LOAD_SUCCESS = "GS/LOAD/TOP/SUCCESS";
export const GET_STORY_LOAD_FAILURE = "GS/LOAD/TOP/FAILURE";

// Action Creators
export const getStoryLoad = (approved = true) => ({
    type: GET_STORY_LOAD,
    approved
});
export const getStoryLoadSuccess = (data, loading) => ({
    type: GET_STORY_LOAD_SUCCESS,
    data,
    loading
});
export const getStoryLoadFailure = (error) => ({
    type: GET_STORY_LOAD_FAILURE,
    error,
});

// Sagas
function* fetchGetStory(action) {
    try {
        const { approved } = action
        yield put(getStoryLoadSuccess([], true));
        const url = `${apiURL}/stories?approved=${approved}`;
        const response = yield axios.get(url, { withCredentials: true })
        yield put(getStoryLoadSuccess(response?.data || [], false));
    } catch (error) {
        yield put(getStoryLoadFailure(error));
    }
}

function* listenGetStroyLoad() {
    yield takeEvery(GET_STORY_LOAD, fetchGetStory);
}

// Root Saga
export function* saga() {
    yield all([fork(listenGetStroyLoad)]);
}

const INIT_STATE = {
    stories: [],
    loading: true
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_STORY_LOAD_SUCCESS:
            return {
                ...state,
                stories: action.data,
                loading: action.loading
            };
        default:
            return state;
    }
};

export default reducer;
