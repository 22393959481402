/* eslint-disable */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useAuth } from "../../contexts/Auth";
import "./ChatContainer.scss";
import { Input, Row, Col } from "antd";
import { addUserLoad } from "../../appRedux/ducks/addUsername";
import { Card, CardBody } from "reactstrap";
import { ButtonBase, makeStyles } from "@material-ui/core";
import { nameChange } from "../../appRedux/ducks/changeName";
import { useHistory } from "react-router-dom";
import { ChatSkeleton } from "../common/Skeletons/ChatSkeleton";

const useStyles = makeStyles((theme) => ({
  background: {
    // backgroundColor: `${theme.palette.primary.tableBackground} !important`,
    color: theme.palette.primary.text,
  },
  contrast: {
    backgroundColor: `${theme.palette.primary.movement} !important`,
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
}));
function UsernameAdd({
  addUsername,
}) {
  const classes = useStyles();
  const allAuth = useAuth();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  function addUsernameReq() {
    setLoading(true)
    addUsername(username);
    setTimeout(() => {
      allAuth.fetchUser();
      setLoading(false)
      history.push("/live-chat");
    }, 2400);
  }
  if (loading) return <ChatSkeleton />
  return (
    <div
      className={classes.background}
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        top: "112px",
      }}
    >
      <form id="signup-form" onSubmit={addUsernameReq}>
        <div>
          <Card
            className={classes.contrast}
            style={{
              width: "525px",
              height: "280px",
              // background: "#F1F5F9",
              borderRadius: "24px",
              border: "none",
            }}
          >
            <CardBody>
              <Row gutter={[16, 10]}>
                <Col span={24}>
                  <span
                    className={classes.text}
                    style={{
                      display: "flex",
                      placeContent: "stretch space-evenly",
                      fontFamily: "poppins",
                      color: "black",
                      lineHeight: "36px",
                      fontSize: "24px",
                      fontWeight: "600",
                      position: "relative",
                      top: "20px",
                    }}
                  >
                    Enter Your Display Name
                  </span>
                </Col>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  <Input
                    style={{
                      height: "44px",
                      width: "429px",
                      borderRadius: "12px",
                      border: "none",
                    }}
                    value={username}
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    prefix={
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.5 21V19C20.5 17.9391 20.0786 16.9217 19.3284 16.1716C18.5783 15.4214 17.5609 15 16.5 15H8.5C7.43913 15 6.42172 15.4214 5.67157 16.1716C4.92143 16.9217 4.5 17.9391 4.5 19V21"
                          stroke="#555555"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 11C14.7091 11 16.5 9.20914 16.5 7C16.5 4.79086 14.7091 3 12.5 3C10.2909 3 8.5 4.79086 8.5 7C8.5 9.20914 10.2909 11 12.5 11Z"
                          stroke="#555555"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    top: "70px",
                  }}
                  span={24}
                >
                  <ButtonBase
                    onClick={addUsernameReq}
                    disabled={username.length === 0}
                    style={{
                      background:
                        "linear-gradient(101.16deg, #8277DF -7.41%, #8500ED 110.44%)",
                      color: "white",
                      borderRadius: "12px",
                      width: "429px",
                      height: "44px",
                    }}
                    htmltype="submit"
                  >
                    Add Username
                  </ButtonBase>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </form>
    </div>
  );
}
const stateToProps = (state) => ({
  usernameAdded: state.addUsername,
});
const dispatchToProps = (dispatch) => ({
  addUsername: (username) => dispatch(addUserLoad(username)),
  nameChanger: (change) => dispatch(nameChange(change)),
});
export default connect(stateToProps, dispatchToProps)(UsernameAdd);
