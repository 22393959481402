import { makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


export const DashboardTrendingButtonSkeleton = ({ width = '450px' }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      // width: 'auto', // allow table to stretch full width
      flexGrow: 1, // allow table to stretch full width
      backgroundColor: theme.palette.primary.innerBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      '& > div': {
        height: 'auto !important', // override zero height from DataGrid
        width: 'auto !important', // override zero width from DataGrid
      },
      '& .MuiDataGrid-root': {
        overflow: 'auto', // set table to be responsive
        fontSize: '0.9rem',
      },
      "@media (max-width: 800px)": {
        flexWrap: "wrap",
        justifyContent: "flex-start",
      },
    },
    skeleton: {
      backgroundColor: theme.palette.primary.skeleton,
      minHeight: "40px",
      maxHeight: "40px",
      width: "calc((100% - 32px) / 5)",

      "@media (max-width: 800px)": {
        minWidth: "100px",
        marginBottom: "8px",
        marginRight: "8px",

        "&:last-child": {
          marginRight: 0
        }
      }
    }
  }));
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      <Skeleton className={classes.skeleton} variant="rect" />
      <Skeleton className={classes.skeleton} variant="rect" />
      <Skeleton className={classes.skeleton} variant="rect" />
      <Skeleton className={classes.skeleton} variant="rect" />
      <Skeleton className={classes.skeleton} variant="rect" />
    </Paper>
  );
};
