/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import TabHeaders2 from "./TabHeaders2";
import CompanyHistory from "./CompanyHistory";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component="div">{children}</Typography>}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  darkBackground: {
    // backgroundColor: theme.palette.primary.tableBackground,
    backgroundColor: "#131722",
    height: "calc(100vh - 345px)",
    minHeight: 600,
    // "@media only screen and (max-width: 992px)": {
    //   height: "calc(100vh - 262px)",
    // }
  },
  background: {
    backgroundColor: theme.palette.primary.contrastBackground,
    color: theme.palette.primary.text,
    "& .ant-card-body": {
      backgroundColor: theme.palette.primary.chartBackground,
      color: theme.palette.primary.text,
    },
  },
  text: {
    color: theme.palette.primary.text,
  },
}));
const Company = (props) => {
  const {
    symbol,
    company,
    ticker,
    securityMetrics,
    securityPrice,
    news,
    currentMarketStatus,
  } = props;

  const [lastPrice, setLastPrice] = useState("");
  const classes = useStyles();

  return (
    <div className="row">
      <div className="col-12" style={{ padding: 0 }}>
        <CompanyHistory
          setLastPrice={setLastPrice}
          lastPrice={lastPrice}
          securityPrice={securityPrice}
          currentMarketStatus={currentMarketStatus}
        />
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  symbol: state.symbol.symbol,
});

export default connect(stateToProps, null)(Company);
