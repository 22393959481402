import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const CALENDAR_LOAD = "CALENDAR/LOAD/TOP";
export const CALENDAR_LOAD_SUCCESS = "CALENDAR/LOAD/TOP/SUCCESS";
export const CALENDAR_LOAD_FAILURE = "CALENDAR/LOAD/TOP/FAILURE";

// Action Creators
export const calendarLoad = (startingDate, endingDate) => ({
  type: CALENDAR_LOAD,
  startingDate,
  endingDate,
});
export const calendarLoadSuccess = (data, loading) => ({
  type: CALENDAR_LOAD_SUCCESS,
  data,
  loading,
});
export const calendarLoadFailure = (error) => ({
  type: CALENDAR_LOAD_FAILURE,
  error,
  loading: false,
});

// Sagas
function* fetchCalendar(action) {
  try {
    yield put(calendarLoadSuccess({}, true));

    const { startingDate, endingDate } = action;

    const url = `${apiURL}/forex/calendar?start_date=${startingDate}&end_date=${endingDate}`
    const response = yield axios.get(url, { withCredentials: true });
    console.log("--response", response);
    yield put(calendarLoadSuccess(response.data, false));
  } catch (error) {
    yield put(calendarLoadFailure(error));
  }
}

function* listenCalendarLoad() {
  yield takeEvery(CALENDAR_LOAD, fetchCalendar);
}
// Root Saga
export function* saga() {
  yield all([fork(listenCalendarLoad)]);
}

const INIT_STATE = {
  calendar: {},
  calendarLoading: false,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CALENDAR_LOAD_SUCCESS:
      return {
        ...state,
        calendar: action.data,
        calendarLoading: action.loading,
      };
    case CALENDAR_LOAD_FAILURE:
      return {
        ...state,
        calendar: {},
        calendarLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
