import { makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.primary.skeletonBackground,
    borderRadius: 0,
    // width: 'auto', // allow table to stretch full width
    flexGrow: 1, // allow table to stretch full width
    '& > div': {
      height: 'auto !important', // override zero height from DataGrid
      width: 'auto !important', // override zero width from DataGrid
    },
    '& .MuiDataGrid-root': {
      overflow: 'auto', // set table to be responsive
      fontSize: '0.9rem',
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  skeleton: {
    backgroundColor: theme.palette.primary.skeleton,
    height: '6em',
    width: "calc(25% - 8px)"
  }
}));

export const SnapshotSkeleton = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      <Skeleton className={classes.skeleton} variant="rect" height={67} />
      <Skeleton className={classes.skeleton} variant="rect" height={67} />
      <Skeleton className={classes.skeleton} variant="rect" height={67} />
      <Skeleton className={classes.skeleton} variant="rect" height={67} />
    </Paper>
  );
};
