import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import { DashboardTrendingButtonSkeleton } from "../../common/Skeletons/DashboardTrendingButtonSkeleton";

import './index.scss';

const useStyles = makeStyles((theme) => ({
  smallButton: {
    background: theme.palette.primary.smallButton
  },
}));

const TrendingArea = ({ loading, trendingUp, trendingDown, themeMode }) => {

  const classes = useStyles();

  return (
    <div className="trending-area">
      <div className="half-width">
        <div className="trending-area-label">
          TRENDING UP
          <span className="ai-score">
            <span>AI Score</span>
            <Tooltip title="Market Makers has a proprietary AI Score that evaluates the strength of any currency based on hundreds of data points. This score is specific to the time period selected in the upper right corner of the dashboard." placement="bottom-start">
              <InfoOutlinedIcon />
            </Tooltip>
          </span>
        </div>

        {
          loading ? (
            <DashboardTrendingButtonSkeleton />
          ) : (
            <div className="trending-buttons">
              {
                trendingUp.map((up, index) => (
                  <div className={classes.smallButton + ' trending-button'} key={index}>
                    <span className="trending-ticker"><span className="trending-up">{up.currency}</span> +{up.score}</span>
                    <ArrowUpwardIcon style={{ color: themeMode === 'dark' ? "#FFFFFF" : "#79797B" }} />
                  </div>
                ))
              }
            </div>
          )
        }
      </div>

      <div className="half-width">
        <div className="trending-area-label space-between">
          <span className="space-between">
            TRENDING down
            <span className="ai-score">
              <span>AI Score</span>
              <Tooltip title="Market Makers has a proprietary AI Score that evaluates the strength of any currency based on hundreds of data points. This score is specific to the time period selected in the upper right corner of the dashboard." placement="bottom-start">
                <InfoOutlinedIcon />
              </Tooltip>
            </span>
          </span>
        </div>

        {
          loading ? (
            <DashboardTrendingButtonSkeleton />
          ) : (
            <div className="trending-buttons">
              {
                trendingDown.map((up, index) => (
                  <div className={classes.smallButton + ' trending-button'} key={index}>
                    <span className="trending-ticker"><span className="trending-down">{up.currency}</span> {up.score}</span>
                    <ArrowDownwardIcon style={{ color: themeMode === 'dark' ? "#FFFFFF" : "#79797B" }} />
                  </div>
                ))
              }

            </div>
          )
        }
      </div>
    </div>
  )
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(TrendingArea);