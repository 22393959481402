import React from "react";
import { makeStyles } from "@material-ui/core";

import "./index.scss";

const useStyles = makeStyles((theme) => ({
  activeHoursButton: {
    background: theme.palette.primary.headerButtonBg,
    color: theme.palette.primary.headerButtonText + ' !important'
  }
}));

const TimeStamp = ({ hours, goHours }) => {
  const classes = useStyles();

  return (
    <div className="timestamp-buttons">
      <span className={`timestamp-hour ${hours === '1h' ? classes.activeHoursButton : ''}`} onClick={() => goHours('1h')}>1 Hr</span>
      <span className={`timestamp-hour ${hours === '4h' ? classes.activeHoursButton : ''}`} onClick={() => goHours('4h')}>4 Hrs</span>
      <span className={`timestamp-hour ${hours === '1d' ? classes.activeHoursButton : ''}`} onClick={() => goHours('1d')}>Daily</span>
      <span className={`timestamp-hour ${hours === '1w' ? classes.activeHoursButton : ''}`} onClick={() => goHours('1w')}>Weekly</span>
    </div>
  )
}

export default TimeStamp;