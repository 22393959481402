import React, { useState } from "react";

import ThankYouComponent from "./ThankYouComponent";
import "./index.scss";

const OptionsMentorshipThankYou = () => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  return (
    <div className="options-mentorship-thank-you">
      <ThankYouComponent loading={loading} />
    </div>
  )
}

export default OptionsMentorshipThankYou;
