import React, { useState } from "react";
import Button from "../common/Button";
import { useAuth } from "../../contexts/Auth";
import "./SignIn.scss";
import { Alert } from "antd";
import Loader from "../common/Loader";
import logo from "./logoTa.png"
import { Link } from "react-router-dom";
import { useMedia } from "react-media";

// const url = process.env["REACT_APP_RELEASE_STAGE"] === 'staging' ? 'https://dev.marketmakers.com' : 'https://dashboard.marketmakers.com';
const url = 'http://www.marketmakers.com';
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
const SignIn = () => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const [values, setValues] = useState({
    email: "",
  });
  const { sendPasswordResetEmail } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  const [errored, setErrored] = useState(false);
  const resetPassword = async (email) => {
    try {
      var actionCodeSettings = {
        url: `${url}/signin`,
        handleCodeInApp: false,
      };
      Promise.race([
        sendPasswordResetEmail(email, actionCodeSettings),
        new Promise((resolve, reject) => setTimeout(reject, 5000)),
      ])
        .then((response) => {
          if (response) {
            setSubmitted(true);
          } else {
            setErrored(true);
          }
        })
        .catch((e) => {
          setErrored(true);
        });
    } catch (error) {
    }
  };
  const handleSubmit = () => {
    setTimeout(() => {
      resetPassword(values.email);
    }, 1000);
  };
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };
  return (
    <>
      {submitted && (
        <Alert
          className="mb-3"
          message="Email has been sent successfully."
          type="success"
        />
      )}
      {errored && (
        <Alert
          className="mb-3"
          message="There was a problem resetting your password."
          type="error"
        />
      )}
      <div className="background-glow" />
      <div className="signin-container">
        <div style={{ borderBottom: "1px solid hsla(0deg, 0%, 100%, 0.12)", position: "sticky", top: 0, background: "#060203", zIndex: 4 }}>
          <div className="signin-navbar">
            <a style={{ cursor: 'pointer' }} href={url}>
              <img src={logo} className="logo-MM" alt="logo" />
            </a>
          </div>
        </div>
        <div className="signin-content">
          <div className="signin-content-tiny">
            <div className="signin-caption"></div>

            <form onKeyDown={onKeyDownHandler} onSubmit={handleSubmit}>
              <div className={"input-row"}>
                <input
                  // aria-id="name"
                  value={values.email}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      email: e.target.value,
                    })
                  }
                  autoComplete="username"
                  type={"text"}
                  className={"input-field full-width"}
                  placeholder={"Your email"}
                />
              </div>
              <div className={"input-row border-button"}>
                <input type="button" value="Reset Password" onClick={handleSubmit} className="login-button" />
              </div>
              <div className="input-row small-text">
                <Link to="/signin" style={{ marginBottom: isMobile ? '10px' : '0px', fontSize: 14 }} >
                  <span className={"sign-up"} >Back to Sign in.</span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
