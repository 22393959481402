
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const AI_SENTIMENT = "AI_SENTIMENT/LOAD/TOP";
export const AI_SENTIMENT_SUCCESS = "AI_SENTIMENT/LOAD/TOP/SUCCESS";
export const AI_SENTIMENT_FAILURE = "AI_SENTIMENT/LOAD/TOP/FAILURE";

// Action Creators
export const aiSentimentLoad = (ticker, resolution) => ({
  type: AI_SENTIMENT,
  ticker,
  resolution
});
export const aiSentimentLoadSuccess = (data, loading) => ({
  type: AI_SENTIMENT_SUCCESS,
  data,
  loading
});
export const aiSentimentLoadFailure = (error) => ({
  type: AI_SENTIMENT_FAILURE,
  error,
  loading: false
});

// Sagas
function* fetchDashboard(action) {
  try {
    const { ticker, resolution } = action;

    const url = `${apiURL}/forex/ai-sentiment/${ticker}/${resolution}`;

    const response = yield axios.get(url, { withCredentials: true });
    yield put(aiSentimentLoadSuccess(response.data, false));

  } catch (error) {
    yield put(aiSentimentLoadFailure(error));
  }
}

function* listenDashboardLoad() {
  yield takeEvery(AI_SENTIMENT, fetchDashboard);
}

// Root Saga
export function* saga() {
  yield all([fork(listenDashboardLoad)]);
}

const INIT_STATE = {
  aiSentiment: 0,
  aiSentimentLoading: false,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AI_SENTIMENT_SUCCESS:
      return {
        ...state,
        aiSentiment: action.data.ai_sentiment,
        aiSentimentLoading: action.loading,
      };
    case AI_SENTIMENT_FAILURE:
      return {
        ...state,
        aiSentiment: 0,
        aiSentimentLoading: false
      }
    default:
      return state;
  }
};

export default reducer;
