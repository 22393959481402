import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useMemo, useState } from "react";
import base64 from "../../common/Logo/base64";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActions, CardMedia, Tooltip } from "@material-ui/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import CheckIcon from '@mui/icons-material/Check';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton  {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  background: 'black',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StoriesCard = ({ data, approved, approveStory }) => {
  const [expanded, setExpanded] = useState(false);
  const description = useMemo(() => {
    const result = []
    const split = data.description.split('.')
    let combined = ''
    split.forEach((str, index) => {
      combined += str + '. '
      if (index % 4 === 0 && index !== 0) {
        result.push(combined)
        combined = ''
      }
      if (index % 4 !== 0 && index === str.length - 1) {
        result.push(combined)
      }
    })
    return !!result.length ? result : [data.description]
  }, [data])
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card
      elevation={0}
      className="mm-success-card"
    >
      {approved && (
        <Tooltip title="Approve this Story">
          <IconButton onClick={() => {
            approveStory(data.id)
          }} style={{ position: 'absolute', top: 10, right: 10, background: 'black' }}>
            <CheckIcon />
          </IconButton>
        </Tooltip>
      )}
      <CardContent className={"mm-success-card-content"} style={{ background: "#F5F5F5" }}>
        <div
          style={{
            height: "200px",
            width: '200px',
            paddingTop: "8px",
            margin: 'auto'
          }}
        >
          <CardMedia
            component="img"
            height="100%"
            image={data.image_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = base64;
            }}
          />
        </div>
        <span className="break-line">
          {data?.title}
        </span>
      </CardContent>
      <CardActions disableSpacing>
        <Typography
          sx={{
            fontFamily: "'Circular Std'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "18px",
            position: 'absolute',
            left: 12,
            bottom: 12,
            letterSpacing: "-0.02em",
            color: "#AEAEB2",
          }}
        >
          {data?.full_name + " - " + moment(data?.date_submitted).format("YYYY-MM-DD")}
        </Typography>
        <ExpandMore
          className="expand-more-icon"
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>

          {
            description.map((p, i) => (
              <Typography key={i} paragraph>
                {p}
              </Typography>
            ))
          }
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default StoriesCard;

const styles = {
  image: {
    height: "100%",
  },
};
