import React, { useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./index.scss";
import { useAuth } from "../../../contexts/Auth";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";

const ThankYouComponent = ({ userSettings, setUserSettings }) => {
  const { loading, hasForexMentorshipNovice, hasForexMentorshipAccelerator, hasForexMentorshipMaster } = useAuth()
  const { tier } = useParams()
  useEffect(() => {
    if (!loading) {
      if (tier === 'novice')
        setUserSettings({
          ...userSettings,
          om_novice_sign_up: true,
          // if they have the options mentoship flag already then its a renewal
          om_novice_renewed: hasForexMentorshipNovice
        })
    } else if (tier === 'accelerator') {
      setUserSettings({
        ...userSettings,
        om_accelerator_sign_up: true,
        // if they have the options mentoship flag already then its a renewal
        om_accelerator_renewed: hasForexMentorshipAccelerator
      })
    } else if (tier === 'master') {
      setUserSettings({
        ...userSettings,
        om_master_sign_up: true,
        // if they have the options mentoship flag already then its a renewal
        om_master_renewed: hasForexMentorshipMaster
      })

    }
  }, [loading])
  const capitalized = tier.charAt(0).toUpperCase() + tier.slice(1)
  return (
    <div className="thank-you-page-inner">
      {
        loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="success" size={200} className="custom-loader" />
            <div className="options-mentorship-loading-text">
              You're one step closer to trading success!<br />
              We're processing your account creation.
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <svg className="custom-check-icon" width="266" height="266" viewBox="0 0 266 266" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1682_14089)">
                <path d="M132.997 0C59.5448 0 0 59.5448 0 132.997C0 206.45 59.5448 265.995 132.997 265.995C206.45 265.995 265.995 206.45 265.995 132.997C265.995 59.5448 206.45 0 132.997 0ZM132.997 249.322C68.7543 249.322 16.6727 197.24 16.6727 132.997C16.6727 68.7543 68.7543 16.6779 132.997 16.6779C197.24 16.6779 249.322 68.7595 249.322 133.003C249.322 197.246 197.24 249.327 132.997 249.327V249.322Z" fill="#00DC0A" />
                <path d="M201.799 81.5031C198.868 78.5615 195.827 75.7238 193.031 72.6626C191.659 71.1658 190.63 71.4152 189.45 72.6002C182.751 79.341 176.067 86.0974 169.384 92.8487C167.242 94.7872 165.267 96.9077 163.209 98.9346C150.289 111.657 137.509 124.526 124.687 137.347C114.184 147.846 103.644 158.308 93.2181 168.879C91.5706 170.547 90.661 170.412 89.0967 168.817C80.6096 160.169 72.0029 151.635 63.4639 143.033C62.5855 142.15 62.0918 141.64 60.8289 142.763C57.5442 145.699 54.4311 148.771 51.5206 152.076C50.5279 153.204 50.7566 153.662 51.5622 154.748C52.9239 156.572 54.5714 158.074 56.1617 159.638C62.6999 166.047 69.0145 172.673 75.7294 178.915C78.4735 181.467 80.859 184.497 83.6136 187.085C88.7329 191.898 92.61 193.135 97.6617 188.145C122.598 163.505 147.29 138.616 172.055 113.799C178.468 107.37 184.84 100.894 191.228 94.439C194.861 90.8685 198.452 87.2616 202.147 83.7535C203.301 82.6621 202.449 82.1527 201.804 81.5083L201.799 81.5031Z" fill="#00DC0A" />
              </g>
              <defs>
                <clipPath id="clip0_1682_14089">
                  <rect width="266" height="266" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div className="options-mentorship-thank-you-done-title">Thank You For <br />Signing Up!</div>
            <div className="options-mentorship-thank-you-done-description">
              This is your official confirmation.<br />
              Thanks for joining <span className="thank-you-strong-text">Forex Mentorship: {capitalized}</span><br />
              Check back in the next 12 hours to access the features fully.
            </div>

            <Link to="/home/4h"><Button className="back-to-dashboard-button">Back to Dashboard</Button></Link>
          </div>
        )
      }
    </div>
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(ThankYouComponent);
