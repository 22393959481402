import { Helmet } from 'react-helmet';
import React, { useState } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.liveSessionCardBackground} !important`,
    color: theme.palette.primary.text,
  },
  contrast: {
    backgroundColor: `${theme.palette.primary.movement} !important`,
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },

}));
function LiveSessions() {
  const classes = useStyles()
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const video = params.get("video");
  const [currentVideo, setCurrentVideo] = useState({
    live: true,
    url: '<iframe src="https://player.vimeo.com/video/636575410?h=372a674979&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442" width="100%" height="800px" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Intro Walkthrough-A holistic view of the platform"></iframe>',
    date: "October 19, 2021 1:05 PM",
    description:
      "This video will be a holistic rundown on the  features of the platform as well as some basic information on how to utilize them.",
    duration: "19 mins",
    link: "https://vimeo.com/636575410",
  });
  
  return (
    <div className="container-xl-live">
      <Helmet>
        <title>Basic Training | MarketMakers</title>
      </Helmet>
      <div className="row">
        <div
          style={{ width: "100%" }}
          className="col-lg-12 col-xl-12 col-md-12 col-sm-12"
        >
          <div className="row" style={{ marginBottom: "20px" }}>
            <iframe
              className="col-xl-9 col-lg-9 col-md-8 col-sm-12"
              src="https://vimeo.com/event/2773820/embed/be71a12721"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{ height: "700px", width: "100%", borderRadius: "0" }}
            ></iframe>
            <iframe
              src="https://vimeo.com/event/2773820/chat/be71a12721"
              style={{ width: "100%", height: "700px" }}
              className="col-xl-3 col-lg-3 col-md-4 col-sm-12"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveSessions;
