import React, { useEffect, useCallback, useState, useMemo } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { TableSkeleton } from "../../common/Skeletons/TableSkeleton";
import { Box, Typography } from "@mui/material";
import { historicalDarkflowLoad } from "../../../appRedux/ducks/historicalDarkflow";
import { symbolSetter } from "../../../appRedux/ducks/symbol";
import DarkflowTabs from "./DarkflowTabs";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import MarketMakersTable from "./MarketMakersTable";
import "./HistoricalActivity.scss";
import { breadCrumbChange } from "../../../appRedux/ducks/breadCrumb";
import TimeStamp from "../../homePage/TimeStamp";
export const getAISentimentClassname = (value, themeMode) => {
    if (value.toUpperCase().includes('BULLISH')) {
      return 'very-bullish'
    }

    if (value.toUpperCase().includes('BEARISH')) {
      return 'very-bearish'
    }

    if (value.toUpperCase().includes('NEUTRAL')) {
      if (themeMode === 'dark') {
        return 'neutral-dark'
      }
      return 'neutral-light'
    }
}
const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.innerBackground} !important`,
    color: theme.palette.primary.text,
    borderRadius: 0,
    "& .text-boldest": {
      backgroundColor: theme.palette.primary.innerBackground,
      color: theme.palette.primary.text,
    },
    "& .text-bold": {
      backgroundColor: theme.palette.primary.innerBackground,
      color: theme.palette.primary.text,
    },
  },
  popupBackground: {
    backgroundColor: `${theme.palette.primary.innerBackground}!important`,
    color: theme.palette.primary.text,
    boxShadow: theme.palette.primary.popBoxShadow,
    "& .text-boldest": {
      backgroundColor: theme.palette.primary.innerBackground,
      color: theme.palette.primary.text,
    },
    "& .text-bold": {
      backgroundColor: theme.palette.primary.innerBackground,
      color: theme.palette.primary.text,
    },
  },
  text: {
    // color: theme.palette.primary.text + " !important",
    color: "#000000 !important",
  },
  standardPadding: {
    padding: "16px!important"
  },
  normalText: {
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
  },
  activeHoursButton: {
    background: theme.palette.primary.headerButtonBg,
    color: theme.palette.primary.headerButtonText + ' !important'
  }
}));

const HistoricalActivity = ({
  getHistoricDarkFlow,
  historicalDarkflow,
  setSymbol,
  loading,
  themeMode,
  timezone,
  breadCrumbChanger,
}) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(25);
  const hardHistory = useHistory();

  // const [cap, setCap] = useState("");
  // const [up, setUp] = useState(window.location.pathname.includes("Up"));
  // const trendingStatus = useMemo(() => {
  //   return up ? "up" : "down";
  // }, [up]);

 
  const flex = 1;

  const columns = useMemo(() => {
    return [
      {
        field: "date_modified",
        headerName: "TIME ALERTED",
        flex,
        renderCell: (params) => {
          return (
            <div
              style={{
                position: "relative",
                left: "3px",
              }}
            >
              <span className={"item-from"}>
                <div className={clsx(classes.text, "history-item-time")}>
                  {moment.tz(params.value, timezone)
                    .format("MMM Do h:mm:ss A")}
                </div>
              </span>
            </div>
          );
        },
      },
      {
        field: "symbol",
        headerName: "CURRENCY PAIR",
        flex,
        renderCell: (params) => {
          return (
            <div
              onClick={() => {
                setSymbol({
                  type: "",
                  value: params.value,
                });
                breadCrumbChanger({
                  name: 'Historical Activity',
                  path: pathname
                })
              }}
              style={{
                position: "relative",
                left: "8px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              <Link className={classes.text}
                style={{ color: "black" }} to={`/company/${params.value.replace("/", "-")}`}>
                {params.value}
              </Link>
            </div>
          );
        },
      },
      {
        field: "price_detected",
        headerName: "PRICE DETECTED",
        flex,
        renderCell: (params) => {
          return (
            <div
              className={clsx(classes.text)}
              style={{
                position: "relative",
                left: "8px",
              }}
            >
              {params.value && parseFloat(params.value).toFixed(8)}
            </div>
          );
        },
        sortComparator: (v1, v2, param1, param2) =>
          parseFloat(Math.round(v1 * 100) / 100) -
          parseFloat(Math.round(v2 * 100) / 100),
      },
      {
        field: "ai_sentiment",
        headerName: "SENTIMENT",
        width: 200,
        renderCell: (params) => {
          return (
            <div
              className={getAISentimentClassname(params.value, themeMode)}
              style={{
                position: "relative",
                left: "5px",
              }}
            >
              {params.value}
            </div>
          );
        },
        sortComparator: (v1, v2, param1, param2) => {
          if (v1 < v2)
            return -1;
          if (v1 > v2)
            return 1;
          return 0;
        }
      },
    ];
  })

  const rows = useMemo(() => {
    return historicalDarkflow.length
      ? historicalDarkflow.map((item, i) => {
        return {
          ...item,
          id: i + 1,
          symbol: item.symbol,
          price_detected: item.price_detected,
          ai_sentiment: item.ai_sentiment,
        };
      })
      : [];
  }, [historicalDarkflow]);

  const [sortModel, setSortModel] = useState([
    {
      field: "date_modified",
      sort: "desc",
    },
  ]);

  const [hours, setHours] = useState('4h');
  const [type, setType] = useState('popular');

  const goHours = (value) => {
    hardHistory.push(`/historical/${value}`);
  }

  const { time } = useParams();

  const getMoreRows = useCallback(() => {
    getHistoricDarkFlow(hours, pageSize, type, false)
  }, [getHistoricDarkFlow, hours, pageSize, type]);

  useEffect(() => {
    if (time) {
      setHours(time);
      console.log({time, pageSize, type})
      getHistoricDarkFlow(time, pageSize, type, true);

    }
  }, [time, pageSize, type])
  return (
    <Box className={classes.background}>
      <InfiniteScroll
        dataLength={historicalDarkflow.length}
        next={getMoreRows}
        hasMore={true}
        scrollableTarget="scrollable-target"
        style={{ overflow: 'none' }}
      >
        <div className={classes.standardPadding + ' historical-activity-header'}>
          <Typography className={classes.normalText}>Historical Activity</Typography>

          <TimeStamp hours={hours} goHours={goHours} />
        </div>

        <div className="historical-type-selector">
          <select className="api-type-selector" value={type} onChange={(e) => setType(e.target.value)}>
            <option value='default'>All</option>
            <option value='popular'>Popular</option>
            <option value='g7'>G7 Pairs</option>
          </select>
        </div>
        {
          loading ? (
            <div style={{ padding: "0 16px" }}>
              <TableSkeleton />
            </div>
          )
            : <MarketMakersTable sortModel={sortModel} setSortModel={setSortModel} rows={rows} columns={columns} loading={false} />
        }

      </InfiniteScroll>
    </Box>
  );
};

const stateToProps = (state) => ({
  historicalDarkflow: state.historicalDarkflow.historicalDarkflow,
  loading: state.historicalDarkflow.loading,
  themeMode: state.themeMode.themeMode,
  timezone: state.userSettings.userSettings.timezone,
});

const dispatchToProps = (dispatch) => ({
  getHistoricDarkFlow: (timeStamp, pageSize, type, firstLoad) =>
    dispatch(historicalDarkflowLoad(timeStamp, pageSize, type, firstLoad)),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  breadCrumbChanger: (change) => dispatch(breadCrumbChange(change)),
});

export default connect(stateToProps, dispatchToProps)(HistoricalActivity);
