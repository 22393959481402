import React, { useEffect, useRef, useState } from "react";
import { useMedia } from "react-media";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { symbolSetter } from "../../appRedux/ducks/symbol";
import { breadCrumbChange } from "../../appRedux/ducks/breadCrumb";

import CurrencyStrengthModal from "./CurrencyStrengthModal";
import "./currencyStrengthTable.scss";

const mediaQuery = {
  isMobile: "(max-width: 767px)",
};

const CurrencyStrengthTable = ({ loading, data, setSymbol, breadCrumbChanger }) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const { pathname } = useLocation();
  const hardHistory = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const formatCurrencyLabel = (label) => {
    const labels = label.split("/");
    if (isMobile) {
      return labels[1] ? labels[1] : '';
    }

    return label;
  }

  const doBlockAction = (symbol) => {
    // if (isMobile) {
    //   symbol = symbol.replace('/', '-');
    //   console.log(symbol)
    //   setSymbol({
    //     value: symbol,
    //     type: "",
    //   });

    //   breadCrumbChanger({
    //     name: 'Currency Strength',
    //     path: pathname
    //   });

    //   const url = `/company/${symbol}`;
    //   hardHistory.push(url);
    // } else {
    //   setOpenModal(true)
    // }
  }

  return (
    <div className="currency-strength-table">
      {
        data.map((item, key) => (
          <div className="currency-strength-table-column" key={key}>
            {
              item.greenList.map((greenItem, greenKey) => (
                greenItem.raw_symbol ? (
                  <div className="currency-strength-table-green-item" key={greenKey} onClick={doBlockAction.bind(this, greenItem.raw_symbol)}>
                    <div className="currency-strength-table-item-currency">{formatCurrencyLabel(greenItem.raw_symbol)}</div>
                    <div className="currency-strength-table-item-percentage">+{greenItem.performance.toFixed(4)}</div>
                    <div className="currency-strength-table-item-price">{greenItem.currentPrice.toFixed(4)}</div>
                  </div>
                ) : (
                  <div className="currency-strength-table-green-item visibility-hidden" key={greenKey}></div>
                )
              ))
            }

            <div className="currency-strength-table-default-item">
              <div className="currency-strength-table-item-currency">{item.currency}</div>
              <div className="currency-strength-table-item-percentage">{item.score}</div>
            </div>

            {
              item.redList.map((redItem, redKey) => (
                <div className="currency-strength-table-red-item" key={redKey} onClick={doBlockAction.bind(this, redItem.raw_symbol)}>
                  <div className="currency-strength-table-item-currency">{formatCurrencyLabel(redItem.raw_symbol)}</div>
                  <div className="currency-strength-table-item-percentage">{redItem.performance.toFixed(4)}</div>
                  <div className="currency-strength-table-item-price">{redItem.currentPrice.toFixed(4)}</div>
                </div>
              ))
            }
          </div>
        ))
      }

      <CurrencyStrengthModal open={openModal} handleClose={handleClose} />
    </div>
  )
}

const stateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  breadCrumbChanger: (change) => dispatch(breadCrumbChange(change)),
});
export default connect(stateToProps, mapDispatchToProps)(CurrencyStrengthTable);