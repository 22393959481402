/* eslint-disable */
import { LicenseInfo } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import Routes from "./routes/index";
import { Helmet } from "react-helmet";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { themeSetter } from "./appRedux/ducks/themeMode";
import { connect } from "react-redux";
import DocusignModal from './components/modals/DocusignModal/index'

LicenseInfo.setLicenseKey(
  '39597b806ce8caf91d01194c6a78c92aT1JERVI6MzEzMDUsRVhQSVJZPTE2NjY0NjcwOTYwMDAsS0VZVkVSU0lPTj0x',
);

const options = {
  autoConfig: true,
  debug: true,
};

const fbq = ReactPixel;

const App = ({ themeMode, themeToggle }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const location = useLocation();
  /*
  useEffect(() => {
    fbq.init("876551646440035", options);
    ReactPixel.init("1091661061298376", options);
  }, []);
  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      fbq.pageView();
      ReactPixel.pageView();

      if (pathname.includes("thank-you") && !location.search) {
        ReactPixel.track("Purchase", { currency: "USD", value: 1 });
      } else if (
        pathname.includes("thank-you") &&
        location.search.includes("secondOrder=true")
      ) {
        ReactPixel.track("Purchase", { currency: "USD", value: 58 });
      }
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
*/
  useEffect(() => {
    const utmParams = new URLSearchParams(location.search);
    localStorage.setItem("utm_source", utmParams.get("utm_source"));
    localStorage.setItem("utm_campaign", utmParams.get("utm_campaign"));
    localStorage.setItem("utm_term", utmParams.get("utm_term"));
    localStorage.setItem("utm_content", utmParams.get("utm_content"));
  }, []);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 403) {
        history.push("/signout");
      }

      return Promise.reject(error);
    }
  );
  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
        },
      },
      MuiPopover: {
        paper: {
          background: themeMode === 'dark' ? "#000" : 'white'
        }
      }
    },
    palette: {
      palette: {
        type: themeMode,
      },
      primary: {
        // background-color: rgba(25, 27, 28, 0.5);
        // color: rgb(201, 194, 186);

        // light: will be calculated from palette.prmary.main,
        main: themeMode === "dark" ? "#001128" : "#0i07bff",
        table: themeMode === "dark" ? "#001528" : "#FFFFFA",
        text: themeMode === "dark" ? "#FFFFFF" : "#000000",
        tableText: themeMode === "dark" ? "#FFFFFF" : "#FFFFFF",
        subMenuText: themeMode === "dark" ? "#FFFFFF" : "#000000",
        secondaryText: themeMode === "dark" ? "#cfd1ff" : "#3c3c4a",
        background: themeMode === "dark" ? "rgb(28, 30, 32)" : "#2F2E31",
        innerBackground: themeMode === "dark" ? "#171817" : "#CCCCCC",
        headerButtonBg: themeMode === "dark" ? "#242426" : "#79797B",
        headerButtonText: themeMode === "dark" ? "#00DC0A" : "#FFFFFF",
        smallButton: themeMode === "dark" ? "#242426" : "#FFFFFF",
        // contrastBackground: themeMode === 'dark' ? "rgba(25, 27, 28, 0.5)" : "white",
        contrastBackground: themeMode === 'dark' ? "rgba(25, 27, 28, 0.5)" : "#79797B",
        chartBackground: themeMode === "dark" ? "#202020" : "white",
        customSearch: themeMode === "dark" ? "#202020" : "#f1f5f9",
        boxShadow: themeMode === 'dark' ? "0 -2px 10px rgba(0, 0, 0, 1)" : "0 0 10px #e9ebed !important",
        popBoxShadow: themeMode === "dark" ? "0 6px 17px 0 rgba(0, 0, 0, 0.25)" : "0 6px 17px 0 rgba(0, 46, 96, 0.09)",
        // hover: themeMode === "dark" ? "#3A3A3D" : "#F5F5F5",
        hover: themeMode === "dark" ? "#3A3A3D" : "#2F2E31",
        seperator: themeMode === "dark" ? "#363637" : "#dee1e6",
        specialInput: themeMode === "dark" ? "#3A3A3D" : "white",
        slider: themeMode === "dark" ? "#3d3a3df2" : "#dfd0d073",
        specialBackground: themeMode === 'dark' ? '#181818' : 'white',
        orderBookGreen: themeMode === "dark" ? "rgba(173, 247, 164, 0.12)" : "#adf7a4cf",
        orderBookRed: themeMode === "dark" ? "rgba(134, 10, 10, 0.28)" : "#ffc4c4",
        appHeader: themeMode === "dark" ? "#2C302E" : "rgba(255,255,255,0.8)",
        tabHeaderText: themeMode === "dark" ? "#FFFFFF" : "#A1A7BB",
        tableBackground: themeMode === "dark" ? "rgba(21, 23, 23, 0.5)" : "#79797B",
        liveSessionCardBackground: themeMode === "dark" ? "rgba(21, 23, 23, 0.5)" : "#ffffff",
        skeleton: themeMode === "dark" ? "#3A3A3D" : "grey",
        skeletonBackground: themeMode === "dark" ? "#1a1717" : "#cccccc",
        tableHeaderText: themeMode === "dark" ? "white" : "grey",
        searchBg: themeMode === "dark" ? "rgb(177 177 177 / 71%)" : "rgba(255, 255, 255, 0.5)",
        searchBg2: themeMode === "dark" ? "rgb(177 177 177 / 71%)" : "#F1F1F1",
        bb: themeMode === "dark" ? "rgb(60, 64, 67)" : "",
        movement: themeMode === "dark" ? "#3e444d" : "#F1F5F9",
        os: themeMode === "dark" ? "rgba(21, 23, 23, 0.5)" : "rgba(255, 255, 255, 0.5)",
        innerWrapper: themeMode === "dark" ? "rgb(1 0 2)" : "white",
        greyTitles: themeMode === "dark" ? "white" : "#64748B",
        description: themeMode === "dark" ? "white" : "#555555",
        tableHeader: themeMode === "dark" ? "white" : "#b7b4c7",
        unusual: themeMode === "dark" ? "white" : "#475569",
        boxBackground: themeMode === "dark" ? "#2F2E31" : "#79797B",
        // rgb(1 0 2) !important
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#ffcc00",
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  });
  // useEffect(() => {
  //   var lastTime = new Date().getTime();
  // const timer = setInterval(function () {
  //     var currentTime = new Date().getTime();
  //     if (currentTime > lastTime + 3000 * 8) {
  //       window.location.reload()
  //     } else {
  //       // console.log('i am awake --')
  //     }
  //     lastTime = currentTime;
  //   }, 3000);
  //   return () => clearInterval(timer);
  // },[])
  useEffect(() => {
    const themeStorage = localStorage.getItem("MM_theme");
    if (themeStorage) {
      themeToggle(themeStorage);
    } else {
      localStorage.setItem("MM_theme", themeMode);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("MM_theme", themeMode)
  }, [themeMode])

  return useMemo(() => {
    return (
      <ThemeProvider theme={theme}>
        {process.env.REACT_APP_GOOGLEANALYTICS && (
          <Helmet>
            <title>MarketMakers</title>
            {/* <meta name="description" content="MarketMakers is a fintech analytics company powering the open finance revolution. We democratize access to the world's most valuable financial data." /> */}
          </Helmet>
        )}
        <DocusignModal />
        <Routes />
      </ThemeProvider>
    );
  }, [theme]);
};


const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  themeToggle: (themeMode) => dispatch(themeSetter(themeMode)),

})

export default connect(stateToProps, dispatchToProps)(App);
