import React, { useEffect, useState, useMemo } from "react";
import NDatePicker from "./Field/DatePicker";
import moment from "moment";
import "./option-scanner.scss";
import { connect } from "react-redux";
import { lastPriceLoad } from "../../appRedux/ducks/polyLastPrice";
import { optionExpiryLoad } from "../../appRedux/ducks/optionExpirations";
import { searchLoad } from "../../appRedux/ducks/search";
import TickerSearch from "./TickerSearch";
import useDebounce from "./useDebounce";
import { optionChainLoad } from "../../appRedux/ducks/optionChain";
import { equitiesPriceLoad } from "../../appRedux/ducks/equities";
import { Helmet } from "react-helmet";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CollapsibleTable from "../common/Tables/CollapsableOptionsTable";
import { useParams } from "react-router-dom";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import CssPop from "../common/Tables/animations/CssPop";
import { useMedia } from "react-media";
import history from "../TradingView/history";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { addUserSettingsLoad } from "../../appRedux/ducks/userSettings";
import { equtiesSocketListen } from "../../appRedux/ducks/equitiesSocket";
import { isInternalWS } from "../../appRedux/actions/helpers";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.os} !important`,
  },
  contrast: {
    backgroundColor: `${theme.palette.primary.movement} !important`,
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
}));
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
export function returnRows(socketData, data = []) {
  return data.map((row) => {
    const lastPrice = Number(socketData.get(row.symbol)?.price || row?.lastPrice || 0).toFixed(2)
    const priceAdded = row?.price_added || 0;
    const change = Math.round(Number(lastPrice) * 100) / 100 - row.openPrice;
    const openPrice = !!row.openPrice ? row.openPrice : 0

    let changePercent =
      Math.round(Number(lastPrice) * 100) / 100 - Number(openPrice);
    changePercent = (changePercent / openPrice) * 100;

    const changeSinceAdded = Math.round(Number(lastPrice) * 100) / 100 - priceAdded;
    let changePercentSinceAdded = Math.round(Number(lastPrice) * 100) / 100 - Number(priceAdded);
    changePercentSinceAdded = (changePercentSinceAdded / priceAdded) * 100;

    const bid = Number(socketData.get(row.symbol)?.bidPrice || row.bid).toFixed(2)
    const ask = Number(socketData.get(row.symbol)?.askPrice || row.ask).toFixed(2)
    return {
      ...row,
      changeSinceAdded,
      changePercentSinceAdded,
      bid,
      lastPrice,
      ask,
      change,
      changePercent,
      rsi: Math.round(row.rsi)
    };
  });
}
const OptionScanner = ({
  searchedSymbols,
  optionExpiry,
  lastPrice,
  optionChain,
  getOptionExpirations,
  getLastPrice,
  getOptionChainData,
  searchAllSymbols,
  price,
  marketStatus,
  loadPrice,
  loading,
  optionsLoading,
  dxFeedListen,
  socketData,
  themeMode,
  userSettings,
  setUserSettings,
}) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const classes = useStyles();
  const search = useLocation().search;
  const { sym } = useParams();
  const [date, setDate] = useState("");
  const [symbol, setSymbol] = useState(sym);
  const [ticker, setTicker] = useState(sym);
  const [active, setActive] = useState("all");
  const [midpoint, setMidpoint] = useState(0);
  const [midpointChange, setMidpointChange] = useState(false);

  const debouncedSymbol = useDebounce(symbol, 500);
  const optionChainData = useMemo(() => {
    return !!optionChain.length ? optionChain : [];
  }, [JSON.stringify(optionChain), ticker, date]);
  const formattedOptionsData = useMemo(() => {
    const firstStrike = optionChainData[0] && optionChainData[0]?.strike;
    const lastStrike =
      optionChainData[optionChainData.length - 1] &&
      optionChainData[optionChainData.length - 1]?.strike;
    let calls = optionChainData.filter((item) => item.cp === "C");
    var closestCall = calls.length
      ? calls.reduce(function (r, a, i, aa) {
        return i &&
          Math.abs(aa[r].strike - lastPrice) < Math.abs(a.strike - lastPrice)
          ? r
          : i;
      }, -1)
      : 0;

    const bestCalls = calls.slice(
      closestCall + midpoint - 5 >= 0 ? closestCall + midpoint - 5 : 0,
      closestCall + midpoint + 5
    );

    const strikeString = `${bestCalls[0] ? bestCalls[0]?.strike.toFixed(2) : ""}  - ${bestCalls[bestCalls.length - 1]
      ? bestCalls[bestCalls.length - 1]?.strike.toFixed(2)
      : ""
      }`;

    const nextStrikesUp = calls.slice(
      closestCall + midpoint - 5 >= 0 ? closestCall + midpoint : 0,
      closestCall + midpoint + 10
    );

    const nextUpString = `${nextStrikesUp[0] ? nextStrikesUp[0]?.strike : ""
      } - ${nextStrikesUp[nextStrikesUp.length - 1]
        ? nextStrikesUp[nextStrikesUp.length - 1]?.strike
        : ""
      }`;

    const nextStrikesDown = calls.slice(
      closestCall + midpoint - 10 >= 0 ? closestCall + midpoint - 10 : 0,
      closestCall + midpoint
    );

    const nextDownString = `${nextStrikesDown[0] ? nextStrikesDown[0]?.strike : ""
      } - ${nextStrikesDown[nextStrikesDown.length - 1]
        ? nextStrikesDown[nextStrikesDown.length - 1]?.strike
        : ""
      }`;

    let puts = optionChainData.filter((item) => item.cp === "P");

    const bestPuts = puts.slice(
      closestCall + midpoint - 5 >= 0 ? closestCall + midpoint - 5 : 0,
      closestCall + midpoint + 5
    );

    const subs = bestCalls.concat(bestPuts).map((item) => item.symbol);
    return {
      calls: bestCalls,
      puts: bestPuts,
      subs,
      firstStrike,
      lastStrike,
      currentString: strikeString,
      upString: nextUpString,
      upStringDisabled:
        lastStrike === bestPuts[bestPuts.length - 1]?.strike || false,
      downString: nextDownString,
      downStringDisabled: firstStrike === bestCalls[0]?.strike || false,
    };
  }, [optionChainData, active, midpoint, lastPrice]);

  useEffect(() => {
    if (formattedOptionsData.subs.concat([ticker]).length > 1) {
      const optionTickers = isInternalWS ? formattedOptionsData.subs.concat([ticker]).map((ticker) => `options_tns_dx;${ticker}`) : formattedOptionsData.subs.concat([ticker])
      dxFeedListen(optionTickers, true)
    }
  }, [formattedOptionsData.subs, ticker])
  useEffect(() => {
    return () => dxFeedListen([], true, true)
  }, [])
  const liveRowData = useMemo(() => {
    const calls = returnRows(socketData, formattedOptionsData.calls)
    const puts = returnRows(socketData, formattedOptionsData.puts)
    return { calls, puts };
  }, [optionChainData, socketData, formattedOptionsData.calls, formattedOptionsData.puts]);
  const livePrice = useMemo(() => {
    return (
      socketData?.get(ticker)?.value?.askPrice || lastPrice
    );
  }, [socketData, lastPrice, ticker]);

  const performance = useMemo(() => {
    let lastPriceUsed = livePrice
    let compare = {
      "pre-open": parseFloat(
        price?.close_price || price?.prev_close_price
      ).toFixed(2),
      open: parseFloat(price.open_price).toFixed(2),
      after: parseFloat(price.open_price).toFixed(2),
      closed: parseFloat(price.open_price).toFixed(2),
    }[marketStatus];
    return {
      present: lastPriceUsed,
      change: Number(lastPriceUsed - compare).toFixed(2),
      percent: (((lastPriceUsed - compare) / compare) * 100)?.toFixed(2),
      positive: Number(lastPriceUsed - compare) >= 0,
    };
  }, [marketStatus, price, ticker, livePrice]);


  const expDates = useMemo(() => {
    return optionExpiry.expirationDates.length
      ? optionExpiry.expirationDates
      : [];
  }, [JSON.stringify(optionExpiry), ticker]);
  useEffect(() => {
    const urlDate = new URLSearchParams(search).get("date");
    if (expDates.length && optionExpiry.ticker === ticker) {
      // if there is no date in the url, set the date to the first exp date
      if (!urlDate || !moment.utc(urlDate).isAfter(moment.utc())) {
        history.push(`/option-chain/${symbol.toUpperCase()}?date=${moment.utc(expDates[0]).format("MM/DD/YY")}`);
        setDate(moment.utc(expDates[0]).format("MM/DD/YY"));
      }
    }
  }, [expDates, ticker]);
  useEffect(() => {
    const urlDate = new URLSearchParams(search).get("date");
    // if there is a date in the url and the date is not before today then set the date
    if (urlDate && moment.utc(urlDate).isAfter(moment.utc())) {
      setDate(urlDate)
    }
  }, [])

  useEffect(() => {
    getLastPrice(ticker);
    loadPrice(ticker);
    getOptionExpirations(ticker);
  }, [ticker]);

  useEffect(() => {
    if (ticker && date) {
      setMidpoint(0);
      getOptionChainData(ticker, date);
      history.push(`/option-chain/${symbol.toUpperCase()}?date=${moment(date).format("MM/DD/YY")}`);
    }
  }, [date, ticker]);

  useEffect(() => {
    searchAllSymbols(debouncedSymbol);
  }, [debouncedSymbol]);
  const DateAndFilter = useMemo(() => {
    return (
      <>
        <div className="dashboard-datepicker">
          <span className={clsx(classes.text, "symbol")} style={{ marginRight: 14, marginLeft: 50 }}>
            Expiration Date
          </span>
          <NDatePicker
            availableDates={expDates}
            onChange={(e) => {
              const date = moment(e)
              const isValid = date.isValid()
              if (isValid) setDate(e)
            }}
            value={date}
          />
        </div>

        <div className={"option-scanner-buttons"}>
          <span
            onClick={() => setActive("all")}
            className={`option-scanner-button ${active === "all" ? "active" : ""
              }`}
          >
            All
          </span>
          <span className={"separator-borders"} />
          <span
            onClick={() => setActive("calls")}
            className={`option-scanner-button ${active === "calls" ? "active" : ""
              }`}
          >
            Calls
          </span>
          <span className={"separator-borders"} />
          <span
            onClick={() => setActive("puts")}
            className={`option-scanner-button ${active === "puts" ? "active" : ""
              }`}
          >
            Puts
          </span>
        </div>
      </>
    );
  }, [expDates, date, active]);
  const livePriceComponent = useMemo(() => {
    return (
      <span className={clsx(classes.text, "option-scanner-robin-hood-wrapper")}>
        <CssPop comparator={`$${Number(livePrice)?.toFixed(2)}`} />
      </span>
    );
  }, [livePrice]);
  const Tables = useMemo(() => {
    return (
      <>
        {active !== "puts" && (
          <CollapsibleTable
            loading={optionsLoading || !date}
            expDate={date}
            symbol={symbol}
            calls={true}
            rows={liveRowData.calls}
            livePrice={livePrice}
            midpointChange={midpointChange}
            themeMode={themeMode}
            swingTrades={false}
          />
        )}
        {active !== "calls" && (
          <>
            {active === "all" && <br />}
            <CollapsibleTable
              loading={optionsLoading || !date}
              livePrice={livePrice}
              expDate={date}
              symbol={symbol}
              calls={false}
              rows={liveRowData.puts}
              midpointChange={midpointChange}
              themeMode={themeMode}
              swingTrades={false}
            />
          </>
        )}
      </>
    );
  }, [active, liveRowData, optionsLoading, midpointChange, themeMode]);
  return (
    <div className="container">
      <Helmet>
        <title>Option Chain | Market Makers</title>
      </Helmet>
      <div className={clsx(classes.background, "option-scanner-filter-box")}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          className={"option-scanner-filter-box-part1"}
        >
          <div className="option-scanner-symbol-selector">
            <label
              className={clsx(classes.text, "symbol")}
              style={{
                textAlign: "center",
                position: "relative",
              }}
            >
              Symbol
            </label>
            <TickerSearch
              userSettings={userSettings}
              options={searchedSymbols}
              loading={loading}
              setTicker={setTicker}
              symbol={symbol}
              setSymbol={setSymbol}
              setUserSettings={setUserSettings}
            />
          </div>

          <div
            style={{ textAlign: "center" }}
            className="option-scanner-price-area"
          >
            <span style={{ whiteSpace: "nowrap" }}>
              <span
                style={{
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {livePriceComponent}
                <span
                  style={{
                    marginLeft: "8px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "21px",
                    color: performance.positive ? "rgba(13, 173, 96, 1)" : "red",
                  }}
                >
                  {performance.positive
                    ? "+ " + performance.change
                    : + performance.change}
                </span>
                <span
                  style={{
                    marginLeft: "8px",
                    color: performance.positive
                      ? "rgba(13, 173, 96, 1)"
                      : "red",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                >
                  {performance.positive
                    ? "+ " + performance.percent
                    : performance.percent}{" "}
                  %
                </span>
              </span>
            </span>
          </div>
        </div>
        <div
          style={{ position: "relative" }}
          className="option-scanner-date-area"
        >
          {DateAndFilter}
        </div>
      </div>
      <br />
      <div
        className={clsx(classes.background, "row")}
        style={{ borderRadius: 16 }}
      >
        <div style={{ textAlign: "center" }} className="col-4">
          <IconButton
            disabled={formattedOptionsData.downStringDisabled}
            onClick={() => {
              setMidpoint(midpoint - 5);
              setMidpointChange(true)
              setTimeout(() => {
                setMidpointChange(false)
              }, 300)
            }}
          >
            <ArrowBackIosIcon style={{ fill: themeMode === "dark" ? "white" : 'black' }} />
          </IconButton>
          {!formattedOptionsData.downStringDisabled && !isMobile && (
            <span className={clsx(classes.text, "up-strikes")}>
              Strike {formattedOptionsData.downString}
            </span>
          )}
        </div>
        <span style={{ left: isMobile ? '-30px' : '' }} className={clsx(classes.text, "col-4 current-strike")}>
          {" "}
          Strike {formattedOptionsData.currentString}
        </span>
        <div style={{ textAlign: "center" }} className="col-4">
          {!formattedOptionsData.upStringDisabled && !isMobile && (
            <span className={clsx(classes.text, "up-strikes")}>
              Strike {formattedOptionsData.upString}
            </span>
          )}
          <IconButton
            disabled={formattedOptionsData.upStringDisabled}
            onClick={() => {
              setMidpoint(midpoint + 5);
              setMidpointChange(true)
              setTimeout(() => {
                setMidpointChange(false)
              }, 300)
            }}
          >
            <ArrowForwardIosIcon style={{ fill: themeMode === "dark" ? "white" : 'black' }} />
          </IconButton>
        </div>
      </div>
      <br />
      {Tables}
    </div>
  );
};

const stateToProps = (state) => ({
  searchedSymbols: state.searchSymbols.searchSymbols,
  loading: state.searchSymbols.symbolsLoading,
  lastPrice: state.lastPrice.lastPrice,
  optionExpiry: state.optionExpiry,
  marketStatus: state.marketStatus.marketStatus,
  optionChain: state.optionChain.optionChain,
  price: state.equities.price,
  optionsLoading: state.optionChain.loading,
  socketData: isInternalWS ? state.EquitiesSocket.currentPrices : state.dxFeedSocket.socketData,
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  getLastPrice: (symbol) => dispatch(lastPriceLoad(symbol)),
  searchAllSymbols: (symbol) => dispatch(searchLoad(symbol)),
  getOptionExpirations: (symbol) => dispatch(optionExpiryLoad(symbol)),
  getOptionChainData: (symbol, date) => dispatch(optionChainLoad(symbol, date)),
  dxFeedListen: (symbols, options, disconnect) => dispatch(isInternalWS ? equtiesSocketListen(symbols) : dxFeedSocketListen(symbols, options, disconnect)),
  loadPrice: (ticker) => dispatch(equitiesPriceLoad(ticker)),
  setUserSettings: (settings, firstRender) => dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(OptionScanner);
