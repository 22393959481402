import React, { useMemo } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import "./tabs.scss";
function OptionContractTabs({themeMode, activeTab,setActiveTab}) {
  
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  },[themeMode])
  return (
    <div style={{borderBottom: '1px solid transparent', marginBottom: '5px'}} className="tabs tabs--justify tabs--bordered-bottom">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
            style={{
              color: activeTab === "1" ? color : "#A2A3A5",
              fontFamily: "inter",
              fontSize: "16px",
            }}
          >
            Summary
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
            style={{
              color: activeTab === "2" ? color : "#A2A3A5",
              fontFamily: "inter",
              fontSize: "16px",
              whiteSpace: "nowrap",
            }}
          >
            Chart
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
}
export default OptionContractTabs;
