import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import moment from "moment-timezone";

import { getPercent, format4Digits } from "../utilities";
import { uniqBy } from "../utilities/objects";
import SparkLines from "../common/SparkLines";
import CssPop from "../common/Tables/animations/CssPop";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { breadCrumbChange } from "../../appRedux/ducks/breadCrumb";
import { isInternalWS } from "../../appRedux/actions/helpers";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "./VerticalCard.scss";

const VerticalCard = ({ card, currentPrices, setSymbol, timeStamp, timezone, breadCrumbChanger, from, noFooter = false, isListView = false }) => {
  const { pathname } = useLocation();
  const symbol = card.symbol.replace("/", "-");
  const percentage = getPercent(((currentPrices.get(card.symbol)?.price || card.currentPrice) - card.open) / card.open);
  const cardType = useMemo(() => {
    if (card.aiSentiment.toUpperCase().includes('BULLISH')) {
      return 'bullish';
    }

    if (card.aiSentiment.toUpperCase().includes('BEARISH')) {
      return 'bearish';
    }

    if (card.aiSentiment.toUpperCase().includes('NEUTRAL')) {
      return 'neutral';
    }
  }, [card]);

  const getUniqueSeries = () => {
    const percision = card.sparklines[0].close >= 0.09 ? 4 : 6;
    let arr = [...(card.sparklines || [])].sort((a, b) => a.time - b.time).map((el) => {
      return [el.time, Number(el.close).toFixed(percision)]
    }
    );
    return arr;
  }

  const symbolClick = () => {
    setSymbol({
      value: symbol,
      type: "",
    });

    breadCrumbChanger({
      name: from === 'currency-strength' ? 'Currency Strength' : 'Dashboard',
      path: pathname
    });
  }

  const Component = useMemo(() => {
    const percision = card.sparklines[0].close >= 0.09 ? 4 : 6;
    const dummyOptions = {
      colors: [percentage >= 0 ? '#00DC0A' : "#FF5757"],
      chart: {
        type: "area",
        width: "100%",
        height: 200,
        sparkline: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        tickAmount: percision,
        labels: {
          formatter: function (value) {
            return ((value * 1000 + Number.EPSILON) / 1000).toFixed(percision);
          },
          rotate: 0,
          style: { colors: ["#8e8e93"], fontSize: "14px" },
        },
      },
      xaxis: {
        tickAmount: 5,
        labels: {
          formatter: function (value) {
            if (timeStamp === '1w') {
              return moment(value).tz(timezone).format('DD/MM');
            } else {
              return moment(value).tz(timezone).format('HH:mm');
            }
          },
          rotate: 0,
          style: { colors: ["#8e8e93"], fontSize: "14px" },
        },
      },
      grid: {
        row: {
          colors: ["#e5e5e5", "transparent"],
          opacity: 0.5,
        },
        yaxis: {
          lines: {
            show: false,
            colors: ["transparent"],
          },
        },

        xaxis: {
          lines: {
            show: false,
          },
        },
      },

      tooltip: {
        theme: "dark",
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            // eslint-disable-next-line no-unused-vars
            formatter(seriesName, s) {
              return "";
            },
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 1.5,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.5,
          opacityFrom: 0.7,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
    };
    return (
      <SparkLines
        series={[
          {
            data: getUniqueSeries(),
          },
        ]}
        options={dummyOptions}
        type={dummyOptions.chart.type}
        height={dummyOptions.chart.height}
        width={dummyOptions.chart.width}
      />
    )
  }, [cardType, timezone]);

  const ListViewComponent = useMemo(() => {
    const dummyOptions = {
      colors: [percentage >= 0 ? '#00DC0A' : "#FF5757"],
      chart: {
        type: "area",
        width: 200,
        height: 100,
        sparkline: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        show: false
      },
      xaxis: {
        labels: {
          show: false
        }
      },
      grid: {
        row: {
          colors: ["transparent"],
          opacity: 0.5,
        },
        yaxis: {
          lines: {
            show: false,
            colors: ["transparent"],
          },
        },

        xaxis: {
          lines: {
            show: false,
          },
        },
      },

      tooltip: {
        theme: "dark",
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          show: false,
          title: {
            // eslint-disable-next-line no-unused-vars
            formatter(seriesName, s) {
              return "";
            },
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 1.5,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.5,
          opacityFrom: 0.7,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
    };
    return (
      <SparkLines
        series={[
          {
            data: getUniqueSeries(),
          },
        ]}
        options={dummyOptions}
        type={dummyOptions.chart.type}
        height={dummyOptions.chart.height}
        width={dummyOptions.chart.width}
      />
    )
  }, [cardType, timezone]);

  return (
    !isListView ? (
      <div className="vertical-card">
        <div className="card-header">
          <div className="card-header-symbol">
            <Link
              onClick={symbolClick}
              className="ticker"
              to={`/company/${symbol}`}
            >
              {card.symbol}
            </Link>
            {
              from === 'currency-strength' && (
                <StarOutlineIcon className='currency-strength-star-outline-icon' />
              )
            }
          </div>
          <div className="card-header-price">
            <span className="card-header-space-between">
              <span className="price" style={{ display: "flex" }}>
                <CssPop
                  comparator={Number(
                    currentPrices.get(card.symbol)?.price || card.currentPrice
                  ).toFixed(4)}
                />
              </span>
              <span className={`percentage-${percentage >= 0 ? 'green' : 'red'}`}>({percentage}%)</span>
            </span>
            {
              from === 'currency-strength' && (
                <Link
                  onClick={symbolClick}
                  className="currency-strength-view-more"
                  to={`/company/${symbol}`}
                >
                  View details <ChevronRightIcon />
                </Link>
              )
            }
          </div>
        </div>

        {/* <div className="splitter" /> */}

        <div className="chart-content">
          {Component}
        </div>
        <div className="card-info">
          <div className="card-info-row-sentiment">
            <div className="sentiment-col">
              <div className="card-info-label">Al Sentiment</div>
              <div
                className={`card-info-value${card.aiSentiment.toUpperCase() === "BULLISH"
                  ? " very-bullish"
                  : card.aiSentiment.toUpperCase() === "VERY BULLISH"
                    ? " very-bullish"
                    : card.aiSentiment.toUpperCase() === "NEUTRAL"
                      ? " neutral"
                      : card.aiSentiment.toUpperCase() === "BEARISH"
                        ? " bearish"
                        : card.aiSentiment.toUpperCase() === "VERY BEARISH"
                          ? " bearish"
                          : " neutral"
                  }`}
              >
                {card.aiSentiment}
              </div>
            </div>
            <div className="sentiment-col">
              <div className="card-info-label">News Sentiment</div>
              <div
                className={`card-info-value${card.newsSentiment.toUpperCase() === "BULLISH"
                  ? " very-bullish"
                  : card.newsSentiment.toUpperCase() === "VERY BULLISH"
                    ? " very-bullish"
                    : card.newsSentiment.toUpperCase() === "NEUTRAL"
                      ? " neutral"
                      : card.newsSentiment.toUpperCase() === "BEARISH"
                        ? " bearish"
                        : card.newsSentiment.toUpperCase() === "VERY BEARISH"
                          ? " bearish"
                          : " neutral"
                  }`}
              >
                {card.newsSentiment}
              </div>
            </div>
          </div>
          <div className="splitter" />

          <div className="card-info-row">
            <div className="card-info-label">Bid</div>
            <div className="card-info-value">
              <CssPop
                comparator={Number(
                  currentPrices.get(card.symbol)?.bidPrice || card.bid
                ).toFixed(4)}
              />
            </div>
            <div className="card-info-label">Ask</div>
            <div className="card-info-value">
              <CssPop
                comparator={Number(
                  currentPrices.get(card.symbol)?.askPrice || card.ask
                ).toFixed(4)}
              />
            </div>
          </div>
          {
            noFooter && (
              <div className="card-info-row">
                <div className="card-info-label"><strong>Open</strong></div>
                <div className="card-info-value">
                  {format4Digits(card.open)}
                </div>
              </div>
            )
          }
          {
            noFooter && (
              <div className="card-info-row">
                <div className="card-info-label"><strong>High</strong></div>
                <div className="card-info-value">
                  {format4Digits(card.high)}
                </div>
              </div>
            )
          }
          {
            noFooter && (
              <div className="card-info-row">
                <div className="card-info-label"><strong>Low</strong></div>
                <div className="card-info-value">
                  {format4Digits(card.low)}
                </div>
              </div>
            )
          }
        </div>

        {
          !noFooter && (
            <div className="card-footer">
              <div className="price-info">Open
                <div className="price-info-value">
                  {format4Digits(card.open)}
                </div>

              </div>
              <div className="price-info">High
                <div className="price-info-value">
                  {format4Digits(card.high)}
                </div>
              </div>
              <div className="price-info">Low
                <div className="price-info-value">
                  {format4Digits(card.low)}
                </div>
              </div>
            </div>
          )
        }

        {
          noFooter && <div style={{ marginTop: 10, marginBottom: 10, height: 10 }} />
        }
      </div>
    ) : (
      <div className="vertical-card-list-mode">
        <div className="currency-strength-header-favorite"><StarOutlineIcon /></div>
        <div className="currency-strength-header-currency">
          <Link
            onClick={symbolClick}
            className="ticker"
            to={`/company/${symbol}`}
          >
            {card.symbol}
          </Link>
        </div>
        <div className="currency-strength-header-rate">
          <CssPop
            comparator={Number(
              currentPrices.get(card.symbol)?.price || card.currentPrice
            ).toFixed(4)}
          />
        </div>
        <div className="currency-strength-header-change">{percentage}%</div>
        <div className="currency-strength-header-graph">
          <div className="chart-content">
            {ListViewComponent}
          </div>
        </div>
        <div className="currency-strength-header-ai-sentiment">
          <div
            className={`card-info-value${card.aiSentiment.toUpperCase() === "BULLISH"
              ? " very-bullish"
              : card.aiSentiment.toUpperCase() === "VERY BULLISH"
                ? " very-bullish"
                : card.aiSentiment.toUpperCase() === "NEUTRAL"
                  ? " neutral"
                  : card.aiSentiment.toUpperCase() === "BEARISH"
                    ? " bearish"
                    : card.aiSentiment.toUpperCase() === "VERY BEARISH"
                      ? " bearish"
                      : " neutral"
              }`}
          >
            {card.aiSentiment}
          </div>
        </div>
        <div className="currency-strength-header-new-sentiment">
          <div
            className={`card-info-value${card.newsSentiment.toUpperCase() === "BULLISH"
              ? " very-bullish"
              : card.newsSentiment.toUpperCase() === "VERY BULLISH"
                ? " very-bullish"
                : card.newsSentiment.toUpperCase() === "NEUTRAL"
                  ? " neutral"
                  : card.newsSentiment.toUpperCase() === "BEARISH"
                    ? " bearish"
                    : card.newsSentiment.toUpperCase() === "VERY BEARISH"
                      ? " bearish"
                      : " neutral"
              }`}
          >
            {card.newsSentiment}
          </div>
        </div>
        <div className="currency-strength-header-bid">
          <CssPop
            comparator={Number(
              currentPrices.get(card.symbol)?.bidPrice || card.bid
            ).toFixed(4)}
          />
        </div>
        <div className="currency-strength-header-ask">
          <CssPop
            comparator={Number(
              currentPrices.get(card.symbol)?.askPrice || card.ask
            ).toFixed(4)}
          />
        </div>
        <div className="currency-strength-header-volume">N/A</div>
      </div>
    )
  );
};

const stateToProps = (state) => ({
  currentPrices: isInternalWS ? state.EquitiesSocket.currentPrices : state.dxFeedSocket.socketData,
  timezone: state.userSettings.userSettings.timezone,
});

const mapDispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  breadCrumbChanger: (change) => dispatch(breadCrumbChange(change)),
});
export default connect(stateToProps, mapDispatchToProps)(VerticalCard);
