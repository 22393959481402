const tickerList = [
    , "ZAR/LKR"
    , "HUF/CHF{mm=COMP}"
    , "EUR/PHP{mm=DRES}"
    , "BTN/GBP"
    , "TWD/SEK{mm=COMP}"
    , "NOK/CNY{mm=EUAM}"
    , "USD/ARS{mm=EUAM}"
    , "GNF/USD{mm=COMP}"
    , "DKK/EUR{mm=COMP}"
    , "GBP/SGD{mm=COMP}"
    , "VES/GBP"
    , "HKD/CNY"
    , "KRW/EUR"
    , "FRN/CHF{mm=UBSW}"
    , "IDR/EUR"
    , "MXN/RUB"
    , "ZAR/USD"
    , "DKK/ZAR"
    , "DJF/GBP{mm=EUAM}"
    , "XAU/INR"
    , "PHP/EUR{mm=EUAM}"
    , "AUD/XDR{mm=COMP}"
    , "BZD/EUR{mm=EUAM}"
    , "THB/SGD{mm=EUAM}"
    , "NPR/USD{mm=COMP}"
    , "IDR/HKD{mm=COMP}"
    , "GBP/BWP"
    , "USD/CLY{mm=COMP}"
    , "USD/GTQ{mm=COMP}"
    , "BDT/GBP{mm=EUAM}"
    , "EUR/SEK{mm=DRES}"
    , "EUR/HKD{mm=MORN}"
    , "INR/SEK"
    , "AED/USD"
    , "EUR/VND"
    , "HUF/MXN{mm=EUAM}"
    , "PEN/COP{mm=COMP}"
    , "AMD/GBP{mm=EUAM}"
    , "JPY/ARS{mm=EUAM}"
    , "BOB/USD"
    , "COP/BRL{mm=EUAM}"
    , "MYR/CNY"
    , "AUD/PHP{mm=COMP}"
    , "NZD/JPY{mm=BNPF}"
    , "BRL/NOK"
    , "ZMW/USD{mm=EUAM}"
    , "USD/CLF{mm=COMP}"
    , "ANG/EUR{mm=COMP}"
    , "CAD/CHF{mm=SAXO}"
    , "GBP/SOS{mm=COMP}"
    , "KYD/BMD"
    , "CNH/JPY{mm=RADA}"
    , "XAU/GBP"
    , "SAR/JPY"
    , "GEL/GBP"
    , "PHP/USD"
    , "SGD/BRL"
    , "HRK/HUF{mm=COMP}"
    , "USD/AFN{mm=EUAM}"
    , "USD/TWD"
    , "JPY/USD"
    , "USD/NGN{mm=SURF}"
    , "ARS/CHF{mm=COMP}"
    , "USD/AUD"
    , "CZK/AED{mm=EUAM}"
    , "MOP/USD"
    , "GTQ/GBP{mm=COMP}"
    , "GBP/GEL{mm=EUAM}"
    , "GBP/BMD"
    , "CNH/USD{mm=COMP}"
    , "ZAR/JOD{mm=EUAM}"
    , "CAD/CHF{mm=SURF}"
    , "EUR/HKD{mm=COMP}"
    , "ZAR/ILS"
    , "CZK/MXN{mm=COMP}"
    , "SGD/IDR"
    , "DKK/CNY"
    , "GBP/EUR{mm=MORN}"
    , "ILS/NOK{mm=COMP}"
    , "INR/SGD"
    , "TWD/DKK{mm=COMP}"
    , "EUR/PEN{mm=DRES}"
    , "EUR/AOA{mm=COMP}"
    , "CAD/CHF"
    , "CNH/KRW"
    , "DKK/RUB{mm=COMP}"
    , "EUR/JOD"
    , "NOK/NZD{mm=CMC}"
    , "AUD/SEK{mm=COMP}"
    , "DKK/USD{mm=COMP}"
    , "SBD/USD{mm=COMP}"
    , "AED/CHF{mm=EUAM}"
    , "CNY/INR{mm=EUAM}"
    , "NBL/USD{mm=UBSW}"
    , "SGD/CHF{mm=CMC}"
    , "ZAR/PGK{mm=COMP}"
    , "AUD/TOP"
    , "PLN/SEK{mm=CMC}"
    , "AED/ILS"
    , "MAD/GBP{mm=EUAM}"
    , "IDR/ZAR{mm=EUAM}"
    , "EUR/KHR{mm=COMP}"
    , "AWG/SRD"
    , "CAD/TRY{mm=EUAM}"
    , "TWD/MYR{mm=COMP}"
    , "MDL/GBP"
    , "AUD/BRL{mm=EUAM}"
    , "ZWD/GBP"
    , "ARS/MXN{mm=EUAM}"
    , "ZAR/THB{mm=EUAM}"
    , "EUR/HTG{mm=COMP}"
    , "HKD/PLN"
    , "GBP/DZD{mm=EUAM}"
    , "CLP/BRL"
    , "EUR/CNH{mm=SAXO}"
    , "VES/GBP{mm=COMP}"
    , "CHF/HKD{mm=EUAM}"
    , "TRY/JPY"
    , "PHP/USD{mm=EUAM}"
    , "BZD/USD{mm=EUAM}"
    , "COP/DKK{mm=COMP}"
    , "USD/KZT"
    , "ZAR/CDF{mm=COMP}"
    , "GBP/BWP{mm=COMP}"
    , "GTQ/EUR"
    , "HKD/ARS{mm=EUAM}"
    , "EUR/DKK{mm=DRES}"
    , "JPY/ILS"
    , "ZAR/BHD{mm=EUAM}"
    , "VND/JPY{mm=COMP}"
    , "EUR/SAR{mm=MORN}"
    , "GBP/EUR{mm=COMP}"
    , "BWP/GBP{mm=COMP}"
    , "EUR/CLP{mm=COMP}"
    , "ZAR/XOF{mm=EUAM}"
    , "NZD/ZAR{mm=COMP}"
    , "MYR/CAD{mm=EUAM}"
    , "AUD/BGN{mm=EUAM}"
    , "BRL/AUD{mm=EUAM}"
    , "CAD/PKR"
    , "USD/NIO"
    , "AFN/USD{mm=EUAM}"
    , "JPY/CNH{mm=EUAM}"
    , "NZD/KRW"
    , "USD/MAD"
    , "NOK/MXN{mm=CMC}"
    , "SEK/SGD{mm=CMC}"
    , "PLN/JPY{mm=CMC}"
    , "BIF/USD{mm=EUAM}"
    , "LKR/GBP"
    , "EUR/MYR{mm=DRES}"
    , "ANG/USD{mm=COMP}"
    , "THB/EUR{mm=EUAM}"
    , "SGD/USD{mm=SAXO}"
    , "JOD/EUR"
    , "AUD/HKD{mm=SAXO}"
    , "USD/MXN{mm=SAXO}"
    , "USD/INR"
    , "NOK/CNH"
    , "USD/TRY{mm=COMP}"
    , "EUR/OMR"
    , "CNH/JPY"
    , "USD/NZD{mm=CMC}"
    , "USD/GEL"
    , "CHF/CLP{mm=EUAM}"
    , "CHF/CAD{mm=CMC}"
    , "MXN/JPY"
    , "USD/CZK"
    , "MXN/HKD{mm=EUAM}"
    , "EUR/MDL"
    , "SEK/ILS{mm=EUAM}"
    , "SGD/THB"
    , "EUR/SAR{mm=COMP}"
    , "EUR/KES{mm=DRES}"
    , "USD/BBD{mm=EUAM}"
    , "GBP/USD{mm=MORN}"
    , "SGD/USD{mm=SURF}"
    , "PLN/HRK{mm=EUAM}"
    , "DKK/ZAR{mm=CMC}"
    , "USD/EGP{mm=RADA}"
    , "SEK/NZD{mm=COMP}"
    , "NZD/CNH{mm=RADA}"
    , "DOP/GBP{mm=EUAM}"
    , "AUD/HKD{mm=SURF}"
    , "USD/MXN{mm=SURF}"
    , "AUD/DKK{mm=COMP}"
    , "USD/CUP{mm=EUAM}"
    , "USD/MUR{mm=DRES}"
    , "SBD/USD"
    , "USD/MVR"
    , "EUR/IQD{mm=COMP}"
    , "ISK/USD{mm=COMP}"
    , "DKK/PLN"
    , "GBP/CVE"
    , "CAD/BMD{mm=COMP}"
    , "CLP/ZAR{mm=COMP}"
    , "SGD/EUR"
    , "USD/GBP"
    , "USD/GHS{mm=EUAM}"
    , "USD/CHF{mm=RADA}"
    , "AUD/CAD:HSFX"
    , "CZK/TRY{mm=CMC}"
    , "ZAR/FJD{mm=COMP}"
    , "KZT/EUR"
    , "TRY/CAD{mm=COMP}"
    , "PLN/NOK"
    , "AUD/MYR{mm=COMP}"
    , "HUF/SEK{mm=EUAM}"
    , "USD/HNL{mm=COMP}"
    , "JPY/TRY{mm=COMP}"
    , "TOP/AUD"
    , "IDR/NOK{mm=EUAM}"
    , "USD/AZN"
    , "MKD/EUR{mm=EUAM}"
    , "LAK/EUR"
    , "USD/MXN{mm=CMC}"
    , "GBP/RUB{mm=COMP}"
    , "CHF/TRY{mm=SAXO}"
    , "ZAR/CAD{mm=COMP}"
    , "MXN/CLP{mm=EUAM}"
    , "GBP/BTN{mm=COMP}"
    , "NZD/JPY"
    , "MYR/GBP{mm=COMP}"
    , "INR/PKR{mm=COMP}"
    , "XAU/CAD{mm=COMP}"
    , "HKD/CNH{mm=EUAM}"
    , "GBP/USD{mm=COMP}"
    , "DKK/COP{mm=COMP}"
    , "MYR/CNY{mm=COMP}"
    , "XCD/GBP{mm=EUAM}"
    , "TWD/JPY{mm=EUAM}"
    , "CHF/RUB"
    , "TMT/USD"
    , "USD/MAD{mm=RADA}"
    , "GBP/LBP"
    , "EUR/BRL"
    , "CAD/HRK{mm=COMP}"
    , "GBP/MMK{mm=COMP}"
    , "CNY/MXN{mm=COMP}"
    , "NZD/NOK{mm=COMP}"
    , "KRW/SGD{mm=COMP}"
    , "SVC/GBP"
    , "GBP/MOP"
    , "DKK/CZK{mm=SAXO}"
    , "SGD/HUF{mm=CMC}"
    , "EUR/PAB{mm=COMP}"
    , "ILS/PLN{mm=EUAM}"
    , "THB/USD{mm=EUAM}"
    , "CLP/EUR"
    , "CZK/SEK{mm=COMP}"
    , "ZAR/XAF{mm=EUAM}"
    , "NZD/CNY{mm=EUAM}"
    , "EUR/YER{mm=COMP}"
    , "EUR/LBP{mm=DRES}"
    , "EUR/IDR"
    , "AUD/TWD{mm=EUAM}"
    , "EUR/CDF"
    , "SZL/CHF{mm=EUAM}"
    , "CHF/ILS{mm=COMP}"
    , "CAD/TWD"
    , "SEK/AUD{mm=COMP}"
    , "PKR/EUR"
    , "CAD/AUD"
    , "XDR/NZD"
    , "USD/BZD{mm=COMP}"
    , "EUR/TRY{mm=RADA}"
    , "SEK/JPY"
    , "TMT/USD{mm=EUAM}"
    , "MYR/KRW{mm=COMP}"
    , "PLN/CHF{mm=EUAM}"
    , "TRY/SEK"
    , "GBP/KWD"
    , "TTD/GBP{mm=EUAM}"
    , "PEN/CAD{mm=EUAM}"
    , "EUR/NAD{mm=COMP}"
    , "USD/SZL{mm=RADA}"
    , "HKD/CAD"
    , "CAD/CHF{mm=MORN}"
    , "GBP/KWD{mm=COMP}"
    , "PHP/JPY{mm=COMP}"
    , "HKD/TRY{mm=COMP}"
    , "USD/VND{mm=RADA}"
    , "EUR/ARS{mm=COMP}"
    , "AUD/RWF{mm=EUAM}"
    , "CNY/USD"
    , "PLN/AED"
    , "NZD/KRW{mm=EUAM}"
    , "HUF/DKK{mm=EUAM}"
    , "ARE/CHF{mm=COMP}"
    , "USD/PHP{mm=SURF}"
    , "TRY/DKK"
    , "MKD/USD{mm=EUAM}"
    , "RSD/EUR{mm=EUAM}"
    , "XAU/AUD{mm=SAXO}"
    , "CLP/GBP{mm=EUAM}"
    , "BZD/USD"
    , "CHF/KRW"
    , "OSO/USD"
    , "USD/NGN{mm=COMP}"
    , "CLP/CNY{mm=EUAM}"
    , "USD/SDG{mm=COMP}"
    , "DOE/CHF{mm=COMP}"
    , "GBP/USD{mm=BNPF}"
    , "TRY/SGD"
    , "MXN/SEK"
    , "USD/SEK{mm=SAXO}"
    , "CNY/SRD{mm=COMP}"
    , "RWF/EUR"
    , "AUD/JPY{mm=EUAM}"
    , "EUR/LAK"
    , "MYR/CAD"
    , "BSD/GBP"
    , "CAD/CHF{mm=COMP}"
    , "CHF/ARS{mm=EUAM}"
    , "MZN/ZAR{mm=EUAM}"
    , "CZK/MXN"
    , "ARS/PEN{mm=EUAM}"
    , "USD/NPR"
    , "VND/GBP"
    , "CRC/GBP{mm=EUAM}"
    , "SGD/EUR{mm=COMP}"
    , "JPY/INR{mm=EUAM}"
    , "TWD/HKD{mm=COMP}"
    , "GBP/PLN:HSFX"
    , "CNY/XDR{mm=COMP}"
    , "TOP/GBP"
    , "CZK/DKK{mm=COMP}"
    , "SEK/EUR{mm=CMC}"
    , "IDR/CNH{mm=COMP}"
    , "GBP/BSD"
    , "EUR/THB"
    , "QAR/EUR{mm=COMP}"
    , "THB/TWD{mm=COMP}"
    , "USD/SEK{mm=SURF}"
    , "NZD/IDR{mm=COMP}"
    , "USD/LRD{mm=RADA}"
    , "USD/BTN"
    , "GBP/COP{mm=COMP}"
    , "EUR/AFN{mm=COMP}"
    , "MXN/DKK"
    , "KWD/EUR"
    , "INR/SEK{mm=COMP}"
    , "CAD/INR"
    , "ZAR/LAK{mm=COMP}"
    , "DKK/CAD"
    , "KRW/EUR{mm=COMP}"
    , "GBP/XPF{mm=EUAM}"
    , "EUR/RWF"
    , "CAD/CZK"
    , "PEN/GBP{mm=COMP}"
    , "BWP/CHF"
    , "CNH/SGD"
    , "LYD/USD{mm=EUAM}"
    , "EUR/IDR{mm=EUAM}"
    , "MXN/SGD"
    , "GBP/CZK{mm=SAXO}"
    , "NOK/TRY"
    , "EUR/HKD{mm=DRES}"
    , "CAD/MXN{mm=EUAM}"
    , "NZD/SEK"
    , "EUR/LSL{mm=COMP}"
    , "AED/JOD{mm=EUAM}"
    , "MXN/ARS{mm=EUAM}"
    , "XAU/HKD"
    , "ETB/GBP{mm=COMP}"
    , "CHF/JPY"
    , "AUD/HKD{mm=MORN}"
    , "USD/MXN{mm=MORN}"
    , "SGD/NOK{mm=CMC}"
    , "EUR/CNH{mm=COMP}"
    , "XOF/BWP{mm=COMP}"
    , "CAD/KYD{mm=EUAM}"
    , "GBP/NOK:HSFX"
    , "QAR/USD"
    , "JPY/SEK{mm=SURF}"
    , "CAD/JPY:HSFX"
    , "CAD/GBP"
    , "GBP/SGD{mm=CMC}"
    , "ARS/ZAR"
    , "EUR/KHR{mm=DRES}"
    , "ZMW/ZAR{mm=COMP}"
    , "USD/SLL{mm=RADA}"
    , "RSD/USD{mm=EUAM}"
    , "CAD/PKR{mm=EUAM}"
    , "EUR/SEK:HSFX"
    , "TWD/ZAR{mm=EUAM}"
    , "CNY/SEK{mm=COMP}"
    , "EUR/HTG{mm=DRES}"
    , "NOK/USD{mm=SAXO}"
    , "USD/KYD"
    , "UAH/EUR{mm=COMP}"
    , "MYR/NZD{mm=EUAM}"
    , "NZD/DKK"
    , "GBP/MKD{mm=EUAM}"
    , "USD/DKK{mm=SAXO}"
    , "THB/JPY{mm=COMP}"
    , "CNY/SRD"
    , "ZAR/AUD{mm=CMC}"
    , "HKD/INR{mm=EUAM}"
    , "COP/CLP{mm=COMP}"
    , "TZS/EUR{mm=COMP}"
    , "VND/GBP{mm=EUAM}"
    , "BND/EUR{mm=EUAM}"
    , "CAD/VND{mm=COMP}"
    , "CNY/BRL{mm=EUAM}"
    , "EUR/CLP{mm=DRES}"
    , "NZD/SGD"
    , "SGD/USD{mm=COMP}"
    , "KHR/GBP"
    , "TOP/USD{mm=COMP}"
    , "AUD/HKD{mm=COMP}"
    , "USD/MXN{mm=COMP}"
    , "AED/BHD{mm=EUAM}"
    , "RUB/EUR{mm=COMP}"
    , "GHS/GBP{mm=COMP}"
    , "DKK/PLN{mm=SAXO}"
    , "HRK/BRL"
    , "EUR/HRK"
    , "COP/ZAR{mm=EUAM}"
    , "GEL/EUR{mm=EUAM}"
    , "USD/DKK{mm=SURF}"
    , "NZD/SGD{mm=SAXO}"
    , "NZD/GBP{mm=CMC}"
    , "QAR/USD{mm=COMP}"
    , "UAH/EUR"
    , "CNH/CAD{mm=EUAM}"
    , "ZAR/SAR"
    , "DOP/USD"
    , "USD/KYD{mm=COMP}"
    , "EUR/QAR"
    , "CNY/BGN{mm=EUAM}"
    , "USD/ANG{mm=EUAM}"
    , "GBP/PEN"
    , "ZAR/MUR{mm=EUAM}"
    , "KWD/PKR{mm=EUAM}"
    , "ZAR/MMK"
    , "CNH/IDR{mm=RADA}"
    , "KRW/RUB{mm=COMP}"
    , "CZK/ZAR{mm=CMC}"
    , "USD/TRY{mm=DRES}"
    , "USD/THB:HSFX"
    , "EUR/GMD{mm=EUAM}"
    , "EUR/SGD{mm=CMC}"
    , "GNF/GBP{mm=COMP}"
    , "TWD/USD"
    , "KRW/USD{mm=COMP}"
    , "SEK/DKK"
    , "JPY/DKK{mm=SAXO}"
    , "USD/PKR{mm=COMP}"
    , "JPY/AED{mm=EUAM}"
    , "NOK/SGD{mm=COMP}"
    , "USD/MYR{mm=SURF}"
    , "GBP/CVE{mm=COMP}"
    , "XAG/CAD{mm=COMP}"
    , "PHP/ZAR{mm=COMP}"
    , "ZAR/CNH"
    , "DKK/CAD{mm=EUAM}"
    , "GBP/AMD{mm=COMP}"
    , "AED/SAR"
    , "AUD/CNH{mm=SAXO}"
    , "EUR/SAR{mm=DRES}"
    , "NZD/SGD{mm=SURF}"
    , "EUR/BBD{mm=COMP}"
    , "INR/MYR{mm=COMP}"
    , "CAD/XDR{mm=EUAM}"
    , "GBP/PGK{mm=EUAM}"
    , "CNY/MYR{mm=MORN}"
    , "SEK/SGD"
    , "JPY/MXN{mm=COMP}"
    , "AUD/USD{mm=CMC}"
    , "BYN/GBP"
    , "EUR/CUP{mm=COMP}"
    , "AUD/CLP{mm=COMP}"
    , "TRY/NZD{mm=COMP}"
    , "CZK/JPY{mm=EUAM}"
    , "EUR/IQD{mm=DRES}"
    , "JPY/DKK{mm=SURF}"
    , "KRU/CHF"
    , "KRW/USD"
    , "ZAR/ILS{mm=EUAM}"
    , "USD/JPY{mm=UBSW}"
    , "CHF/MXN{mm=SAXO}"
    , "IDR/USD"
    , "XOF/USD{mm=COMP}"
    , "MZN/ZAR"
    , "USD/KES{mm=SURF}"
    , "MYR/AUD{mm=EUAM}"
    , "NPR/GBP{mm=COMP}"
    , "EUR/GHS{mm=COMP}"
    , "JPY/SAR"
    , "CHF/TRY{mm=COMP}"
    , "KRU/USD{mm=COMP}"
    , "LRD/EUR"
    , "INR/MYR"
    , "PKR/JPY{mm=EUAM}"
    , "EUR/XDR"
    , "CNY/DKK{mm=COMP}"
    , "ZAR/NZD{mm=COMP}"
    , "GBP/CDF{mm=EUAM}"
    , "GBP/DZD"
    , "MXN/SGD{mm=CMC}"
    , "BRL/CHF{mm=EUAM}"
    , "USD/SEK:HSFX"
    , "AUD/ZAR{mm=EUAM}"
    , "UAH/USD{mm=COMP}"
    , "USD/ALL{mm=EUAM}"
    , "JPY/PKR{mm=COMP}"
    , "BRL/IDR"
    , "GBP/RUB"
    , "JPY/CNH"
    , "MAD/AUD"
    , "TZS/USD{mm=COMP}"
    , "USD/BAM{mm=EUAM}"
    , "BND/USD{mm=EUAM}"
    , "USD/THB{mm=RADA}"
    , "USD/SRD{mm=COMP}"
    , "GBP/USD{mm=DRES}"
    , "CNY/MYR{mm=COMP}"
    , "DKK/CZK{mm=COMP}"
    , "USD/HKD"
    , "NOK/TRY{mm=CMC}"
    , "AED/CAD{mm=COMP}"
    , "TND/ZAR"
    , "INR/TWD{mm=EUAM}"
    , "RUB/USD{mm=COMP}"
    , "GBP/QAR{mm=COMP}"
    , "USD/XDR{mm=COMP}"
    , "SLL/EUR{mm=COMP}"
    , "USD/BHD{mm=RADA}"
    , "USD/XOF{mm=RADA}"
    , "GBP/SCR{mm=EUAM}"
    , "CNH/GBP{mm=COMP}"
    , "GEL/USD{mm=EUAM}"
    , "IDR/INR{mm=COMP}"
    , "EUR/PAB{mm=DRES}"
    , "EUR/INR{mm=MORN}"
    , "OMR/AED{mm=EUAM}"
    , "CHF/SEK"
    , "NOK/EUR{mm=MORN}"
    , "UYU/EUR"
    , "HKD/AED{mm=EUAM}"
    , "HUF/HKD{mm=EUAM}"
    , "EUR/HNL"
    , "MXN/TRY{mm=COMP}"
    , "XAG/AUD{mm=SAXO}"
    , "MGA/EUR{mm=COMP}"
    , "OMR/AED"
    , "USD/SEK{mm=MORN}"
    , "DKK/GBP{mm=COMP}"
    , "USD/PHP{mm=COMP}"
    , "GBP/MKD"
    , "CLP/COP"
    , "HKD/MXN{mm=COMP}"
    , "USD/NAD"
    , "RUB/KRW"
    , "TRY/AUD{mm=COMP}"
    , "DKK/CNY{mm=COMP}"
    , "GBP/PLN{mm=SAXO}"
    , "MWK/GBP"
    , "NZD/EUR{mm=SAXO}"
    , "CNY/TWD{mm=EUAM}"
    , "HRK/EUR"
    , "GBP/FJD{mm=EUAM}"
    , "EUR/NAD{mm=DRES}"
    , "KES/ZAR"
    , "JPY/XDR{mm=COMP}"
    , "CHF/DKK"
    , "GBP/BSD{mm=COMP}"
    , "PLN/CZK{mm=CMC}"
    , "PHP/GBP{mm=EUAM}"
    , "ZAR/AUD{mm=COMP}"
    , "COP/ARS{mm=COMP}"
    , "USD/NIO{mm=RADA}"
    , "XAU/AUD{mm=COMP}"
    , "EUR/INR{mm=COMP}"
    , "SEK/CHF{mm=MORN}"
    , "OMR/PKR{mm=COMP}"
    , "EUR/ARS{mm=DRES}"
    , "NOK/EUR{mm=COMP}"
    , "CNH/KRW{mm=COMP}"
    , "HKD/PKR{mm=COMP}"
    , "CZK/HKD{mm=COMP}"
    , "GBP/KRW"
    , "CHF/SGD"
    , "USD/EGP"
    , "INR/JPY{mm=EUAM}"
    , "THB/ZAR{mm=COMP}"
    , "NZD/EUR{mm=SURF}"
    , "GBP/CAD{mm=EUAM}"
    , "GBP/RSD{mm=COMP}"
    , "GBP/EUR{mm=CMC}"
    , "CAD/PEN{mm=EUAM}"
    , "XPD/EUR"
    , "USD/TRY{mm=CMC}"
    , "SEK/NZD{mm=CMC}"
    , "AFN/GBP{mm=EUAM}"
    , "USD/SEK{mm=COMP}"
    , "JPY/PHP{mm=COMP}"
    , "GBP/CZK{mm=MORN}"
    , "GTQ/USD"
    , "XPTK/CHF{mm=UBSW}"
    , "GBP/TND"
    , "CAD/KYD"
    , "GBP/DJF{mm=EUAM}"
    , "ANG/GBP{mm=COMP}"
    , "NIO/GBP"
    , "AUD/NOK{mm=EUAM}"
    , "AUD/PGK"
    , "USD/BBD"
    , "EUR/USD{mm=UBSW}"
    , "USD/BRL{mm=EUAM}"
    , "ARS/HKD{mm=EUAM}"
    , "DJF/EUR{mm=COMP}"
    , "CHF/INR{mm=EUAM}"
    , "GBP/MDL{mm=EUAM}"
    , "BRL/EUR"
    , "SLL/USD{mm=COMP}"
    , "GBP/VES{mm=COMP}"
    , "DKK/HUF{mm=SAXO}"
    , "TWD/IDR{mm=EUAM}"
    , "SEK/CHF{mm=COMP}"
    , "CHF/HKD{mm=CMC}"
    , "BDT/EUR{mm=COMP}"
    , "JOD/USD"
    , "RUB/JPY"
    , "AMD/EUR{mm=COMP}"
    , "USD/UAH"
    , "PLN/BRL"
    , "CNY/JPY{mm=EUAM}"
    , "MUR/EUR{mm=EUAM}"
    , "PGK/EUR{mm=EUAM}"
    , "USD/BGN{mm=EUAM}"
    , "MAD/GBP"
    , "USD/FJD"
    , "AUD/ARS{mm=COMP}"
    , "JPY/SEK{mm=COMP}"
    , "USD/DKK{mm=MORN}"
    , "MGA/USD{mm=COMP}"
    , "GBP/CNY{mm=MORN}"
    , "CAD/THB{mm=COMP}"
    , "GBP/CZK{mm=COMP}"
    , "SEK/MXN{mm=CMC}"
    , "TRY/NOK"
    , "EUR/AFN{mm=DRES}"
    , "GBP/UYU{mm=COMP}"
    , "ISK/GBP{mm=COMP}"
    , "USD/PEN{mm=COMP}"
    , "USD/SDG"
    , "USD/PHP{mm=BNPF}"
    , "EUR/ETB"
    , "CAD/MYR{mm=EUAM}"
    , "CHF/SEK{mm=SAXO}"
    , "VND/HKD"
    , "MAL/USD{mm=COMP}"
    , "CZK/ZAR{mm=EUAM}"
    , "JPY/BRL{mm=EUAM}"
    , "EUR/GBP:HSFX"
    , "NZD/USD{mm=SAXO}"
    , "USD/HKD{mm=SAXO}"
    , "GBP/JPY"
    , "ARS/CLP{mm=EUAM}"
    , "NZD/CAD{mm=CMC}"
    , "ZAR/LKR{mm=COMP}"
    , "PKR/ZAR{mm=EUAM}"
    , "SGD/USD"
    , "EUR/LSL{mm=DRES}"
    , "NZD/JPY:HSFX"
    , "BWP/GBP"
    , "NOK/RUB{mm=COMP}"
    , "KZT/USD"
    , "MXV/MXN{mm=COMP}"
    , "EUR/COP"
    , "ZAR/TRY"
    , "DOE/USD"
    , "CHF/SEK{mm=SURF}"
    , "NOK/USD{mm=COMP}"
    , "AUD/RON"
    , "HKD/PHP{mm=COMP}"
    , "USD/ISK{mm=RADA}"
    , "EUR/CNH{mm=DRES}"
    , "GBP/MVR{mm=EUAM}"
    , "NZD/USD{mm=SURF}"
    , "ILS/EUR{mm=COMP}"
    , "IDR/SGD{mm=EUAM}"
    , "USD/HKD{mm=SURF}"
    , "USD/DKK{mm=COMP}"
    , "GBP/CNY{mm=COMP}"
    , "LAK/USD"
    , "MXN/EUR{mm=CMC}"
    , "GBP/CRC{mm=EUAM}"
    , "USD/SEK{mm=BNPF}"
    , "JOD/ILS"
    , "INR/HKD{mm=COMP}"
    , "MXN/NOK"
    , "MAD/EUR{mm=COMP}"
    , "PLN/CAD{mm=COMP}"
    , "USD/NZD"
    , "AED/TRY"
    , "CNY/HKD{mm=MORN}"
    , "NAD/XOF{mm=EUAM}"
    , "EUR/SEK{mm=RADA}"
    , "MAL/CHF"
    , "USD/AMD{mm=CBOA}"
    , "THB/GBP{mm=EUAM}"
    , "GYD/SRD{mm=COMP}"
    , "GBP/RON{mm=COMP}"
    , "EUR/TND{mm=COMP}"
    , "DKK/PLN{mm=COMP}"
    , "EUR/AED{mm=COMP}"
    , "ZMW/ZAR"
    , "USD/MYR{mm=COMP}"
    , "DJF/USD{mm=COMP}"
    , "KES/CHF{mm=COMP}"
    , "THB/CNY{mm=EUAM}"
    , "USD/KHR"
    , "CHF/PLN{mm=CMC}"
    , "NZD/SGD{mm=COMP}"
    , "CAD/HKD"
    , "ZAR/TRY{mm=EUAM}"
    , "CHF/RSD"
    , "AUD/IDR{mm=EUAM}"
    , "CNH/NZD{mm=EUAM}"
    , "USD/MXN{mm=DRES}"
    , "JPY/TRY"
    , "HKD/SEK{mm=COMP}"
    , "BDT/USD{mm=COMP}"
    , "CLP/USD"
    , "BMD/CAD{mm=COMP}"
    , "AMD/USD{mm=COMP}"
    , "AWG/SRD{mm=EUAM}"
    , "MUR/USD{mm=EUAM}"
    , "NSO/CHF{mm=COMP}"
    , "GBP/LAK{mm=EUAM}"
    , "PGK/USD{mm=EUAM}"
    , "JPY/DKK{mm=COMP}"
    , "GBP/KRW{mm=COMP}"
    , "CAD/GBP{mm=CMC}"
    , "USD/CLP{mm=SURF}"
    , "USD/KES{mm=COMP}"
    , "NGN/USD{mm=EUAM}"
    , "ZAR/USD{mm=CMC}"
    , "AUD/CNH{mm=COMP}"
    , "ARS/CAD"
    , "PKR/USD"
    , "HKD/BRL{mm=EUAM}"
    , "DKK/NZD{mm=EUAM}"
    , "THB/ZAR"
    , "CNY/HKD{mm=COMP}"
    , "CHF/DKK{mm=SAXO}"
    , "EUR/BAM"
    , "CHF/AED{mm=EUAM}"
    , "EUR/GMD"
    , "NZD/NOK"
    , "HUF/CHF"
    , "GBP/HUF{mm=SAXO}"
    , "TRY/GBP{mm=CMC}"
    , "AUD/JPY:HSFX"
    , "USD/SAR{mm=SAXO}"
    , "JPY/MYR{mm=COMP}"
    , "GBP/PYG{mm=COMP}"
    , "USD/BGN"
    , "THB/KRW{mm=EUAM}"
    , "CHF/MXN{mm=COMP}"
    , "BWP/CHF{mm=EUAM}"
    , "GBP/PAB"
    , "EUR/BBD{mm=DRES}"
    , "EUR/PHP"
    , "GBP/XCD"
    , "MKD/GBP{mm=EUAM}"
    , "INR/ZAR{mm=EUAM}"
    , "CLF/CLP{mm=CBOC}"
    , "XAG/AUD"
    , "GBP/SCR"
    , "CHF/DKK{mm=SURF}"
    , "USD/NOK{mm=UBSW}"
    , "PLN/EUR"
    , "EUR/CUP{mm=DRES}"
    , "HRK/CAD{mm=COMP}"
    , "CZK/HUF"
    , "TOP/FJD{mm=EUAM}"
    , "NOK/ZAR{mm=CMC}"
    , "DOP/EUR{mm=COMP}"
    , "CNH/MYR"
    , "ILS/USD{mm=COMP}"
    , "LKR/ZAR{mm=EUAM}"
    , "FRN/CHF"
    , "EUR/GHS{mm=DRES}"
    , "TOP/FJD"
    , "AUD/CHF"
    , "MXN/AED"
    , "RUB/SEK"
    , "CHF/SZL"
    , "CHF/PKR{mm=COMP}"
    , "AED/ILS{mm=EUAM}"
    , "AUN/USD{mm=UBSW}"
    , "MAD/USD{mm=COMP}"
    , "GBP/PLN{mm=MORN}"
    , "CNY/CLP{mm=COMP}"
    , "XAF/USD{mm=EUAM}"
    , "EUR/DKK{mm=RADA}"
    , "SGD/CAD{mm=EUAM}"
    , "USD/RWF{mm=EUAM}"
    , "EUR/ALL{mm=COMP}"
    , "MXN/AED{mm=EUAM}"
    , "SEK/NOK"
    , "RWF/USD"
    , "AED/NZD{mm=COMP}"
    , "AUD/NZD:HSFX"
    , "CNH/AUD{mm=EUAM}"
    , "EUR/BAM{mm=COMP}"
    , "GBP/BOB{mm=EUAM}"
    , "JPY/TWD{mm=EUAM}"
    , "THB/CNY"
    , "CHF/SEK{mm=CMC}"
    , "THB/IDR{mm=COMP}"
    , "IDR/EUR{mm=EUAM}"
    , "CNY/ZAR{mm=EUAM}"
    , "USD/LBP{mm=COMP}"
    , "BBD/EUR{mm=EUAM}"
    , "HKD/DKK{mm=COMP}"
    , "GBP/SEK"
    , "USD/LYD"
    , "EUR/LKR"
    , "XAG/AUD{mm=COMP}"
    , "DKK/AUD{mm=EUAM}"
    , "KRW/CAD{mm=EUAM}"
    , "AUD/LTL"
    , "LYD/GBP{mm=EUAM}"
    , "NZD/MYR"
    , "AUD/PKR"
    , "BRL/XOF{mm=EUAM}"
    , "KWD/USD"
    , "GBP/PLN{mm=COMP}"
    , "HKD/MYR{mm=COMP}"
    , "TWD/INR{mm=COMP}"
    , "USD/OMR{mm=EUAM}"
    , "NZD/EUR{mm=COMP}"
    , "PLN/HRK"
    , "NZD/AED"
    , "TOP/NZD"
    , "DKK/CHF{mm=UBSW}"
    , "DKK/AUD{mm=CMC}"
    , "EUR/USD"
    , "BSD/USD{mm=EUAM}"
    , "GBP/NZD{mm=CMC}"
    , "ZAR/HRK{mm=COMP}"
    , "USD/PHP{mm=DRES}"
    , "EUR/JPY{mm=SAXO}"
    , "PLN/QAR"
    , "SGD/CZK{mm=COMP}"
    , "CZK/ZAR"
    , "USD/ZAR{mm=CMC}"
    , "GBP/DKK"
    , "MYR/CHF{mm=EUAM}"
    , "DKK/HUF{mm=MORN}"
    , "CHF/JPY{mm=CMC}"
    , "EUR/SVC"
    , "CHF/XDR{mm=COMP}"
    , "XDR/DKK{mm=COMP}"
    , "RSD/GBP{mm=EUAM}"
    , "XAG/INR"
    , "JOD/ILS{mm=COMP}"
    , "GBP/SGD"
    , "ZAR/NGN{mm=EUAM}"
    , "INR/IDR"
    , "SAR/PKR{mm=COMP}"
    , "EGP/BRL"
    , "DOP/USD{mm=COMP}"
    , "PLN/DKK{mm=CMC}"
    , "EUR/JPY{mm=SURF}"
    , "MGA/ZAR"
    , "EUR/INR{mm=DRES}"
    , "GBP/TTD"
    , "CAD/HKD{mm=EUAM}"
    , "USD/LSL"
    , "TRY/CHF{mm=MORN}"
    , "AED/AUD{mm=COMP}"
    , "USD/BHD"
    , "USD/MWK{mm=EUAM}"
    , "CHF/PHP{mm=COMP}"
    , "SGD/GBP{mm=COMP}"
    , "TOP/GBP{mm=COMP}"
    , "AUD/INR{mm=MORN}"
    , "HKD/TWD{mm=EUAM}"
    , "NZD/USD{mm=MORN}"
    , "SZL/CHF"
    , "USD/SEK{mm=DRES}"
    , "USD/HKD{mm=MORN}"
    , "SEK/AED"
    , "PGK/EUR"
    , "RUB/CAD{mm=EUAM}"
    , "SGD/CNY{mm=COMP}"
    , "HKD/GBP{mm=CMC}"
    , "ZAR/CHF{mm=MORN}"
    , "MDL/USD{mm=EUAM}"
    , "CAD/NZD"
    , "XAG/GBP"
    , "USD/ARS{mm=SURF}"
    , "USD/BIF{mm=EUAM}"
    , "DKK/HUF{mm=COMP}"
    , "GBP/MXN{mm=CMC}"
    , "QAR/GBP{mm=COMP}"
    , "CLP/EUR{mm=COMP}"
    , "NGN/JPY{mm=COMP}"
    , "IDR/USD{mm=EUAM}"
    , "EUR/NZD{mm=CMC}"
    , "EUR/BRL{mm=COMP}"
    , "BBD/USD{mm=EUAM}"
    , "EUR/ILS"
    , "EUR/HUF:HSFX"
    , "CHF/NOK"
    , "UAH/USD"
    , "EUR/LBP{mm=RADA}"
    , "CNY/NOK{mm=EUAM}"
    , "BRL/CAD{mm=COMP}"
    , "TRY/CHF{mm=COMP}"
    , "KRW/CNY{mm=COMP}"
    , "ZAR/EGP{mm=COMP}"
    , "DKK/RUB"
    , "HKD/KRW"
    , "CHF/SEK{mm=COMP}"
    , "CRC/EUR{mm=COMP}"
    , "BRL/COP"
    , "HUF/AUD"
    , "EUR/BGN{mm=COMP}"
    , "AUD/INR{mm=COMP}"
    , "NZD/USD{mm=COMP}"
    , "IDR/CNH"
    , "USD/HKD{mm=COMP}"
    , "SGD/KRW{mm=COMP}"
    , "GBP/ALL"
    , "ZAR/CHF{mm=COMP}"
    , "USD/ZMW{mm=EUAM}"
    , "LSL/GBP"
    , "USD/JMD{mm=EUAM}"
    , "USD/AOA{mm=COMP}"
    , "CHF/BRL{mm=EUAM}"
    , "EUR/DJF"
    , "MXN/NZD{mm=CMC}"
    , "BRI/USD{mm=UBSW}"
    , "EUR/CUP"
    , "GBP/AUD{mm=EUAM}"
    , "BRL/NZD{mm=SURF}"
    , "XOF/CNY{mm=COMP}"
    , "CNY/TRY"
    , "CAD/BGN"
    , "EUR/MTL{mm=COMP}"
    , "GBP/SEK:HSFX"
    , "INR/THB"
    , "USD/KHR{mm=COMP}"
    , "AUD/TWD"
    , "EUR/MXN{mm=CMC}"
    , "AUD/USD{mm=UBSW}"
    , "DKK/UAH{mm=COMP}"
    , "XAG/TRY{mm=EUAM}"
    , "ISK/CHF"
    , "TTD/USD{mm=COMP}"
    , "PLN/ILS{mm=EUAM}"
    , "EUR/SRD"
    , "USD/HTG{mm=COMP}"
    , "XAU/MXN"
    , "MYR/KRW"
    , "LRD/USD"
    , "USD/CNH{mm=SAXO}"
    , "CAD/SAR{mm=EUAM}"
    , "GBP/CHF{mm=UBSW}"
    , "BND/GBP{mm=EUAM}"
    , "CHF/DKK{mm=MORN}"
    , "ZAR/MAD{mm=COMP}"
    , "BGN/EUR{mm=EUAM}"
    , "PLN/NZD{mm=COMP}"
    , "CAD/ILS{mm=MORN}"
    , "SEK/KES{mm=EUAM}"
    , "TRY/CAD{mm=CMC}"
    , "MXN/SEK{mm=COMP}"
    , "INR/EUR"
    , "JPY/HKD{mm=COMP}"
    , "CAD/TRY{mm=SAXO}"
    , "M5P/USD{mm=COMP}"
    , "USD/DKK{mm=DRES}"
    , "USD/CLP"
    , "GBP/HUF{mm=MORN}"
    , "NOK/QAR{mm=COMP}"
    , "USD/SAR{mm=MORN}"
    , "USD/XPF"
    , "RUB/GBP{mm=COMP}"
    , "USD/CLP{mm=COMP}"
    , "INR/IDR{mm=EUAM}"
    , "ZAR/MXN{mm=EUAM}"
    , "TWD/SGD{mm=EUAM}"
    , "XAU/THB{mm=SAXO}"
    , "XAU/MXN{mm=EUAM}"
    , "GEL/GBP{mm=EUAM}"
    , "USD/CLY{mm=RADA}"
    , "USD/CNH{mm=SURF}"
    , "MXN/BRL{mm=EUAM}"
    , "CHF/MYR"
    , "EUR/VES"
    , "CLP/USD{mm=COMP}"
    , "GBP/RSD"
    , "EUR/TND{mm=DRES}"
    , "XDR/JPY{mm=EUAM}"
    , "EUR/AED{mm=DRES}"
    , "HKD/JPY"
    , "LRD/EUR{mm=EUAM}"
    , "EGP/EUR"
    , "INR/CNH{mm=COMP}"
    , "CHF/AED"
    , "USD/HUF:HSFX"
    , "IDR/TWD{mm=COMP}"
    , "VND/EUR{mm=COMP}"
    , "ZAR/PKR{mm=EUAM}"
    , "UYU/USD"
    , "CHF/DKK{mm=COMP}"
    , "CAD/ILS{mm=COMP}"
    , "CRC/USD{mm=COMP}"
    , "AED/TRY{mm=EUAM}"
    , "SEK/TRY{mm=CMC}"
    , "ZAR/SZL{mm=COMP}"
    , "CZK/PLN"
    , "EUR/ZAR{mm=SAXO}"
    , "EUR/SLL"
    , "GBP/HUF{mm=COMP}"
    , "USD/SAR{mm=COMP}"
    , "JPY/CLP{mm=COMP}"
    , "OMR/EUR"
    , "GBP/GYD{mm=EUAM}"
    , "NZD/USD{mm=BNPF}"
    , "HUF/CZK"
    , "MXN/PEN{mm=COMP}"
    , "USD/HKD{mm=BNPF}"
    , "CNY/IDR{mm=EUAM}"
    , "ZAR/VND{mm=COMP}"
    , "BRL/AUD{mm=SURF}"
    , "EUR/ANG{mm=DRES}"
    , "RUB/KRW{mm=COMP}"
    , "CHF/MYR{mm=COMP}"
    , "SGD/PLN{mm=COMP}"
    , "AUD/MAD"
    , "EUR/HKD{mm=RADA}"
    , "GBP/BND{mm=COMP}"
    , "GBP/LKR{mm=EUAM}"
    , "USD/IQD{mm=COMP}"
    , "MYR/JPY"
    , "HRK/USD"
    , "JPY/ZAR{mm=EUAM}"
    , "SLL/EUR"
    , "GBP/GNF{mm=EUAM}"
    , "EUR/TWD{mm=COMP}"
    , "AUD/INR"
    , "SBD/USD{mm=RADA}"
    , "VRN/CHF{mm=COMP}"
    , "PLN/AUD{mm=COMP}"
    , "HUF/GBP"
    , "UGX/ZAR{mm=EUAM}"
    , "AUD/AED{mm=COMP}"
    , "GBP/MGA{mm=COMP}"
    , "SCR/EUR{mm=EUAM}"
    , "AUD/CZK"
    , "EUR/BIF"
    , "GBP/SZL"
    , "GBP/DOP{mm=EUAM}"
    , "STN/EUR{mm=EUAM}"
    , "BGN/USD{mm=EUAM}"
    , "XPD/USD"
    , "EUR/GBP{mm=UBSW}"
    , "MXN/DKK{mm=COMP}"
    , "JPY/SAR{mm=COMP}"
    , "DZD/EUR"
    , "SGD/NZD{mm=EUAM}"
    , "TOP/NZD{mm=EUAM}"
    , "IDR/JPY{mm=COMP}"
    , "USD/NPR{mm=EUAM}"
    , "CDF/GBP"
    , "MUR/ZAR{mm=COMP}"
    , "EUR/JPY{mm=MORN}"
    , "DKK/JPY"
    , "TRY/BRL"
    , "NGN/ZAR{mm=COMP}"
    , "SLL/GBP{mm=COMP}"
    , "NZD/CZK{mm=SAXO}"
    , "BBD/EUR"
    , "PAB/USD{mm=EUAM}"
    , "AUD/SGD{mm=EUAM}"
    , "ZAR/XDR{mm=EUAM}"
    , "THB/CAD"
    , "GBP/TOP{mm=EUAM}"
    , "CAD/ARS{mm=EUAM}"
    , "EUR/DOP"
    , "GBP/UAH{mm=COMP}"
    , "EUR/RWF{mm=COMP}"
    , "VRL/CHF{mm=COMP}"
    , "EUR/ALL{mm=DRES}"
    , "AUD/GBP"
    , "CHF/TWD{mm=EUAM}"
    , "EGP/PKR{mm=COMP}"
    , "BRL/USD"
    , "LRD/USD{mm=EUAM}"
    , "BWP/XOF{mm=EUAM}"
    , "EUR/BAM{mm=DRES}"
    , "USD/PAB{mm=COMP}"
    , "CYP/ZAR{mm=EUAM}"
    , "NOK/CZK{mm=COMP}"
    , "MGA/GBP{mm=COMP}"
    , "MXV/MXN{mm=BANX}"
    , "KRW/NZD{mm=EUAM}"
    , "USD/MXN"
    , "USD/YER{mm=COMP}"
    , "ZMW/EUR{mm=COMP}"
    , "VND/USD{mm=COMP}"
    , "GBP/MOP{mm=COMP}"
    , "RON/EUR"
    , "ZAR/PHP{mm=EUAM}"
    , "TWD/EUR{mm=EUAM}"
    , "CHF/JPY:HSFX"
    , "GBP/ETB{mm=COMP}"
    , "CZK/AUD{mm=CMC}"
    , "SGD/CNH"
    , "NZD/GBP{mm=SAXO}"
    , "HKD/CAD{mm=CMC}"
    , "EUR/JPY{mm=COMP}"
    , "XCD/SRD"
    , "USD/TRY{mm=RADA}"
    , "CLP/COP{mm=COMP}"
    , "GBP/BDT{mm=EUAM}"
    , "OMR/ZAR{mm=EUAM}"
    , "EUR/OMR{mm=COMP}"
    , "NZD/HKD{mm=CMC}"
    , "MXN/BRL"
    , "HKD/ZAR{mm=EUAM}"
    , "MZN/USD{mm=EUAM}"
    , "JMD/GBP"
    , "HRK/AUD{mm=COMP}"
    , "EUR/NOK{mm=SAXO}"
    , "USD/NAD{mm=COMP}"
    , "EUR/SAR{mm=RADA}"
    , "USD/MUR"
    , "RUB/PLN{mm=COMP}"
    , "NOK/GBP{mm=COMP}"
    , "NZD/GBP{mm=SURF}"
    , "NOK/CNY{mm=COMP}"
    , "USD/ARS{mm=COMP}"
    , "COP/EUR{mm=EUAM}"
    , "ILS/CAD{mm=EUAM}"
    , "CNH/IDR"
    , "ZAR/SEK{mm=EUAM}"
    , "SGD/AUD{mm=EUAM}"
    , "USD/BND"
    , "TOP/AUD{mm=EUAM}"
    , "HTG/GBP"
    , "SAR/EUR"
    , "AUN/USD"
    , "HKD/SEK"
    , "SCR/USD{mm=EUAM}"
    , "EUR/NOK{mm=SURF}"
    , "CHF/JPY{mm=EUAM}"
    , "XAU/ARS"
    , "BRL/ILS"
    , "USD/LYD{mm=EUAM}"
    , "RUB/NOK"
    , "CNH/CHF{mm=EUAM}"
    , "USD/HNL{mm=RADA}"
    , "DKK/USD{mm=CMC}"
    , "DJF/GBP{mm=COMP}"
    , "MYR/THB{mm=EUAM}"
    , "PHP/EUR{mm=COMP}"
    , "BZD/EUR{mm=COMP}"
    , "HUF/AED{mm=EUAM}"
    , "THB/SGD{mm=COMP}"
    , "SGD/EUR{mm=CMC}"
    , "XDR/ZAR{mm=EUAM}"
    , "EUR/MWK{mm=COMP}"
    , "GBP/BMD{mm=EUAM}"
    , "NZD/BRL"
    , "BDT/GBP{mm=COMP}"
    , "USD/MWK"
    , "KRW/AUD{mm=EUAM}"
    , "HUF/MXN{mm=COMP}"
    , "AMD/GBP{mm=COMP}"
    , "USD/INR{mm=SURF}"
    , "BAM/EUR{mm=EUAM}"
    , "BRL/ILS{mm=EUAM}"
    , "MUR/GBP{mm=EUAM}"
    , "JPY/ARS{mm=COMP}"
    , "EUR/BIF{mm=COMP}"
    , "PGK/GBP{mm=EUAM}"
    , "HKD/DKK"
    , "GBP/NOK"
    , "BHD/PKR{mm=EUAM}"
    , "CZK/CAD"
    , "COP/BRL{mm=COMP}"
    , "XDR/ZAR"
    , "SZL/GBP"
    , "NGN/GBP{mm=EUAM}"
    , "CLP/EUR{mm=DRES}"
    , "AUD/JPY{mm=SAXO}"
    , "XAF/USD"
    , "EUR/BRL{mm=DRES}"
    , "BRL/NZD{mm=COMP}"
    , "ZMW/USD{mm=COMP}"
    , "ILS/ZAR"
    , "USD/IQD"
    , "NZD/IDR"
    , "HKD/SGD"
    , "TWD/USD{mm=EUAM}"
    , "XPT/USD{mm=UBSW}"
    , "USD/AFN{mm=COMP}"
    , "AUD/EUR{mm=EUAM}"
    , "PLN/CHF{mm=CMC}"
    , "MXN/JPY{mm=EUAM}"
    , "NZD/PLN{mm=CMC}"
    , "CZK/AED{mm=COMP}"
    , "TRY/EUR"
    , "BRL/SRD"
    , "USD/UGX{mm=EUAM}"
    , "EUR/JPY{mm=BNPF}"
    , "GBP/GEL{mm=COMP}"
    , "GBP/HRK{mm=EUAM}"
    , "CHF/HKD{mm=MORN}"
    , "XAG/HKD"
    , "AUD/JPY{mm=SURF}"
    , "ZAR/JOD{mm=COMP}"
    , "EUR/BGN{mm=DRES}"
    , "USD/IDR{mm=EUAM}"
    , "NZD/USD{mm=DRES}"
    , "TRY/DKK{mm=EUAM}"
    , "USD/HKD{mm=DRES}"
    , "XOF/NAD"
    , "XAG/MXN{mm=EUAM}"
    , "SGD/HUF{mm=COMP}"
    , "USD/LSL{mm=COMP}"
    , "SEK/BRL"
    , "MYR/DKK"
    , "EUR/ZMW{mm=COMP}"
    , "EUR/JMD{mm=COMP}"
    , "DKK/SEK"
    , "SAR/JPY{mm=EUAM}"
    , "COP/USD{mm=EUAM}"
    , "CNH/THB"
    , "KRU/CHF{mm=UBSW}"
    , "USD/CNH{mm=COMP}"
    , "AED/CHF{mm=COMP}"
    , "ZAR/DKK{mm=EUAM}"
    , "CNY/INR{mm=COMP}"
    , "ARS/PEN"
    , "EGP/BRL{mm=EUAM}"
    , "CAD/MXN{mm=SAXO}"
    , "PLN/USD"
    , "BRL/VES"
    , "MYR/SGD"
    , "MAD/GBP{mm=COMP}"
    , "IDR/ZAR{mm=COMP}"
    , "AUD/HKD:HSFX"
    , "RUB/AUD{mm=EUAM}"
    , "GBP/XAF"
    , "CAD/TRY{mm=COMP}"
    , "EUR/ZAR{mm=MORN}"
    , "ZAR/MGA"
    , "AUD/BRL{mm=COMP}"
    , "ARS/MXN{mm=COMP}"
    , "ZAR/THB{mm=COMP}"
    , "GBP/GTQ"
    , "GBP/DZD{mm=COMP}"
    , "XDR/CNY"
    , "XAU/THB{mm=COMP}"
    , "CNH/EUR"
    , "MXN/EUR"
    , "CHF/HKD{mm=COMP}"
    , "PEN/GBP"
    , "ZAR/MYR{mm=EUAM}"
    , "PHP/USD{mm=COMP}"
    , "BZD/USD{mm=COMP}"
    , "ISK/GBP"
    , "JPY/IDR{mm=EUAM}"
    , "NSO/CHF"
    , "NZD/PLN{mm=SAXO}"
    , "EUR/SAR"
    , "HKD/ARS{mm=COMP}"
    , "GBP/TRY{mm=CMC}"
    , "GBP/MYR"
    , "ZAR/BHD{mm=COMP}"
    , "ZAR/XOF{mm=COMP}"
    , "MYR/CAD{mm=COMP}"
    , "USD/SDG{mm=RADA}"
    , "SEK/ZAR{mm=CMC}"
    , "ISK/CHF{mm=EUAM}"
    , "USD/TZS{mm=EUAM}"
    , "AUD/BGN{mm=COMP}"
    , "BRL/AUD{mm=COMP}"
    , "EUR/MMK"
    , "GBP/AED"
    , "BAM/USD{mm=EUAM}"
    , "DKK/SGD"
    , "CHF/CZK{mm=CMC}"
    , "AFN/USD{mm=COMP}"
    , "JPY/CNH{mm=COMP}"
    , "USD/AED{mm=SAXO}"
    , "USD/AFN"
    , "AED/MXN{mm=EUAM}"
    , "CLP/USD{mm=DRES}"
    , "EUR/GYD"
    , "NOK/PLN{mm=COMP}"
    , "EUR/CNH"
    , "ZAR/KES{mm=EUAM}"
    , "NOK/CHF"
    , "GBP/ISK"
    , "BIF/USD{mm=COMP}"
    , "NZD/THB"
    , "USD/ARS"
    , "EUR/ZAR{mm=COMP}"
    , "GBP/EGP{mm=EUAM}"
    , "AUD/RUB{mm=EUAM}"
    , "THB/EUR{mm=COMP}"
    , "NZD/CAD{mm=EUAM}"
    , "BSD/GBP{mm=EUAM}"
    , "CAD/MXN"
    , "NZD/SEK{mm=CMC}"
    , "PLN/ILS"
    , "GBP/PYG"
    , "CHF/CLP{mm=COMP}"
    , "NZD/EUR"
    , "MXN/HKD{mm=COMP}"
    , "SEK/ILS{mm=COMP}"
    , "AED/PKR{mm=EUAM}"
    , "UYU/USD{mm=EUAM}"
    , "USD/SAR{mm=DRES}"
    , "USD/BBD{mm=COMP}"
    , "PLN/HRK{mm=COMP}"
    , "DOP/GBP{mm=COMP}"
    , "SGD/TRY"
    , "USD/CUP{mm=COMP}"
    , "CHF/ZAR{mm=EUAM}"
    , "XPT/EUR"
    , "EUR/TRY{mm=CMC}"
    , "USD/BYN{mm=EUAM}"
    , "USD/GHS"
    , "USD/CNH{mm=BNPF}"
    , "USD/GHS{mm=COMP}"
    , "CHF/BRL"
    , "GBP/MAD{mm=EUAM}"
    , "JOD/AED{mm=EUAM}"
    , "EUR/TWD{mm=DRES}"
    , "EUR/CNH{mm=RADA}"
    , "INR/SGD{mm=EUAM}"
    , "HKD/IDR{mm=EUAM}"
    , "GBP/YER"
    , "EUR/VES{mm=EUAM}"
    , "HUF/SEK{mm=COMP}"
    , "EUR/HTG"
    , "NZD/CZK{mm=COMP}"
    , "NZD/JPY{mm=CMC}"
    , "MDL/GBP{mm=EUAM}"
    , "PLN/HUF{mm=CMC}"
    , "IDR/NOK{mm=COMP}"
    , "TTD/USD"
    , "MKD/EUR{mm=COMP}"
    , "EUR/NOK{mm=MORN}"
    , "IDR/GBP{mm=EUAM}"
    , "SEK/RWF"
    , "CZK/EUR{mm=EUAM}"
    , "MXN/CLP{mm=COMP}"
    , "BBD/GBP{mm=EUAM}"
    , "SEK/EUR"
    , "EUR/NPR{mm=COMP}"
    , "PGK/USD"
    , "HUF/BRL{mm=EUAM}"
    , "ZAR/LBP{mm=EUAM}"
    , "HKD/CNH{mm=COMP}"
    , "CHF/IDR"
    , "IDR/CNY{mm=EUAM}"
    , "AUD/ZAR{mm=SAXO}"
    , "MXN/TRY{mm=CMC}"
    , "XCD/GBP{mm=COMP}"
    , "TWD/JPY{mm=COMP}"
    , "ILS/PLN"
    , "CAD/INR{mm=EUAM}"
    , "PKR/EUR{mm=EUAM}"
    , "HUF/HKD"
    , "EUR/RWF{mm=DRES}"
    , "BHD/PKR"
    , "MXN/ZAR{mm=EUAM}"
    , "EUR/TJS"
    , "USD/MXN{mm=RADA}"
    , "NZD/GBP{mm=COMP}"
    , "ILS/PLN{mm=COMP}"
    , "USD/INR{mm=MORN}"
    , "THB/USD{mm=COMP}"
    , "IQD/USD"
    , "GBP/SZL{mm=EUAM}"
    , "GBP/XCD{mm=EUAM}"
    , "ZAR/XAF{mm=COMP}"
    , "BRL/CHF{mm=SURF}"
    , "CNY/SGD{mm=EUAM}"
    , "NZD/CNY{mm=COMP}"
    , "GHS/XOF{mm=EUAM}"
    , "MAL/CHF{mm=UBSW}"
    , "AUD/TWD{mm=COMP}"
    , "EUR/NOK{mm=COMP}"
    , "GBP/VND{mm=EUAM}"
    , "SZL/CHF{mm=COMP}"
    , "NGN/GBP"
    , "CAD/SEK{mm=SAXO}"
    , "AUD/HKD"
    , "EUR/JPY{mm=DRES}"
    , "IDR/KRW{mm=EUAM}"
    , "USD/STN"
    , "TMT/USD{mm=COMP}"
    , "USD/PKR{mm=RADA}"
    , "PLN/CHF{mm=COMP}"
    , "ZAR/PGK"
    , "CAD/HKD{mm=CMC}"
    , "EUR/OMR{mm=DRES}"
    , "BRL/TRY{mm=EUAM}"
    , "TTD/GBP{mm=COMP}"
    , "BGN/CNY"
    , "PEN/CAD{mm=COMP}"
    , "EUR/MRU"
    , "AUD/JPY{mm=MORN}"
    , "NOK/CHF{mm=UBSW}"
    , "SGD/NZD{mm=CMC}"
    , "GBP/VND"
    , "USD/INR{mm=COMP}"
    , "USD/ARS{mm=DRES}"
    , "NOK/AUD{mm=CMC}"
    , "AUD/RWF{mm=COMP}"
    , "CAD/SEK{mm=SURF}"
    , "ARS/BRL{mm=EUAM}"
    , "CZK/USD{mm=CMC}"
    , "TRY/HKD{mm=CMC}"
    , "NZD/KRW{mm=COMP}"
    , "CHF/NOK{mm=EUAM}"
    , "NZD/XDR"
    , "OSO/USD{mm=UBSW}"
    , "HUF/DKK{mm=COMP}"
    , "CLF/CLP"
    , "KMF/USD"
    , "AUD/GBP{mm=CMC}"
    , "CVE/EUR"
    , "EUR/LYD{mm=COMP}"
    , "MKD/USD{mm=COMP}"
    , "RSD/EUR{mm=COMP}"
    , "INR/USD"
    , "XPT/EUR{mm=EUAM}"
    , "CLP/GBP{mm=COMP}"
    , "AED/SEK{mm=EUAM}"
    , "USD/CRC"
    , "CZK/USD{mm=EUAM}"
    , "CHF/THB"
    , "ARS/JPY"
    , "EUR/AOA"
    , "EGP/JPY{mm=EUAM}"
    , "NZD/HUF{mm=SAXO}"
    , "CLP/CNY{mm=COMP}"
    , "ZAR/NGN"
    , "GBP/HUF:HSFX"
    , "GBP/CAD{mm=SAXO}"
    , "GBP/LRD{mm=EUAM}"
    , "NOK/AUD"
    , "EUR/MWK{mm=DRES}"
    , "AUD/JPY{mm=COMP}"
    , "PKR/USD{mm=EUAM}"
    , "VND/CAD{mm=EUAM}"
    , "INR/EUR{mm=EUAM}"
    , "CHF/ARS{mm=COMP}"
    , "MZN/ZAR{mm=COMP}"
    , "ARS/PEN{mm=COMP}"
    , "TTD/SRD"
    , "CRC/GBP{mm=COMP}"
    , "EGP/USD"
    , "JPY/INR{mm=COMP}"
    , "SGD/MXN{mm=CMC}"
    , "XPD/USD{mm=UBSW}"
    , "CHF/EUR"
    , "AUD/NOK{mm=SAXO}"
    , "NOK/HUF{mm=COMP}"
    , "GBP/JOD"
    , "EUR/BIF{mm=DRES}"
    , "XAU/ZAR"
    , "LKR/EUR{mm=EUAM}"
    , "ZAR/HKD{mm=EUAM}"
    , "GBP/CAD{mm=SURF}"
    , "ZAR/RON"
    , "USD/LRD"
    , "CAD/ARS"
    , "FRN/CHF{mm=COMP}"
    , "PLN/NOK{mm=CMC}"
    , "DKK/THB{mm=EUAM}"
    , "EUR/NOK{mm=BNPF}"
    , "CAD/DKK{mm=SAXO}"
    , "OMR/USD"
    , "EUR/TRY"
    , "CAD/AED{mm=EUAM}"
    , "ILS/AUD{mm=EUAM}"
    , "CAD/PLN{mm=CMC}"
    , "AMD/GBP"
    , "AUD/NOK{mm=SURF}"
    , "BMD/KYD{mm=EUAM}"
    , "EUR/UGX{mm=COMP}"
    , "GBP/XPF{mm=COMP}"
    , "USD/AUD{mm=CMC}"
    , "USD/BRL{mm=SURF}"
    , "M5P/CHF"
    , "ZAR/KHR{mm=EUAM}"
    , "USD/PHP{mm=RADA}"
    , "GBP/SLL{mm=EUAM}"
    , "SGD/CHF{mm=EUAM}"
    , "LYD/USD{mm=COMP}"
    , "MAD/AUD{mm=EUAM}"
    , "EUR/IDR{mm=COMP}"
    , "USD/UYU"
    , "HKD/INR{mm=MORN}"
    , "CAD/MXN{mm=COMP}"
    , "CNY/EUR{mm=EUAM}"
    , "SLL/USD"
    , "TRY/PLN{mm=CMC}"
    , "AED/JOD{mm=COMP}"
    , "EUR/ZMW{mm=DRES}"
    , "MXN/ARS{mm=COMP}"
    , "GBP/SVC{mm=EUAM}"
    , "EUR/JMD{mm=DRES}"
    , "GBP/ZAR{mm=CMC}"
    , "CAD/DKK{mm=SURF}"
    , "HKD/NOK"
    , "USD/INR{mm=BNPF}"
    , "BGN/CNY{mm=EUAM}"
    , "GBP/KZT{mm=EUAM}"
    , "AUD/FJD"
    , "USD/CNH{mm=DRES}"
    , "CAD/KYD{mm=COMP}"
    , "XDR/CAD"
    , "JPY/RON"
    , "ZAR/CLP{mm=EUAM}"
    , "EUR/INR{mm=RADA}"
    , "RON/ZAR{mm=EUAM}"
    , "NZD/PLN{mm=COMP}"
    , "ILS/CAD"
    , "CNH/KRW{mm=RADA}"
    , "AED/DKK{mm=EUAM}"
    , "DZD/USD"
    , "KRW/CHF{mm=EUAM}"
    , "RSD/USD{mm=COMP}"
    , "XPT/USD{mm=EUAM}"
    , "CAD/PKR{mm=COMP}"
    , "BAM/EUR"
    , "USD/HUF"
    , "TWD/ZAR{mm=COMP}"
    , "SEK/TRY{mm=COMP}"
    , "KWD/AED{mm=EUAM}"
    , "CHF/IDR{mm=EUAM}"
    , "MYR/NZD{mm=COMP}"
    , "LRD/GBP{mm=EUAM}"
    , "GBP/OMR"
    , "XAU/CNY"
    , "USD/SEK{mm=RADA}"
    , "BBD/USD"
    , "GBP/MKD{mm=COMP}"
    , "HUF/NZD"
    , "USD/TND{mm=COMP}"
    , "GBP/MDL"
    , "USD/AED{mm=COMP}"
    , "NOK/INR"
    , "HKD/INR{mm=COMP}"
    , "USD/TZS"
    , "VND/GBP{mm=COMP}"
    , "BND/EUR{mm=COMP}"
    , "AUD/JPY{mm=BNPF}"
    , "INR/USD{mm=EUAM}"
    , "CNY/BRL{mm=COMP}"
    , "HNL/EUR"
    , "USD/BYN"
    , "USD/UGX"
    , "NOK/CZK"
    , "MXN/COP"
    , "CHF/CNH{mm=COMP}"
    , "EUR/TZS{mm=COMP}"
    , "AED/BHD{mm=COMP}"
    , "LKR/ZAR"
    , "CHF/DKK{mm=CMC}"
    , "ZAR/KES"
    , "ZAR/SAR{mm=EUAM}"
    , "COP/ZAR{mm=COMP}"
    , "LKR/USD{mm=EUAM}"
    , "NBL/USD{mm=COMP}"
    , "RON/USD"
    , "GEL/EUR{mm=COMP}"
    , "XAU/SAR{mm=EUAM}"
    , "XPD/USD:HSFX"
    , "EUR/ZAR{mm=DRES}"
    , "EUR/ZAR{mm=CMC}"
    , "AFN/GBP"
    , "CNH/CAD{mm=COMP}"
    , "CNY/BGN{mm=COMP}"
    , "AUD/NZD"
    , "USD/ANG{mm=COMP}"
    , "ZAR/MUR{mm=COMP}"
    , "RUB/BRL"
    , "GBP/JOD{mm=EUAM}"
    , "XAU/TRY{mm=SAXO}"
    , "ZAR/CHF"
    , "KWD/PKR{mm=COMP}"
    , "NOK/GBP"
    , "BRL/CHF{mm=MORN}"
    , "AUD/ZAR{mm=MORN}"
    , "CAD/SEK{mm=CMC}"
    , "EUR/GMD{mm=COMP}"
    , "MKD/GBP"
    , "JPY/AED{mm=COMP}"
    , "EUR/SGD{mm=SAXO}"
    , "CHF/XDR"
    , "DKK/NOK"
    , "USD/PEN{mm=RADA}"
    , "USD/ANG"
    , "DKK/CAD{mm=COMP}"
    , "CNY/RUB{mm=EUAM}"
    , "USD/BOB"
    , "HKD/MYR"
    , "RUB/CHF{mm=EUAM}"
    , "CNY/USD{mm=EUAM}"
    , "SCR/GBP{mm=EUAM}"
    , "AED/CHF"
    , "CAD/XDR{mm=COMP}"
    , "GBP/PGK{mm=COMP}"
    , "TRY/SEK{mm=CMC}"
    , "HKD/AED"
    , "GBP/BRL"
    , "GYD/GBP"
    , "USD/ZAR"
    , "CZK/JPY{mm=COMP}"
    , "EUR/BYN{mm=COMP}"
    , "GBP/THB{mm=EUAM}"
    , "MXN/ZAR{mm=CMC}"
    , "SAR/USD"
    , "ZAR/ILS{mm=COMP}"
    , "USD/ZMW"
    , "EUR/SGD{mm=SURF}"
    , "MYR/AUD{mm=COMP}"
    , "EGP/ZAR{mm=EUAM}"
    , "USD/SOS{mm=EUAM}"
    , "PKR/JPY{mm=COMP}"
    , "EUR/TRY:HSFX"
    , "JPY/CHF"
    , "ZAR/PKR"
    , "GBP/CDF{mm=COMP}"
    , "BRL/CHF{mm=COMP}"
    , "AUD/ZAR{mm=COMP}"
    , "GBP/BHD{mm=EUAM}"
    , "CAD/JPY{mm=CMC}"
    , "USD/DKK{mm=RADA}"
    , "GBP/IDR"
    , "GBP/XOF{mm=EUAM}"
    , "GBP/CDF"
    , "USD/ALL{mm=COMP}"
    , "AUD/BGN"
    , "ARS/JPY{mm=EUAM}"
    , "CNH/PHP"
    , "USD/BAM{mm=COMP}"
    , "NZD/AUD{mm=EUAM}"
    , "BND/USD{mm=COMP}"
    , "EUR/NPR{mm=DRES}"
    , "USD/TWD{mm=SURF}"
    , "ARS/SGD"
    , "AED/PKR"
    , "TRY/JPY{mm=CMC}"
    , "HKD/PLN{mm=CMC}"
    , "USD/MYR{mm=RADA}"
    , "DKK/ISK{mm=EUAM}"
    , "INR/TWD{mm=COMP}"
    , "TWD/CNY{mm=EUAM}"
    , "CNY/TWD{mm=MORN}"
    , "USD/SBD"
    , "SEK/COP"
    , "AUD/CAD{mm=CMC}"
    , "SEK/INR{mm=EUAM}"
    , "BBD/SRD"
    , "GBP/SCR{mm=COMP}"
    , "GEL/USD{mm=COMP}"
    , "OMR/AED{mm=COMP}"
    , "CAD/SEK{mm=COMP}"
    , "USD/AMD"
    , "ZAR/NAD{mm=EUAM}"
    , "HKD/AED{mm=COMP}"
    , "JPY/PKR"
    , "BRL/MXN{mm=EUAM}"
    , "HUF/HKD{mm=COMP}"
    , "TRY/USD"
    , "NZD/HUF{mm=MORN}"
    , "DKK/MYR"
    , "INR/JPY{mm=MORN}"
    , "USD/CNY"
    , "GBP/CAD{mm=MORN}"
    , "XPF/EUR{mm=EUAM}"
    , "EUR/DKK:HSFX"
    , "USD/KES{mm=RADA}"
    , "GBP/NIO{mm=EUAM}"
    , "USD/BZD"
    , "AUD/CNH{mm=RADA}"
    , "EUR/NOK{mm=DRES}"
    , "BGN/CAD"
    , "DKK/AED"
    , "BRI/USD"
    , "ZAR/ARS{mm=EUAM}"
    , "USD/JPY{mm=SAXO}"
    , "CAD/BRL{mm=EUAM}"
    , "XAU/ARS{mm=EUAM}"
    , "USD/BWP{mm=EUAM}"
    , "KHR/USD{mm=EUAM}"
    , "DKK/ISK"
    , "ZAR/GBP{mm=CMC}"
    , "HKD/SGD{mm=EUAM}"
    , "GBP/LAK"
    , "GHS/GBP"
    , "USD/BRL{mm=MORN}"
    , "TWD/KRW{mm=EUAM}"
    , "CNY/TWD{mm=COMP}"
    , "GBP/FJD{mm=COMP}"
    , "PLN/DKK{mm=EUAM}"
    , "PHP/GBP{mm=COMP}"
    , "CLP/AUD{mm=EUAM}"
    , "USD/JPY{mm=SURF}"
    , "XPD/EUR{mm=EUAM}"
    , "CAD/BGN{mm=EUAM}"
    , "M5P/CHF{mm=UBSW}"
    , "USD/INR{mm=DRES}"
    , "THB/KRW"
    , "RUB/EUR"
    , "MUR/GBP"
    , "NZD/HUF{mm=COMP}"
    , "USD/TRY:HSFX"
    , "INR/JPY{mm=COMP}"
    , "GBP/THB"
    , "GBP/CAD{mm=COMP}"
    , "CNH/USD"
    , "CAD/PEN{mm=COMP}"
    , "MXN/USD"
    , "USD/BGN{mm=MORN}"
    , "BAM/GBP{mm=EUAM}"
    , "KYD/EUR"
    , "GBP/NZD{mm=SAXO}"
    , "AFN/GBP{mm=COMP}"
    , "EUR/LYD{mm=DRES}"
    , "EUR/MZN"
    , "CAD/HUF"
    , "GBP/DJF{mm=COMP}"
    , "USD/KMF{mm=COMP}"
    , "AUD/NOK{mm=COMP}"
    , "BRL/TRY"
    , "GBP/RWF"
    , "JPY/EUR{mm=EUAM}"
    , "GBP/EUR"
    , "HUF/ZAR{mm=EUAM}"
    , "USD/BRL{mm=COMP}"
    , "ARS/HKD{mm=COMP}"
    , "AUD/GBP{mm=EUAM}"
    , "THB/CAD{mm=EUAM}"
    , "NOK/USD{mm=CMC}"
    , "HKD/SEK{mm=CMC}"
    , "CHF/INR{mm=COMP}"
    , "UGX/EUR{mm=EUAM}"
    , "GBP/MDL{mm=COMP}"
    , "AUD/CNY{mm=EUAM}"
    , "GBP/NZD{mm=SURF}"
    , "GBP/XAF{mm=EUAM}"
    , "HKD/VND"
    , "ZAR/TWD"
    , "TWD/IDR{mm=COMP}"
    , "PLN/KES{mm=EUAM}"
    , "USD/LBP{mm=RADA}"
    , "ZAR/AUD"
    , "BRL/SRD{mm=EUAM}"
    , "LTL/AUD{mm=EUAM}"
    , "UYU/GBP{mm=EUAM}"
    , "CAD/DKK{mm=COMP}"
    , "TJS/EUR{mm=EUAM}"
    , "CNY/JPY{mm=COMP}"
    , "NZD/CZK{mm=CMC}"
    , "MUR/EUR{mm=COMP}"
    , "AUD/RON{mm=EUAM}"
    , "PGK/EUR{mm=COMP}"
    , "USD/BGN{mm=COMP}"
    , "ZAR/LSL{mm=EUAM}"
    , "XPF/USD{mm=EUAM}"
    , "USD/DKK:HSFX"
    , "KYD/EUR{mm=EUAM}"
    , "NZD/USD"
    , "AED/HKD{mm=EUAM}"
    , "AED/AUD"
    , "NZD/TOP{mm=EUAM}"
    , "GBP/ISK{mm=EUAM}"
    , "CAD/MYR{mm=COMP}"
    , "ZAR/CNH{mm=EUAM}"
    , "USD/PLN"
    , "ALL/EUR"
    , "SEK/DKK{mm=SAXO}"
    , "USD/BTN{mm=EUAM}"
    , "CZK/ZAR{mm=COMP}"
    , "SEK/AED{mm=EUAM}"
    , "EUR/UGX{mm=DRES}"
    , "THB/JPY"
    , "JPY/BRL{mm=COMP}"
    , "XAG/MXN"
    , "HKD/JPY{mm=CMC}"
    , "XPT/USD"
    , "ARS/CLP{mm=COMP}"
    , "EUR/IDR{mm=DRES}"
    , "AUD/KRW{mm=EUAM}"
    , "USD/GNF"
    , "EUR/MZN{mm=EUAM}"
    , "JPY/TWD"
    , "PKR/ZAR{mm=COMP}"
    , "SEK/MXN{mm=COMP}"
    , "USD/MMK{mm=EUAM}"
    , "JPY/AUD"
    , "XPD/USD{mm=EUAM}"
    , "GBP/AUD{mm=SAXO}"
    , "AUD/IDR{mm=MORN}"
    , "CAD/ZAR"
    , "XAG/SAR{mm=EUAM}"
    , "EUR/USD{mm=SAXO}"
    , "GBP/HRK"
    , "HKD/JPY{mm=SAXO}"
    , "SEK/DKK{mm=SURF}"
    , "GBP/MVR{mm=COMP}"
    , "ARS/ZAR{mm=EUAM}"
    , "XAU/CAD"
    , "IDR/SGD{mm=COMP}"
    , "SGD/THB{mm=EUAM}"
    , "GBP/CAD{mm=BNPF}"
    , "EUR/SGD{mm=MORN}"
    , "OMR/EUR{mm=EUAM}"
    , "GBP/QAR"
    , "HKD/EUR{mm=EUAM}"
    , "GBP/CRC{mm=COMP}"
    , "SEK/USD"
    , "JPY/RUB{mm=EUAM}"
    , "NAD/XOF{mm=COMP}"
    , "GBP/AUD{mm=SURF}"
    , "THB/GBP{mm=COMP}"
    , "ZAR/MAD"
    , "JPY/USD{mm=EUAM}"
    , "CAD/TWD{mm=EUAM}"
    , "EUR/USD{mm=SURF}"
    , "HKD/JPY{mm=SURF}"
    , "USD/AED{mm=DRES}"
    , "SGD/TRY{mm=CMC}"
    , "BRL/SEK{mm=EUAM}"
    , "THB/CNY{mm=COMP}"
    , "UGX/USD{mm=EUAM}"
    , "USD/TWD{mm=MORN}"
    , "ZAR/TRY{mm=COMP}"
    , "KRW/THB{mm=EUAM}"
    , "ZAR/INR"
    , "EUR/TZS{mm=DRES}"
    , "AUD/IDR{mm=COMP}"
    , "CNH/NZD{mm=COMP}"
    , "ILS/CHF{mm=EUAM}"
    , "XAU/TRY{mm=COMP}"
    , "AWG/GBP"
    , "NPR/GBP"
    , "CNY/CHF"
    , "TJS/USD{mm=EUAM}"
    , "ZAR/CZK"
    , "USD/ZAR{mm=SAXO}"
    , "CHF/PHP"
    , "AUD/CLP"
    , "AWG/SRD{mm=COMP}"
    , "MUR/USD{mm=COMP}"
    , "GBP/LAK{mm=COMP}"
    , "PGK/USD{mm=COMP}"
    , "EUR/SGD{mm=COMP}"
    , "NGN/USD{mm=COMP}"
    , "GBP/TTD{mm=EUAM}"
    , "XDR/EUR{mm=EUAM}"
    , "XPT/USD:HSFX"
    , "AED/SAR{mm=EUAM}"
    , "GBP/XDR"
    , "KYD/USD{mm=EUAM}"
    , "HKD/BRL{mm=COMP}"
    , "AED/INR"
    , "DKK/NZD{mm=COMP}"
    , "OSO/CHF"
    , "CHF/AED{mm=COMP}"
    , "CAD/CNY"
    , "USD/HKD{mm=RADA}"
    , "AED/CZK"
    , "ZAR/GHS{mm=EUAM}"
    , "INR/CNH"
    , "ZAR/GBP"
    , "THB/KRW{mm=COMP}"
    , "GYD/EUR{mm=EUAM}"
    , "USD/ZAR{mm=SURF}"
    , "BWP/CHF{mm=COMP}"
    , "SEK/ILS"
    , "PLN/TRY"
    , "USD/TWD{mm=COMP}"
    , "BRL/PEN{mm=EUAM}"
    , "ETB/EUR"
    , "CZK/NOK{mm=COMP}"
    , "CZK/JPY"
    , "MKD/GBP{mm=COMP}"
    , "INR/ZAR{mm=COMP}"
    , "JPY/INR"
    , "EUR/BDT"
    , "CNY/ZAR{mm=MORN}"
    , "USD/KHR{mm=RADA}"
    , "CHF/SGD{mm=EUAM}"
    , "CAD/JPY{mm=EUAM}"
    , "SHP/USD"
    , "EUR/BYN{mm=DRES}"
    , "NOK/HKD"
    , "JPY/CZK"
    , "TOP/FJD{mm=COMP}"
    , "AED/GBP"
    , "RSD/EUR"
    , "CVE/USD"
    , "LKR/ZAR{mm=COMP}"
    , "USD/XOF"
    , "BOB/GBP"
    , "USD/JPY{mm=MORN}"
    , "OMR/USD{mm=EUAM}"
    , "PKR/GBP{mm=EUAM}"
    , "EUR/SOS{mm=COMP}"
    , "HKD/USD{mm=EUAM}"
    , "GBP/HNL"
    , "AED/ILS{mm=COMP}"
    , "USD/COP{mm=EUAM}"
    , "ZAR/CAD{mm=CMC}"
    , "XAF/USD{mm=COMP}"
    , "SEK/AUD{mm=CMC}"
    , "SGD/CAD{mm=COMP}"
    , "USD/AWG{mm=COMP}"
    , "USD/RWF{mm=COMP}"
    , "USD/CLP{mm=RADA}"
    , "MXN/AED{mm=COMP}"
    , "XAG/ARS{mm=EUAM}"
    , "PHP/GBP"
    , "BRL/DKK{mm=EUAM}"
    , "JPY/GBP"
    , "CNH/AUD{mm=COMP}"
    , "EUR/GEL{mm=EUAM}"
    , "MDL/EUR{mm=COMP}"
    , "GBP/BOB{mm=COMP}"
    , "JPY/TWD{mm=COMP}"
    , "AUD/SGD{mm=SAXO}"
    , "CHF/USD"
    , "IDR/EUR{mm=COMP}"
    , "CNY/ZAR{mm=COMP}"
    , "BBD/EUR{mm=COMP}"
    , "HKD/BRL"
    , "MWK/ZAR{mm=EUAM}"
    , "EUR/AUD:HSFX"
    , "HUF/MXN"
    , "GBP/MUR{mm=EUAM}"
    , "DKK/AUD{mm=COMP}"
    , "USD/CAD"
    , "MXN/SGD{mm=EUAM}"
    , "GBP/NZD{mm=MORN}"
    , "KRW/CAD{mm=COMP}"
    , "USD/JPY{mm=COMP}"
    , "LYD/GBP{mm=COMP}"
    , "XAUK/CHF{mm=UBSW}"
    , "USD/UZS"
    , "EUR/SGD{mm=BNPF}"
    , "XDR/USD{mm=EUAM}"
    , "BRL/XOF{mm=COMP}"
    , "CAD/HKD{mm=MORN}"
    , "USD/OMR{mm=COMP}"
    , "AUD/SGD{mm=SURF}"
    , "EUR/JMD"
    , "USD/NOK{mm=SAXO}"
    , "USD/SAR{mm=RADA}"
    , "HKD/IDR"
    , "BSD/USD{mm=COMP}"
    , "ZAR/INR{mm=EUAM}"
    , "MWK/ZAR"
    , "XAU/INR{mm=EUAM}"
    , "GYD/USD{mm=EUAM}"
    , "XAG/ARS"
    , "MYR/CHF{mm=COMP}"
    , "CAD/PLN"
    , "EUR/BWP{mm=COMP}"
    , "AUD/MXN"
    , "SEK/BRL{mm=EUAM}"
    , "USD/IQD{mm=RADA}"
    , "GBP/ILS{mm=EUAM}"
    , "USD/TWD{mm=BNPF}"
    , "MYR/BRL"
    , "RSD/GBP{mm=COMP}"
    , "XOF/GHS"
    , "XPT/GBP{mm=EUAM}"
    , "THB/SGD"
    , "TZS/EUR"
    , "USD/NOK{mm=SURF}"
    , "ZAR/NGN{mm=COMP}"
    , "GBP/NZD{mm=COMP}"
    , "QAR/PKR"
    , "CHF/ILS"
    , "NZD/CHF{mm=EUAM}"
    , "CAD/HKD{mm=COMP}"
    , "BAM/USD"
    , "INR/GBP{mm=EUAM}"
    , "MYR/IDR"
    , "USD/MWK{mm=COMP}"
    , "JPY/NOK{mm=SAXO}"
    , "CHF/JPY{mm=SAXO}"
    , "INR/CNY{mm=EUAM}"
    , "HKD/TWD{mm=COMP}"
    , "ZAR/NPR"
    , "CHF/BWP{mm=EUAM}"
    , "TWD/CHF"
    , "THB/NZD{mm=EUAM}"
    , "DKK/BRL"
    , "LKR/GBP{mm=EUAM}"
    , "CHF/EUR{mm=EUAM}"
    , "GBP/ETB"
    , "MDL/USD{mm=COMP}"
    , "USD/BIF{mm=COMP}"
    , "USD/CVE{mm=EUAM}"
    , "HNL/USD"
    , "CNY/TWD"
    , "USD/AMD{mm=EUAM}"
    , "CNY/AUD"
    , "PEN/CLP"
    , "EUR/RUB{mm=MORN}"
    , "IDR/USD{mm=COMP}"
    , "GBP/AUD{mm=MORN}"
    , "USD/BRL{mm=DRES}"
    , "BBD/USD{mm=COMP}"
    , "USD/CLF"
    , "EUR/USD{mm=MORN}"
    , "USD/SBD{mm=EUAM}"
    , "JPY/NOK{mm=SURF}"
    , "CHF/JPY{mm=SURF}"
    , "HKD/JPY{mm=MORN}"
    , "ZAR/BTN"
    , "USD/BWP"
    , "CHF/HUF{mm=CMC}"
    , "USD/YER{mm=RADA}"
    , "KRW/CHF"
    , "GBP/COP"
    , "IDR/CHF"
    , "CZK/SEK"
    , "CNY/NOK{mm=COMP}"
    , "MAD/ZAR"
    , "USD/JPY{mm=BNPF}"
    , "CNY/GBP{mm=EUAM}"
    , "MYR/PKR{mm=EUAM}"
    , "SEK/DKK{mm=COMP}"
    , "CAD/CZK{mm=CMC}"
    , "HKD/THB"
    , "INR/KRW{mm=EUAM}"
    , "USD/BGN{mm=DRES}"
    , "EUR/JPY{mm=RADA}"
    , "NZD/DKK{mm=CMC}"
    , "AUD/EUR{mm=SAXO}"
    , "EUR/MGA"
    , "MXN/JPY{mm=SAXO}"
    , "TWD/PKR"
    , "CLP/CHF{mm=EUAM}"
    , "USD/ZMW{mm=COMP}"
    , "USD/JMD{mm=COMP}"
    , "CHF/BRL{mm=COMP}"
    , "XPTK/CHF{mm=COMP}"
    , "PLN/SGD{mm=CMC}"
    , "USD/NAD{mm=RADA}"
    , "CAD/ZAR{mm=EUAM}"
    , "MXN/EUR{mm=EUAM}"
    , "EUR/RUB{mm=COMP}"
    , "TRY/CZK{mm=CMC}"
    , "EUR/BTN{mm=COMP}"
    , "GBP/AUD{mm=COMP}"
    , "HKD/EUR"
    , "EUR/USD{mm=COMP}"
    , "TRY/DKK{mm=SAXO}"
    , "USD/BMD"
    , "CZK/DKK"
    , "NOK/NZD"
    , "HKD/JPY{mm=COMP}"
    , "USD/ARS{mm=RADA}"
    , "BWP/ZAR"
    , "VND/CAD"
    , "MMK/USD{mm=EUAM}"
    , "USD/ZAR{mm=MORN}"
    , "AUD/EUR{mm=SURF}"
    , "XAG/TRY{mm=COMP}"
    , "BDT/JPY"
    , "USD/QAR{mm=EUAM}"
    , "DKK/TRY{mm=EUAM}"
    , "GBP/GTQ{mm=EUAM}"
    , "PLN/ILS{mm=COMP}"
    , "SGD/ZAR{mm=CMC}"
    , "EUR/MMK{mm=COMP}"
    , "CZK/SGD"
    , "ZAR/TND{mm=EUAM}"
    , "TRY/MXN{mm=COMP}"
    , "CAD/SAR{mm=COMP}"
    , "BND/GBP{mm=COMP}"
    , "ZAR/AED{mm=EUAM}"
    , "MYR/THB"
    , "THB/AUD{mm=EUAM}"
    , "SAR/EUR{mm=EUAM}"
    , "BGN/EUR{mm=COMP}"
    , "USD/IDR{mm=SURF}"
    , "SEK/KES{mm=COMP}"
    , "CNY/KRW{mm=EUAM}"
    , "BMD/GBP"
    , "CHF/RUB{mm=EUAM}"
    , "INR/IDR{mm=COMP}"
    , "ZAR/MXN{mm=COMP}"
    , "TWD/SGD{mm=COMP}"
    , "GBP/BAM"
    , "CHF/USD{mm=EUAM}"
    , "XAU/MXN{mm=COMP}"
    , "AUD/HKD{mm=CMC}"
    , "GEL/GBP{mm=COMP}"
    , "MYR/EUR"
    , "DKK/GBP{mm=CMC}"
    , "MXN/BRL{mm=COMP}"
    , "CNY/INR"
    , "XDR/JPY{mm=COMP}"
    , "LRD/EUR{mm=COMP}"
    , "USD/ZAR{mm=COMP}"
    , "ILS/JOD{mm=EUAM}"
    , "DKK/THB"
    , "GBP/PHP"
    , "USD/BSD{mm=EUAM}"
    , "EUR/KWD{mm=COMP}"
    , "ZAR/PKR{mm=COMP}"
    , "YER/USD"
    , "USD/AFN{mm=RADA}"
    , "AED/TRY{mm=COMP}"
    , "KHR/GBP{mm=EUAM}"
    , "CHF/NOK:HSFX"
    , "XPF/EUR"
    , "EUR/SGD{mm=DRES}"
    , "NZD/CAD{mm=SAXO}"
    , "PEN/MXN"
    , "DKK/RWF"
    , "USD/RSD{mm=EUAM}"
    , "GBP/GYD{mm=COMP}"
    , "AUD/USD{mm=SAXO}"
    , "DKK/EUR"
    , "CNY/GBP"
    , "CNY/IDR{mm=COMP}"
    , "USD/TZS{mm=SURF}"
    , "MXN/RUB{mm=EUAM}"
    , "SEK/RWF{mm=EUAM}"
    , "GBP/CHF{mm=SAXO}"
    , "AUD/SGD{mm=MORN}"
    , "TND/ZAR{mm=EUAM}"
    , "GBP/LKR{mm=COMP}"
    , "MXN/USD{mm=EUAM}"
    , "USD/LSL{mm=RADA}"
    , "PEN/MXN{mm=EUAM}"
    , "GBP/AUD{mm=CMC}"
    , "AUD/ARS"
    , "GBP/AUD{mm=BNPF}"
    , "BRL/HKD{mm=EUAM}"
    , "NOK/CAD{mm=COMP}"
    , "JPY/ZAR{mm=COMP}"
    , "EUR/SOS"
    , "EUR/USD{mm=BNPF}"
    , "NZD/CAD{mm=SURF}"
    , "GBP/GNF{mm=COMP}"
    , "AUD/USD{mm=SURF}"
    , "DKK/SEK{mm=UBSW}"
    , "CAD/NOK{mm=EUAM}"
    , "USD/CNH{mm=RADA}"
    , "UGX/ZAR{mm=COMP}"
    , "RUB/USD"
    , "CHF/ZAR{mm=SAXO}"
    , "CHF/NOK{mm=CMC}"
    , "ANG/EUR"
    , "SCR/EUR{mm=COMP}"
    , "GBP/LKR"
    , "GBP/CHF{mm=SURF}"
    , "STN/EUR"
    , "GBP/DOP{mm=COMP}"
    , "TWD/AUD"
    , "STN/EUR{mm=COMP}"
    , "SAR/USD{mm=EUAM}"
    , "BGN/USD{mm=COMP}"
    , "USD/NOK{mm=MORN}"
    , "KYD/USD"
    , "USD/CVE"
    , "SGD/NZD{mm=COMP}"
    , "JPY/RSD{mm=EUAM}"
    , "TOP/NZD{mm=COMP}"
    , "USD/NPR{mm=COMP}"
    , "AUD/PLN{mm=CMC}"
    , "EUR/SOS{mm=DRES}"
    , "ZAR/HKD"
    , "EUR/COP{mm=COMP}"
    , "PAB/USD{mm=COMP}"
    , "AUD/SGD{mm=COMP}"
    , "USD/UYU{mm=EUAM}"
    , "ZAR/XDR{mm=COMP}"
    , "GBP/TOP{mm=COMP}"
    , "HKD/ZAR{mm=MORN}"
    , "XAG/INR{mm=EUAM}"
    , "GBP/USD"
    , "CAD/ARS{mm=COMP}"
    , "HKD/CZK{mm=CMC}"
    , "GBP/TRY{mm=EUAM}"
    , "CHF/TWD{mm=COMP}"
    , "KRW/TWD"
    , "NZD/CNH"
    , "IDR/TWD"
    , "KRW/AUD"
    , "MDL/EUR{mm=DRES}"
    , "BRL/CLP{mm=EUAM}"
    , "LRD/USD{mm=COMP}"
    , "BWP/XOF{mm=COMP}"
    , "IDR/AUD"
    , "AED/HKD"
    , "XPF/GBP{mm=EUAM}"
    , "CYP/ZAR{mm=COMP}"
    , "KMF/USD{mm=EUAM}"
    , "EUR/PGK"
    , "USD/ZAR{mm=BNPF}"
    , "EUR/CZK{mm=SAXO}"
    , "GBP/SVC"
    , "SGD/CHF"
    , "KRW/NZD{mm=COMP}"
    , "USD/IRR{mm=COMP}"
    , "EUR/AUD{mm=CMC}"
    , "RON/EUR{mm=EUAM}"
    , "XDR/JPY"
    , "EUR/ZAR{mm=RADA}"
    , "ZAR/PHP{mm=COMP}"
    , "DOE/CHF"
    , "USD/LBP"
    , "USD/NOK{mm=COMP}"
    , "TWD/EUR{mm=COMP}"
    , "CHF/JPY{mm=MORN}"
    , "ZAR/NAD"
    , "SEK/USD{mm=CMC}"
    , "ILS/JPY"
    , "USD/GBP{mm=EUAM}"
    , "USD/JPY{mm=DRES}"
    , "PHP/HKD"
    , "USD/MOP"
    , "JPY/HKD"
    , "JPY/CZK{mm=EUAM}"
    , "GBP/BDT{mm=COMP}"
    , "OMR/ZAR{mm=COMP}"
    , "USD/OMR{mm=DRES}"
    , "SAR/KWD{mm=EUAM}"
    , "ALL/USD"
    , "HKD/ZAR{mm=COMP}"
    , "QAR/GBP"
    , "MZN/USD{mm=COMP}"
    , "AUN/USD{mm=COMP}"
    , "XPD/GBP{mm=EUAM}"
    , "USD/MRU{mm=EUAM}"
    , "GBP/HNL{mm=EUAM}"
    , "TRY/SEK{mm=COMP}"
    , "EUR/GBP{mm=SAXO}"
    , "ZAR/CYP"
    , "PLN/EUR{mm=CMC}"
    , "DKK/CHF{mm=MORN}"
    , "SEK/CNH"
    , "EUR/NGN"
    , "NZD/CNH{mm=CMC}"
    , "MXN/AUD{mm=CMC}"
    , "COP/EUR{mm=COMP}"
    , "EUR/BWP{mm=DRES}"
    , "ILS/CAD{mm=COMP}"
    , "AED/INR{mm=EUAM}"
    , "ZAR/EGP"
    , "SGD/PKR"
    , "XOF/ZAR"
    , "ZAR/SEK{mm=COMP}"
    , "GBP/ILS"
    , "CAD/BMD"
    , "TRY/BRL{mm=EUAM}"
    , "USD/KWD"
    , "SGD/AUD{mm=COMP}"
    , "TOP/AUD{mm=COMP}"
    , "MYR/DKK{mm=EUAM}"
    , "DKK/XDR"
    , "USD/GHS{mm=RADA}"
    , "JPY/NOK{mm=COMP}"
    , "SCR/USD{mm=COMP}"
    , "CLP/CHF"
    , "CHF/JPY{mm=COMP}"
    , "USD/LYD{mm=COMP}"
    , "TWD/INR"
    , "EUR/RON{mm=SAXO}"
    , "EUR/GBP{mm=SURF}"
    , "AUD/EUR{mm=MORN}"
    , "CNH/CHF{mm=COMP}"
    , "CAD/IDR{mm=EUAM}"
    , "CHF/NOK{mm=SAXO}"
    , "MYR/THB{mm=COMP}"
    , "ZAR/BRL{mm=EUAM}"
    , "JPY/CNY{mm=EUAM}"
    , "HUF/AED{mm=COMP}"
    , "AUD/SEK{mm=CMC}"
    , "MXN/COP{mm=EUAM}"
    , "EUR/RON"
    , "XAU/BRL{mm=EUAM}"
    , "XDR/ZAR{mm=COMP}"
    , "SEK/HKD{mm=COMP}"
    , "USD/IRR"
    , "GBP/BMD{mm=COMP}"
    , "USD/IDR{mm=MORN}"
    , "XAG/ZAR"
    , "DOP/GBP"
    , "GBP/SEK{mm=UBSW}"
    , "DKK/CHF{mm=COMP}"
    , "KRW/AUD{mm=COMP}"
    , "CAD/CNH{mm=COMP}"
    , "NBL/CHF{mm=UBSW}"
    , "JPY/RON{mm=EUAM}"
    , "GBP/DJF"
    , "KRW/INR"
    , "BAM/EUR{mm=COMP}"
    , "BRL/ILS{mm=COMP}"
    , "MUR/GBP{mm=COMP}"
    , "IDR/INR"
    , "MDL/USD{mm=DRES}"
    , "USD/PYG{mm=EUAM}"
    , "PGK/GBP{mm=COMP}"
    , "EUR/CVE{mm=COMP}"
    , "BMD/KYD"
    , "GBP/CUP"
    , "BHD/PKR{mm=COMP}"
    , "NZD/THB{mm=EUAM}"
    , "EUR/AMD{mm=COMP}"
    , "ZAR/BGN{mm=EUAM}"
    , "NGN/GBP{mm=COMP}"
    , "CHF/NOK{mm=SURF}"
    , "GBP/BZD{mm=EUAM}"
    , "HUF/SGD{mm=EUAM}"
    , "PLN/TRY{mm=COMP}"
    , "KYD/GBP{mm=EUAM}"
    , "EGP/EUR{mm=EUAM}"
    , "ZAR/FJD"
    , "RON/USD{mm=EUAM}"
    , "TWD/USD{mm=COMP}"
    , "AUD/EUR{mm=COMP}"
    , "USD/NOK{mm=BNPF}"
    , "GBP/SRD"
    , "MXN/JPY{mm=COMP}"
    , "JPY/KRW{mm=EUAM}"
    , "AUD/JPY{mm=CMC}"
    , "USD/UGX{mm=COMP}"
    , "XOF/CNY"
    , "EUR/NOK{mm=RADA}"
    , "PEN/ARS"
    , "GBP/HRK{mm=COMP}"
    , "XAU/HKD{mm=SAXO}"
    , "DKK/CAD{mm=CMC}"
    , "USD/IDR{mm=COMP}"
    , "UYU/EUR{mm=COMP}"
    , "IDR/GBP"
    , "USD/BSD"
    , "CAD/DKK{mm=CMC}"
    , "TRY/DKK{mm=COMP}"
    , "DKK/THB{mm=SAXO}"
    , "XAG/MXN{mm=COMP}"
    , "DKK/MXN{mm=EUAM}"
    , "ISK/CHF{mm=MORN}"
    , "GBP/KMF"
    , "SAR/JPY{mm=COMP}"
    , "GBP/NGN{mm=EUAM}"
    , "HKD/NOK{mm=COMP}"
    , "COP/USD{mm=COMP}"
    , "CZK/SGD{mm=COMP}"
    , "ETB/USD"
    , "GBP/VES"
    , "BMD/BBD{mm=EUAM}"
    , "EUR/RUB{mm=DRES}"
    , "EUR/BTN{mm=DRES}"
    , "OMR/GBP{mm=EUAM}"
    , "ZAR/DKK{mm=COMP}"
    , "SEK/ZAR{mm=EUAM}"
    , "EUR/USD{mm=DRES}"
    , "TRY/DKK{mm=CMC}"
    , "EGP/BRL{mm=COMP}"
    , "XAG/CNY"
    , "USD/INR{mm=RADA}"
    , "XAU/RUB"
    , "HKD/CNY{mm=EUAM}"
    , "MXN/TRY"
    , "ZAR/HKD{mm=CMC}"
    , "CHF/JPY{mm=BNPF}"
    , "EUR/QAR{mm=COMP}"
    , "RSD/USD"
    , "CHF/CNH"
    , "BRL/ARS{mm=EUAM}"
    , "ZAR/NZD"
    , "AUD/USD{mm=MORN}"
    , "EUR/MMK{mm=DRES}"
    , "DKK/PKR{mm=EUAM}"
    , "BWP/XOF"
    , "EUR/KES"
    , "ZAR/MYR{mm=COMP}"
    , "ARS/SGD{mm=EUAM}"
    , "CZK/GBP{mm=CMC}"
    , "GBP/CHF{mm=MORN}"
    , "JPY/IDR{mm=COMP}"
    , "GBP/SLL"
    , "THB/MYR"
    , "NZD/CHF{mm=CMC}"
    , "USD/PLN{mm=EUAM}"
    , "ZAR/KHR"
    , "ILS/SEK"
    , "IDR/CAD{mm=EUAM}"
    , "ARS/BRL"
    , "SGD/TWD"
    , "FJD/EUR{mm=EUAM}"
    , "EUR/CHF"
    , "AED/NZD"
    , "ISK/CHF{mm=COMP}"
    , "USD/TZS{mm=COMP}"
    , "SGD/AUD"
    , "BAM/USD{mm=COMP}"
    , "BRI/USD{mm=COMP}"
    , "AED/MXN{mm=COMP}"
    , "CHF/ZAR{mm=MORN}"
    , "XDR/GBP{mm=EUAM}"
    , "EUR/PLN{mm=SAXO}"
    , "ZAR/KES{mm=COMP}"
    , "EGP/USD{mm=EUAM}"
    , "CZK/NOK"
    , "SGD/TRY{mm=EUAM}"
    , "HKD/KRW{mm=EUAM}"
    , "GBP/EGP{mm=COMP}"
    , "XDR/CNY{mm=EUAM}"
    , "USD/ZAR{mm=DRES}"
    , "XDR/DKK"
    , "AUD/HUF"
    , "NZD/CAD{mm=COMP}"
    , "AUD/USD{mm=COMP}"
    , "BSD/GBP{mm=COMP}"
    , "EUR/BSD{mm=COMP}"
    , "JPY/NZD"
    , "NZD/TRY"
    , "EUR/KWD{mm=DRES}"
    , "GYD/GBP{mm=EUAM}"
    , "GBP/BIF"
    , "USD/GMD{mm=COMP}"
    , "HUF/JPY{mm=SAXO}"
    , "GBP/CHF{mm=COMP}"
    , "KWD/PKR"
    , "USD/SGD{mm=SAXO}"
    , "NOK/ILS{mm=EUAM}"
    , "ZAR/BGN"
    , "JPY/PLN{mm=EUAM}"
    , "EUR/PLN{mm=SURF}"
    , "ZAR/TWD{mm=EUAM}"
    , "AED/PKR{mm=COMP}"
    , "UYU/USD{mm=COMP}"
    , "EUR/RSD{mm=COMP}"
    , "USD/IDR{mm=BNPF}"
    , "HUF/EUR{mm=EUAM}"
    , "EUR/PKR"
    , "NOK/NZD{mm=COMP}"
    , "USD/PEN"
    , "XAU/KRW"
    , "DKK/XDR{mm=EUAM}"
    , "KES/ZAR{mm=EUAM}"
    , "EUR/CZK{mm=MORN}"
    , "EUR/IDR{mm=RADA}"
    , "GBP/DOP"
    , "CHF/ZAR{mm=COMP}"
    , "CLP/AUD"
    , "USD/BYN{mm=COMP}"
    , "CAD/INR{mm=MORN}"
    , "THB/CHF{mm=EUAM}"
    , "DKK/COP"
    , "TZS/USD"
    , "ZAR/PLN{mm=CMC}"
    , "GTQ/GBP"
    , "USD/SGD{mm=SURF}"
    , "HUF/ZAR"
    , "GBP/MAD{mm=COMP}"
    , "JOD/AED{mm=COMP}"
    , "HKD/PHP"
    , "INR/SGD{mm=COMP}"
    , "COP/CLP"
    , "HKD/IDR{mm=COMP}"
    , "EUR/VES{mm=COMP}"
    , "CAD/KWD"
    , "ZAR/RWF{mm=EUAM}"
    , "SEK/NOK{mm=EUAM}"
    , "DKK/PHP{mm=EUAM}"
    , "MDL/GBP{mm=COMP}"
    , "CNY/HKD"
    , "GBP/THB{mm=SAXO}"
    , "SEK/TRY"
    , "CHF/RSD{mm=EUAM}"
    , "PLN/SEK{mm=SAXO}"
    , "GBP/MXN{mm=EUAM}"
    , "NOK/MXN"
    , "IDR/GBP{mm=COMP}"
    , "CZK/EUR{mm=COMP}"
    , "BBD/GBP{mm=COMP}"
    , "EUR/GBP{mm=MORN}"
    , "ANG/SRD{mm=EUAM}"
    , "GBP/USD{mm=CMC}"
    , "HUF/BRL{mm=COMP}"
    , "ZAR/LBP{mm=COMP}"
    , "IDR/CNY{mm=COMP}"
    , "FJD/USD{mm=EUAM}"
    , "GBP/AUD:HSFX"
    , "USD/TND{mm=RADA}"
    , "SGD/INR"
    , "EUR/CNY{mm=MORN}"
    , "EUR/CZK{mm=COMP}"
    , "GBP/KYD{mm=EUAM}"
    , "AUD/ZAR"
    , "USD/AED{mm=RADA}"
    , "EUR/COP{mm=DRES}"
    , "USD/DZD"
    , "EUR/UYU{mm=COMP}"
    , "CAD/INR{mm=COMP}"
    , "PKR/EUR{mm=COMP}"
    , "SGD/CZK"
    , "ARE/USD{mm=UBSW}"
    , "ZAR/JPY{mm=EUAM}"
    , "JPY/SGD{mm=SURF}"
    , "NZD/AUD{mm=SAXO}"
    , "CZK/AED"
    , "CHF/CNH{mm=RADA}"
    , "MXN/ZAR{mm=COMP}"
    , "HKD/DKK{mm=CMC}"
    , "GBP/PKR{mm=EUAM}"
    , "XAU/JPY{mm=EUAM}"
    , "USD/RUB"
    , "ZAR/OMR{mm=EUAM}"
    , "RUB/TRY{mm=EUAM}"
    , "ARS/EUR{mm=EUAM}"
    , "SCR/EUR"
    , "PLN/NZD{mm=CMC}"
    , "BMD/BBD"
    , "GBP/SZL{mm=COMP}"
    , "GBP/XCD{mm=COMP}"
    , "CNY/SGD{mm=COMP}"
    , "CHF/NOK{mm=MORN}"
    , "HKD/PLN{mm=EUAM}"
    , "DOE/USD{mm=UBSW}"
    , "AUD/USD{mm=BNPF}"
    , "GHS/XOF{mm=COMP}"
    , "MYR/HKD{mm=EUAM}"
    , "XAU/JPY"
    , "GBP/VND{mm=COMP}"
    , "NOK/AUD{mm=COMP}"
    , "USD/NOK{mm=DRES}"
    , "SGD/GBP"
    , "ARS/EUR"
    , "GBP/CHF{mm=BNPF}"
    , "EUR/GBP{mm=COMP}"
    , "XAG/BRL{mm=EUAM}"
    , "EUR/MVR{mm=EUAM}"
    , "KZT/GBP"
    , "IDR/KRW{mm=COMP}"
    , "HUF/USD{mm=EUAM}"
    , "NZD/HUF{mm=CMC}"
    , "MMK/GBP{mm=EUAM}"
    , "EUR/CNY{mm=COMP}"
    , "EUR/KRW{mm=MORN}"
    , "BRL/TRY{mm=COMP}"
    , "DKK/PHP"
    , "EUR/MRU{mm=COMP}"
    , "HKD/USD"
    , "LAK/GBP"
    , "EUR/USD{mm=CMC}"
    , "EUR/RON{mm=COMP}"
    , "USD/MKD"
    , "ZAR/SEK{mm=CMC}"
    , "BGN/CAD{mm=EUAM}"
    , "ZAR/LSL"
    , "ZAR/MWK{mm=EUAM}"
    , "ARS/BRL{mm=COMP}"
    , "BRL/MXN{mm=SURF}"
    , "ZAR/BHD"
    , "AUD/CNY"
    , "CHF/NOK{mm=COMP}"
    , "GBP/SRD{mm=EUAM}"
    , "PLN/MXN{mm=CMC}"
    , "PLN/DKK{mm=SAXO}"
    , "EUR/TWD"
    , "PLN/AED{mm=EUAM}"
    , "AUD/CZK{mm=SAXO}"
    , "EUR/AUD"
    , "XPT/EUR{mm=COMP}"
    , "CHF/CNY{mm=EUAM}"
    , "AED/SEK{mm=COMP}"
    , "GBP/XDR{mm=EUAM}"
    , "PGK/AUD{mm=EUAM}"
    , "CZK/USD{mm=COMP}"
    , "SEK/UGX{mm=EUAM}"
    , "XAG/HKD{mm=SAXO}"
    , "EGP/JPY{mm=COMP}"
    , "BGN/DKK"
    , "EUR/KRW{mm=COMP}"
    , "AED/BHD"
    , "CLP/GBP"
    , "MXN/CZK{mm=EUAM}"
    , "USD/ALL{mm=RADA}"
    , "USD/EUR{mm=SURF}"
    , "PLN/MXN{mm=COMP}"
    , "GBP/LRD{mm=COMP}"
    , "USD/KRW"
    , "HKD/SGD{mm=SURF}"
    , "MXN/USD{mm=CMC}"
    , "MYR/USD"
    , "CHF/TRY"
    , "CHF/RON{mm=EUAM}"
    , "SGD/CHF{mm=MORN}"
    , "PKR/USD{mm=COMP}"
    , "COP/MXN"
    , "TRY/HKD{mm=COMP}"
    , "VND/CAD{mm=COMP}"
    , "INR/EUR{mm=COMP}"
    , "USD/BND{mm=EUAM}"
    , "SZL/ZAR"
    , "PKR/GBP"
    , "ZAR/JPY{mm=CMC}"
    , "EUR/HUF{mm=SAXO}"
    , "UZS/EUR"
    , "CNH/THB{mm=COMP}"
    , "GBP/PHP{mm=EUAM}"
    , "EUR/PYG{mm=COMP}"
    , "USD/TND"
    , "EUR/CVE{mm=DRES}"
    , "SEK/CNH{mm=COMP}"
    , "ARS/USD{mm=EUAM}"
    , "EUR/AMD{mm=DRES}"
    , "AUD/GBP{mm=SAXO}"
    , "GNF/USD"
    , "CNH/MYR{mm=EUAM}"
    , "LKR/EUR{mm=COMP}"
    , "ZAR/HKD{mm=COMP}"
    , "NIO/USD{mm=EUAM}"
    , "CZK/CAD{mm=CMC}"
    , "XAU/HKD{mm=COMP}"
    , "USD/MGA{mm=EUAM}"
    , "MXN/NOK{mm=COMP}"
    , "ZAR/ZMW{mm=EUAM}"
    , "JOD/EUR{mm=EUAM}"
    , "CHF/KRW{mm=EUAM}"
    , "MXN/GBP{mm=EUAM}"
    , "DKK/THB{mm=COMP}"
    , "XPF/USD"
    , "KRW/CHF{mm=MORN}"
    , "VND/XAF{mm=EUAM}"
    , "XPT/USD{mm=MORN}"
    , "CAD/AED{mm=COMP}"
    , "DKK/USD"
    , "ILS/AUD{mm=COMP}"
    , "JPY/HUF{mm=EUAM}"
    , "MXN/CNY{mm=EUAM}"
    , "EUR/GBP{mm=BNPF}"
    , "DKK/MYR{mm=EUAM}"
    , "JPY/EUR{mm=SURF}"
    , "BMD/KYD{mm=COMP}"
    , "EUR/KZT"
    , "AUD/GBP{mm=SURF}"
    , "ZAR/KHR{mm=COMP}"
    , "HUF/PLN"
    , "GBP/SLL{mm=COMP}"
    , "GBP/SAR"
    , "SGD/CHF{mm=COMP}"
    , "MAD/AUD{mm=COMP}"
    , "UYU/EUR{mm=DRES}"
    , "TWD/HKD"
    , "EUR/PLN{mm=MORN}"
    , "CNY/EUR{mm=COMP}"
    , "NOK/CHF{mm=SURF}"
    , "SHP/USD{mm=COMP}"
    , "EUR/NIO"
    , "XAG/CAD"
    , "GBP/MMK"
    , "USD/UAH{mm=EUAM}"
    , "CAD/PEN"
    , "GBP/SVC{mm=COMP}"
    , "XAG/USD:HSFX"
    , "MWK/EUR{mm=EUAM}"
    , "BRL/CHF"
    , "CAD/SGD{mm=EUAM}"
    , "EUR/MAD"
    , "BGN/CNY{mm=COMP}"
    , "GBP/KZT{mm=COMP}"
    , "GBP/GYD"
    , "CNY/NZD"
    , "GBP/CNH"
    , "DKK/KES{mm=EUAM}"
    , "ANG/USD"
    , "RWF/GBP"
    , "ZAR/CLP{mm=COMP}"
    , "NZD/TOP"
    , "ZAR/CLP"
    , "USD/JPY"
    , "TRY/ZAR{mm=EUAM}"
    , "RON/ZAR{mm=COMP}"
    , "USD/SGD{mm=MORN}"
    , "USD/MOP{mm=EUAM}"
    , "AED/DKK{mm=COMP}"
    , "EUR/INR"
    , "KRW/HKD"
    , "KRW/CHF{mm=COMP}"
    , "AUD/PLN"
    , "IDR/HKD"
    , "EUR/QAR{mm=DRES}"
    , "XPT/USD{mm=COMP}"
    , "IDR/NZD{mm=EUAM}"
    , "AUD/PGK{mm=EUAM}"
    , "KWD/AED{mm=COMP}"
    , "USD/ETB{mm=EUAM}"
    , "EUR/GEL"
    , "CHF/IDR{mm=COMP}"
    , "USD/RUB{mm=SURF}"
    , "LRD/GBP{mm=COMP}"
    , "EUR/CZK"
    , "ZAR/CYP{mm=EUAM}"
    , "KMF/GBP{mm=EUAM}"
    , "NZD/NOK{mm=CMC}"
    , "SGD/MXN{mm=EUAM}"
    , "CAD/CHF{mm=CMC}"
    , "XAU/ZAR{mm=EUAM}"
    , "EUR/PLN{mm=COMP}"
    , "CNY/RUB{mm=MORN}"
    , "USD/KMF{mm=RADA}"
    , "INR/USD{mm=COMP}"
    , "KWD/GBP"
    , "USD/BRL{mm=RADA}"
    , "TWD/CAD{mm=EUAM}"
    , "EUR/MVR"
    , "GBP/PEN{mm=EUAM}"
    , "JPY/USD{mm=SAXO}"
    , "KRU/CHF{mm=COMP}"
    , "ZAR/SAR{mm=COMP}"
    , "ISK/DKK{mm=EUAM}"
    , "EUR/GBP"
    , "TRY/CHF{mm=CMC}"
    , "LKR/USD{mm=COMP}"
    , "HUF/JPY{mm=COMP}"
    , "XOF/BWP"
    , "CHF/SEK:HSFX"
    , "XAU/SAR{mm=COMP}"
    , "CAD/RUB"
    , "USD/SGD{mm=COMP}"
    , "JPY/CLP"
    , "AUN/CHF"
    , "NOK/GBP{mm=CMC}"
    , "JOD/USD{mm=EUAM}"
    , "SGD/PKR{mm=EUAM}"
    , "CNY/BGN"
    , "AUD/USD{mm=DRES}"
    , "GBP/JOD{mm=COMP}"
    , "EUR/BSD{mm=DRES}"
    , "GBP/HTG"
    , "PEN/CLP{mm=EUAM}"
    , "EUR/AZN"
    , "CHF/PLN{mm=EUAM}"
    , "JPY/USD{mm=SURF}"
    , "QAR/PKR{mm=EUAM}"
    , "COP/CAD{mm=EUAM}"
    , "XAU/SGD"
    , "CNY/RUB{mm=COMP}"
    , "UYU/USD{mm=DRES}"
    , "RUB/CHF{mm=COMP}"
    , "EUR/RSD{mm=DRES}"
    , "CNY/USD{mm=COMP}"
    , "SCR/GBP{mm=COMP}"
    , "ARS/COP{mm=EUAM}"
    , "MWK/USD{mm=EUAM}"
    , "XAG/JPY{mm=EUAM}"
    , "SGD/AUD{mm=CMC}"
    , "GBP/THB{mm=COMP}"
    , "ZAR/NPR{mm=EUAM}"
    , "IDR/AUD{mm=EUAM}"
    , "PLN/SEK{mm=COMP}"
    , "JPY/SGD{mm=COMP}"
    , "EGP/ZAR{mm=COMP}"
    , "GBP/MYR{mm=EUAM}"
    , "USD/SOS{mm=COMP}"
    , "NOK/SEK{mm=UBSW}"
    , "AUD/PLN{mm=SAXO}"
    , "USD/PAB"
    , "THB/IDR"
    , "USD/XCD"
    , "CHF/SGD{mm=SAXO}"
    , "CAD/JPY{mm=SAXO}"
    , "AUD/CZK{mm=CMC}"
    , "GBP/BHD{mm=COMP}"
    , "ILS/NOK"
    , "PLN/BRL{mm=EUAM}"
    , "GBP/XOF{mm=COMP}"
    , "USD/SCR"
    , "AUD/FJD{mm=EUAM}"
    , "CHF/SGD{mm=CMC}"
    , "USD/ILS:HSFX"
    , "ARS/JPY{mm=COMP}"
    , "CAD/EUR{mm=EUAM}"
    , "HRK/AUD"
    , "RON/GBP{mm=EUAM}"
    , "ANG/SRD"
    , "NZD/AUD{mm=COMP}"
    , "RUB/MXN{mm=EUAM}"
    , "BRL/PEN{mm=SURF}"
    , "USD/GBP{mm=CMC}"
    , "COP/ARS"
    , "GBP/KES{mm=EUAM}"
    , "PLN/KES"
    , "ZAR/MXN"
    , "HKD/JPY:HSFX"
    , "CAD/KRW"
    , "EUR/CZK{mm=DRES}"
    , "USD/EUR{mm=MORN}"
    , "ZAR/NOK{mm=EUAM}"
    , "DKK/ISK{mm=COMP}"
    , "EUR/UYU{mm=DRES}"
    , "CHF/SGD{mm=SURF}"
    , "AUD/CAD{mm=EUAM}"
    , "CAD/JPY{mm=SURF}"
    , "HKD/SGD{mm=MORN}"
    , "TWD/CNY{mm=COMP}"
    , "SEK/INR{mm=COMP}"
    , "VND/JPY"
    , "PLN/CHF"
    , "DKK/HKD{mm=CMC}"
    , "HUF/CAD"
    , "USD/SGD{mm=BNPF}"
    , "AED/MXN"
    , "AED/JPY{mm=EUAM}"
    , "ZAR/NAD{mm=COMP}"
    , "USD/COP{mm=SURF}"
    , "BRL/MXN{mm=COMP}"
    , "LYD/USD"
    , "NGN/ZAR"
    , "EUR/SGD{mm=RADA}"
    , "XPF/EUR{mm=COMP}"
    , "TJS/EUR"
    , "AED/OMR{mm=EUAM}"
    , "ZAR/MUR"
    , "GBP/NIO{mm=COMP}"
    , "USD/DZD{mm=EUAM}"
    , "USD/SEK"
    , "TWD/NZD"
    , "EUR/CHF{mm=UBSW}"
    , "ZAR/ARS{mm=COMP}"
    , "KWD/EUR{mm=EUAM}"
    , "CAD/BRL{mm=COMP}"
    , "EUR/GBP{mm=DRES}"
    , "XAU/ARS{mm=COMP}"
    , "BRL/AUD"
    , "USD/BWP{mm=COMP}"
    , "LRD/GBP"
    , "ALL/EUR{mm=EUAM}"
    , "CAD/HUF{mm=CMC}"
    , "KHR/USD{mm=COMP}"
    , "EUR/HUF{mm=MORN}"
    , "EUR/CNY{mm=DRES}"
    , "JPY/MXN"
    , "USD/EUR{mm=COMP}"
    , "HKD/SGD{mm=COMP}"
    , "TWD/KRW{mm=COMP}"
    , "AUD/GBP{mm=MORN}"
    , "EUR/NPR"
    , "ARS/COP"
    , "AUD/CAD"
    , "RUB/TRY"
    , "EUR/MRU{mm=DRES}"
    , "ZMW/EUR"
    , "USD/TWD{mm=RADA}"
    , "PLN/DKK{mm=COMP}"
    , "CLP/AUD{mm=COMP}"
    , "AUD/CZK{mm=COMP}"
    , "HRK/BRL{mm=EUAM}"
    , "NOK/CHF{mm=MORN}"
    , "HKD/CHF{mm=CMC}"
    , "KRW/NZD"
    , "XPD/EUR{mm=COMP}"
    , "EUR/RON{mm=DRES}"
    , "CAD/BGN{mm=COMP}"
    , "IDR/NZD"
    , "TRY/HUF{mm=CMC}"
    , "SGD/SEK{mm=EUAM}"
    , "USD/DKK"
    , "GBP/ILS{mm=SAXO}"
    , "SGD/HKD"
    , "EUR/MTL"
    , "XAG/HKD{mm=COMP}"
    , "DKK/HKD{mm=EUAM}"
    , "ILS/AED"
    , "XCD/GBP"
    , "EUR/BTN"
    , "CAD/JPY"
    , "BAM/GBP{mm=COMP}"
    , "GBP/LBP{mm=EUAM}"
    , "MAL/CHF{mm=COMP}"
    , "ZAR/MWK"
    , "CAD/RUB{mm=EUAM}"
    , "USD/SGD"
    , "NZD/CHF{mm=SAXO}"
    , "GBP/TRY"
    , "CAD/USD{mm=EUAM}"
    , "BOB/EUR{mm=EUAM}"
    , "THB/EUR"
    , "EUR/HUF{mm=COMP}"
    , "EUR/KRW{mm=DRES}"
    , "USD/AWG{mm=RADA}"
    , "USD/TTD"
    , "UYU/GBP"
    , "USD/RWF{mm=RADA}"
    , "JPY/EUR{mm=COMP}"
    , "HRK/CZK"
    , "HUF/ZAR{mm=COMP}"
    , "PEN/ARS{mm=EUAM}"
    , "AUD/GBP{mm=COMP}"
    , "KRW/SEK{mm=EUAM}"
    , "THB/CAD{mm=COMP}"
    , "YER/USD{mm=EUAM}"
    , "UGX/EUR{mm=COMP}"
    , "AUD/CNY{mm=COMP}"
    , "DKK/PLN{mm=CMC}"
    , "GBP/XAF{mm=COMP}"
    , "NOK/CHF{mm=COMP}"
    , "EUR/BND{mm=COMP}"
    , "PLN/KES{mm=COMP}"
    , "ZAR/UGX{mm=EUAM}"
    , "BRL/SRD{mm=COMP}"
    , "NZD/CHF{mm=SURF}"
    , "LTL/AUD{mm=COMP}"
    , "EUR/PYG{mm=DRES}"
    , "UYU/GBP{mm=COMP}"
    , "XPD/USD{mm=MORN}"
    , "TJS/EUR{mm=COMP}"
    , "ZAR/IDR{mm=EUAM}"
    , "AUD/RON{mm=COMP}"
    , "OSO/USD{mm=COMP}"
    , "EUR/MGA{mm=COMP}"
    , "ZAR/LSL{mm=COMP}"
    , "USD/JPY{mm=RADA}"
    , "XPF/USD{mm=COMP}"
    , "KYD/EUR{mm=COMP}"
    , "CHF/HUF{mm=EUAM}"
    , "AED/HKD{mm=COMP}"
    , "EUR/KYD"
    , "BGN/AUD{mm=EUAM}"
    , "NZD/TOP{mm=COMP}"
    , "GBP/ISK{mm=COMP}"
    , "KWD/USD{mm=EUAM}"
    , "CZK/PLN{mm=SAXO}"
    , "DZD/EUR{mm=EUAM}"
    , "ZAR/CNH{mm=COMP}"
    , "USD/RUB{mm=COMP}"
    , "USD/BTN{mm=COMP}"
    , "XAG/ZAR{mm=EUAM}"
    , "ALL/USD{mm=EUAM}"
    , "SEK/AED{mm=COMP}"
    , "NOK/HUF"
    , "XPD/GBP"
    , "CAD/KWD{mm=EUAM}"
    , "AUD/KRW{mm=COMP}"
    , "NSO/USD{mm=UBSW}"
    , "EUR/MZN{mm=COMP}"
    , "NOK/CAD{mm=CMC}"
    , "XPTK/CHF"
    , "EUR/UAH{mm=COMP}"
    , "CNY/CLP"
    , "USD/MMK{mm=COMP}"
    , "USD/ALL"
    , "GBP/AWG"
    , "SGD/DKK{mm=EUAM}"
    , "PLN/TRY{mm=CMC}"
    , "XPD/USD{mm=COMP}"
    , "XAG/SAR{mm=COMP}"
    , "RUB/SEK{mm=EUAM}"
    , "USD/KGS{mm=EUAM}"
    , "CZK/CAD{mm=EUAM}"
    , "FJD/GBP{mm=EUAM}"
    , "ARS/ZAR{mm=COMP}"
    , "AUD/HUF{mm=SAXO}"
    , "USD/CAD{mm=UBSW}"
    , "HTG/EUR{mm=EUAM}"
    , "SGD/THB{mm=COMP}"
    , "BRL/GBP"
    , "ZAR/TZS{mm=EUAM}"
    , "OMR/EUR{mm=COMP}"
    , "HUF/CZK{mm=EUAM}"
    , "EUR/MOP{mm=COMP}"
    , "HKD/EUR{mm=COMP}"
    , "SGD/MYR{mm=EUAM}"
    , "USD/MWK{mm=RADA}"
    , "TND/USD{mm=EUAM}"
    , "EUR/ETB{mm=COMP}"
    , "NAD/XOF"
    , "JPY/RUB{mm=COMP}"
    , "BOB/USD{mm=EUAM}"
    , "CHF/EUR{mm=CMC}"
    , "JPY/USD{mm=COMP}"
    , "CAD/TWD{mm=COMP}"
    , "TWD/NZD{mm=EUAM}"
    , "CAD/NOK{mm=CMC}"
    , "BRL/SEK{mm=COMP}"
    , "ZAR/ARS"
    , "PLN/AUD"
    , "USD/BIF{mm=RADA}"
    , "EUR/PLN{mm=DRES}"
    , "UGX/USD{mm=COMP}"
    , "CZK/EUR"
    , "CHF/USD{mm=SAXO}"
    , "AED/ZAR{mm=EUAM}"
    , "PLN/JPY{mm=EUAM}"
    , "DKK/SEK{mm=CMC}"
    , "KRW/THB{mm=COMP}"
    , "USD/KWD{mm=COMP}"
    , "SCR/USD"
    , "ILS/CHF{mm=COMP}"
    , "HKD/HUF{mm=CMC}"
    , "KRW/MYR{mm=EUAM}"
    , "TRY/NOK{mm=CMC}"
    , "HUF/GBP{mm=EUAM}"
    , "TJS/USD{mm=COMP}"
    , "CAD/JPY{mm=MORN}"
    , "HKD/CNH"
    , "USD/RSD"
    , "AUN/CHF{mm=UBSW}"
    , "BTN/EUR{mm=EUAM}"
    , "CAD/COP{mm=EUAM}"
    , "USD/SGD{mm=DRES}"
    , "NOK/ZAR"
    , "GBP/TTD{mm=COMP}"
    , "XDR/EUR{mm=COMP}"
    , "ARS/USD"
    , "AED/SAR{mm=COMP}"
    , "KYD/USD{mm=COMP}"
    , "GBP/KHR{mm=EUAM}"
    , "MGA/EUR"
    , "INR/CHF"
    , "USD/CAD{mm=CMC}"
    , "DZD/USD{mm=EUAM}"
    , "PEN/CAD"
    , "UAH/DKK{mm=EUAM}"
    , "GBP/HTG{mm=EUAM}"
    , "ZAR/GHS"
    , "CAD/SEK"
    , "ZAR/GHS{mm=COMP}"
    , "GYD/EUR{mm=COMP}"
    , "JPY/ARS"
    , "MYR/INR{mm=EUAM}"
    , "EUR/HKD"
    , "CRC/EUR"
    , "CNH/JPY{mm=CMC}"
    , "BRL/PEN{mm=COMP}"
    , "DKK/JPY{mm=CMC}"
    , "ZAR/CZK{mm=CMC}"
    , "GBP/CLP{mm=EUAM}"
    , "SGD/NZD"
    , "AUD/PLN{mm=COMP}"
    , "LPD/USD:HSFX"
    , "MXN/USD{mm=SAXO}"
    , "MYR/CNH"
    , "CZK/GBP{mm=COMP}"
    , "CHF/SGD{mm=COMP}"
    , "CAD/JPY{mm=COMP}"
    , "HTG/USD{mm=EUAM}"
    , "CAD/NOK{mm=SAXO}"
    , "CZK/HRK"
    , "CAD/DKK"
    , "OMR/USD{mm=COMP}"
    , "PKR/GBP{mm=COMP}"
    , "TWD/AUD{mm=EUAM}"
    , "HKD/USD{mm=COMP}"
    , "INR/PKR"
    , "INR/CAD{mm=EUAM}"
    , "USD/COP{mm=COMP}"
    , "ILS/JOD"
    , "CNY/MXN"
    , "SEK/JPY{mm=SAXO}"
    , "AUD/DKK{mm=CMC}"
    , "PYG/EUR{mm=EUAM}"
    , "XAG/ARS{mm=COMP}"
    , "ARS/GBP{mm=EUAM}"
    , "CAD/SGD"
    , "EUR/NAD"
    , "HNL/EUR{mm=EUAM}"
    , "USD/SZL"
    , "BRL/DKK{mm=COMP}"
    , "ZAR/INR{mm=MORN}"
    , "AUD/NZD{mm=EUAM}"
    , "EUR/GEL{mm=COMP}"
    , "NIO/GBP{mm=EUAM}"
    , "NOK/CNY"
    , "SGD/USD{mm=CMC}"
    , "SEK/EUR{mm=EUAM}"
    , "EUR/CNH:HSFX"
    , "USD/CZK{mm=SAXO}"
    , "GBP/SAR{mm=EUAM}"
    , "CAD/NOK{mm=SURF}"
    , "CZK/HKD{mm=CMC}"
    , "UZS/USD"
    , "PLN/CZK"
    , "EGP/PKR"
    , "GBP/ILS{mm=MORN}"
    , "USD/ZAR{mm=RADA}"
    , "GBP/TRY{mm=SAXO}"
    , "XPDK/CHF{mm=UBSW}"
    , "MWK/ZAR{mm=COMP}"
    , "GBP/MUR{mm=COMP}"
    , "SEK/JPY{mm=SURF}"
    , "PLN/HKD{mm=COMP}"
    , "MXN/SGD{mm=COMP}"
    , "AED/NOK{mm=EUAM}"
    , "GBP/IQD{mm=EUAM}"
    , "BTN/USD{mm=EUAM}"
    , "EUR/EGP"
    , "MZN/USD"
    , "XDR/USD{mm=COMP}"
    , "PGK/AUD"
    , "NZD/CHF{mm=MORN}"
    , "USD/CZK{mm=SURF}"
    , "CNY/CAD{mm=EUAM}"
    , "GBP/TOP"
    , "OMR/PKR"
    , "PLN/GBP"
    , "EUR/DZD{mm=COMP}"
    , "USD/GBP{mm=SAXO}"
    , "ZAR/INR{mm=COMP}"
    , "COP/ZAR"
    , "USD/MKD{mm=EUAM}"
    , "USD/TMT{mm=COMP}"
    , "IDR/CHF{mm=EUAM}"
    , "USD/UZS{mm=EUAM}"
    , "BRL/CLP{mm=SURF}"
    , "XAU/INR{mm=COMP}"
    , "GYD/USD{mm=COMP}"
    , "FJD/EUR"
    , "EUR/BBD"
    , "CHF/EUR{mm=MORN}"
    , "GHS/ZAR"
    , "SEK/BRL{mm=COMP}"
    , "GBP/ILS{mm=COMP}"
    , "XPT/GBP{mm=COMP}"
    , "TND/USD"
    , "HKD/NOK{mm=CMC}"
    , "USD/RON{mm=SAXO}"
    , "CAD/JPY{mm=BNPF}"
    , "EUR/MXN:HSFX"
    , "VRL/CHF"
    , "USD/GBP{mm=SURF}"
    , "MUR/ZAR"
    , "RON/CHF"
    , "NZD/CHF{mm=COMP}"
    , "AWG/USD{mm=EUAM}"
    , "EUR/UAH"
    , "USD/CNY{mm=SURF}"
    , "XAU/USD{mm=UBSW}"
    , "SZL/ZAR{mm=EUAM}"
    , "GBP/PAB{mm=EUAM}"
    , "INR/GBP{mm=COMP}"
    , "EUR/FJD"
    , "SVC/EUR{mm=EUAM}"
    , "HUF/PLN{mm=EUAM}"
    , "UGX/ZAR"
    , "MVR/USD{mm=EUAM}"
    , "INR/CNY{mm=COMP}"
    , "GBP/YER{mm=EUAM}"
    , "SOS/USD"
    , "BRI/CHF{mm=UBSW}"
    , "EUR/SDG"
    , "PYG/USD{mm=EUAM}"
    , "CHF/BWP{mm=COMP}"
    , "THB/NZD{mm=COMP}"
    , "HNL/USD{mm=EUAM}"
    , "USD/RON{mm=SURF}"
    , "CZK/PLN{mm=CMC}"
    , "LKR/GBP{mm=COMP}"
    , "CHF/EUR{mm=COMP}"
    , "USD/CVE{mm=COMP}"
    , "USD/AMD{mm=COMP}"
    , "EUR/HUF{mm=DRES}"
    , "SEK/USD{mm=EUAM}"
    , "USD/CNH:HSFX"
    , "IQD/USD{mm=EUAM}"
    , "CAD/ZAR{mm=MORN}"
    , "AUD/CHF{mm=UBSW}"
    , "USD/SBD{mm=COMP}"
    , "USD/PGK{mm=EUAM}"
    , "NOK/PLN"
    , "BDT/EUR"
    , "AUD/HUF{mm=MORN}"
    , "INR/TWD"
    , "KES/USD"
    , "JPY/GBP{mm=SURF}"
    , "EUR/BND{mm=DRES}"
    , "USD/IRR{mm=RADA}"
    , "INR/AUD"
    , "NZD/THB{mm=SAXO}"
    , "SGD/HKD{mm=EUAM}"
    , "USD/KRW{mm=SURF}"
    , "M5P/CHF{mm=COMP}"
    , "CZK/PLN{mm=COMP}"
    , "USD/NOK"
    , "USD/NOK{mm=RADA}"
    , "CNY/GBP{mm=COMP}"
    , "GBP/ARS{mm=EUAM}"
    , "MYR/PKR{mm=COMP}"
    , "PLN/ZAR{mm=CMC}"
    , "INR/KRW{mm=COMP}"
    , "ZAR/UYU"
    , "EUR/MGA{mm=DRES}"
    , "MWK/GBP{mm=EUAM}"
    , "ILS/SEK{mm=EUAM}"
    , "EUR/NZD"
    , "CLP/CHF{mm=COMP}"
    , "BRL/JPY{mm=EUAM}"
    , "HKD/TRY"
    , "XAG/RUB"
    , "SEK/GBP{mm=CMC}"
    , "CHF/USD{mm=MORN}"
    , "CAD/ZAR{mm=COMP}"
    , "MXN/EUR{mm=COMP}"
    , "TTD/GBP"
    , "NZD/PKR{mm=EUAM}"
    , "KRW/HKD{mm=EUAM}"
    , "EUR/KHR"
    , "CHF/NZD{mm=CMC}"
    , "AUD/HUF{mm=COMP}"
    , "MMK/USD{mm=COMP}"
    , "PGK/GBP"
    , "USD/QAR{mm=COMP}"
    , "DKK/TRY{mm=COMP}"
    , "GBP/GTQ{mm=COMP}"
    , "USD/MXN:HSFX"
    , "ZAR/HUF"
    , "SAR/PKR"
    , "EUR/UAH{mm=DRES}"
    , "ZAR/TND{mm=COMP}"
    , "USD/SCR{mm=EUAM}"
    , "ZAR/AED{mm=COMP}"
    , "THB/AUD{mm=COMP}"
    , "USD/LYD{mm=RADA}"
    , "SAR/EUR{mm=COMP}"
    , "SVC/USD{mm=EUAM}"
    , "TRY/CHF"
    , "ZAR/TZS"
    , "CNY/KRW{mm=COMP}"
    , "TRY/SGD{mm=EUAM}"
    , "ZAR/UGX"
    , "AED/HUF"
    , "EUR/MOP{mm=DRES}"
    , "CHF/RUB{mm=COMP}"
    , "USD/XAF"
    , "CLP/MXN{mm=EUAM}"
    , "IQD/GBP"
    , "KES/USD{mm=EUAM}"
    , "BRI/CHF"
    , "GBP/AFN{mm=EUAM}"
    , "CHF/USD{mm=COMP}"
    , "XCD/SRD{mm=EUAM}"
    , "CZK/SEK{mm=CMC}"
    , "USD/GTQ"
    , "EUR/ETB{mm=DRES}"
    , "EUR/AZN{mm=EUAM}"
    , "EUR/BGN"
    , "XAUK/CHF"
    , "ZAR/SGD{mm=EUAM}"
    , "USD/PLN{mm=SAXO}"
    , "CAD/CNH{mm=RADA}"
    , "XAU/SGD{mm=EUAM}"
    , "ILS/JOD{mm=COMP}"
    , "NZD/SGD{mm=CMC}"
    , "AUD/CNH{mm=CMC}"
    , "USD/MYR"
    , "CHF/MXN{mm=CMC}"
    , "JPY/HUF"
    , "USD/BSD{mm=COMP}"
    , "BRL/ARS{mm=SURF}"
    , "BRL/HKD"
    , "EUR/ZAR:HSFX"
    , "GBP/BDT"
    , "GBP/LSL{mm=EUAM}"
    , "USD/KWD{mm=DRES}"
    , "AUD/TOP{mm=EUAM}"
    , "NZD/XDR{mm=EUAM}"
    , "LSL/EUR{mm=EUAM}"
    , "DKK/TRY"
    , "XAG/KRW"
    , "KHR/GBP{mm=COMP}"
    , "TJS/USD"
    , "USD/PLN{mm=SURF}"
    , "CNH/CHF"
    , "CAD/CZK{mm=EUAM}"
    , "USD/ISK"
    , "MXN/CHF"
    , "USD/RSD{mm=COMP}"
    , "CZK/JPY{mm=CMC}"
    , "USD/CZK{mm=MORN}"
    , "TTD/SRD{mm=EUAM}"
    , "USD/IDR{mm=RADA}"
    , "SEK/RWF{mm=COMP}"
    , "KMF/GBP"
    , "TND/ZAR{mm=COMP}"
    , "MXN/USD{mm=COMP}"
    , "USD/DJF{mm=EUAM}"
    , "PEN/MXN{mm=COMP}"
    , "USD/PYG"
    , "ZAR/ZMW"
    , "GBP/TRY{mm=MORN}"
    , "BRL/HKD{mm=COMP}"
    , "INR/GBP"
    , "EUR/CAD{mm=SAXO}"
    , "ZMW/USD"
    , "CAD/NOK{mm=COMP}"
    , "USD/MDL{mm=EUAM}"
    , "USD/VES{mm=COMP}"
    , "XDR/EUR"
    , "ZAR/DKK{mm=CMC}"
    , "AED/ZAR"
    , "EUR/LYD"
    , "LPT/USD:HSFX"
    , "INR/NZD{mm=EUAM}"
    , "ILS/EUR"
    , "SAR/USD{mm=COMP}"
    , "CAD/CNY{mm=EUAM}"
    , "SEK/JPY{mm=COMP}"
    , "JPY/CAD{mm=EUAM}"
    , "EUR/CAD{mm=SURF}"
    , "JPY/RSD{mm=COMP}"
    , "USD/GBP{mm=MORN}"
    , "YER/GBP{mm=EUAM}"
    , "NZD/CHF"
    , "NOK/CAD"
    , "SEK/NOK{mm=SAXO}"
    , "MYR/BRL{mm=EUAM}"
    , "USD/CNY{mm=MORN}"
    , "USD/CZK{mm=COMP}"
    , "GBP/JMD"
    , "PHP/ZAR"
    , "USD/UYU{mm=COMP}"
    , "THB/USD"
    , "GBP/MXN{mm=SAXO}"
    , "USD/YER"
    , "JPY/ZAR"
    , "CAD/RON{mm=EUAM}"
    , "XAG/INR{mm=COMP}"
    , "DKK/INR{mm=EUAM}"
    , "GBP/TRY{mm=COMP}"
    , "TRY/JPY{mm=SAXO}"
    , "EUR/XPF{mm=COMP}"
    , "BRL/CLP{mm=COMP}"
    , "OMR/GBP"
    , "XAG/JPY"
    , "XPF/GBP{mm=COMP}"
    , "KMF/USD{mm=COMP}"
    , "GBP/BBD{mm=EUAM}"
    , "RON/EUR{mm=COMP}"
    , "USD/ZAR:HSFX"
    , "SEK/NOK{mm=SURF}"
    , "ZAR/CNY"
    , "KYD/CAD{mm=EUAM}"
    , "RWF/ZAR{mm=EUAM}"
    , "LBP/USD{mm=EUAM}"
    , "KWD/GBP{mm=EUAM}"
    , "ZAR/JPY{mm=SAXO}"
    , "CAD/KRW{mm=EUAM}"
    , "GBP/CUP{mm=EUAM}"
    , "LSL/USD{mm=EUAM}"
    , "CNY/NZD{mm=EUAM}"
    , "SEK/KES"
    , "CZK/HUF{mm=COMP}"
    , "ALL/GBP{mm=EUAM}"
    , "XAU/JPY{mm=SAXO}"
    , "BRL/ZAR{mm=EUAM}"
    , "SLL/GBP"
    , "XAG/USD{mm=UBSW}"
    , "ZAR/BWP{mm=EUAM}"
    , "NBL/USD"
    , "USD/GBP{mm=COMP}"
    , "EUR/LSL"
    , "VND/XAF"
    , "ZAR/EUR{mm=EUAM}"
    , "EUR/BHD"
    , "CAD/NOK"
    , "USD/GMD{mm=RADA}"
    , "USD/CNY{mm=COMP}"
    , "NZD/PKR"
    , "GBP/GHS{mm=EUAM}"
    , "USD/KRW{mm=MORN}"
    , "XAU/EUR{mm=EUAM}"
    , "JPY/CZK{mm=COMP}"
    , "AED/CNY"
    , "SAR/KWD{mm=COMP}"
    , "SEK/CHF"
    , "USD/CRC{mm=EUAM}"
    , "SGD/ARS{mm=EUAM}"
    , "NOK/HKD{mm=CMC}"
    , "XPD/GBP{mm=COMP}"
    , "AUD/CHF{mm=CMC}"
    , "USD/MRU{mm=COMP}"
    , "EUR/RSD{mm=RADA}"
    , "XAGK/CHF{mm=UBSW}"
    , "GBP/HNL{mm=COMP}"
    , "EUR/DZD{mm=DRES}"
    , "CHF/CZK{mm=SAXO}"
    , "CHF/RON"
    , "BND/EUR"
    , "EUR/MKD{mm=COMP}"
    , "EUR/UZS{mm=COMP}"
    , "USD/RON{mm=COMP}"
    , "AED/INR{mm=COMP}"
    , "INR/AUD{mm=EUAM}"
    , "BBD/GBP"
    , "TWD/CHF{mm=EUAM}"
    , "DKK/SEK{mm=SURF}"
    , "AUD/HRK{mm=EUAM}"
    , "JPY/CNY"
    , "TRY/BRL{mm=COMP}"
    , "TRY/AUD"
    , "HKD/CAD{mm=EUAM}"
    , "MYR/DKK{mm=COMP}"
    , "TND/GBP{mm=EUAM}"
    , "GHS/XOF"
    , "BYN/USD{mm=EUAM}"
    , "BOB/GBP{mm=EUAM}"
    , "PLN/HKD"
    , "USD/VND"
    , "JPY/GBP{mm=COMP}"
    , "IDR/THB{mm=EUAM}"
    , "EUR/VES{mm=RADA}"
    , "CAD/IDR{mm=COMP}"
    , "USD/LAK{mm=EUAM}"
    , "USD/KRW{mm=COMP}"
    , "ZAR/BRL{mm=COMP}"
    , "JPY/CNY{mm=COMP}"
    , "XAUK/CHF{mm=COMP}"
    , "SEK/CAD{mm=CMC}"
    , "MXN/COP{mm=COMP}"
    , "SGD/MXN"
    , "XAU/BRL{mm=COMP}"
    , "RON/GBP"
    , "XOF/NAD{mm=EUAM}"
    , "NZD/DKK{mm=EUAM}"
    , "GBP/MGA"
    , "JPY/RON{mm=COMP}"
    , "EUR/CZK{mm=RADA}"
    , "USD/HUF{mm=SAXO}"
    , "USD/PYG{mm=COMP}"
    , "BRL/NZD"
    , "COP/CAD"
    , "CZK/USD"
    , "DKK/CZK{mm=CMC}"
    , "DJF/EUR"
    , "NZD/THB{mm=COMP}"
    , "ZAR/BGN{mm=COMP}"
    , "CNY/AUD{mm=EUAM}"
    , "TRY/RUB{mm=EUAM}"
    , "GBP/BZD{mm=COMP}"
    , "HUF/SGD{mm=COMP}"
    , "CNH/TWD"
    , "KYD/GBP{mm=COMP}"
    , "EGP/EUR{mm=COMP}"
    , "XDR/CAD{mm=EUAM}"
    , "TRY/USD{mm=EUAM}"
    , "RON/USD{mm=COMP}"
    , "NZD/MYR{mm=EUAM}"
    , "CNH/AUD"
    , "NZD/EUR{mm=CMC}"
    , "EUR/PGK{mm=COMP}"
    , "MXN/AUD"
    , "CLP/PEN{mm=EUAM}"
    , "USD/HKD{mm=CMC}"
    , "CAD/MYR"
    , "CUP/EUR"
    , "HKD/CNY{mm=MORN}"
    , "HKD/CZK{mm=COMP}"
    , "ZAR/RUB{mm=EUAM}"
    , "ZAR/BTN{mm=EUAM}"
    , "ZAR/PLN"
    , "JPY/KRW{mm=COMP}"
    , "USD/HUF{mm=SURF}"
    , "CAD/AED"
    , "USD/JOD"
    , "AUD/KRW"
    , "XAU/RUB{mm=EUAM}"
    , "DKK/AED{mm=EUAM}"
    , "ZAR/USD{mm=EUAM}"
    , "NOK/PLN{mm=CMC}"
    , "MGA/USD"
    , "GBP/INR{mm=EUAM}"
    , "XAU/BRL"
    , "XAU/USD{mm=EUAM}"
    , "EUR/CLP"
    , "BGN/EUR"
    , "MVR/USD"
    , "EUR/XPF"
    , "BRL/NOK{mm=EUAM}"
    , "EUR/CDF{mm=COMP}"
    , "CLP/MXN"
    , "USD/PLN{mm=MORN}"
    , "PEN/BRL{mm=EUAM}"
    , "ZAR/MMK{mm=EUAM}"
    , "MYR/TWD{mm=EUAM}"
    , "DKK/MXN{mm=COMP}"
    , "AED/PLN"
    , "CRC/USD"
    , "GBP/NGN{mm=COMP}"
    , "HTG/GBP{mm=EUAM}"
    , "BMD/BBD{mm=COMP}"
    , "USD/BOB{mm=EUAM}"
    , "OMR/GBP{mm=COMP}"
    , "AUD/CHF{mm=EUAM}"
    , "HKD/GBP{mm=COMP}"
    , "SEK/ZAR{mm=COMP}"
    , "EUR/RON{mm=RADA}"
    , "NZD/TWD"
    , "GBP/SEK{mm=SAXO}"
    , "HKD/CNY{mm=COMP}"
    , "HKD/KRW{mm=MORN}"
    , "NZD/AUD"
    , "TRY/CZK"
    , "HUF/JPY"
    , "EUR/GBP{mm=CMC}"
    , "BRL/ARS{mm=COMP}"
    , "JPY/PLN"
    , "DKK/PKR{mm=COMP}"
    , "USD/KRW{mm=BNPF}"
    , "ARS/SGD{mm=COMP}"
    , "TRY/ZAR{mm=SAXO}"
    , "USD/PLN{mm=COMP}"
    , "IDR/CAD{mm=COMP}"
    , "EUR/KRW{mm=RADA}"
    , "ZAR/KWD{mm=EUAM}"
    , "EUR/CAD{mm=MORN}"
    , "FJD/EUR{mm=COMP}"
    , "GBP/SEK{mm=SURF}"
    , "AUD/MAD{mm=EUAM}"
    , "AUD/HUF{mm=CMC}"
    , "TRY/GBP"
    , "CAD/SGD{mm=SURF}"
    , "BTN/GBP{mm=EUAM}"
    , "USD/OMR"
    , "CVE/EUR{mm=EUAM}"
    , "XDR/GBP{mm=COMP}"
    , "USD/PLN{mm=CMC}"
    , "CHF/PKR"
    , "BTN/EUR"
    , "AUD/JPY"
    , "EGP/USD{mm=COMP}"
    , "GBP/SOS"
    , "USD/CLY"
    , "CNH/INR"
    , "USD/MDL"
    , "SGD/TRY{mm=COMP}"
    , "CNH/THB{mm=RADA}"
    , "MYR/JPY{mm=EUAM}"
    , "HKD/KRW{mm=COMP}"
    , "XDR/CNY{mm=COMP}"
    , "MXN/GBP{mm=CMC}"
    , "SEK/CNH{mm=RADA}"
    , "CAD/SGD{mm=CMC}"
    , "BHD/EUR{mm=EUAM}"
    , "AED/SGD{mm=EUAM}"
    , "EUR/FJD{mm=COMP}"
    , "VES/EUR"
    , "USD/NZD{mm=EUAM}"
    , "MXN/CZK"
    , "SEK/AUD"
    , "GYD/GBP{mm=COMP}"
    , "CZK/HRK{mm=EUAM}"
    , "NOK/SEK{mm=CMC}"
    , "NOK/ILS{mm=COMP}"
    , "CNY/ZAR"
    , "JPY/PLN{mm=COMP}"
    , "TRY/SGD{mm=CMC}"
    , "ZAR/TWD{mm=COMP}"
    , "EUR/CAD{mm=COMP}"
    , "USD/RSD{mm=DRES}"
    , "HUF/EUR{mm=COMP}"
    , "SEK/CZK{mm=EUAM}"
    , "EUR/NZD{mm=SAXO}"
    , "FJD/USD"
    , "CHF/PLN{mm=SAXO}"
    , "DKK/XDR{mm=COMP}"
    , "CNH/GBP"
    , "KES/ZAR{mm=COMP}"
    , "MXN/GBP"
    , "IDR/ISK{mm=EUAM}"
    , "XAU/THB"
    , "AWG/GBP{mm=EUAM}"
    , "CNH/PHP{mm=COMP}"
    , "EUR/DJF{mm=COMP}"
    , "THB/CHF{mm=COMP}"
    , "SHP/USD{mm=RADA}"
    , "BRL/IDR{mm=EUAM}"
    , "ZAR/JPY{mm=MORN}"
    , "GBP/TND{mm=EUAM}"
    , "PLN/NZD"
    , "GBP/DKK{mm=SAXO}"
    , "ZAR/XOF"
    , "GBP/AED{mm=EUAM}"
    , "GBP/PGK"
    , "ZAR/COP{mm=EUAM}"
    , "EUR/MXN"
    , "NZD/INR"
    , "EUR/MDL{mm=COMP}"
    , "JPY/NZD{mm=EUAM}"
    , "EUR/NZD{mm=SURF}"
    , "HNL/GBP{mm=EUAM}"
    , "ZAR/RWF{mm=COMP}"
    , "XAG/JPY{mm=SAXO}"
    , "SEK/NOK{mm=COMP}"
    , "XAU/EUR"
    , "DKK/PHP{mm=COMP}"
    , "GEL/EUR"
    , "NOK/JPY{mm=CMC}"
    , "USD/BRL"
    , "CHF/CAD{mm=EUAM}"
    , "NZD/CZK"
    , "CHF/RSD{mm=COMP}"
    , "GBP/MXN{mm=COMP}"
    , "SEK/GBP{mm=EUAM}"
    , "IQD/GBP{mm=EUAM}"
    , "CAD/VND"
    , "SGD/ARS"
    , "XAG/EUR{mm=EUAM}"
    , "SEK/CNY{mm=EUAM}"
    , "CHF/CZK{mm=MORN}"
    , "TRY/JPY{mm=COMP}"
    , "MMK/USD"
    , "ANG/SRD{mm=COMP}"
    , "GBP/DKK{mm=SURF}"
    , "INR/HKD"
    , "FJD/USD{mm=COMP}"
    , "GBP/KYD{mm=COMP}"
    , "USD/CZK{mm=DRES}"
    , "LRD/XOF{mm=EUAM}"
    , "GBP/ANG{mm=EUAM}"
    , "EUR/MUR"
    , "BWP/ZAR{mm=EUAM}"
    , "USD/IDR"
    , "CHF/TRY{mm=CMC}"
    , "ZAR/CAD"
    , "USD/CDF"
    , "CVE/USD{mm=EUAM}"
    , "ZAR/JPY{mm=COMP}"
    , "NZD/GBP"
    , "USD/SEK{mm=CMC}"
    , "OSO/CHF{mm=UBSW}"
    , "USD/AUD{mm=EUAM}"
    , "EUR/XPF{mm=DRES}"
    , "GBP/NGN"
    , "GBP/PKR{mm=COMP}"
    , "XAU/JPY{mm=COMP}"
    , "USD/STN{mm=EUAM}"
    , "BDT/USD"
    , "XOF/GHS{mm=EUAM}"
    , "EUR/CZK:HSFX"
    , "ZAR/OMR{mm=COMP}"
    , "ARS/EUR{mm=COMP}"
    , "BHD/USD{mm=EUAM}"
    , "EUR/BND"
    , "AUD/CAD{mm=SAXO}"
    , "ZAR/CHF{mm=CMC}"
    , "DKK/SEK{mm=COMP}"
    , "HKD/PLN{mm=COMP}"
    , "SEK/INR"
    , "USD/SGD{mm=RADA}"
    , "XPT/GBP"
    , "MYR/HKD{mm=COMP}"
    , "AED/CAD"
    , "CHF/GBP{mm=MORN}"
    , "EUR/AUD{mm=SAXO}"
    , "CAD/HUF{mm=EUAM}"
    , "MDL/EUR"
    , "USD/CHF{mm=UBSW}"
    , "SEK/CZK"
    , "CAD/EUR{mm=SURF}"
    , "JMD/EUR{mm=EUAM}"
    , "MXN/CAD{mm=EUAM}"
    , "THB/PKR{mm=EUAM}"
    , "CHF/CZK{mm=COMP}"
    , "HUF/SEK"
    , "XAG/BRL{mm=COMP}"
    , "UZS/EUR{mm=EUAM}"
    , "EUR/MVR{mm=COMP}"
    , "DKK/BRL{mm=EUAM}"
    , "QAR/ZAR"
    , "HUF/USD{mm=COMP}"
    , "CHF/TWD"
    , "GBP/RON"
    , "MMK/GBP{mm=COMP}"
    , "CLP/ARS"
    , "EUR/CAD{mm=BNPF}"
    , "USD/HUF{mm=MORN}"
    , "USD/CNY{mm=DRES}"
    , "CHF/AUD"
    , "SGD/INR{mm=EUAM}"
    , "AUD/CAD{mm=SURF}"
    , "GBP/CAD{mm=CMC}"
    , "EUR/CRC{mm=COMP}"
    , "AUD/NOK{mm=CMC}"
    , "EUR/MWK"
    , "AED/EUR{mm=EUAM}"
    , "NZD/HKD{mm=EUAM}"
    , "SOS/USD{mm=EUAM}"
    , "JPY/CAD"
    , "USD/JPY{mm=CMC}"
    , "JPY/AUD{mm=EUAM}"
    , "EUR/AUD{mm=SURF}"
    , "HKD/NZD{mm=EUAM}"
    , "GBP/ALL{mm=EUAM}"
    , "SVC/GBP{mm=EUAM}"
    , "EUR/MKD{mm=DRES}"
    , "EUR/UZS{mm=DRES}"
    , "SEK/GBP"
    , "USD/RON{mm=DRES}"
    , "BGN/CAD{mm=COMP}"
    , "DKK/BGN{mm=EUAM}"
    , "ZAR/MWK{mm=COMP}"
    , "GBP/BAM{mm=EUAM}"
    , "CNH/HKD{mm=SAXO}"
    , "GBP/SRD{mm=COMP}"
    , "EUR/IQD"
    , "LKR/EUR"
    , "HUF/DKK"
    , "AUD/SEK"
    , "CHF/GBP{mm=COMP}"
    , "USD/LAK"
    , "PLN/AED{mm=COMP}"
    , "USD/SOS{mm=RADA}"
    , "JPY/CHF{mm=UBSW}"
    , "KRW/INR{mm=EUAM}"
    , "XAG/RUB{mm=EUAM}"
    , "CHF/CNY{mm=COMP}"
    , "GBP/XDR{mm=COMP}"
    , "PGK/AUD{mm=COMP}"
    , "HKD/SGD{mm=CMC}"
    , "SEK/UGX{mm=COMP}"
    , "HUF/SGD"
    , "EUR/LAK{mm=COMP}"
    , "USD/HUF{mm=COMP}"
    , "USD/KRW{mm=DRES}"
    , "MXN/CZK{mm=COMP}"
    , "USD/GYD{mm=EUAM}"
    , "TWD/THB{mm=EUAM}"
    , "USD/THB"
    , "MYR/ZAR{mm=EUAM}"
    , "CHF/RON{mm=COMP}"
    , "ZAR/BWP"
    , "BRL/CLP"
    , "CAD/USD{mm=SAXO}"
    , "XDR/NZD{mm=EUAM}"
    , "USD/BND{mm=COMP}"
    , "USD/LKR{mm=EUAM}"
    , "AUD/DKK"
    , "TWD/ZAR"
    , "GBP/SEK{mm=MORN}"
    , "GBP/PHP{mm=COMP}"
    , "EUR/CAD{mm=CMC}"
    , "USD/CZK:HSFX"
    , "ARS/USD{mm=COMP}"
    , "CAD/SGD{mm=MORN}"
    , "CNH/MYR{mm=COMP}"
    , "USD/GNF{mm=EUAM}"
    , "USD/RWF"
    , "NIO/USD{mm=COMP}"
    , "USD/EUR"
    , "RUB/CHF"
    , "CAD/EUR{mm=CMC}"
    , "USD/MGA{mm=COMP}"
    , "AUD/SGD"
    , "EUR/PGK{mm=DRES}"
    , "ZAR/ZMW{mm=COMP}"
    , "JOD/EUR{mm=COMP}"
    , "CHF/KRW{mm=COMP}"
    , "MXN/GBP{mm=COMP}"
    , "CAD/USD{mm=SURF}"
    , "JMD/USD{mm=EUAM}"
    , "XPDK/CHF"
    , "VND/XAF{mm=COMP}"
    , "USD/DOP{mm=EUAM}"
    , "GBP/KES"
    , "UZS/USD{mm=EUAM}"
    , "JPY/HUF{mm=COMP}"
    , "NZD/MXN{mm=CMC}"
    , "MXN/CNY{mm=COMP}"
    , "KRW/ZAR"
    , "DKK/MYR{mm=COMP}"
    , "IDR/ZAR"
    , "PHP/DKK{mm=EUAM}"
    , "INR/CHF{mm=EUAM}"
    , "HKD/AUD{mm=EUAM}"
    , "TRY/EUR{mm=CMC}"
    , "CHF/INR"
    , "CHF/HUF{mm=SAXO}"
    , "ZAR/QAR{mm=EUAM}"
    , "CVE/GBP"
    , "AED/USD{mm=EUAM}"
    , "EUR/CDF{mm=DRES}"
    , "GBP/CHF"
    , "USD/TOP{mm=EUAM}"
    , "CHF/CZK"
    , "MXN/CAD{mm=CMC}"
    , "GBP/SEK{mm=COMP}"
    , "USD/UAH{mm=COMP}"
    , "USD/EUR{mm=RADA}"
    , "NZD/TRY{mm=SAXO}"
    , "MWK/EUR{mm=COMP}"
    , "NBL/CHF{mm=COMP}"
    , "EUR/AFN"
    , "THB/CNH"
    , "CAD/SGD{mm=COMP}"
    , "EUR/BOB{mm=COMP}"
    , "XDR/USD"
    , "GBP/KMF{mm=EUAM}"
    , "DKK/KES{mm=COMP}"
    , "EUR/ARS"
    , "SVC/EUR"
    , "GBP/BRL{mm=EUAM}"
    , "ZAR/HUF{mm=CMC}"
    , "TRY/ZAR{mm=COMP}"
    , "ILS/USD"
    , "USD/MOP{mm=COMP}"
    , "NOK/TRY{mm=COMP}"
    , "SGD/AED{mm=EUAM}"
    , "TWD/CNY"
    , "CHF/GBP"
    , "INR/NZD"
    , "IDR/NZD{mm=COMP}"
    , "MOP/EUR{mm=EUAM}"
    , "AUD/PGK{mm=COMP}"
    , "USD/ETB{mm=COMP}"
    , "EUR/NZD{mm=MORN}"
    , "EUR/SCR{mm=DRES}"
    , "CAD/BRL"
    , "ZAR/CYP{mm=COMP}"
    , "CHF/PLN{mm=MORN}"
    , "CNY/CHF{mm=EUAM}"
    , "XDR/AUD{mm=EUAM}"
    , "KMF/GBP{mm=COMP}"
    , "USD/HRK"
    , "QAR/AED{mm=EUAM}"
    , "AUD/THB{mm=EUAM}"
    , "DKK/TWD{mm=EUAM}"
    , "SGD/MXN{mm=COMP}"
    , "XAU/ZAR{mm=COMP}"
    , "USD/BDT{mm=EUAM}"
    , "USD/PLN{mm=DRES}"
    , "GBP/BGN{mm=EUAM}"
    , "GBP/PKR"
    , "USD/QAR"
    , "LSL/GBP{mm=EUAM}"
    , "GBP/DKK{mm=MORN}"
    , "TWD/CAD{mm=COMP}"
    , "KRW/CNY"
    , "EUR/GHS"
    , "IDR/CNY"
    , "GBP/PEN{mm=COMP}"
    , "ARE/USD"
    , "CAD/IDR"
    , "AED/KWD{mm=EUAM}"
    , "ISK/DKK{mm=COMP}"
    , "SZL/EUR{mm=EUAM}"
    , "HKD/HUF{mm=COMP}"
    , "PLN/JPY{mm=SAXO}"
    , "GBP/MTL{mm=EUAM}"
    , "JOD/USD{mm=COMP}"
    , "SGD/PKR{mm=COMP}"
    , "PLN/AUD{mm=CMC}"
    , "EUR/FJD{mm=DRES}"
    , "DKK/RWF{mm=EUAM}"
    , "BRL/MXN"
    , "PEN/CLP{mm=COMP}"
    , "EUR/NZD{mm=COMP}"
    , "VND/HKD{mm=EUAM}"
    , "PLN/EUR{mm=EUAM}"
    , "CHF/PLN{mm=COMP}"
    , "QAR/PKR{mm=COMP}"
    , "TRY/HKD"
    , "BMD/CAD"
    , "COP/CAD{mm=COMP}"
    , "NGN/JPY"
    , "CAD/EUR{mm=MORN}"
    , "EUR/CAD{mm=DRES}"
    , "GBP/HKD{mm=SURF}"
    , "USD/BMD{mm=EUAM}"
    , "USD/RUB{mm=RADA}"
    , "BND/USD"
    , "GBP/DKK{mm=COMP}"
    , "ARS/COP{mm=COMP}"
    , "BYN/GBP{mm=EUAM}"
    , "SGD/HUF"
    , "BAM/GBP"
    , "USD/XDR"
    , "CNY/XOF"
    , "MWK/USD{mm=COMP}"
    , "AUD/CAD{mm=MORN}"
    , "XAG/JPY{mm=COMP}"
    , "VND/EUR"
    , "EUR/DJF{mm=DRES}"
    , "LAK/EUR{mm=EUAM}"
    , "BMD/EUR{mm=EUAM}"
    , "ZAR/NPR{mm=COMP}"
    , "CUP/EUR{mm=EUAM}"
    , "IDR/AUD{mm=COMP}"
    , "SEK/HKD{mm=CMC}"
    , "ETB/ZAR{mm=EUAM}"
    , "EUR/AUD{mm=MORN}"
    , "CHF/NZD{mm=EUAM}"
    , "GBP/MYR{mm=COMP}"
    , "HNL/GBP"
    , "ZAR/CZK{mm=EUAM}"
    , "CHF/ZAR{mm=CMC}"
    , "USD/KGS"
    , "ZAR/UYU{mm=EUAM}"
    , "EUR/MDL{mm=DRES}"
    , "NOK/RUB"
    , "MOP/USD{mm=EUAM}"
    , "PLN/BRL{mm=COMP}"
    , "AUD/FJD{mm=COMP}"
    , "TRY/NOK{mm=COMP}"
    , "CNH/HKD"
    , "MXN/PLN{mm=COMP}"
    , "MXN/HKD"
    , "HKD/EUR{mm=CMC}"
    , "USD/GEL{mm=COMP}"
    , "CAD/EUR{mm=COMP}"
    , "ISK/DKK"
    , "EUR/STN"
    , "RON/GBP{mm=COMP}"
    , "EUR/CHF:HSFX"
    , "CNY/CAD"
    , "THB/MYR{mm=EUAM}"
    , "GBP/KES{mm=COMP}"
    , "TRY/CNY{mm=EUAM}"
    , "DJF/USD"
    , "USD/HNL"
    , "ZAR/NOK{mm=COMP}"
    , "EUR/USD:HSFX"
    , "CAD/THB"
    , "AUD/CAD{mm=COMP}"
    , "ZAR/LBP"
    , "KZT/EUR{mm=EUAM}"
    , "NOK/INR{mm=EUAM}"
    , "AUD/NZD{mm=SAXO}"
    , "USD/TJS{mm=EUAM}"
    , "HRK/EUR{mm=EUAM}"
    , "XAU/GBP{mm=EUAM}"
    , "EUR/AUD{mm=COMP}"
    , "USD/KWD{mm=RADA}"
    , "ZAR/CNY{mm=EUAM}"
    , "EUR/STN{mm=COMP}"
    , "SZL/USD{mm=EUAM}"
    , "ZAR/MOP"
    , "CUP/USD"
    , "GHS/ZAR{mm=EUAM}"
    , "XAU/CNY{mm=EUAM}"
    , "ZAR/NOK{mm=CMC}"
    , "AED/JPY{mm=COMP}"
    , "GBP/TWD{mm=EUAM}"
    , "CAD/EUR"
    , "MYR/IDR{mm=EUAM}"
    , "NAD/EUR{mm=EUAM}"
    , "SGD/ZAR"
    , "GBP/TWD"
    , "KYD/BMD{mm=EUAM}"
    , "AED/OMR{mm=COMP}"
    , "PLN/USD{mm=EUAM}"
    , "EUR/NZD{mm=BNPF}"
    , "USD/DZD{mm=COMP}"
    , "ZAR/RON{mm=EUAM}"
    , "GBP/AUD"
    , "BGN/USD"
    , "EUR/CRC"
    , "AUD/NZD{mm=SURF}"
    , "CLP/ARS{mm=EUAM}"
    , "BRL/SGD{mm=EUAM}"
    , "KWD/EUR{mm=COMP}"
    , "CAD/USD{mm=MORN}"
    , "NZD/HKD"
    , "NOK/SEK{mm=SAXO}"
    , "ALL/EUR{mm=COMP}"
    , "MYR/CNH{mm=COMP}"
    , "ZAR/KWD"
    , "CNH/HKD{mm=COMP}"
    , "EUR/CRC{mm=DRES}"
    , "CHF/AUD{mm=EUAM}"
    , "SEK/PLN{mm=CMC}"
    , "LAK/USD{mm=EUAM}"
    , "HRK/BRL{mm=COMP}"
    , "GBP/AWG{mm=EUAM}"
    , "CUP/USD{mm=EUAM}"
    , "GBP/RWF{mm=EUAM}"
    , "ARE/USD{mm=COMP}"
    , "ZAR/KRW{mm=EUAM}"
    , "SGD/SEK{mm=COMP}"
    , "XAU/KRW{mm=EUAM}"
    , "USD/EGP{mm=EUAM}"
    , "NOK/SEK{mm=SURF}"
    , "AED/KWD"
    , "DKK/HKD{mm=COMP}"
    , "ZAR/JPY:HSFX"
    , "EUR/LRD"
    , "DOE/USD{mm=COMP}"
    , "GBP/LBP{mm=COMP}"
    , "CAD/RUB{mm=COMP}"
    , "SGD/BRL{mm=EUAM}"
    , "CAD/NZD{mm=CMC}"
    , "CLP/ZAR"
    , "CHF/HUF{mm=MORN}"
    , "PLN/MXN"
    , "USD/CHF{mm=EUAM}"
    , "USD/COP{mm=RADA}"
    , "CAD/USD{mm=COMP}"
    , "USD/CHF:HSFX"
    , "BOB/EUR{mm=COMP}"
    , "EUR/LAK{mm=DRES}"
    , "GBP/KZT"
    , "USD/HUF{mm=DRES}"
    , "CLP/CLF{mm=COMP}"
    , "GBP/JPY{mm=EUAM}"
    , "YER/GBP"
    , "PHP/HKD{mm=EUAM}"
    , "SEK/HKD"
    , "EUR/GYD{mm=COMP}"
    , "CAD/HRK"
    , "EUR/UYU"
    , "PEN/ARS{mm=COMP}"
    , "KRW/SEK{mm=COMP}"
    , "SGD/CNY"
    , "CZK/DKK{mm=CMC}"
    , "YER/USD{mm=COMP}"
    , "PKR/ZAR"
    , "GBP/OMR{mm=EUAM}"
    , "TRY/NZD{mm=CMC}"
    , "KZT/USD{mm=EUAM}"
    , "BTN/USD"
    , "GBP/NIO"
    , "MXN/AUD{mm=EUAM}"
    , "EUR/CHF{mm=SAXO}"
    , "HRK/USD{mm=EUAM}"
    , "EUR/LKR{mm=COMP}"
    , "ZAR/UGX{mm=COMP}"
    , "USD/ETB"
    , "EUR/AUD{mm=BNPF}"
    , "GBP/MAD"
    , "KRW/BRL{mm=EUAM}"
    , "VES/USD"
    , "SGD/GBP{mm=CMC}"
    , "ZAR/IDR{mm=COMP}"
    , "EUR/GNF{mm=COMP}"
    , "USD/MAD{mm=EUAM}"
    , "HUF/CAD{mm=EUAM}"
    , "CNH/ZAR{mm=EUAM}"
    , "TRY/NZD"
    , "NOK/CZK{mm=CMC}"
    , "BRL/ARS"
    , "GBP/INR"
    , "EUR/HUF"
    , "CHF/HUF{mm=COMP}"
    , "SGD/HKD{mm=SAXO}"
    , "JPY/CHF{mm=EUAM}"
    , "EUR/CHF{mm=SURF}"
    , "NZD/MXN{mm=SAXO}"
    , "BGN/AUD{mm=COMP}"
    , "KWD/USD{mm=COMP}"
    , "GBP/GEL"
    , "EUR/DOP{mm=COMP}"
    , "DZD/EUR{mm=COMP}"
    , "RUB/GBP"
    , "NOK/JPY"
    , "NOK/DKK{mm=SAXO}"
    , "NOK/AED{mm=EUAM}"
    , "CAD/MXN{mm=CMC}"
    , "USD/COP"
    , "NZD/TRY{mm=COMP}"
    , "GBP/CZK"
    , "XAG/ZAR{mm=COMP}"
    , "ALL/USD{mm=COMP}"
    , "EUR/TZS"
    , "XOF/BRL{mm=EUAM}"
    , "USD/TMT{mm=RADA}"
    , "KYD/GBP"
    , "CAD/KWD{mm=COMP}"
    , "GBP/MWK{mm=EUAM}"
    , "EUR/BYN"
    , "EUR/UGX"
    , "NOK/MXN{mm=COMP}"
    , "CLP/CNY"
    , "CYP/ZAR"
    , "RWF/EUR{mm=EUAM}"
    , "CAD/XDR"
    , "GBP/HKD{mm=MORN}"
    , "TRY/MXN{mm=CMC}"
    , "SGD/HKD{mm=SURF}"
    , "AUD/NOK"
    , "SGD/DKK{mm=COMP}"
    , "GBP/MVR"
    , "AED/QAR{mm=EUAM}"
    , "RWF/ZAR"
    , "TWD/CAD"
    , "BRL/EUR{mm=EUAM}"
    , "DKK/CHF{mm=CMC}"
    , "GBP/BIF{mm=EUAM}"
    , "RUB/SEK{mm=COMP}"
    , "USD/KGS{mm=COMP}"
    , "CZK/CAD{mm=COMP}"
    , "EUR/BOB{mm=DRES}"
    , "INR/THB{mm=EUAM}"
    , "FJD/GBP{mm=COMP}"
    , "SEK/SGD{mm=COMP}"
    , "XAU/USD"
    , "USD/SZL{mm=EUAM}"
    , "USD/XCD{mm=EUAM}"
    , "GEL/USD"
    , "CNH/NZD"
    , "HTG/EUR{mm=COMP}"
    , "MXN/NZD"
    , "ZAR/TZS{mm=COMP}"
    , "HUF/CZK{mm=COMP}"
    , "BRL/JPY{mm=SURF}"
    , "SGD/MYR{mm=COMP}"
    , "CVE/GBP{mm=EUAM}"
    , "RUB/BRL{mm=EUAM}"
    , "TND/USD{mm=COMP}"
    , "MXN/HUF{mm=COMP}"
    , "BOB/USD{mm=COMP}"
    , "USD/AZN{mm=EUAM}"
    , "EUR/ANG"
    , "CZK/TRY"
    , "KRW/CAD"
    , "TWD/NZD{mm=COMP}"
    , "IDR/CAD"
    , "EUR/BOB"
    , "ARS/CAD{mm=EUAM}"
    , "EUR/BDT{mm=COMP}"
    , "USD/CVE{mm=RADA}"
    , "SEK/JPY{mm=CMC}"
    , "MWK/EUR"
    , "USD/CZK{mm=CMC}"
    , "HKD/CHF"
    , "GBP/HKD{mm=COMP}"
    , "AED/ZAR{mm=COMP}"
    , "PLN/JPY{mm=COMP}"
    , "SGD/PLN"
    , "EUR/ZAR"
    , "GBP/CLP{mm=MORN}"
    , "ALL/GBP"
    , "USD/BAM"
    , "GBP/JMD{mm=EUAM}"
    , "USD/GMD"
    , "CHF/HKD"
    , "KRW/MYR{mm=COMP}"
    , "HUF/GBP{mm=COMP}"
    , "EUR/ZMW"
    , "CNY/THB{mm=EUAM}"
    , "HKD/CHF{mm=EUAM}"
    , "HUF/AED"
    , "BTN/EUR{mm=COMP}"
    , "CAD/COP{mm=COMP}"
    , "MDL/USD"
    , "NZD/TRY{mm=CMC}"
    , "GBP/HKD{mm=CMC}"
    , "THB/HKD{mm=EUAM}"
    , "GBP/KHR{mm=COMP}"
    , "SOS/GBP{mm=EUAM}"
    , "USD/PHP"
    , "EUR/CAD:HSFX"
    , "EUR/NZD{mm=DRES}"
    , "DZD/USD{mm=COMP}"
    , "CNY/XOF{mm=EUAM}"
    , "UAH/DKK{mm=COMP}"
    , "SGD/TWD{mm=EUAM}"
    , "GBP/HTG{mm=COMP}"
    , "ILS/AED{mm=EUAM}"
    , "USD/CAD{mm=SAXO}"
    , "AUD/MYR"
    , "MYR/INR{mm=COMP}"
    , "MYR/CHF"
    , "AUD/NZD{mm=MORN}"
    , "HKD/PKR"
    , "HKD/NZD{mm=CMC}"
    , "EUR/BMD{mm=COMP}"
    , "SEK/EUR{mm=MORN}"
    , "RWF/USD{mm=EUAM}"
    , "AUD/AED"
    , "GBP/CLP{mm=COMP}"
    , "EUR/SEK{mm=UBSW}"
    , "DKK/NOK{mm=EUAM}"
    , "BRL/RUB{mm=EUAM}"
    , "PLN/USD{mm=CMC}"
    , "XAG/GBP{mm=EUAM}"
    , "BRL/USD{mm=EUAM}"
    , "LKR/USD"
    , "XDR/CHF{mm=EUAM}"
    , "USD/QAR{mm=RADA}"
    , "DKK/KES"
    , "XAG/CNY{mm=EUAM}"
    , "KRW/TWD{mm=EUAM}"
    , "NZD/INR{mm=EUAM}"
    , "USD/CAD{mm=SURF}"
    , "HTG/USD{mm=COMP}"
    , "EUR/AMD"
    , "SEK/COP{mm=SURF}"
    , "TWD/AUD{mm=COMP}"
    , "EUR/CNY"
    , "MAD/EUR"
    , "INR/CAD{mm=COMP}"
    , "ZAR/DZD"
    , "AUD/ILS{mm=EUAM}"
    , "SEK/NZD"
    , "EUR/BZD"
    , "DKK/CHF"
    , "AED/CZK{mm=EUAM}"
    , "PYG/EUR"
    , "PYG/EUR{mm=COMP}"
    , "GBP/CZK:HSFX"
    , "USD/LKR"
    , "ARS/GBP{mm=COMP}"
    , "EUR/HKD{mm=CMC}"
    , "HNL/EUR{mm=COMP}"
    , "USD/TMT"
    , "AUD/NZD{mm=COMP}"
    , "USD/XPF{mm=COMP}"
    , "ZAR/RUB"
    , "NIO/GBP{mm=COMP}"
    , "EUR/HRK{mm=COMP}"
    , "MYR/PKR"
    , "NOK/SEK"
    , "SEK/EUR{mm=COMP}"
    , "GBP/SAR{mm=COMP}"
    , "AUD/LTL{mm=EUAM}"
    , "CDF/USD{mm=EUAM}"
    , "HKD/MXN{mm=CMC}"
    , "CAD/GBP{mm=SURF}"
    , "NZD/SEK{mm=SAXO}"
    , "JMD/GBP{mm=EUAM}"
    , "GBP/NPR"
    , "USD/SVC{mm=EUAM}"
    , "EUR/TJS{mm=COMP}"
    , "XAG/KRW{mm=EUAM}"
    , "HKD/VND{mm=EUAM}"
    , "GBP/PLN{mm=CMC}"
    , "USD/KZT{mm=EUAM}"
    , "BWP/EUR"
    , "DKK/HUF{mm=CMC}"
    , "AED/NOK{mm=COMP}"
    , "JPY/CAD{mm=SURF}"
    , "GBP/IQD{mm=COMP}"
    , "EUR/AUD{mm=DRES}"
    , "BTN/USD{mm=COMP}"
    , "EUR/STN{mm=DRES}"
    , "EUR/SDG{mm=EUAM}"
    , "GBP/MTL"
    , "AED/GBP{mm=EUAM}"
    , "ETB/GBP"
    , "NOK/SEK{mm=COMP}"
    , "USD/SVC"
    , "CNY/CAD{mm=COMP}"
    , "USD/CAD:HSFX"
    , "GBP/BTN"
    , "AED/CNY{mm=EUAM}"
    , "MXN/HKD{mm=CMC}"
    , "DKK/PKR"
    , "NZD/SEK{mm=SURF}"
    , "NOK/DKK"
    , "USD/DZD{mm=DRES}"
    , "KRW/JPY{mm=EUAM}"
    , "HRK/HUF"
    , "USD/MKD{mm=COMP}"
    , "IDR/CHF{mm=COMP}"
    , "USD/UZS{mm=COMP}"
    , "JPY/RUB"
    , "RSD/GBP"
    , "NOK/BRL{mm=EUAM}"
    , "PLN/QAR{mm=EUAM}"
    , "EUR/CHF{mm=MORN}"
    , "CAD/COP"
    , "XAU/EUR{mm=SAXO}"
    , "NOK/SGD"
    , "GBP/NPR{mm=EUAM}"
    , "SGD/CAD{mm=CMC}"
    , "CNH/IDR{mm=EUAM}"
    , "AUD/SGD{mm=CMC}"
    , "SVC/USD"
    , "ZAR/HUF{mm=EUAM}"
    , "DKK/UGX{mm=EUAM}"
    , "USD/VES{mm=RADA}"
    , "AWG/USD{mm=COMP}"
    , "SZL/ZAR{mm=COMP}"
    , "GBP/PAB{mm=COMP}"
    , "EUR/EGP{mm=COMP}"
    , "ZAR/KRW"
    , "SVC/EUR{mm=COMP}"
    , "HUF/PLN{mm=COMP}"
    , "XOF/BRL"
    , "EUR/PLN{mm=CMC}"
    , "MVR/USD{mm=COMP}"
    , "GTQ/EUR{mm=EUAM}"
    , "USD/ILS"
    , "HKD/TWD"
    , "GBP/YER{mm=COMP}"
    , "SGD/CAD"
    , "PYG/USD{mm=COMP}"
    , "HKD/AUD"
    , "GBP/KYD"
    , "PLN/ZAR{mm=COMP}"
    , "HNL/USD{mm=COMP}"
    , "EUR/PLN"
    , "GBP/NOK{mm=EUAM}"
    , "USD/CZK{mm=RADA}"
    , "EUR/CHF{mm=COMP}"
    , "ZAR/TND"
    , "AUD/NZD{mm=BNPF}"
    , "SEK/USD{mm=COMP}"
    , "BRL/COP{mm=EUAM}"
    , "IQD/USD{mm=COMP}"
    , "ZAR/MGA{mm=EUAM}"
    , "EUR/GYD{mm=DRES}"
    , "NZD/DKK{mm=SAXO}"
    , "CHF/NZD"
    , "NZD/AED{mm=EUAM}"
    , "EUR/GNF"
    , "MYR/SGD{mm=EUAM}"
    , "USD/PGK{mm=COMP}"
    , "RUB/JPY{mm=EUAM}"
    , "XAG/BRL"
    , "HUF/NZD{mm=EUAM}"
    , "EUR/LKR{mm=DRES}"
    , "GBP/SEK{mm=CMC}"
    , "USD/THB{mm=EUAM}"
    , "SGD/HKD{mm=COMP}"
    , "USD/DJF"
    , "NZD/MXN{mm=COMP}"
    , "MXN/PLN{mm=CMC}"
    , "USD/CUP"
    , "NOK/DKK{mm=COMP}"
    , "JPY/KRW"
    , "GBP/ARS{mm=COMP}"
    , "VES/EUR{mm=EUAM}"
    , "EUR/GNF{mm=DRES}"
    , "EUR/MAD{mm=COMP}"
    , "MYR/TWD"
    , "NZD/DKK{mm=SURF}"
    , "NOK/SEK{mm=BNPF}"
    , "USD/CDF{mm=COMP}"
    , "MYR/AUD"
    , "MWK/GBP{mm=COMP}"
    , "ILS/SEK{mm=COMP}"
    , "USD/BHD{mm=EUAM}"
    , "NOK/DKK{mm=CMC}"
    , "USD/XOF{mm=EUAM}"
    , "BRL/JPY{mm=COMP}"
    , "CAD/PLN{mm=SAXO}"
    , "BWP/EUR{mm=EUAM}"
    , "GBP/LYD{mm=EUAM}"
    , "ZAR/USD{mm=SAXO}"
    , "USD/SRD"
    , "EUR/DOP{mm=DRES}"
    , "XAU/USD{mm=SAXO}"
    , "USD/MRU{mm=RADA}"
    , "NZD/PKR{mm=COMP}"
    , "KRW/HKD{mm=COMP}"
    , "CZK/CHF{mm=CMC}"
    , "COP/DKK"
    , "BSD/USD"
    , "ZAR/MOP{mm=EUAM}"
    , "USD/RON{mm=RADA}"
    , "ZAR/JPY"
    , "VND/USD"
    , "CZK/NZD{mm=COMP}"
    , "ZAR/ETB{mm=EUAM}"
    , "GBP/JPY{mm=CMC}"
    , "JPY/THB{mm=EUAM}"
    , "DKK/TWD"
    , "TOP/USD"
    , "DKK/NOK{mm=CMC}"
    , "DKK/AUD"
    , "USD/KMF"
    , "USD/SCR{mm=COMP}"
    , "EUR/PKR{mm=EUAM}"
    , "AUD/CHF{mm=SAXO}"
    , "MXN/CLP"
    , "BRL/ZAR"
    , "USD/VES"
    , "SVC/USD{mm=COMP}"
    , "AED/JPY"
    , "EUR/SZL{mm=COMP}"
    , "GTQ/USD{mm=EUAM}"
    , "EUR/SEK{mm=CMC}"
    , "TRY/SGD{mm=COMP}"
    , "BBD/SRD{mm=EUAM}"
    , "USD/KRW{mm=RADA}"
    , "USD/CAD{mm=MORN}"
    , "HKD/INR"
    , "AED/PLN{mm=EUAM}"
    , "MXN/CHF{mm=EUAM}"
    , "CLP/MXN{mm=COMP}"
    , "KES/USD{mm=COMP}"
    , "USD/NIO{mm=EUAM}"
    , "EUR/VND{mm=COMP}"
    , "GBP/AFN{mm=COMP}"
    , "SGD/ZAR{mm=EUAM}"
    , "XCD/SRD{mm=COMP}"
    , "NZD/ZAR{mm=CMC}"
    , "EUR/CHF{mm=BNPF}"
    , "SZL/GBP{mm=EUAM}"
    , "HKD/CZK"
    , "EUR/AZN{mm=COMP}"
    , "HUF/AUD{mm=EUAM}"
    , "ZAR/SGD{mm=COMP}"
    , "AUD/CHF{mm=SURF}"
    , "PHP/JPY"
    , "GBP/UGX{mm=EUAM}"
    , "PLN/NOK{mm=COMP}"
    , "XAU/SGD{mm=COMP}"
    , "USD/SLL"
    , "GBP/HKD"
    , "EUR/BDT{mm=DRES}"
    , "QAR/ZAR{mm=EUAM}"
    , "GBP/IDR{mm=EUAM}"
    , "PLN/GBP{mm=EUAM}"
    , "NSO/USD{mm=COMP}"
    , "USD/FJD{mm=COMP}"
    , "USD/DKK{mm=CMC}"
    , "GBP/LSL{mm=COMP}"
    , "CHF/SZL{mm=EUAM}"
    , "CAD/GBP{mm=MORN}"
    , "AUD/TOP{mm=COMP}"
    , "MXN/SEK{mm=CMC}"
    , "NZD/XDR{mm=COMP}"
    , "LSL/EUR{mm=COMP}"
    , "HKD/GBP"
    , "CAD/USD"
    , "KRW/ZAR{mm=EUAM}"
    , "VES/USD{mm=EUAM}"
    , "EUR/XOF"
    , "EUR/JPY{mm=CMC}"
    , "GBP/CNH{mm=COMP}"
    , "CAD/CZK{mm=COMP}"
    , "EUR/SRD{mm=EUAM}"
    , "MYR/INR"
    , "USD/CAD{mm=COMP}"
    , "CAD/TRY{mm=CMC}"
    , "CLP/CLF"
    , "SEK/COP{mm=COMP}"
    , "MYR/EUR{mm=EUAM}"
    , "LAK/GBP{mm=EUAM}"
    , "ZWD/GBP{mm=EUAM}"
    , "BWP/USD{mm=EUAM}"
    , "BMD/GBP{mm=EUAM}"
    , "TTD/SRD{mm=COMP}"
    , "CZK/AUD{mm=COMP}"
    , "BRL/CNY"
    , "LTL/AUD"
    , "USD/BIF"
    , "USD/DJF{mm=COMP}"
    , "HRK/CZK{mm=EUAM}"
    , "TRY/MXN"
    , "HKD/THB{mm=EUAM}"
    , "PLN/HUF"
    , "XAG/EUR"
    , "THB/CNH{mm=EUAM}"
    , "EUR/LRD{mm=COMP}"
    , "XOF/ZAR{mm=EUAM}"
    , "KHR/USD"
    , "EUR/BMD{mm=DRES}"
    , "USD/MDL{mm=COMP}"
    , "USD/XAF{mm=EUAM}"
    , "IDR/SEK{mm=EUAM}"
    , "CAD/GBP{mm=COMP}"
    , "DKK/INR"
    , "MYR/GBP"
    , "GBP/CHF:HSFX"
    , "AUD/EUR{mm=CMC}"
    , "MXN/JPY{mm=CMC}"
    , "EUR/CAD"
    , "NOK/JPY{mm=EUAM}"
    , "USD/DOP"
    , "ARS/AUD{mm=EUAM}"
    , "INR/NZD{mm=COMP}"
    , "CAD/CNY{mm=COMP}"
    , "CNH/INR{mm=COMP}"
    , "EUR/UZS"
    , "DKK/CZK"
    , "HUF/BRL"
    , "JPY/CAD{mm=COMP}"
    , "TZS/ZAR{mm=EUAM}"
    , "GBP/EGP"
    , "YER/GBP{mm=COMP}"
    , "GBP/USD:HSFX"
    , "AUN/CHF{mm=COMP}"
    , "FJD/TOP"
    , "KZT/GBP{mm=EUAM}"
    , "CNY/RUB"
    , "GNF/GBP"
    , "MYR/BRL{mm=COMP}"
    , "USD/PLN{mm=RADA}"
    , "CAD/ILS"
    , "CAD/RON{mm=COMP}"
    , "USD/ISK{mm=EUAM}"
    , "RUB/ZAR{mm=EUAM}"
    , "FJD/TOP{mm=EUAM}"
    , "NZD/SEK{mm=COMP}"
    , "DKK/INR{mm=COMP}"
    , "XAG/EUR{mm=SAXO}"
    , "LSL/EUR"
    , "XPF/GBP"
    , "GBP/BBD"
    , "EUR/HRK{mm=DRES}"
    , "HRK/PLN"
    , "EUR/SLL{mm=COMP}"
    , "DKK/GBP"
    , "CZK/HUF{mm=CMC}"
    , "SGD/NOK{mm=EUAM}"
    , "JPY/NZD{mm=SURF}"
    , "BYN/USD"
    , "GBP/BBD{mm=COMP}"
    , "NZD/BRL{mm=EUAM}"
    , "TRY/EUR{mm=COMP}"
    , "ZAR/DZD{mm=EUAM}"
    , "AUD/BRL"
    , "KYD/CAD{mm=COMP}"
    , "RWF/ZAR{mm=COMP}"
    , "LBP/USD{mm=COMP}"
    , "KWD/GBP{mm=COMP}"
    , "BDT/JPY{mm=EUAM}"
    , "EUR/SVC{mm=COMP}"
    , "ZAR/SEK"
    , "XAU/SAR"
    , "USD/AUD{mm=SAXO}"
    , "CAD/KRW{mm=COMP}"
    , "GBP/CUP{mm=COMP}"
    , "LSL/USD{mm=COMP}"
    , "CNY/NZD{mm=COMP}"
    , "EUR/TJS{mm=DRES}"
    , "ALL/GBP{mm=COMP}"
    , "BRL/ZAR{mm=COMP}"
    , "GBP/BYN{mm=EUAM}"
    , "ZAR/BWP{mm=COMP}"
    , "EUR/KZT{mm=COMP}"
    , "USD/MVR{mm=COMP}"
    , "GBP/UAH"
    , "ZAR/EUR{mm=COMP}"
    , "PLN/ZAR"
    , "GBP/FJD"
    , "USD/CAD{mm=BNPF}"
    , "GBP/GHS{mm=COMP}"
    , "XAU/EUR{mm=COMP}"
    , "MYR/USD{mm=EUAM}"
    , "AUD/IDR"
    , "ANG/GBP"
    , "USD/CRC{mm=COMP}"
    , "SGD/ARS{mm=COMP}"
    , "ZAR/SGD{mm=CMC}"
    , "AED/SEK"
    , "USD/AUD{mm=SURF}"
    , "BRL/RSD{mm=EUAM}"
    , "SEK/CZK{mm=CMC}"
    , "RON/CHF{mm=EUAM}"
    , "ZAR/DKK"
    , "NZD/MXN"
    , "INR/AUD{mm=COMP}"
    , "CNH/PHP{mm=RADA}"
    , "LRD/XOF"
    , "TWD/CHF{mm=COMP}"
    , "IDR/DKK{mm=EUAM}"
    , "AUD/HRK{mm=COMP}"
    , "EUR/BWP"
    , "CHF/AUD{mm=CMC}"
    , "HKD/CAD{mm=COMP}"
    , "TND/GBP{mm=COMP}"
    , "CNY/KRW"
    , "JPY/SEK"
    , "BYN/USD{mm=COMP}"
    , "BOB/GBP{mm=COMP}"
    , "ZAR/SGD"
    , "XPDK/CHF{mm=COMP}"
    , "IDR/THB{mm=COMP}"
    , "AED/DKK"
    , "ILS/JPY{mm=EUAM}"
    , "USD/LAK{mm=COMP}"
    , "CLP/BRL{mm=EUAM}"
    , "NOK/HKD{mm=COMP}"
    , "ARS/CHF"
    , "IDR/MYR{mm=EUAM}"
    , "EUR/EGP{mm=DRES}"
    , "GBP/INR{mm=MORN}"
    , "USD/TTD{mm=EUAM}"
    , "AED/HUF{mm=EUAM}"
    , "XAU/USD{mm=MORN}"
    , "NZD/HKD{mm=SURF}"
    , "EUR/JOD{mm=COMP}"
    , "XOF/NAD{mm=COMP}"
    , "NZD/DKK{mm=COMP}"
    , "AED/SGD"
    , "PEN/EUR{mm=EUAM}"
    , "GBP/NZD"
    , "JPY/AUD{mm=SURF}"
    , "XAG/USD{mm=SAXO}"
    , "PHP/DKK"
    , "EUR/CHF{mm=DRES}"
    , "MWK/USD"
    , "CHF/CLP"
    , "HKD/TRY{mm=CMC}"
    , "JPY/DKK"
    , "CNY/AUD{mm=COMP}"
    , "TRY/RUB{mm=COMP}"
    , "EUR/BMD"
    , "RUB/NOK{mm=EUAM}"
    , "ETB/EUR{mm=EUAM}"
    , "GBP/KHR"
    , "SEK/MXN"
    , "XDR/CAD{mm=COMP}"
    , "TRY/USD{mm=COMP}"
    , "NZD/MYR{mm=COMP}"
    , "AUD/CHF{mm=MORN}"
    , "CLP/PEN{mm=COMP}"
    , "JPY/SGD"
    , "MGA/ZAR{mm=EUAM}"
    , "HUF/EUR"
    , "SGD/IDR{mm=EUAM}"
    , "ZAR/BTN{mm=COMP}"
    , "EUR/THB{mm=COMP}"
    , "CAD/PLN{mm=COMP}"
    , "XAU/RUB{mm=COMP}"
    , "DKK/AED{mm=COMP}"
    , "ZAR/USD{mm=COMP}"
    , "GBP/INR{mm=COMP}"
    , "AUD/THB"
    , "USD/CNH{mm=CMC}"
    , "XAU/USD{mm=COMP}"
    , "CNH/SGD{mm=EUAM}"
    , "RWF/GBP{mm=EUAM}"
    , "EUR/MAD{mm=DRES}"
    , "TWD/PKR{mm=EUAM}"
    , "BRL/NOK{mm=COMP}"
    , "PEN/BRL{mm=COMP}"
    , "COP/MXN{mm=EUAM}"
    , "ZAR/MMK{mm=COMP}"
    , "SGD/CNH{mm=COMP}"
    , "EUR/BHD{mm=COMP}"
    , "MYR/TWD{mm=COMP}"
    , "BRI/CHF{mm=COMP}"
    , "EUR/XOF{mm=COMP}"
    , "CNY/JPY"
    , "NIO/USD"
    , "HRK/PLN{mm=EUAM}"
    , "BRL/CNY{mm=EUAM}"
    , "HTG/GBP{mm=COMP}"
    , "AUD/RWF"
    , "SEK/PLN{mm=SAXO}"
    , "THB/INR{mm=EUAM}"
    , "GBP/BGN"
    , "AUD/EUR"
    , "NOK/ZAR{mm=EUAM}"
    , "CZK/NOK{mm=CMC}"
    , "KRW/IDR{mm=EUAM}"
    , "USD/BOB{mm=COMP}"
    , "AUD/CHF{mm=COMP}"
    , "GHS/EUR{mm=EUAM}"
    , "GBP/USD{mm=UBSW}"
    , "CAD/NZD{mm=EUAM}"
    , "USD/SAR"
    , "CHF/THB{mm=EUAM}"
    , "NZD/TWD{mm=EUAM}"
    , "USD/MUR{mm=EUAM}"
    , "USD/MMK"
    , "USD/SCR{mm=DRES}"
    , "MAD/USD"
    , "HUF/HRK{mm=EUAM}"
    , "HRK/CAD"
    , "USD/GYD"
    , "USD/CNH"
    , "SEK/CAD{mm=EUAM}"
    , "CDF/GBP{mm=EUAM}"
    , "PYG/USD"
    , "EUR/SZL{mm=DRES}"
    , "KRW/RUB"
    , "NOK/AED"
    , "NOK/CHF{mm=CMC}"
    , "PEN/USD{mm=EUAM}"
    , "HUF/HRK"
    , "BRL/XOF"
    , "ZAR/KWD{mm=COMP}"
    , "AUD/MAD{mm=COMP}"
    , "EUR/NIO{mm=COMP}"
    , "PEN/BRL"
    , "EUR/VND{mm=DRES}"
    , "JMD/EUR"
    , "BRL/KRW{mm=EUAM}"
    , "LYD/GBP"
    , "CAD/ZAR{mm=CMC}"
    , "BTN/GBP{mm=COMP}"
    , "MXN/ARS"
    , "CVE/EUR{mm=COMP}"
    , "ETB/USD{mm=EUAM}"
    , "AUD/MXN{mm=EUAM}"
    , "GBP/CAD:HSFX"
    , "MYR/JPY{mm=COMP}"
    , "EUR/JPY:HSFX"
    , "BHD/EUR{mm=COMP}"
    , "AED/SGD{mm=COMP}"
    , "BWP/USD"
    , "USD/FJD{mm=DRES}"
    , "NPR/EUR{mm=EUAM}"
    , "TRY/ZAR{mm=CMC}"
    , "VRN/CHF"
    , "GBP/LYD"
    , "USD/NZD{mm=COMP}"
    , "HTG/EUR"
    , "USD/MGA{mm=RADA}"
    , "CZK/HRK{mm=COMP}"
    , "AUD/HRK"
    , "CHF/MXN"
    , "EUR/CVE"
    , "CHF/CAD{mm=MORN}"
    , "EUR/ILS{mm=SAXO}"
    , "BGN/DKK{mm=EUAM}"
    , "BRL/CAD"
    , "AUD/NZD{mm=CMC}"
    , "NZD/JPY{mm=EUAM}"
    , "AUD/PKR{mm=EUAM}"
    , "SGD/HKD{mm=CMC}"
    , "USD/CAD{mm=DRES}"
    , "TWD/KRW"
    , "SEK/CZK{mm=COMP}"
    , "ARE/CHF{mm=UBSW}"
    , "ZAR/EUR{mm=CMC}"
    , "CAD/AUD{mm=EUAM}"
    , "USD/HTG"
    , "INR/ZAR"
    , "IDR/ISK{mm=COMP}"
    , "USD/MZN{mm=RADA}"
    , "AWG/GBP{mm=COMP}"
    , "CNH/JPY{mm=SAXO}"
    , "CNY/TRY{mm=EUAM}"
    , "BWP/ZAR{mm=MORN}"
    , "JPY/RSD"
    , "BRL/IDR{mm=COMP}"
    , "GHS/USD{mm=EUAM}"
    , "JPY/ILS{mm=EUAM}"
    , "GBP/TND{mm=COMP}"
    , "DOE/CHF{mm=UBSW}"
    , "ILS/ZAR{mm=EUAM}"
    , "EUR/LRD{mm=DRES}"
    , "GBP/AED{mm=COMP}"
    , "XAU/TRY"
    , "SZL/EUR"
    , "ZAR/COP{mm=COMP}"
    , "EUR/NZD:HSFX"
    , "CNH/EUR{mm=EUAM}"
    , "USD/CHF{mm=CMC}"
    , "KES/CHF"
    , "ARS/AUD"
    , "JPY/NZD{mm=COMP}"
    , "IDR/KRW"
    , "PLN/HUF{mm=EUAM}"
    , "EUR/XAF{mm=COMP}"
    , "HNL/GBP{mm=COMP}"
    , "CAD/CHF{mm=UBSW}"
    , "MAD/ZAR{mm=EUAM}"
    , "EUR/LBP"
    , "DKK/JPY{mm=SAXO}"
    , "EGP/ZAR"
    , "MTL/GBP{mm=EUAM}"
    , "CHF/CAD{mm=COMP}"
    , "CZK/CHF{mm=MORN}"
    , "HUF/CHF{mm=EUAM}"
    , "SEK/GBP{mm=COMP}"
    , "ZAR/SZL"
    , "IQD/GBP{mm=COMP}"
    , "TWD/SEK{mm=EUAM}"
    , "EUR/MOP"
    , "GNF/USD{mm=EUAM}"
    , "GBP/LSL"
    , "XAG/EUR{mm=COMP}"
    , "USD/ETB{mm=RADA}"
    , "DKK/EUR{mm=EUAM}"
    , "SEK/CNY{mm=COMP}"
    , "USD/TJS"
    , "GBP/BHD"
    , "AUD/XDR"
    , "AUD/MXN{mm=CMC}"
    , "LRD/XOF{mm=COMP}"
    , "GBP/ANG{mm=COMP}"
    , "SGD/INR{mm=MORN}"
    , "EUR/ISK{mm=COMP}"
    , "BWP/ZAR{mm=COMP}"
    , "OMR/ZAR"
    , "DKK/JPY{mm=SURF}"
    , "CVE/USD{mm=COMP}"
    , "MYR/HKD"
    , "USD/AUD{mm=COMP}"
    , "CNY/SEK"
    , "USD/STN{mm=COMP}"
    , "AUD/XDR{mm=EUAM}"
    , "XOF/GHS{mm=COMP}"
    , "EUR/SLL{mm=DRES}"
    , "USD/JPY:HSFX"
    , "BHD/USD{mm=COMP}"
    , "GBP/CZK{mm=CMC}"
    , "EUR/KWD"
    , "NPR/USD{mm=EUAM}"
    , "TWD/JPY"
    , "IDR/HKD{mm=EUAM}"
    , "INR/CNY"
    , "USD/GTQ{mm=EUAM}"
    , "CZK/CHF{mm=COMP}"
    , "USD/MRU"
    , "EUR/SVC{mm=DRES}"
    , "PEN/COP{mm=EUAM}"
    , "SGD/PLN{mm=CMC}"
    , "CAD/HUF{mm=COMP}"
    , "NAD/EUR"
    , "JMD/EUR{mm=COMP}"
    , "MXN/CAD{mm=COMP}"
    , "EUR/KZT{mm=DRES}"
    , "THB/PKR{mm=COMP}"
    , "UZS/EUR{mm=COMP}"
    , "DKK/BRL{mm=COMP}"
    , "PEN/EUR"
    , "XAG/USD{mm=MORN}"
    , "AUD/PHP{mm=EUAM}"
    , "KRU/USD"
    , "USD/CLF{mm=EUAM}"
    , "ANG/EUR{mm=EUAM}"
    , "NOK/HUF{mm=CMC}"
    , "GBP/SOS{mm=EUAM}"
    , "DKK/HKD"
    , "CNY/DKK"
    , "SGD/INR{mm=COMP}"
    , "KRW/JPY"
    , "AED/EUR{mm=COMP}"
    , "NZD/HKD{mm=COMP}"
    , "IDR/JPY"
    , "HRK/HUF{mm=EUAM}"
    , "SOS/USD{mm=COMP}"
    , "ARS/CHF{mm=EUAM}"
    , "JPY/AUD{mm=COMP}"
    , "HKD/NZD{mm=COMP}"
    , "GBP/ALL{mm=COMP}"
    , "SVC/GBP{mm=COMP}"
    , "CNY/SGD"
    , "GTQ/GBP{mm=EUAM}"
    , "USD/AOA"
    , "DKK/BGN{mm=COMP}"
    , "SEK/DKK{mm=CMC}"
    , "GBP/BAM{mm=COMP}"
    , "CNH/USD{mm=EUAM}"
    , "CHF/AUD{mm=SAXO}"
    , "HKD/ZAR{mm=CMC}"
    , "CZK/MXN{mm=EUAM}"
    , "CAD/SAR"
    , "CLP/PEN"
    , "ILS/NOK{mm=EUAM}"
    , "KRW/INR{mm=COMP}"
    , "TWD/DKK{mm=EUAM}"
    , "EUR/CZK{mm=CMC}"
    , "EUR/AOA{mm=EUAM}"
    , "XAG/RUB{mm=COMP}"
    , "DKK/RUB{mm=EUAM}"
    , "AUD/SEK{mm=EUAM}"
    , "XAG/USD{mm=COMP}"
    , "SCR/GBP"
    , "DKK/USD{mm=EUAM}"
    , "RON/ZAR"
    , "ZAR/PGK{mm=EUAM}"
    , "USD/GYD{mm=COMP}"
    , "MXV/MXN"
    , "EUR/TTD{mm=COMP}"
    , "TWD/THB{mm=COMP}"
    , "PLN/CAD"
    , "GBP/CLP"
    , "EUR/BSD"
    , "CAD/CNH"
    , "GBP/XPF"
    , "USD/GEL{mm=RADA}"
    , "MYR/ZAR{mm=COMP}"
    , "EUR/JOD{mm=DRES}"
    , "TWD/MYR{mm=EUAM}"
    , "USD/CHF{mm=SAXO}"
    , "PLN/SGD{mm=COMP}"
    , "XAGK/CHF{mm=COMP}"
    , "XDR/NZD{mm=COMP}"
    , "ARS/GBP"
    , "CHF/USD{mm=CMC}"
    , "USD/LKR{mm=COMP}"
    , "NOK/CNH{mm=COMP}"
    , "VES/GBP{mm=EUAM}"
    , "USD/TRY"
    , "GBP/JPY{mm=SAXO}"
    , "ZAR/NOK"
    , "USD/EGP{mm=SURF}"
    , "COP/DKK{mm=EUAM}"
    , "ZAR/CDF{mm=EUAM}"
    , "GBP/BWP{mm=EUAM}"
    , "USD/GNF{mm=COMP}"
    , "TRY/HUF"
    , "VND/JPY{mm=EUAM}"
    , "XOF/USD"
    , "USD/HUF{mm=CMC}"
    , "MXN/CZK{mm=CMC}"
    , "BWP/GBP{mm=EUAM}"
    , "NZD/ZAR{mm=EUAM}"
    , "USD/CHF{mm=SURF}"
    , "SGD/SEK{mm=CMC}"
    , "JMD/USD{mm=COMP}"
    , "CHF/ARS"
    , "USD/DOP{mm=COMP}"
    , "AED/NOK"
    , "EUR/THB{mm=DRES}"
    , "UZS/USD{mm=COMP}"
    , "GBP/JPY{mm=SURF}"
    , "USD/DZD{mm=RADA}"
    , "PHP/DKK{mm=COMP}"
    , "INR/CHF{mm=COMP}"
    , "HKD/AUD{mm=COMP}"
    , "ANG/USD{mm=EUAM}"
    , "SEK/PLN{mm=COMP}"
    , "KRU/USD{mm=UBSW}"
    , "THB/CHF"
    , "ZAR/QAR{mm=COMP}"
    , "AED/USD{mm=COMP}"
    , "EUR/BHD{mm=DRES}"
    , "USD/TOP{mm=COMP}"
    , "HKD/NZD"
    , "XAG/USD"
    , "MYR/CNH{mm=RADA}"
    , "EUR/XOF{mm=DRES}"
    , "CNH/HKD{mm=RADA}"
    , "JPY/NOK"
    , "DKK/ZAR{mm=SAXO}"
    , "SGD/KRW"
    , "GBP/KMF{mm=COMP}"
    , "DKK/UAH"
    , "MXN/HUF"
    , "GBP/BRL{mm=COMP}"
    , "CNH/TWD{mm=COMP}"
    , "PAB/USD"
    , "EUR/ILS{mm=MORN}"
    , "ZAR/XAF"
    , "SGD/JPY{mm=CMC}"
    , "JPY/CHF{mm=SURF}"
    , "EUR/TRY{mm=SAXO}"
    , "SGD/AED{mm=COMP}"
    , "EUR/MUR{mm=COMP}"
    , "NBL/CHF"
    , "MOP/EUR{mm=COMP}"
    , "TWD/SEK"
    , "USD/AWG"
    , "ISK/USD{mm=EUAM}"
    , "CNY/CHF{mm=COMP}"
    , "XDR/AUD{mm=COMP}"
    , "CAD/BMD{mm=EUAM}"
    , "CLP/ZAR{mm=EUAM}"
    , "DKK/SGD{mm=CMC}"
    , "QAR/AED{mm=COMP}"
    , "RUB/MXN"
    , "AUD/THB{mm=COMP}"
    , "DKK/TWD{mm=COMP}"
    , "ZAR/FJD{mm=EUAM}"
    , "THB/PKR"
    , "USD/BDT{mm=COMP}"
    , "MYR/NZD"
    , "BIF/USD"
    , "GBP/BGN{mm=COMP}"
    , "TRY/ZAR"
    , "ZAR/MYR"
    , "LSL/USD"
    , "EUR/PEN"
    , "AUD/MYR{mm=EUAM}"
    , "LSL/GBP{mm=COMP}"
    , "USD/HNL{mm=EUAM}"
    , "JPY/TRY{mm=EUAM}"
    , "EUR/NIO{mm=DRES}"
    , "ZAR/AED"
    , "BRL/EUR{mm=SURF}"
    , "AED/KWD{mm=COMP}"
    , "KRW/SEK"
    , "ZAR/NZD{mm=CMC}"
    , "IDR/SEK"
    , "GBP/RUB{mm=EUAM}"
    , "ZAR/CAD{mm=EUAM}"
    , "GBP/BTN{mm=EUAM}"
    , "TWD/DKK"
    , "MYR/GBP{mm=EUAM}"
    , "INR/PKR{mm=EUAM}"
    , "SZL/EUR{mm=COMP}"
    , "EUR/ILS{mm=COMP}"
    , "XAU/CAD{mm=EUAM}"
    , "GBP/MTL{mm=COMP}"
    , "GBP/MXN"
    , "DKK/COP{mm=EUAM}"
    , "NZD/HUF"
    , "MYR/CNY{mm=EUAM}"
    , "DKK/RWF{mm=COMP}"
    , "VND/HKD{mm=COMP}"
    , "CAD/HRK{mm=EUAM}"
    , "DKK/NZD"
    , "TWD/SGD"
    , "PLN/EUR{mm=COMP}"
    , "GBP/MMK{mm=EUAM}"
    , "TND/GBP"
    , "CNY/MXN{mm=EUAM}"
    , "KRW/SGD{mm=EUAM}"
    , "CNH/JPY{mm=COMP}"
    , "SGD/JPY"
    , "JPY/MYR"
    , "NOK/BRL"
    , "CNH/ZAR"
    , "CZK/SEK{mm=EUAM}"
    , "IDR/DKK"
    , "USD/BMD{mm=COMP}"
    , "MXN/ZAR"
    , "EUR/YER{mm=EUAM}"
    , "BYN/GBP{mm=COMP}"
    , "EUR/DZD"
    , "USD/SEK{mm=UBSW}"
    , "GBP/MUR"
    , "TRY/CZK{mm=COMP}"
    , "JPY/AED"
    , "CZK/CHF"
    , "CHF/ILS{mm=EUAM}"
    , "SOS/GBP"
    , "SEK/AUD{mm=EUAM}"
    , "KRW/SGD"
    , "LAK/EUR{mm=COMP}"
    , "DKK/JPY{mm=COMP}"
    , "IDR/SGD"
    , "EUR/RUB"
    , "BMD/EUR{mm=COMP}"
    , "CUP/EUR{mm=COMP}"
    , "ZAR/MXN{mm=CMC}"
    , "USD/BZD{mm=EUAM}"
    , "ETB/ZAR{mm=COMP}"
    , "TRY/CNY"
    , "CHF/NZD{mm=COMP}"
    , "MAL/USD"
    , "AUD/PHP"
    , "USD/NOK{mm=CMC}"
    , "MYR/KRW{mm=EUAM}"
    , "ZAR/CZK{mm=COMP}"
    , "GBP/BND"
    , "ZAR/UYU{mm=COMP}"
    , "SEK/HUF"
    , "EUR/XAF{mm=DRES}"
    , "MOP/USD{mm=COMP}"
    , "GBP/KWD{mm=EUAM}"
    , "SEK/CHF{mm=UBSW}"
    , "PHP/JPY{mm=EUAM}"
    , "NZD/AUD{mm=CMC}"
    , "USD/HRK{mm=COMP}"
    , "AMD/EUR"
    , "TRY/GBP{mm=COMP}"
    , "DKK/BGN"
    , "GBP/ZAR{mm=SAXO}"
    , "THB/MYR{mm=COMP}"
    , "SEK/UGX"
    , "TRY/CNY{mm=COMP}"
    , "JPY/SEK{mm=UBSW}"
    , "DKK/NOK{mm=SURF}"
    , "NZD/ZAR"
    , "GBP/MWK"
    , "CHF/AUD{mm=MORN}"
    , "KZT/EUR{mm=COMP}"
    , "EUR/ISK{mm=DRES}"
    , "NOK/INR{mm=COMP}"
    , "ZAR/GBP{mm=COMP}"
    , "USD/TJS{mm=COMP}"
    , "INR/CAD"
    , "USD/NGN{mm=EUAM}"
    , "EUR/MKD"
    , "HRK/EUR{mm=COMP}"
    , "MAL/USD{mm=UBSW}"
    , "XAU/GBP{mm=COMP}"
    , "PKR/JPY"
    , "CAD/TRY"
    , "ZAR/CNY{mm=COMP}"
    , "SZL/USD{mm=COMP}"
    , "HUF/USD"
    , "MXN/CNY"
    , "GHS/ZAR{mm=COMP}"
    , "XAU/CNY{mm=COMP}"
    , "SGD/JPY{mm=SAXO}"
    , "MXN/NZD{mm=COMP}"
    , "GBP/IQD"
    , "CNY/SRD{mm=EUAM}"
    , "THB/TWD"
    , "GBP/TWD{mm=COMP}"
    , "GBP/DKK{mm=CMC}"
    , "MYR/IDR{mm=COMP}"
    , "NAD/EUR{mm=COMP}"
    , "EUR/GTQ{mm=COMP}"
    , "THB/AUD"
    , "KYD/BMD{mm=COMP}"
    , "PLN/USD{mm=COMP}"
    , "ZAR/RON{mm=COMP}"
    , "SGD/EUR{mm=EUAM}"
    , "TWD/HKD{mm=EUAM}"
    , "CLP/ARS{mm=COMP}"
    , "BRL/SGD{mm=COMP}"
    , "CDF/USD"
    , "CNY/XDR{mm=EUAM}"
    , "SEK/HUF{mm=COMP}"
    , "CZK/DKK{mm=EUAM}"
    , "USD/CHF{mm=MORN}"
    , "IDR/CNH{mm=EUAM}"
    , "EUR/KRW"
    , "CLF/CLP{mm=COMP}"
    , "USD/DKK{mm=UBSW}"
    , "QAR/EUR{mm=EUAM}"
    , "GBP/JPY{mm=MORN}"
    , "THB/TWD{mm=EUAM}"
    , "SGD/JPY{mm=SURF}"
    , "NZD/IDR{mm=EUAM}"
    , "CHF/AUD{mm=COMP}"
    , "USD/TOP"
    , "AUD/USD"
    , "GBP/COP{mm=EUAM}"
    , "SEK/ZAR"
    , "ZAR/VND"
    , "PEN/COP"
    , "LAK/USD{mm=COMP}"
    , "INR/SEK{mm=EUAM}"
    , "USD/MZN"
    , "GBP/AWG{mm=COMP}"
    , "ZAR/LAK{mm=EUAM}"
    , "CUP/USD{mm=COMP}"
    , "GBP/RWF{mm=COMP}"
    , "EUR/TND"
    , "ZAR/KRW{mm=COMP}"
    , "COP/BRL"
    , "XAU/USD:HSFX"
    , "OSO/CHF{mm=COMP}"
    , "XAU/KRW{mm=COMP}"
    , "KRW/EUR{mm=EUAM}"
    , "PEN/GBP{mm=EUAM}"
    , "USD/EGP{mm=COMP}"
    , "NZD/CNH{mm=COMP}"
    , "NZD/CNY"
    , "TRY/PLN"
    , "PLN/GBP{mm=CMC}"
    , "AUD/TRY{mm=CMC}"
    , "SGD/BRL{mm=COMP}"
    , "NOK/EUR"
    , "ETB/GBP{mm=EUAM}"
    , "USD/CHF{mm=COMP}"
    , "JPY/CHF{mm=MORN}"
    , "EUR/DKK{mm=CMC}"
    , "XOF/BWP{mm=EUAM}"
    , "NSO/CHF{mm=UBSW}"
    , "DKK/EUR{mm=CMC}"
    , "GBP/JPY{mm=COMP}"
    , "MKD/EUR"
    , "PHP/HKD{mm=COMP}"
    , "CHF/HUF"
    , "JMD/USD"
    , "EUR/TTD{mm=DRES}"
    , "AUD/NZD{mm=RADA}"
    , "CNY/NOK"
    , "USD/XPF{mm=RADA}"
    , "ZMW/ZAR{mm=EUAM}"
    , "GBP/OMR{mm=COMP}"
    , "SGD/SEK"
    , "KZT/USD{mm=COMP}"
    , "GBP/NOK{mm=SAXO}"
    , "MXN/AUD{mm=COMP}"
    , "CNY/SEK{mm=EUAM}"
    , "HRK/USD{mm=COMP}"
    , "UAH/EUR{mm=EUAM}"
    , "GBP/AFN"
    , "GYD/EUR"
    , "ZAR/JOD"
    , "SEK/CHF{mm=CMC}"
    , "KRW/BRL{mm=COMP}"
    , "THB/JPY{mm=EUAM}"
    , "HTG/USD"
    , "COP/CLP{mm=EUAM}"
    , "AUD/ILS"
    , "GBP/ARS"
    , "TZS/EUR{mm=EUAM}"
    , "USD/MAD{mm=COMP}"
    , "EUR/JPY"
    , "HUF/CAD{mm=COMP}"
    , "CNH/ZAR{mm=COMP}"
    , "CAD/VND{mm=EUAM}"
    , "SEK/CNY"
    , "THB/INR"
    , "MTL/GBP"
    , "MXN/PLN"
    , "MXN/DKK{mm=CMC}"
    , "SGD/USD{mm=EUAM}"
    , "USD/THB{mm=SAXO}"
    , "BHD/EUR"
    , "TOP/USD{mm=EUAM}"
    , "GBP/NOK{mm=SURF}"
    , "JPY/CHF{mm=COMP}"
    , "AUD/HKD{mm=EUAM}"
    , "EUR/HKD:HSFX"
    , "RUB/EUR{mm=EUAM}"
    , "AED/JOD"
    , "GHS/GBP{mm=EUAM}"
    , "SGD/DKK"
    , "NOK/AED{mm=COMP}"
    , "ARS/HKD"
    , "CZK/AUD"
    , "BRL/COP{mm=SURF}"
    , "DKK/ZAR{mm=COMP}"
    , "QAR/USD{mm=EUAM}"
    , "XOF/BRL{mm=COMP}"
    , "USD/UZS{mm=RADA}"
    , "TRY/PLN{mm=COMP}"
    , "USD/KYD{mm=EUAM}"
    , "GBP/MWK{mm=COMP}"
    , "EUR/MXN{mm=SAXO}"
    , "RWF/EUR{mm=COMP}"
    , "USD/THB{mm=SURF}"
    , "KRW/RUB{mm=EUAM}"
    , "BRL/PEN"
    , "EUR/TRY{mm=COMP}"
    , "GBP/GHS"
    , "SZL/USD"
    , "AED/QAR{mm=COMP}"
    , "GNF/GBP{mm=EUAM}"
    , "THB/GBP"
    , "KRW/USD{mm=EUAM}"
    , "USD/PKR{mm=EUAM}"
    , "BRL/EUR{mm=COMP}"
    , "GBP/BIF{mm=COMP}"
    , "GBP/CVE{mm=EUAM}"
    , "NOK/QAR"
    , "XAG/CAD{mm=EUAM}"
    , "PHP/ZAR{mm=EUAM}"
    , "ZAR/PLN{mm=COMP}"
    , "INR/THB{mm=COMP}"
    , "GBP/AMD{mm=EUAM}"
    , "USD/SZL{mm=COMP}"
    , "USD/XCD{mm=COMP}"
    , "HKD/CHF{mm=MORN}"
    , "CZK/SGD{mm=CMC}"
    , "CHF/ZAR"
    , "INR/MYR{mm=EUAM}"
    , "JPY/MXN{mm=EUAM}"
    , "CVE/GBP{mm=COMP}"
    , "USD/VND{mm=COMP}"
    , "NZD/PLN"
    , "RUB/BRL{mm=COMP}"
    , "EUR/MUR{mm=DRES}"
    , "CNY/MYR"
    , "AUD/CLP{mm=EUAM}"
    , "USD/CHF{mm=BNPF}"
    , "USD/AZN{mm=COMP}"
    , "ZAR/OMR"
    , "GBP/JPY:HSFX"
    , "XOF/USD{mm=EUAM}"
    , "GBP/JPY{mm=BNPF}"
    , "NPR/GBP{mm=EUAM}"
    , "ARS/CAD{mm=COMP}"
    , "CHF/TRY{mm=EUAM}"
    , "COP/EUR"
    , "CNY/DKK{mm=EUAM}"
    , "ZAR/NZD{mm=EUAM}"
    , "EUR/HNL{mm=COMP}"
    , "GHS/EUR"
    , "UAH/USD{mm=EUAM}"
    , "USD/PGK{mm=RADA}"
    , "JPY/PKR{mm=EUAM}"
    , "AED/OMR"
    , "GBP/JMD{mm=COMP}"
    , "CNY/THB{mm=COMP}"
    , "TZS/USD{mm=EUAM}"
    , "HKD/CHF{mm=COMP}"
    , "USD/SRD{mm=EUAM}"
    , "BRL/RUB"
    , "EUR/ILS{mm=DRES}"
    , "CNY/MYR{mm=EUAM}"
    , "EUR/PAB"
    , "NZD/INR{mm=MORN}"
    , "MUR/EUR"
    , "THB/HKD{mm=COMP}"
    , "SOS/GBP{mm=COMP}"
    , "AED/CAD{mm=EUAM}"
    , "GBP/ZAR{mm=MORN}"
    , "USD/HKD:HSFX"
    , "RUB/USD{mm=EUAM}"
    , "EUR/SCR"
    , "PEN/USD"
    , "GBP/QAR{mm=EUAM}"
    , "USD/BDT"
    , "SEK/PLN"
    , "LBP/USD"
    , "USD/CDF{mm=RADA}"
    , "USD/XDR{mm=EUAM}"
    , "SLL/EUR{mm=EUAM}"
    , "CNY/XOF{mm=COMP}"
    , "SGD/TWD{mm=COMP}"
    , "ISK/USD"
    , "UGX/EUR"
    , "AUD/TRY{mm=SAXO}"
    , "GBP/NZD:HSFX"
    , "CNH/GBP{mm=EUAM}"
    , "IDR/INR{mm=EUAM}"
    , "GBP/CNH{mm=CMC}"
    , "ILS/AED{mm=COMP}"
    , "CHF/CNY"
    , "USD/LRD{mm=COMP}"
    , "RWF/USD{mm=COMP}"
    , "TRY/CAD"
    , "DKK/NOK{mm=COMP}"
    , "MGA/EUR{mm=EUAM}"
    , "XAG/GBP{mm=COMP}"
    , "ZAR/BRL"
    , "DKK/GBP{mm=EUAM}"
    , "BRL/USD{mm=COMP}"
    , "XDR/CHF{mm=COMP}"
    , "SEK/HUF{mm=CMC}"
    , "HKD/MXN{mm=EUAM}"
    , "XAG/CNY{mm=COMP}"
    , "KRW/TWD{mm=COMP}"
    , "EUR/BZD{mm=COMP}"
    , "DKK/CNY{mm=EUAM}"
    , "NZD/INR{mm=COMP}"
    , "CZK/GBP"
    , "HKD/MXN"
    , "USD/SCR{mm=RADA}"
    , "GBP/ZAR{mm=COMP}"
    , "AUD/ILS{mm=COMP}"
    , "JPY/XDR{mm=EUAM}"
    , "GBP/BSD{mm=EUAM}"
    , "EUR/SEK"
    , "NOK/JPY{mm=SAXO}"
    , "USD/VND{mm=BNPF}"
    , "ZAR/IDR"
    , "AED/CZK{mm=COMP}"
    , "ZAR/AUD{mm=EUAM}"
    , "ZAR/CDF"
    , "COP/ARS{mm=EUAM}"
    , "GBP/CRC"
    , "XAU/AUD{mm=EUAM}"
    , "CAD/AUD{mm=CMC}"
    , "OMR/PKR{mm=EUAM}"
    , "USD/HRK{mm=DRES}"
    , "CHF/SEK{mm=UBSW}"
    , "NOK/EUR{mm=EUAM}"
    , "XAG/SAR"
    , "USD/SLL{mm=COMP}"
    , "HKD/PKR{mm=EUAM}"
    , "QAR/AED"
    , "IDR/NOK"
    , "AUD/LTL{mm=COMP}"
    , "THB/ZAR{mm=EUAM}"
    , "MGA/GBP"
    , "GBP/RSD{mm=EUAM}"
    , "CNH/CAD"
    , "CDF/USD{mm=COMP}"
    , "BRL/KRW"
    , "MXN/CAD"
    , "EUR/CNH{mm=CMC}"
    , "SGD/JPY{mm=COMP}"
    , "JMD/GBP{mm=COMP}"
    , "USD/JMD"
    , "JPY/BRL"
    , "JPY/PHP{mm=EUAM}"
    , "USD/SVC{mm=COMP}"
    , "NOK/JPY{mm=SURF}"
    , "TRY/AUD{mm=CMC}"
    , "USD/FJD{mm=RADA}"
    , "XAG/KRW{mm=COMP}"
    , "EUR/NGN{mm=COMP}"
    , "HKD/VND{mm=COMP}"
    , "XDR/CHF"
    , "PLN/CAD{mm=CMC}"
    , "USD/KZT{mm=COMP}"
    , "ANG/GBP{mm=EUAM}"
    , "EUR/DKK"
    , "SGD/CZK{mm=CMC}"
    , "CRC/GBP"
    , "EUR/SDG{mm=COMP}"
    , "ILS/CHF"
    , "AED/GBP{mm=COMP}"
    , "NZD/USD{mm=CMC}"
    , "GBP/LRD"
    , "DJF/EUR{mm=EUAM}"
    , "EUR/SEK{mm=SAXO}"
    , "GBP/CNH{mm=RADA}"
    , "AED/CNY{mm=COMP}"
    , "USD/ISK{mm=SURF}"
    , "EUR/GTQ{mm=DRES}"
    , "JPY/IDR"
    , "EUR/SGD"
    , "SLL/USD{mm=EUAM}"
    , "GBP/VES{mm=EUAM}"
    , "KRW/JPY{mm=COMP}"
    , "BDT/EUR{mm=EUAM}"
    , "EUR/TTD"
    , "GBP/NOK{mm=MORN}"
    , "NOK/BRL{mm=COMP}"
    , "PLN/QAR{mm=COMP}"
    , "AMD/EUR{mm=EUAM}"
    , "AUD/ZAR{mm=CMC}"
    , "TRY/HUF{mm=COMP}"
    , "USD/DJF{mm=RADA}"
    , "GBP/NPR{mm=COMP}"
    , "GBP/UYU"
    , "M5P/USD{mm=UBSW}"
    , "CNH/IDR{mm=COMP}"
    , "AUD/ARS{mm=EUAM}"
    , "EUR/SEK{mm=SURF}"
    , "CHF/PLN"
    , "DKK/MXN"
    , "ZAR/TRY{mm=CMC}"
    , "TWD/MYR"
    , "ZAR/LAK"
    , "DKK/NZD{mm=CMC}"
    , "MGA/USD{mm=EUAM}"
    , "CAD/THB{mm=EUAM}"
    , "ARE/CHF"
    , "NZD/CAD"
    , "GBP/UYU{mm=EUAM}"
    , "ISK/GBP{mm=EUAM}"
    , "USD/PEN{mm=EUAM}"
    , "ZAR/HUF{mm=COMP}"
    , "DKK/UGX{mm=COMP}"
    , "NPR/EUR"
    , "USD/EGP{mm=DRES}"
    , "GBP/CHF{mm=CMC}"
    , "CZK/EUR{mm=CMC}"
    , "CNH/INR{mm=RADA}"
    , "ZAR/THB"
    , "GTQ/EUR{mm=COMP}"
    , "USD/JOD{mm=COMP}"
    , "BRL/JPY"
    , "ZAR/LKR{mm=EUAM}"
    , "GBP/HUF"
    , "NGN/USD"
    , "KRW/MYR"
    , "IDR/MYR"
    , "NOK/RUB{mm=EUAM}"
    , "GBP/NOK{mm=COMP}"
    , "USD/CHF{mm=DRES}"
    , "EUR/MXN{mm=MORN}"
    , "NOK/USD{mm=EUAM}"
    , "HKD/PHP{mm=EUAM}"
    , "UAH/DKK"
    , "BRL/COP{mm=COMP}"
    , "ZAR/RWF"
    , "ZAR/MGA{mm=COMP}"
    , "ILS/EUR{mm=EUAM}"
    , "ZAR/EUR"
    , "NZD/AED{mm=COMP}"
    , "GBP/CNY{mm=EUAM}"
    , "MYR/SGD{mm=COMP}"
    , "GBP/BYN"
    , "GBP/UGX"
    , "IDR/ISK"
    , "SGD/JPY{mm=BNPF}"
    , "RUB/JPY{mm=COMP}"
    , "USD/MGA"
    , "EUR/ALL"
    , "INR/HKD{mm=EUAM}"
    , "HUF/NZD{mm=COMP}"
    , "DKK/MXN{mm=CMC}"
    , "MAD/EUR{mm=EUAM}"
    , "SEK/CAD"
    , "FJD/GBP"
    , "SEK/NOK{mm=CMC}"
    , "USD/THB{mm=COMP}"
    , "XAGK/CHF"
    , "GYD/SRD{mm=EUAM}"
    , "GBP/RON{mm=EUAM}"
    , "BRL/VES{mm=SURF}"
    , "EUR/DKK{mm=SAXO}"
    , "DKK/PLN{mm=EUAM}"
    , "AED/EUR"
    , "DJF/USD{mm=EUAM}"
    , "VES/EUR{mm=COMP}"
    , "KES/CHF{mm=EUAM}"
    , "JPY/THB"
    , "NZD/SGD{mm=EUAM}"
    , "VRN/CHF{mm=UBSW}"
    , "USD/MVR{mm=RADA}"
    , "BOB/EUR"
    , "RUB/ZAR"
    , "USD/BHD{mm=COMP}"
    , "USD/XOF{mm=COMP}"
    , "MXN/CHF{mm=MORN}"
    , "EUR/CHF{mm=CMC}"
    , "EUR/MXN{mm=COMP}"
    , "BWP/EUR{mm=COMP}"
    , "HKD/SEK{mm=EUAM}"
    , "BDT/USD{mm=EUAM}"
    , "GBP/LYD{mm=COMP}"
    , "BMD/CAD{mm=EUAM}"
    , "AMD/USD{mm=EUAM}"
    , "GBP/ANG"
    , "PHP/EUR"
    , "GBP/BOB"
    , "GBP/KRW{mm=EUAM}"
    , "EUR/DKK{mm=SURF}"
    , "JPY/EUR"
    , "USD/KES{mm=EUAM}"
    , "ZAR/MOP{mm=COMP}"
    , "EUR/KYD{mm=COMP}"
    , "MOP/EUR"
    , "MMK/GBP"
    , "CNY/HKD{mm=EUAM}"
    , "HKD/AUD{mm=CMC}"
    , "ZAR/ETB{mm=COMP}"
    , "JPY/THB{mm=COMP}"
    , "GBP/ZAR"
    , "EUR/TRY{mm=DRES}"
    , "VRL/CHF{mm=UBSW}"
    , "DKK/SGD{mm=SAXO}"
    , "PLN/CZK{mm=COMP}"
    , "EUR/PKR{mm=COMP}"
    , "EUR/RSD"
    , "JPY/MYR{mm=EUAM}"
    , "GBP/PYG{mm=EUAM}"
    , "ZAR/HRK"
    , "CAD/CHF:HSFX"
    , "HKD/ARS"
    , "CHF/MXN{mm=EUAM}"
    , "GTQ/USD{mm=COMP}"
    , "BDT/GBP"
    , "MXN/CHF{mm=CMC}"
    , "BBD/SRD{mm=COMP}"
    , "USD/LAK{mm=RADA}"
    , "BRL/GBP{mm=SURF}"
    , "ZAR/QAR"
    , "AED/PLN{mm=COMP}"
    , "MXN/CHF{mm=COMP}"
    , "USD/NIO{mm=COMP}"
    , "AMD/USD"
    , "SGD/ZAR{mm=COMP}"
    , "HRK/CAD{mm=EUAM}"
    , "SGD/NOK"
    , "SZL/GBP{mm=COMP}"
    , "DOP/EUR{mm=EUAM}"
    , "HUF/AUD{mm=COMP}"
    , "EUR/JPY{mm=UBSW}"
    , "ILS/USD{mm=EUAM}"
    , "XDR/AUD"
    , "THB/HKD"
    , "GBP/UGX{mm=COMP}"
    , "HKD/THB{mm=MORN}"
    , "RUB/CNY"
    , "QAR/ZAR{mm=COMP}"
    , "AED/QAR"
    , "GBP/IDR{mm=COMP}"
    , "PLN/GBP{mm=COMP}"
    , "ILS/AUD"
    , "CHF/PKR{mm=EUAM}"
    , "NOK/SGD{mm=CMC}"
    , "MAD/USD{mm=EUAM}"
    , "USD/MUR{mm=SURF}"
    , "CNY/CLP{mm=EUAM}"
    , "EUR/HNL{mm=DRES}"
    , "CHF/SZL{mm=COMP}"
    , "USD/THB{mm=BNPF}"
    , "AUD/CNH"
    , "AUD/MXN{mm=SAXO}"
    , "AED/NZD{mm=EUAM}"
    , "KRW/ZAR{mm=COMP}"
    , "USD/ILS{mm=SAXO}"
    , "AUD/TRY{mm=COMP}"
    , "VES/USD{mm=COMP}"
    , "USD/SOS"
    , "EUR/SRD{mm=COMP}"
    , "GBP/HUF{mm=CMC}"
    , "GBP/AMD"
    , "THB/IDR{mm=EUAM}"
    , "CHF/CAD"
    , "CLP/CLF{mm=CBOC}"
    , "USD/LBP{mm=EUAM}"
    , "GBP/CNY"
    , "ZAR/XDR"
    , "HKD/DKK{mm=EUAM}"
    , "MYR/EUR{mm=COMP}"
    , "LAK/GBP{mm=COMP}"
    , "SAR/KWD"
    , "ZWD/GBP{mm=COMP}"
    , "BWP/USD{mm=COMP}"
    , "BMD/GBP{mm=COMP}"
    , "GBP/BZD"
    , "XAG/TRY"
    , "XAG/AUD{mm=EUAM}"
    , "PLN/JPY"
    , "EUR/XDR{mm=COMP}"
    , "EUR/SZL"
    , "SGD/CNH{mm=RADA}"
    , "JOD/AED"
    , "BRL/SEK"
    , "HRK/CZK{mm=COMP}"
    , "NZD/JPY{mm=SAXO}"
    , "HKD/THB{mm=COMP}"
    , "THB/CNH{mm=COMP}"
    , "CNY/BRL"
    , "XOF/ZAR{mm=COMP}"
    , "HKD/MYR{mm=EUAM}"
    , "TWD/INR{mm=EUAM}"
    , "NSO/USD"
    , "USD/XAF{mm=COMP}"
    , "IDR/SEK{mm=COMP}"
    , "EUR/SEK{mm=MORN}"
    , "EUR/PHP{mm=COMP}"
    , "ZAR/HRK{mm=EUAM}"
    , "NOK/JPY{mm=COMP}"
    , "ARS/AUD{mm=COMP}"
    , "AFN/USD"
    , "CNY/IDR"
    , "NZD/JPY{mm=SURF}"
    , "EUR/BZD{mm=DRES}"
    , "TZS/ZAR{mm=COMP}"
    , "SGD/MYR"
    , "CHF/XDR{mm=EUAM}"
    , "BRL/DKK"
    , "PLN/HUF{mm=SAXO}"
    , "XDR/DKK{mm=EUAM}"
    , "NOK/USD"
    , "USD/PGK"
    , "KZT/GBP{mm=COMP}"
    , "JPY/XDR"
    , "JOD/ILS{mm=EUAM}"
    , "USD/SGD{mm=CMC}"
    , "CAD/AUD{mm=SURF}"
    , "SGD/AED"
    , "MKD/USD"
    , "SAR/PKR{mm=EUAM}"
    , "USD/ISK{mm=COMP}"
    , "EUR/HUF{mm=CMC}"
    , "FJD/TOP{mm=COMP}"
    , "DOP/USD{mm=EUAM}"
    , "BRL/SGD"
    , "GBP/SGD{mm=SAXO}"
    , "SGD/NOK{mm=COMP}"
    , "RON/CHF{mm=MORN}"
    , "AED/AUD{mm=EUAM}"
    , "CHF/PHP{mm=EUAM}"
    , "GYD/USD"
    , "SGD/GBP{mm=EUAM}"
    , "TOP/GBP{mm=EUAM}"
    , "NZD/BRL{mm=COMP}"
    , "ZAR/DZD{mm=COMP}"
    , "CZK/HKD"
    , "EUR/SEK{mm=COMP}"
    , "RUB/PLN"
    , "BDT/JPY{mm=COMP}"
    , "SGD/CNY{mm=EUAM}"
    , "EUR/NGN{mm=DRES}"
    , "DKK/HUF{mm=EUAM}"
    , "GBP/BYN{mm=COMP}"
    , "ETB/ZAR"
    , "ARS/CLP"
    , "QAR/GBP{mm=EUAM}"
    , "CHF/BWP"
    , "USD/NGN"
    , "GBP/SGD{mm=SURF}"
    , "BHD/USD"
    , "NGN/JPY{mm=EUAM}"
    , "CAD/USD{mm=CMC}"
    , "SGD/DKK{mm=CMC}"
    , "MXN/HUF{mm=CMC}"
    , "MYR/USD{mm=COMP}"
    , "XDR/GBP"
    , "BMD/EUR"
    , "CZK/NZD{mm=CMC}"
    , "GBP/PLN"
    , "BRL/CAD{mm=EUAM}"
    , "BRL/RSD{mm=COMP}"
    , "TRY/CHF{mm=EUAM}"
    , "NOK/ILS"
    , "RON/CHF{mm=COMP}"
    , "KRW/CNY{mm=EUAM}"
    , "ZAR/EGP{mm=EUAM}"
    , "TRY/USD{mm=CMC}"
    , "CRC/EUR{mm=EUAM}"
    , "EUR/PLN:HSFX"
    , "AUD/INR{mm=EUAM}"
    , "USD/RON"
    , "GBP/GNF"
    , "IDR/DKK{mm=COMP}"
    , "EUR/DKK{mm=MORN}"
    , "SGD/KRW{mm=EUAM}"
    , "ZAR/CHF{mm=EUAM}"
    , "EUR/PEN{mm=COMP}"
    , "CNY/THB"
    , "USD/AOA{mm=EUAM}"
    , "BGN/AUD"
    , "EUR/NOK"
    , "ILS/JPY{mm=COMP}"
    , "NOK/SEK:HSFX"
    , "CLP/BRL{mm=COMP}"
    , "GBP/NOK{mm=CMC}"
    , "INR/KRW"
    , "XOF/CNY{mm=EUAM}"
    , "EUR/HKD{mm=SAXO}"
    , "IDR/MYR{mm=COMP}"
    , "USD/TTD{mm=COMP}"
    , "EUR/MTL{mm=EUAM}"
    , "AED/HUF{mm=COMP}"
    , "USD/KHR{mm=EUAM}"
    , "USD/JOD{mm=DRES}"
    , "THB/NZD"
    , "DKK/UAH{mm=EUAM}"
    , "CZK/TRY{mm=COMP}"
    , "PEN/EUR{mm=COMP}"
    , "TTD/USD{mm=EUAM}"
    , "CNY/EUR"
    , "AUD/SEK{mm=SAXO}"
    , "USD/HTG{mm=EUAM}"
    , "DKK/USD{mm=SAXO}"
    , "CZK/MXN{mm=CMC}"
    , "ZAR/ETB"
    , "COP/USD"
    , "ZAR/MAD{mm=EUAM}"
    , "PLN/SEK"
    , "GHS/USD"
    , "RUB/NOK{mm=COMP}"
    , "ETB/EUR{mm=COMP}"
    , "JPY/HKD{mm=EUAM}"
    , "EUR/HKD{mm=SURF}"
    , "BZD/EUR"
    , "EUR/DKK{mm=COMP}"
    , "NOK/QAR{mm=EUAM}"
    , "RUB/GBP{mm=EUAM}"
    , "USD/CLP{mm=EUAM}"
    , "USD/STN{mm=RADA}"
    , "EUR/NOK:HSFX"
    , "NOK/EUR{mm=CMC}"
    , "CAD/NZD{mm=MORN}"
    , "EUR/SEK{mm=BNPF}"
    , "MGA/ZAR{mm=COMP}"
    , "BND/GBP"
    , "RUB/CNY{mm=EUAM}"
    , "AUD/SEK{mm=SURF}"
    , "SGD/IDR{mm=COMP}"
    , "USD/THB{mm=DRES}"
    , "ZAR/COP"
    , "TZS/ZAR"
    , "MUR/USD"
    , "EUR/MYR{mm=COMP}"
    , "GBP/EUR{mm=SAXO}"
    , "NZD/ZAR{mm=SAXO}"
    , "CNH/SGD{mm=COMP}"
    , "RWF/GBP{mm=COMP}"
    , "BRL/RSD"
    , "INR/CNH{mm=EUAM}"
    , "TWD/PKR{mm=COMP}"
    , "IDR/TWD{mm=EUAM}"
    , "GYD/SRD"
    , "UGX/USD"
    , "EUR/XAF"
    , "PLN/DKK"
    , "COP/MXN{mm=COMP}"
    , "HKD/HUF"
    , "VND/EUR{mm=EUAM}"
    , "EUR/NOK{mm=CMC}"
    , "BRL/GBP{mm=COMP}"
    , "AUD/TRY"
    , "EUR/GTQ"
    , "USD/SGD:HSFX"
    , "EUR/MXN{mm=DRES}"
    , "HRK/PLN{mm=COMP}"
    , "TWD/IDR"
    , "CHF/DKK{mm=EUAM}"
    , "BRL/CNY{mm=COMP}"
    , "CAD/ILS{mm=EUAM}"
    , "USD/KES"
    , "CRC/USD{mm=EUAM}"
    , "KRW/BRL"
    , "THB/INR{mm=COMP}"
    , "ARS/MXN"
    , "ZAR/SZL{mm=EUAM}"
    , "NOK/ZAR{mm=COMP}"
    , "KRW/IDR{mm=COMP}"
    , "DKK/SGD{mm=COMP}"
    , "PLN/SGD"
    , "USD/PLN:HSFX"
    , "USD/SAR{mm=EUAM}"
    , "GHS/EUR{mm=COMP}"
    , "KWD/AED"
    , "GBP/EUR{mm=SURF}"
    , "JPY/CLP{mm=EUAM}"
    , "INR/JPY"
    , "EUR/KES{mm=COMP}"
    , "EUR/MYR"
    , "CAD/NZD{mm=COMP}"
    , "CHF/THB{mm=COMP}"
    , "MXN/PEN{mm=EUAM}"
    , "EUR/KYD{mm=DRES}"
    , "USD/ILS{mm=MORN}"
    , "ZAR/VND{mm=EUAM}"
    , "RUB/KRW{mm=EUAM}"
    , "NZD/TWD{mm=COMP}"
    , "USD/TRY{mm=SAXO}"
    , "USD/CHF"
    , "CHF/MYR{mm=EUAM}"
    , "EUR/AED"
    , "USD/MUR{mm=COMP}"
    , "SGD/PLN{mm=EUAM}"
    , "DJF/GBP"
    , "GBP/BND{mm=EUAM}"
    , "NZD/USD:HSFX"
    , "USD/IQD{mm=EUAM}"
    , "KRW/IDR"
    , "EUR/PKR{mm=DRES}"
    , "HUF/HRK{mm=COMP}"
    , "EUR/ISK"
    , "GBP/XOF"
    , "SEK/CAD{mm=COMP}"
    , "CDF/GBP{mm=COMP}"
    , "MXN/NOK{mm=CMC}"
    , "EGP/JPY"
    , "EUR/TWD{mm=EUAM}"
    , "PEN/USD{mm=COMP}"
    , "AUD/DKK{mm=SAXO}"
    , "NZD/JPY{mm=MORN}"
    , "AUD/AED{mm=EUAM}"
    , "GBP/MGA{mm=EUAM}"
    , "USD/EUR{mm=CMC}"
    , "RUB/CAD"
    , "USD/TRY{mm=SURF}"
    , "QAR/EUR"
    , "BRL/KRW{mm=COMP}"
    , "HKD/USD{mm=CMC}"
    , "PLN/HKD{mm=CMC}"
    , "CAD/AUD{mm=MORN}"
    , "CZK/NZD"
    , "EUR/PYG"
    , "MXN/DKK{mm=EUAM}"
    , "JPY/SAR{mm=EUAM}"
    , "DKK/TRY{mm=CMC}"
    , "ETB/USD{mm=COMP}"
    , "KYD/CAD"
    , "AUD/MXN{mm=COMP}"
    , "IDR/JPY{mm=EUAM}"
    , "MUR/ZAR{mm=EUAM}"
    , "NOK/CNH{mm=RADA}"
    , "XAU/AUD"
    , "USD/ILS{mm=COMP}"
    , "EUR/DKK{mm=BNPF}"
    , "USD/NOK:HSFX"
    , "M5P/USD"
    , "NGN/ZAR{mm=EUAM}"
    , "SLL/GBP{mm=EUAM}"
    , "AUD/DKK{mm=SURF}"
    , "ZAR/PHP"
    , "USD/PKR"
    , "NPR/EUR{mm=COMP}"
    , "USD/GNF{mm=RADA}"
    , "HKD/ZAR"
    , "CHF/GBP{mm=CMC}"
    , "DKK/HUF"
    , "PLN/HUF{mm=MORN}"
    , "GBP/UAH{mm=EUAM}"
    , "GBP/CAD"
    , "CNY/XDR"
    , "EGP/PKR{mm=EUAM}"
    , "HUF/CHF{mm=MORN}"
    , "BGN/DKK{mm=COMP}"
    , "GBP/USD{mm=SAXO}"
    , "USD/PAB{mm=EUAM}"
    , "USD/DOP{mm=RADA}"
    , "MXN/PEN"
    , "NZD/JPY{mm=COMP}"
    , "AUD/PKR{mm=COMP}"
    , "MGA/GBP{mm=EUAM}"
    , "DKK/EUR{mm=MORN}"
    , "ZMW/EUR{mm=EUAM}"
    , "VND/USD{mm=EUAM}"
    , "EUR/LBP{mm=COMP}"
    , "TWD/THB"
    , "GBP/SGD{mm=MORN}"
    , "EUR/YER"
    , "DKK/UGX"
    , "GBP/MOP{mm=EUAM}"
    , "TRY/RUB"
    , "CAD/AUD{mm=COMP}"
    , "NZD/NOK{mm=SAXO}"
    , "AUD/CHF:HSFX"
    , "EUR/XDR{mm=DRES}"
    , "DOP/EUR"
    , "GBP/ETB{mm=EUAM}"
    , "CNY/TRY{mm=COMP}"
    , "EUR/JPY{mm=EUAM}"
    , "GHS/USD{mm=COMP}"
    , "JPY/ILS{mm=COMP}"
    , "GBP/USD{mm=SURF}"
    , "ILS/ZAR{mm=COMP}"
    , "AUD/USD:HSFX"
    , "CLP/COP{mm=EUAM}"
    , "MYR/ZAR"
    , "CNH/EUR{mm=COMP}"
    , "TWD/EUR"
    , "JPY/PHP"
    , "CAD/RON"
    , "AWG/USD"
    , "NPR/USD"
    , "HRK/AUD{mm=EUAM}"
    , "PLN/HUF{mm=COMP}"
    , "KRW/THB"
    , "MAD/ZAR{mm=COMP}"
    , "IDR/THB"
    , "RUB/PLN{mm=EUAM}"
    , "CNH/TWD{mm=RADA}"
    , "NOK/GBP{mm=EUAM}"
    , "MTL/GBP{mm=COMP}"
    , "NZD/NOK{mm=SURF}"
]
export default tickerList