import React from "react";
import Modal from '@mui/material/Modal';

import VerticalCard from "../homePage/VerticalCard";
import { cardData } from "./cardData";

const CurrencyStrengthModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="currency-strength-modal">
        <VerticalCard
          card={cardData}
          from='currency-strength'
        />
      </div>
    </Modal>
  )
}

export default CurrencyStrengthModal;