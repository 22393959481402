import { all, put, takeEvery, fork, select } from "redux-saga/effects";
import moment from "moment";
import { generateBars } from "../../components/TradingView/candleControl";
// Action Types
export const OPTION_SPARKLINE_LOAD = "OS/LOAD/TOP";
export const OPTION_SPARKLINE_LOAD_SUCCESS = "OS/LOAD/TOP/SUCCESS";
export const OPTION_SPARKLINE_LOAD_FAILURE = "OS/LOAD/TOP/FAILURE";

export const optionSparklineLoad = (symbol, timeline) => ({
  type: OPTION_SPARKLINE_LOAD,
  symbol,
  timeline,
});
export const optionSparklineLoadSuccess = (data, loading) => ({
  type: OPTION_SPARKLINE_LOAD_SUCCESS,
  data,
  loading,
});
export const optionSparklineLoadFailure = (error) => ({
  type: OPTION_SPARKLINE_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchOptionSparkline(action) {
  try {
    // expects date as MM/DD/YYYY
    const liveState = yield select((state) => state.optionSparkline);
    const { symbol, timeline } = action;
    yield put(
      optionSparklineLoadSuccess({ optionSparkline: { ...liveState.optionSparkline }, loading: true })
    );
    let resolution, timespan, fromTime, toTime;
    toTime = moment().valueOf();
    switch (timeline) {
      case "1D":
        resolution = 1;
        timespan = "m";
        fromTime = moment().subtract(1, "day").valueOf();
        break;
      case "5D":
        resolution = 5;
        timespan = "m";
        fromTime = moment().subtract(5, "day").valueOf();
        break;
      case "1M":
        resolution = 15;
        timespan = "m";
        fromTime = moment().subtract(1, "month").valueOf();
        break;
      case "6M":
        resolution = 1;
        timespan = "h";
        fromTime = moment().subtract(6, "month").valueOf();
        break;
      case "YTD":
        resolution = 1;
        timespan = "h";
        fromTime = moment().startOf("year").valueOf();
        break;
      case "1Y":
        resolution = 1;
        timespan = "h";
        fromTime = moment().subtract(1, "year").valueOf();
        break;
      case "5Y":
        resolution = 1;
        timespan = "h";
        fromTime = moment().subtract(5, "year").valueOf();
        break;
      case "Max":
        resolution = 1;
        timespan = "h";
        fromTime = moment().subtract(100, "year").valueOf();
        break;
      default:
        resolution = 5;
        timespan = "m";
        break;
    }
    const bars = yield generateBars(true, symbol, resolution, timespan, fromTime, toTime)
    yield put(
      optionSparklineLoadSuccess({
        optionSparkline: {
          ...liveState.optionSparkline,
          [symbol]: bars
        },
        loading: false,
      })
    );
  } catch (error) {
    yield put(optionSparklineLoadFailure(error));
  }
}

function* listenOptionSparklineLoad() {
  yield takeEvery(OPTION_SPARKLINE_LOAD, fetchOptionSparkline);
}

// Root Saga
export function* saga() {
  yield all([fork(listenOptionSparklineLoad)]);
}

const INIT_STATE = {
  optionSparkline: [],
  loading: false,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPTION_SPARKLINE_LOAD_SUCCESS:
      return {
        ...state,
        optionSparkline: action.data.optionSparkline,
        loading: action.data.loading,
      };
    default:
      return state;
  }
};

export default reducer;
