import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/base';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
const groupedOptions = [{
    value: '1',
    title: "Above Ask"
}, {
    value: '2',
    title: "At Ask"
},
{
    value: '3',
    title: "Lean Ask"
},
{
    value: '4',
    title: "Below Bid"
}, 
{
    value: '5',
    title: "At Bid"
},
{
    value: '6',
    title: "Lean Bid"
},
{
    value: '7',
    title: "In Between"
},

]
const Root = styled('div')(
    ({ theme }) => `
  color: white;
  font-size: 14px;
`,
);


const InputWrapper = styled('div')(
    ({ theme }) => `
  min-width: 230px;
  width: 100%;
  z-index: 10;
  border: 1px solid #434343;
  background-color: #141414;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color:#177ddc;
  }

  &.focused {
    border-color: #177ddc;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color:#141414;
    color:rgba(255,255,255,0.65);
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

function Tag(props) {
    const { label, onDelete, ...other } = props;
    return (
        <div {...other}>
            <span>{label}</span>
            <CloseIcon onClick={onDelete} />
        </div>
    );
}

Tag.propTypes = {
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
    ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: rgba(255,255,255,0.08);
  border: 1px solid #303030;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color:#177ddc;
    background-color: #003b57;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

const Listbox = styled('ul')(
    ({ theme }) => `
  width: 300px;
  z-index: 1;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #141414};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  & li.${autocompleteClasses.focused} {
    background-color:#003b57;
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

function BidAskAutoComplete({ bidAsk, setBidAsk }) {
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        value,
        focused,
        setAnchorEl,
    } = useAutocomplete({
        id: 'bid-ask-auto-complete',
        multiple: true,
        options: groupedOptions,
        getOptionLabel: (option) => option.title,
    });
    useEffect(() => {
        const arr = value?.map((i) => i?.value || '')
        setBidAsk(arr)
    }, [value])
    return (
        <Root className='autocomplete-lo' style={{ position: 'relative', top: '7px' }}>
            <div {...getRootProps()} className="input-wrapper">
                <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
                    {bidAsk.map((option, index) => {
                        const tagProps = {
                            ...getTagProps({ index }),
                            onDelete: () => {
                                const removed = bidAsk.filter((item) => option !== item)
                                setBidAsk(removed)
                            }
                        }
                        if (!!option) {
                            return (
                                <StyledTag label={groupedOptions.find((item) => item.value === option).title || ''} {...tagProps} />
                            )
                        }
                    })}
                    <input placeholder={!bidAsk.length ? 'Bid Ask Filter' : ''} {...getInputProps()} />
                </InputWrapper>
            </div>
            {focused ? (
                <Listbox {...getListboxProps()}>
                    {groupedOptions.map((option, index) => {
                        const optionProps = {
                            ...getOptionProps({ option, index }),
                            'aria-selected': bidAsk.includes(option.value),
                            onClick: () => {
                                const val = option.value;
                                if (bidAsk.includes(val)) {
                                    const removed = bidAsk.filter((item) => val !== item)
                                    setBidAsk(removed)
                                } else {
                                    setBidAsk([...bidAsk, val].filter(Boolean))
                                }
                            }
                        }
                        return (
                            <li {...optionProps}>
                                <span>{option?.title}</span>
                                <CheckIcon fontSize="small" />
                            </li>
                        )
                    })}
                </Listbox>
            ) : null}
        </Root>
    );
}


export default BidAskAutoComplete