import { makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.primary.skeletonBackground,
    // width: 'auto', // allow table to stretch full width
    flexGrow: 1, // allow table to stretch full width
    '& > div': {
      height: 'auto !important', // override zero height from DataGrid
      width: 'auto !important', // override zero width from DataGrid
    },
    '& .MuiDataGrid-root': {
      overflow: 'auto', // set table to be responsive
      fontSize: '0.9rem',
    },
  },
  skeleton: {
    backgroundColor: theme.palette.primary.skeleton,
    height: '6em',
    margin: "10px 0",
  }
}));

export const TableSkeleton = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      <br />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
      <Skeleton className={classes.skeleton} variant="rect" height={50} />
    </Paper>
  );
};
