import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./infoModal.css";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import BarChartIcon from "@mui/icons-material/BarChart";
import { styled } from "@mui/material/styles";
import { Tooltip, tooltipClasses } from "@mui/material";
import moment from "moment";

const style = {
  width: "100%",
  height: "100%",
  maxHeight: "250px",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "auto",
};
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} enterDelay={200} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 900,
    fontSize: theme.typography.pxToRem(12),
    // border: "1px solid #dadde9",
  },
}));

export default function BasicModal({ date, children, data, fetching, timezone }) {
  if (data !== undefined) {
    return (
      <HtmlTooltip
        title={
          <Box sx={style}>
            <table
              id="info__modal__table"
              style={{ width: "100%", height: "100%", backgroundColor: "#fff" }}
            >
              <thead>
                <tr id="header__row">
                  <th colSpan={3}>{date}</th>
                  <th>Actual</th>
                  <th>Previous</th>
                  <th>Consensus</th>
                  <th colSpan={2}>Forecast</th>
                </tr>
              </thead>
              <tbody>
                {data.filter((item) => !!item.last_update).map((eachEvent, idx) => {
                  return (
                    <tr key={idx}>
                      <td id="time__info__modal">{moment.tz(eachEvent.last_update, timezone).format('HH:mm')}</td>
                      <td className="bold__content__modal">
                        <FlagCircleIcon /> {eachEvent.country}
                      </td>
                      <td className="bold__content__modal">
                        {eachEvent.event}
                      </td>
                      <td className="bold__content__modal">
                        {eachEvent.actual}
                      </td>
                      <td>{eachEvent.previous}</td>
                      <td className="bold__content__modal">
                        {eachEvent.previous}
                      </td>
                      <td className="bold__content__modal">
                        {eachEvent.forecast}
                      </td>
                      <td>
                        <BarChartIcon style={{ color: "#00DC0A" }} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        }
      >
        {children}
      </HtmlTooltip>
    );
  }
}
