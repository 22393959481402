import React, { useEffect, useMemo, useState } from "react";
import DocusignApproveModal from './DocusignApprove'
import moment from "moment";
import Cookies from 'universal-cookie'
import { useAuth } from "../../../contexts/Auth";
import { useLocation } from 'react-router-dom'

function DocusignModal(props) {
  const cookies = new Cookies()
  let agreement_signed_cookie = useMemo(() => {
    return cookies.get('agreement_signed_new') === 'true'
    // eslint-disable-next-line
  }, [cookies.get('agreement_signed_new')])
  const [modalVisible, setModalVisible] = useState(false)

  const { userData, user } = useAuth()
  const search = useLocation().search
  let agreement_signed = localStorage.getItem('agreement_signed_new')
  function setCookie() {
    cookies.set('agreement_signed_new', 'true', { path: '/', maxAge: 1800 })
  }

  useEffect(() => {
    if (!!userData) {
      if ((userData?.agreement_signed_new || false) === false && moment(user?.metadata?.creationTime || '').isAfter(moment("2023-03-27"))) {
        const event = new URLSearchParams(search).get('event')
        if ((!!event && event === 'signing_complete') || agreement_signed) {
          localStorage.setItem('agreement_signed_new', true)
          setCookie()
        } else {
          if (!agreement_signed && !agreement_signed_cookie && moment(user?.metadata?.creationTime || '').isAfter(moment("2023-03-27"))) {
            // if both the cookie and localstorage are false, show the modal
            setModalVisible(true)
            document.getElementsByTagName("body")[0].classList.add("scroll-freeze")
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(userData), agreement_signed, user])

  return (
    <DocusignApproveModal setCookie={setCookie} userData={userData} modalVisible={modalVisible && userData} setModalVisible={setModalVisible} />
  );
}

export default DocusignModal;