import { all, call, put, takeEvery, fork, delay } from 'redux-saga/effects';
import { apiURL } from '../../appRedux/actions/helpers';
import axios from 'axios';
import moment from 'moment';

function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

// Action Types
export const MARKET_STATUS_CALCULATE = 'MARKET_STATUS/CALCULATE';
export const MARKET_STATUS_SET = 'MARKET_STATUS/SET';

// Action Creators
export const marketstatusCalculate = () => {
  return {
    type: MARKET_STATUS_CALCULATE,
  }
}
export const marketstatusSet = (marketStatus) => {
  return {
    type: MARKET_STATUS_SET,
    marketStatus
  }
}

let timeToMarketChange = 1;

const marketStatusByTime = (state) => {
  const currentTime = moment().tz("America/New_York").format("HH:mm:ss");
  const currentDate = moment(currentTime, "HH:mm:ss").tz("America/New_York");

  if (currentDate.isBetween(moment('16:00:00', "HH:mm:ss").tz("America/New_York"), moment('20:00:00', "HH:mm:ss").tz("America/New_York"))) {
    // After Hours (4pm ~ 8pm )
    return 'after';
  }

  if (currentDate.isBetween(moment('20:00:00', "HH:mm:ss").tz("America/New_York"), moment('24:00:00', "HH:mm:ss").tz("America/New_York"))) {
    // Market Closed Hours ( 8pm ~ 4am )
    return 'closed';
  }

  if (currentDate.isBetween(moment('00:00:00', "HH:mm:ss").tz("America/New_York"), moment('04:00:00', "HH:mm:ss").tz("America/New_York"))) {
    // Market Closed Hours ( 8pm ~ 4am )
    return 'closed';
  }

  if (currentDate.isBetween(moment('09:30:00', "HH:mm:ss").tz("America/New_York"), moment('16:00:00', "HH:mm:ss").tz("America/New_York"))) {
    // Market open hours ( 9:30am ~ 4pm )
    return 'open';
  }

  if (currentDate.isBetween(moment('04:00:00', "HH:mm:ss"), moment('09:30:00', "HH:mm:ss"))) {
    // Market pre-open hours ( 4am ~ 9:30am )
    return 'pre-open';
  }
  return state;
}

function* marketStatusCalculation(action) {
  const closedData = yield axios.get(
    `${apiURL}/security/market/status`,
    {
      withCredentials: true
    }
  );
  let marketStatus;
  if (closedData?.data?.ttmc) {
    timeToMarketChange = closedData?.data?.ttmc;
  }
  if (closedData?.data?.state === 'closed') {
    marketStatus = 'closed';
  } else {
    marketStatus = marketStatusByTime(closedData.data.state);
  }
  return yield put(marketstatusSet(marketStatus));
}

function* marketUpdateLoop() {
  while (timeToMarketChange) {
    yield delay(timeToMarketChange + randomNumber(0, 2000));
    yield call(marketStatusCalculation);
  }
}

function* listenMarketStatus() {
  yield takeEvery(MARKET_STATUS_CALCULATE, marketStatusCalculation);
}

// Root Saga
export function* saga() {
  yield all([fork(listenMarketStatus), fork(marketUpdateLoop)]);
}

const INIT_STATE = {
  marketStatus: marketStatusByTime()
};


// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MARKET_STATUS_SET:
      return {
        marketStatus: action.marketStatus
      }
    default:
      return state;
  }
};

export default reducer;
