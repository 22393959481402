import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import LayoutContext from "../LayoutContext";
import "./sidebarStyles.scss";
import "./private-layout.css";
import "./styles.css";
import { useAuth } from "../../../../contexts/Auth";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { Layout, Grid } from "antd";
import HomeHeader from "../../../company/HomeHeader";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
// import CopyrightFooter from "../CopyrightFooter";
import Sidebar from "./Sidebar";
import { analytics } from "../../../../firebase/firebase";
// import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.innerBackground,

    height: "100%",
    flexGrow: 1,
  },
  appBannerLight: {
    fontSize: "16px",
    backgroundColor: "#bbc1ca !important",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    position: "sticky",
    top: 0,
    zIndex: 99999999,
  },
  appBannerDark: {
    fontSize: "16px",
    backgroundColor: "#3c3c4a !important",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    position: "sticky",
    top: 0,
    zIndex: 99999999,
  },
}));
const { Content } = Layout;
const { useBreakpoint } = Grid;
const PrivateLayout = (props) => {
  const { children, name, from, noLayout, type, liveStream, themeMode } = props;
  const classes = useStyles();
  const { authUser, authToken, fetchUser } = useAuth();
  const [isActivePopUp, setIsActivePopUp] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const location = useLocation();
  const userPopupRef = useRef();
  const userPopupTriggerRef = useRef();
  const userPopupTriggerMbRef = useRef();
  const { lg } = useBreakpoint();
  useOnClickOutside(
    userPopupRef,
    () => {
      if (isActivePopUp) {
        setIsActivePopUp(false);
      }
    },
    !lg ? userPopupTriggerMbRef : userPopupTriggerRef
  );

  useEffect(() => {
    setIsActivePopUp(false);

    analytics.logEvent(location.pathname + " PAGE_VISITED");
  }, [location]);
  useEffect(() => {
    if (authUser && authToken) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, []);

  if (noLayout) {
    return children;
  }

  const onChangeDarkMode = (checked) => {
    setIsDark(checked);
  };

  return (
    <LayoutContext.Provider value={{ isActivePopUp, setIsActivePopUp }}>
      <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} />
      <Layout
        id="layout-wrapper"
        className={clsx(classes.background, `content-in-layout`)}
      >
        <Content
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          className={`main-content-${themeMode} main-content`}
        >
          {type !== "internal" && (
            <HomeHeader
              isDark={isDark}
              onChangeDarkMode={onChangeDarkMode}
              name={name}
              from={from}
            />
          )}
          {/*<img src={dashboardBottomGradientImg} alt="bottom_gradient" className={"dashboard-bottom-gradient"} />*/}
          <div
            className={clsx(
              classes.background,
              "site-layout-background sidebar-layout-content"
            )}
          >
            <div
              style={{
                flexGrow: 1,
                minHeight: '90vh'
              }}
              className={`container-temp${liveStream ? "-xl-live" : ""
                } sidebar-content-container`}
            >
              {children}
            </div>
          </div>

          <div
            ref={userPopupRef}
            className={`user-profile-popup ${isActivePopUp ? "open" : "close"}`}
          >
            <CSSTransition
              children={children}
              classNames="user-popup-transtion"
              timeout={500}
              unmountOnExit
              in={isActivePopUp}
            ></CSSTransition>
          </div>
        </Content>
      </Layout>
      {/* <CopyrightFooter /> */}
    </LayoutContext.Provider>
  );
};

PrivateLayout.defaultProps = {
  fixedPage: "",
  pageCenter: "",
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(PrivateLayout);
