import { all, call, put, takeEvery, fork } from "redux-saga/effects";
import { push } from "connected-react-router";

import { auth } from "../../firebase/firebase";
import { apiURL } from "../actions/helpers";

import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  USER_PASSWORD_RESET,
} from "../constants/ActionTypes";

import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  userAuthSuccess,
} from "../../appRedux/actions/Auth";
import axios from "axios";

//TODO: Consolidate yield puts when there are 2 in a row, can be mashed into one event
//axios.defaults.withCredentials = true;
const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const createUserRequest = async (userId, email, firstName, lastName) => {
  try {
    const url = `${apiURL}/signup`;
    const response = await axios.post(url, {
      userId,
      email,
      firstName,
      lastName,
    }, {
      withCredentials: true
    });

    return response;
  } catch (err) {
    console.error(err);
  }
};

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const signOutRequest = async () => {
  const user = await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);

  const url = `${apiURL}/signout`;

  await axios.get(url).catch((err) => err);
  return user;
};

const getTokenRequest = async (idToken) => {
  const url = `${apiURL}/getToken`;
  return await axios
    .request({
      method: "POST",
      url: url,
      data: { token: idToken },
      config: { withCredentials: true }
    })
    .then((resp) => resp)
    .catch((error) => error);
};

const checkoutSessionRequest = async (email, user) => {
  const url = `${apiURL}/checkout`;
  return await axios
    .request({
      method: "POST",
      url: url,
      data: { email: email, user_id: user },
      config: { withCredentials: true }
    }) //customer_id: customerId, subscription_id: subscriptionId}})
    .then((resp) => resp)
    .catch((error) => error);
};

const passwordResetRequest = async (email) => {
  var actionCodeSettings = {
    url: `http://${process.env.REACT_APP_REDIRECT_DOMAIN}/signin`,
    handleCodeInApp: false
  };
  const result = await auth
    .sendPasswordResetEmail(email, actionCodeSettings)
    .then(function () {
      // Email sent
      return true;
    })
    .catch(function (error) {
      // there was an error
      return false;
    });

  return result;
};

function* userPasswordReset({ payload }) {
  const result = yield passwordResetRequest(payload);
  if (result) {
    yield put(showAuthMessage("email sent"));
  } else {
    yield put(showAuthMessage("error"));
  }
}

function* createUserWithEmailPassword({ payload }) {
  const { email, password, firstName, lastName } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );

    // Catch firebase user signup errors
    if (signUpUser.code) {
      let errMsg = "Signup Error";
      if (signUpUser.code === "auth/email-already-in-use") {
        errMsg = "The email address is already in use by another account";
      }
      throw errMsg;
    }
    const checkoutSession = yield checkoutSessionRequest(
      email,
      signUpUser.user.uid
    );
    // TODO: check for checkoutSession error
    console.log("CHECKOUT SESSION RESP");
    console.log(checkoutSession.data.session.id);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      //localStorage.setItem('user_id', signUpUser.user.uid);
      //localStorage.setItem('email', email);

      yield call(
        createUserRequest,
        signUpUser.user.uid,
        email,
        firstName,
        lastName
      );

      yield put(
        userSignUpSuccess(signUpUser.user.uid, checkoutSession.data.session.id)
      );
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  console.log("HIT SIGN IN FUNC");
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    // Catch firebase user signin errors
    if (signInUser.code) {
      let errMsg = "Login Error";
      switch (signInUser.code) {
        case "auth/user-not-found":
          errMsg = "There is no user with that email address";
          break;
        case "auth/wrong-password":
          errMsg = "Incorrect password.";
          break;
        default:
          errMsg = "Login Error";
      }
      throw errMsg;
    }

    console.log("got here");
    const idToken = yield signInUser.user.getIdToken();
    console.log("got id token");
    const getTokenResult = yield getTokenRequest(idToken);
    console.log("req to gettoken");

    // remove user_id if it exists
    localStorage.removeItem("user_id");
    localStorage.removeItem("auth_token");
    console.log("also got here");
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else if (getTokenResult.data.error_code && getTokenResult.data.message) {
      console.log("--AUTH TOKEN ERROR--");
      console.log(getTokenResult.data.error_code);
      console.log(getTokenResult.data.message);
      // remove auth token if it exists
      if (
        getTokenResult.data.error_code === "PAYMENT_INCOMPLETE" ||
        getTokenResult.data.error_code === "USER_NOT_FOUND"
      ) {
        // push user to payment page with message that their payment has expired
        localStorage.setItem("email", email);
        const checkoutSession = yield checkoutSessionRequest(
          email,
          signInUser.user.uid
        );
        yield put(
          userSignInSuccess(
            signInUser.user.uid,
            checkoutSession.data.session.id
          )
        );
      } else {
        //USER_NOT_FOUND or SESSION_EXPIRED
        yield put(showAuthMessage(getTokenResult.data.message));
      }
    } else {
      localStorage.setItem("user_id", signInUser.user.uid);
      localStorage.setItem("auth_token", idToken);
      localStorage.setItem("email", email);
      yield put(userAuthSuccess(signInUser.user.uid, idToken));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("auth_token");
      yield put(userSignOutSuccess(signOutUser));
      yield put(push("/signin"));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}


export function* resetPassword() {
  yield takeEvery(USER_PASSWORD_RESET, userPasswordReset);
}

export default function* authSagas() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signOutUser),
    fork(resetPassword),
  ]);
}
