export const cardData = {
  "id": 1579,
  "ask": 21.73714,
  "bid": 21.72648,
  "low": 21.615625,
  "cboe": false,
  "comp": true,
  "high": 21.8415,
  "open": 21.74458,
  "score": 74,
  "origin": "GBP",
  "symbol": "GBP/ZAR",
  "delisted": false,
  "rawSymbol": "GBP/ZAR",
  "raw_symbol": "GBP/ZAR",
  "sparklines": [
    {
      "low": 21.65855,
      "high": 21.67125,
      "open": 21.664475,
      "time": 1676653200000,
      "vwap": 21.66543252304148,
      "close": 21.66247,
      "count": 434,
      "volume": 434,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676653200000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.66134,
      "high": 21.67098,
      "open": 21.66229,
      "time": 1676653500000,
      "vwap": 21.66614175853018,
      "close": 21.66749,
      "count": 381,
      "volume": 381,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676653500000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.65308,
      "high": 21.67271,
      "open": 21.66793,
      "time": 1676653800000,
      "vwap": 21.66238400966184,
      "close": 21.65481,
      "count": 414,
      "volume": 414,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676653800000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.655165,
      "high": 21.6824,
      "open": 21.65594,
      "time": 1676654100000,
      "vwap": 21.67083868932039,
      "close": 21.67978,
      "count": 412,
      "volume": 412,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676654100000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.67731,
      "high": 21.68558,
      "open": 21.67996,
      "time": 1676654400000,
      "vwap": 21.68093684135977,
      "close": 21.68446,
      "count": 353,
      "volume": 353,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676654400000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.68185,
      "high": 21.718395,
      "open": 21.68284,
      "time": 1676654700000,
      "vwap": 21.70199964692483,
      "close": 21.716445,
      "count": 439,
      "volume": 439,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676654700000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.712825,
      "high": 21.729305,
      "open": 21.717165,
      "time": 1676655000000,
      "vwap": 21.72080768134716,
      "close": 21.720105,
      "count": 386,
      "volume": 386,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676655000000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.716965,
      "high": 21.72355,
      "open": 21.720425,
      "time": 1676655300000,
      "vwap": 21.72008202702703,
      "close": 21.719965,
      "count": 407,
      "volume": 407,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676655300000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.713025,
      "high": 21.723815,
      "open": 21.720325,
      "time": 1676655600000,
      "vwap": 21.71736917721519,
      "close": 21.716205,
      "count": 395,
      "volume": 395,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676655600000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.715165,
      "high": 21.730165,
      "open": 21.715485,
      "time": 1676655900000,
      "vwap": 21.72409768617023,
      "close": 21.730165,
      "count": 376,
      "volume": 376,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676655900000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.722705,
      "high": 21.730915,
      "open": 21.730345,
      "time": 1676656200000,
      "vwap": 21.7266125994695,
      "close": 21.72554,
      "count": 377,
      "volume": 377,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676656200000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.71485,
      "high": 21.72655,
      "open": 21.72546,
      "time": 1676656500000,
      "vwap": 21.72052368945869,
      "close": 21.71825,
      "count": 351,
      "volume": 351,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676656500000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.71032,
      "high": 21.71982,
      "open": 21.71867,
      "time": 1676656800000,
      "vwap": 21.71606720538721,
      "close": 21.71293,
      "count": 297,
      "volume": 297,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676656800000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.71236,
      "high": 21.72294,
      "open": 21.71236,
      "time": 1676657100000,
      "vwap": 21.71893121753247,
      "close": 21.72019,
      "count": 308,
      "volume": 308,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676657100000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.71339,
      "high": 21.727455,
      "open": 21.72037,
      "time": 1676657400000,
      "vwap": 21.72039598939929,
      "close": 21.725935,
      "count": 283,
      "volume": 283,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676657400000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.7246,
      "high": 21.728565,
      "open": 21.725515,
      "time": 1676657700000,
      "vwap": 21.72664483333334,
      "close": 21.72754500000001,
      "count": 300,
      "volume": 300,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676657700000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72189,
      "high": 21.7282,
      "open": 21.727715,
      "time": 1676658000000,
      "vwap": 21.72446034534535,
      "close": 21.72307,
      "count": 333,
      "volume": 333,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676658000000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.7222,
      "high": 21.729325,
      "open": 21.72343,
      "time": 1676658300000,
      "vwap": 21.72556138235294,
      "close": 21.725665,
      "count": 340,
      "volume": 340,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676658300000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.71715,
      "high": 21.72726,
      "open": 21.725485,
      "time": 1676658600000,
      "vwap": 21.72201237160121,
      "close": 21.71832,
      "count": 331,
      "volume": 331,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676658600000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.71834,
      "high": 21.727325,
      "open": 21.71866,
      "time": 1676658900000,
      "vwap": 21.72329215568863,
      "close": 21.72427,
      "count": 334,
      "volume": 334,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676658900000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72175,
      "high": 21.730385,
      "open": 21.725365,
      "time": 1676659200000,
      "vwap": 21.72600409348442,
      "close": 21.727705,
      "count": 353,
      "volume": 353,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676659200000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.719355,
      "high": 21.733815,
      "open": 21.727915,
      "time": 1676659500000,
      "vwap": 21.72767342175066,
      "close": 21.719715,
      "count": 377,
      "volume": 377,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676659500000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.719345,
      "high": 21.727645,
      "open": 21.719535,
      "time": 1676659800000,
      "vwap": 21.72317321637428,
      "close": 21.727205,
      "count": 342,
      "volume": 342,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676659800000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.7223,
      "high": 21.728835,
      "open": 21.726215,
      "time": 1676660100000,
      "vwap": 21.72555605263158,
      "close": 21.725605,
      "count": 304,
      "volume": 304,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676660100000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.718115,
      "high": 21.731595,
      "open": 21.726335,
      "time": 1676660400000,
      "vwap": 21.72470186186187,
      "close": 21.723575,
      "count": 333,
      "volume": 333,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676660400000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.722575,
      "high": 21.732725,
      "open": 21.723365,
      "time": 1676660700000,
      "vwap": 21.7275857480315,
      "close": 21.732455,
      "count": 254,
      "volume": 254,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676660700000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.728425,
      "high": 21.734275,
      "open": 21.732635,
      "time": 1676661000000,
      "vwap": 21.7311006557377,
      "close": 21.730235,
      "count": 244,
      "volume": 244,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676661000000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.724105,
      "high": 21.7337,
      "open": 21.730955,
      "time": 1676661300000,
      "vwap": 21.72854597609562,
      "close": 21.726905,
      "count": 251,
      "volume": 251,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676661300000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.721365,
      "high": 21.729225,
      "open": 21.727085,
      "time": 1676661600000,
      "vwap": 21.72539405737705,
      "close": 21.72743,
      "count": 244,
      "volume": 244,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676661600000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72029,
      "high": 21.729545,
      "open": 21.72761,
      "time": 1676661900000,
      "vwap": 21.72418858085808,
      "close": 21.722695,
      "count": 303,
      "volume": 303,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676661900000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72125,
      "high": 21.72807,
      "open": 21.724315,
      "time": 1676662200000,
      "vwap": 21.72417724252493,
      "close": 21.72606,
      "count": 301,
      "volume": 301,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676662200000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.71564,
      "high": 21.72696,
      "open": 21.72696,
      "time": 1676662500000,
      "vwap": 21.72299554817276,
      "close": 21.71746,
      "count": 301,
      "volume": 301,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676662500000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.714505,
      "high": 21.726185,
      "open": 21.71764,
      "time": 1676662800000,
      "vwap": 21.72059657986111,
      "close": 21.723655,
      "count": 288,
      "volume": 288,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676662800000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.713085,
      "high": 21.727135,
      "open": 21.723835,
      "time": 1676663100000,
      "vwap": 21.71908747916666,
      "close": 21.714845,
      "count": 240,
      "volume": 240,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676663100000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.71211,
      "high": 21.72709,
      "open": 21.715025,
      "time": 1676663400000,
      "vwap": 21.71835248987855,
      "close": 21.71975,
      "count": 247,
      "volume": 247,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676663400000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.71404,
      "high": 21.72877,
      "open": 21.72029,
      "time": 1676663700000,
      "vwap": 21.72101396825398,
      "close": 21.72877,
      "count": 252,
      "volume": 252,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676663700000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.7208,
      "high": 21.73434,
      "open": 21.72863,
      "time": 1676664000000,
      "vwap": 21.72672080996885,
      "close": 21.72433,
      "count": 321,
      "volume": 321,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676664000000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72172,
      "high": 21.72651,
      "open": 21.72379,
      "time": 1676664300000,
      "vwap": 21.72433231914893,
      "close": 21.72441,
      "count": 235,
      "volume": 235,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676664300000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72162,
      "high": 21.7342,
      "open": 21.72422,
      "time": 1676664600000,
      "vwap": 21.72687549429658,
      "close": 21.73348,
      "count": 263,
      "volume": 263,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676664600000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72852,
      "high": 21.74422,
      "open": 21.7335,
      "time": 1676664900000,
      "vwap": 21.73396666666666,
      "close": 21.73918,
      "count": 276,
      "volume": 276,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676664900000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.73551,
      "high": 21.74821,
      "open": 21.73916,
      "time": 1676665200000,
      "vwap": 21.74081691570881,
      "close": 21.74363,
      "count": 261,
      "volume": 261,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676665200000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.73543,
      "high": 21.74969,
      "open": 21.7433,
      "time": 1676665500000,
      "vwap": 21.73975304435484,
      "close": 21.74969,
      "count": 248,
      "volume": 248,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676665500000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72427,
      "high": 21.75106,
      "open": 21.75106,
      "time": 1676665800000,
      "vwap": 21.73638435064935,
      "close": 21.72446,
      "count": 308,
      "volume": 308,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676665800000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72222,
      "high": 21.72986,
      "open": 21.72464,
      "time": 1676666100000,
      "vwap": 21.72545592369478,
      "close": 21.72327,
      "count": 249,
      "volume": 249,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676666100000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.719805,
      "high": 21.72358,
      "open": 21.72348,
      "time": 1676666400000,
      "vwap": 21.72195746124032,
      "close": 21.72181,
      "count": 258,
      "volume": 258,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676666400000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.720855,
      "high": 21.73945,
      "open": 21.72199,
      "time": 1676666700000,
      "vwap": 21.72655924444445,
      "close": 21.73612,
      "count": 225,
      "volume": 225,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676666700000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72485,
      "high": 21.74123,
      "open": 21.73925,
      "time": 1676667000000,
      "vwap": 21.73609185416667,
      "close": 21.72523,
      "count": 240,
      "volume": 240,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676667000000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    },
    {
      "low": 21.72334,
      "high": 21.73351,
      "open": 21.724955,
      "time": 1676667300000,
      "vwap": 21.72910260869565,
      "close": 21.73154,
      "count": 184,
      "volume": 184,
      "#=Candle": "Candle",
      "sequence": 0,
      "askVolume": "NaN",
      "bidVolume": "NaN",
      "eventTime": 1676667300000,
      "eventSymbol": "GBP/ZAR{=5m,price=mark}",
      "openInterest": "NaN",
      "impVolatility": "NaN"
    }
  ],
  "aiSentiment": "Very Bullish",
  "description": "Pound sterling - South African rand",
  "destination": "ZAR",
  "originScore": 35,
  "site_symbol": "GBP/ZAR",
  "symbol_dash": "GBP-ZAR",
  "currentPrice": 21.73163,
  "newsSentiment": "Bullish",
  "default_symbol": "GBP/ZAR",
  "symbol_no_punc": "GBPZAR",
  "symbol_reversed": "ZAR/GBP",
  "destinationSscore": -39
}