import { all, put, takeEvery, fork, select } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const HISTORICAL_DARKFLOW_LOAD_TOP = "HDF/LOAD/TOP";
export const HISTORICAL_DARKFLOW_LOAD_TOP_SUCCESS = "HDF/LOAD/TOP/SUCCESS";
export const HISTORICAL_DARKFLOW_LOAD_TOP_FAILURE = "HDF/LOAD/TOP/FAILURE";

// Action Creators
export const historicalDarkflowLoad = (resolution, pageSize, actionType, firstLoad, ticker = '') => ({
  type: HISTORICAL_DARKFLOW_LOAD_TOP,
  resolution,
  pageSize,
  actionType,
  firstLoad,
  ticker
});
export const historicalDarkflowLoadSuccess = (data) => ({
  type: HISTORICAL_DARKFLOW_LOAD_TOP_SUCCESS,
  data,
});
export const historicalDarkflowLoadFailure = (error) => ({
  type: HISTORICAL_DARKFLOW_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchHistoricalDarkFlow(action) {
  try {
    const { historicalDarkflow, pageNumber } = yield select((state) => state.historicalDarkflow);

    const historicalDarkflowState = yield select((state) => state.historicalDarkflow);
    const { resolution, pageSize, firstLoad, actionType, ticker } = action;

    let nextPageNumber;

    if (firstLoad) {
      nextPageNumber = 1;
      yield put(historicalDarkflowLoadSuccess({ ...historicalDarkflowState, historicalDarkflow, loading: true }));
    } else {
      nextPageNumber = pageNumber + 1;
      yield put(historicalDarkflowLoadSuccess({ ...historicalDarkflowState, historicalDarkflow, loading: false }));
    }
    let params = {
      pagenumber: nextPageNumber,
      pagesize: pageSize,
    }
    if(!!ticker) {
      params.ticker = ticker
    }
    const response = yield axios.get(`${apiURL}/forex/alerts/${resolution}/${actionType}`, {
      params,
      withCredentials: true
    });

    let data = [];
    if (firstLoad) {
      data = [...response.data.results]
    } else {
      data = [...historicalDarkflow.concat(response.data.results)]
    }

    data = [
      ...new Map(data.map((item, index) => [index, item])).values(),
    ];

    yield put(
      historicalDarkflowLoadSuccess({
        historicalDarkflow: data,
        loading: false,
        pageNumber: nextPageNumber,
      })
    );
  } catch (error) {
    yield put(historicalDarkflowLoadFailure({
      historicalDarkflow: [],
      loading: false,
    }));
  }
}

function* listenHistoricalDarkflowLoad() {
  yield takeEvery(HISTORICAL_DARKFLOW_LOAD_TOP, fetchHistoricalDarkFlow);
}

// Root Saga
export function* saga() {
  yield all([fork(listenHistoricalDarkflowLoad)]);
}

const INIT_STATE = {
  historicalDarkflow: [],
  loading: true,
  pageNumber: 0,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case HISTORICAL_DARKFLOW_LOAD_TOP_SUCCESS:
      return {
        ...state,
        historicalDarkflow: action.data.historicalDarkflow,
        loading: action.data.loading,
        pageNumber: action.data.pageNumber
      };
    default:
      return state;
  }
};

export default reducer;
