import React from "react";
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'
import StockContainer from "../TradingView/StockContainer";
const capitalize = (str) => {
  if (typeof str === 'string') {
    return str.replace(/^\w/, c => c.toUpperCase());
  } else {
    return '';
  }
};

const CompanyHistory = ({ themeMode, securityPrice, lastPrice, setLastPrice }) => {
  const { symbol } = useParams()
  return (
    <div
      className='d-flex flex-column justify-content-center bg-white position-relative security-tabs-border-bottom'
      style={{ minHeight: 300 }}
    >
      <StockContainer lastPrice={lastPrice} securityPrice={securityPrice} themeMode={capitalize(themeMode)} ticker={symbol.replace(/-/g, '/')} setLastPrice={setLastPrice} />
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode
});

export default connect(stateToProps, null)(CompanyHistory);