import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange, Range } from "react-date-range";
import styled from "styled-components";

const MuiDateRange = styled(DateRange)`
  display: block;
  width: 279px;
  margin: 0 auto;
  grid-area: 1 / 1 / 2 / 2;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  .rdrMonth {
    width: 280px;
  }
  .rdrWeekDay {
    width: 37px;
    max-width: 37px;
    font-weight: bold;
    color: #000;
  }
  .rdrDay {
    height: 35px;
    width: 35px;
    border: 1px solid #ddd;
    border-radius: 10px;
    &.rdrDayPassive {
      background: #f0f3f8;
    }
    .rdrDayNumber {
      top: 0;
      bottom: 0;
    }
    &:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    &:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    &:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
    &:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
      color: #000000 !important;
      font-weight: 500;
    }
  }
  .rdrStartEdge,
  .rdrInRange,
  .rdrEndEdge,
  .rdrDayInPreview,
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
  }

  .rdrMonthAndYearWrapper {
    position: relative;
    span.rdrMonthAndYearPickers {
      flex: none;
      padding-left: 5px;
    }
    .rdrMonthPicker {
      margin-right: 0;
    }
    .rdrMonthPicker select,
    .rdrYearPicker select {
      font-weight: bold;
      font-size: large;
      padding-left: 0;
      text-align: left;
      background: none;
      padding-right: 0;
    }
    .rdrNextPrevButton {
      background: none;
      margin: 0;
      i {
        width: 10px;
        height: 10px;
        border: 2px solid #34495e;
        border-left: 0;
        border-top: 0;
      }
    }
    .rdrPprevButton {
      position: absolute;
      right: 40px;
      transform: rotate(135deg);
      margin-top: 3px;
    }
    .rdrNextButton {
      transform: rotate(-45deg);
      margin-right: 15px;
      margin-top: 3px;
    }
  }
`;
type RangePickerProps = {
  selectedRange: Range;
  onRangeSelect: (range: Range) => void;
};
export function RangePicker({
  selectedRange,
  onRangeSelect,
  ...props
}: RangePickerProps): JSX.Element {
  const [range, setRange] = useState<Range[]>([selectedRange]);
  const onDateChange = (item: any) => {
    setRange([item.selection]);
    onRangeSelect(item.selection);    
  };
  useEffect(() => {
    var monthPickerSpan = document.querySelector(".rdrMonthPicker");
    if (monthPickerSpan != null) {
      let numberInputs: HTMLCollection =
        monthPickerSpan.getElementsByTagName("select");
      if (numberInputs) {
        numberInputs[0].addEventListener("change", function (e: any) {
          monthChange(e.target, -1);
        });
        monthChange(numberInputs[0], -1);
        let rdrMonthAndYearWrapper = document.querySelector(
          ".rdrMonthAndYearWrapper"
        );
        if (rdrMonthAndYearWrapper != null) {
          let prevBtn: HTMLElement =
            rdrMonthAndYearWrapper.getElementsByTagName(
              "button"
            )[0] as HTMLElement;
          if (prevBtn != null) {
            prevBtn.onclick = function () {
              monthChange(numberInputs[0], 0);
            };
          }
          let nextBtn: HTMLElement =
            rdrMonthAndYearWrapper.getElementsByTagName(
              "button"
            )[1] as HTMLElement;
          if (nextBtn != null) {
            nextBtn.onclick = function () {
              monthChange(numberInputs[0], 1);
            };
          }
        }
        return () => {
          numberInputs[0].removeEventListener("change", function (e) {
            monthChange(e.target, -1);
          });
        };
      }
    }
  }, []);
  const monthChange = (event: any, index: number) => {
    let select = event;
    
    let selectedIndex = select.selectedIndex;
    if (index === 1) {
      selectedIndex = selectedIndex + 1;
    } else if (index === 0) {
      selectedIndex = selectedIndex - 1;
    }
    if(selectedIndex === -1) selectedIndex = 0;
    if(selectedIndex === 12) selectedIndex = 11;
    let selectedText = select.options[selectedIndex].text;
    var selectList = document.createElement("select");
    let opt = document.createElement("option");
    opt.text = selectedText;
    selectList.add(opt, null);
    select.parentElement.appendChild(selectList);
    select.style.width = selectList.offsetWidth + "px";
    selectList.remove();
  };
  return (
    <>
      <MuiDateRange
        onChange={onDateChange}
        ranges={range}
        showDateDisplay={false}
        rangeColors={["#6eeac9"]}
        weekdayDisplayFormat="EEEEEE"
        weekStartsOn={1}
        {...props}
      />
    </>
  );
}
