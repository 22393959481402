import React, { useEffect, useMemo } from "react";
import { Switch, useHistory, useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { cancelAxios } from "../helpers/axios";

import SignOut from "../components/auth/SignOut";
// Protected pages
import SignIn from "../components/auth/SignIn";
import EmailForm from "../components/auth/EmailForm";

// Public pages
import HomePage from "../components/homePage";

import ForexActivity from "../components/ForexActivity";
import OptionsActivity from "../components/OptionsActivity";
import HistoricalActivity from "../components/common/Tables/HistoricalActivity";
import Stories from "../components/Stories";
import AddStory from "../components/AddStory";
import Calendar from "../components/calendar";
import News from "../components/News";
import LiveOptions from "../components/LiveOptions";
import MinuteScanner from "../components/MinuteScanner";
import LiveSessions from "../components/LiveSessions/LiveSessionsContainer";
import ChatContainer from "../components/LiveChat/ChatContainer";
import UsernameAdd from "../components/LiveChat/UsernameAdd";
import OptionScanner from "../components/OptionScanner/OptionScannerContainer";
import AlphaOptions from "../components/AlphaOptions";
import HedgeFundMentors from "../components/HedgeFundMentors";
import CurrencyStrength from "../components/CurrencyStrength";
import TradeWithMike from "../components/TradeWithMike";
import AlphaOptionsSignUp from '../components/AlphaOptions/SignUp/AlphaOptionsSignUp';
import OptionsMentorship from "../components/OptionsMentorship";
import OptionsMentorshipThankYou from "../components/OptionsMentorship/Thankyou";
import OptionsMentorshipStarter from "../components/OptionsMentorship/OptionsMentorshipStarter";

// Semi-public pages
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import FallbackError from "../components/common/FallbackError";
import { useAuth } from "../contexts/Auth";
import Company from "../components/company";
import AlphaOptionsSignUpResult from "../components/AlphaOptions/SignUp/AlphaOptionsSignUpResult";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "rgba(19, 17, 26, 1) !important",
  },
  text: {
    color: "white",
  },
}));

const Routes = ({ themeMode }) => {
  const { hasOptionChain, hasAlpha, hasHedgeFunds } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const params = history?.location?.pathname?.split("/");
  const classes = useStyles();
  let param = params[1];
  document.body.className =
    param === "" ? `home-page ${themeMode}` : `${param} ${themeMode}`; // Set body class to our current page
  // home-page is the initial launchscreen/login page

  useEffect(() => {
    document.querySelector("body").classList.remove("dark");
    document.querySelector("body").classList.remove("light");
    document.querySelector("body").classList.add(themeMode);
    const light = themeMode === "light";
    const root = document.documentElement;
    root.style.setProperty(
      "--background-color-scroll",
      light ? "white" : "#2F2E31"
    );
  }, [themeMode]);

  useEffect(() => {
    cancelAxios("Navigated away"); // Stop all api requests during url change
  }, [location?.pathname]);

  return useMemo(() => {
    return (
      <div className={clsx(classes.background, "appWrapper")}>
        <Switch>
          <PublicRoute
            exact
            path="/signin"
            component={SignIn}
            type="protected"
            fixedPage
            pageCenter
          />
          <PublicRoute
            exact
            path="/reset-password"
            component={EmailForm}
            type="protected"
            fixedPage
            pageCenter
          />
          <PrivateRoute
            exact
            internal={true}
            path="/home/:time"
            component={HomePage}
            name="Dashboard"
          />
          {
            (hasOptionChain || hasAlpha) && (
              <PrivateRoute
                exact
                path="/option-chain/:sym"
                component={OptionScanner}
                name="Option Chain"
              />
            )
          }
          <Redirect exact={true} from="/home" to="/home/4h" />
          <PrivateRoute
            internal={true}
            exact
            path="/company/:symbol"
            name={`Company`}
            component={Company}
          />
          <PrivateRoute
            internal={true}
            exact
            path="/ticker/:symbol"
            name={`Ticker`}
            component={Company}
          />
          <PrivateRoute
            exact
            path="/live-options/*"
            component={LiveOptions}
            name="Live Options"
            internal={true}
          />
          <Redirect exact={true} from="/live-options" to="/live-options/35000/730" />
          <PrivateRoute
            exact
            path="/scanner"
            component={MinuteScanner}
            name="Minute Scanner"
            internal={true}
          />
          <PrivateRoute
            exact
            path="/forex-activity"
            component={ForexActivity}
            name="Forex Activity"
            internal={true}
          />
          <PrivateRoute
            exact
            path="/options-activity"
            component={OptionsActivity}
            name="Options Activity"
            internal={true}
          />
          <PrivateRoute
            exact
            path="/historical/:time"
            name="Historical Activity"
            component={HistoricalActivity}
            internal={true}
          />
          {/* <Redirect exact={true} from="/historical" to="/historical/Auto/Up" /> */}
          <Redirect exact={true} from="/historical" to="/historical/4h" />
          {/* <PrivateRoute
            internal={true}
            exact
            path="/stories"
            component={Stories}
            name="Stories"
          />*/}
          <PrivateRoute
            exact
            path="/news/:tp/:st/:sn"
            component={News}
            name="News"
            internal={true}
          /> 
          <Redirect exact={true} from="/news" to="/news/all/all/all" />
          {/* <PrivateRoute
            exact
            path="/stories/add-story"
            component={AddStory}
            name="Add Story"
          /> */}
          <PrivateRoute
            exact
            path="/calendar"
            component={Calendar}
            name="Calendar"
            internal={true}
          />
          <PrivateRoute
            exact
            path="/basic-training"
            name={`Basic Training`}
            component={LiveSessions}
            liveStream={true}
            internal={true}
          />
          <PrivateRoute
            exact
            name="Chat"
            path="/live-chat"
            component={ChatContainer}
            internal={true}
          />
          <PrivateRoute
            exact
            path="/modify-username"
            name="Modify Username"
            component={UsernameAdd}
            internal={true}
          />
          <PrivateRoute
            exact
            name="signOut"
            path="/signout"
            type="protected"
            component={SignOut}
          />
          <PrivateRoute
            exact
            path="/alpha-options/thank-you"
            name={`Alpha Options`}
            component={AlphaOptionsSignUpResult}
          />
          <PrivateRoute
            exact
            path="/alpha-options/:main?/:sub"
            name={`Alpha Options`}
            component={hasAlpha ? AlphaOptions : AlphaOptionsSignUp}
          />
          <Redirect exact={true} from="/alpha-options" to="/alpha-options/livestream" />
          <PrivateRoute
            exact
            path="/hedgefund-mentors"
            name={`HedgeFund Mentors`}
            component={HedgeFundMentors}
          />
          <PrivateRoute
            exact
            path="/currency-strength/:time"
            name={`Currency Strength`}
            component={CurrencyStrength}
            internal={true}
          />
          <PrivateRoute
            exact
            path="/trade-with-mike"
            name={`Trade With Mike Andrews`}
            component={TradeWithMike}
          />
          <PrivateRoute
            exact
            path="/forex-mentorship-signup"
            name={`Forex Mentorship`}
            component={OptionsMentorship}
          />
          <PrivateRoute
            exact
            path="/forex-mentorship-thank-you/:tier"
            name={`Forex Mentorship Thank You`}
            component={OptionsMentorshipThankYou}
          />
          <PrivateRoute
            exact
            path="/forex-mentorship/:tier"
            name={`Forex Mentorship`}
            component={OptionsMentorshipStarter}
          />
          <PublicRoute
            exact
            path="/"
            component={SignIn}
            type="protected"
            fixedPage
            pageCenter
          />
          <PrivateRoute notFound={true} component={FallbackError} />
        </Switch>
      </div>
    );
    // eslint-disable-next-line
  }, [history, location.pathname, hasOptionChain, hasAlpha, hasHedgeFunds]);
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});
const dispatchToProps = (dispatch) => ({});

export default connect(stateToProps, dispatchToProps)(Routes);