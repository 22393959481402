import { makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


export const NewsFilterSkeleton = ({ width = '450px' }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      // width: 'auto', // allow table to stretch full width
      flexGrow: 1, // allow table to stretch full width
      backgroundColor: theme.palette.primary.innerBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: "10px",
    },
    skeleton: {
      backgroundColor: theme.palette.primary.skeleton,
      minHeight: "40px",
      maxHeight: "40px",
      width: "calc(33.3333333333% - 20px)",
      borderRadius: 4,

      "@media (max-width: 768px)": {
        width: "100%",
        marginBottom: "10px",
      }
    }
  }));
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      <Skeleton className={classes.skeleton} variant="rect" />
      <Skeleton className={classes.skeleton} variant="rect" />
      <Skeleton className={classes.skeleton} variant="rect" />
    </Paper>
  );
};
