
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const DASHBOARD_LOAD = "DASHBOARD/LOAD/TOP";
export const DASHBOARD_LOAD_SUCCESS = "DASHBOARD/LOAD/TOP/SUCCESS";
export const DASHBOARD_LOAD_FAILURE = "DASHBOARD/LOAD/TOP/FAILURE";

// Action Creators
export const dashboardLoad = (resolution, apiType) => ({
  type: DASHBOARD_LOAD,
  resolution,
  apiType
});
export const dashboardLoadSuccess = (data, loading) => ({
  type: DASHBOARD_LOAD_SUCCESS,
  data,
  loading
});
export const dashboardLoadFailure = (error) => ({
  type: DASHBOARD_LOAD_FAILURE,
  error,
  loading: false
});

// Sagas
function* fetchDashboard(action) {
  try {
    yield put(dashboardLoadSuccess([], true));

    const { resolution, apiType } = action;

    let url = `${apiURL}/forex/dashboard/${resolution}`;

    if (!!apiType) {
      url += `/${apiType}`
    }

    const response = yield axios.get(url, { withCredentials: true });
    const data = { ...response.data.data, time: response.data.epoch_time };
    yield put(dashboardLoadSuccess(data, false));

  } catch (error) {
    yield put(dashboardLoadFailure(error));
  }
}

function* listenDashboardLoad() {
  yield takeEvery(DASHBOARD_LOAD, fetchDashboard);
}

// Root Saga
export function* saga() {
  yield all([fork(listenDashboardLoad)]);
}

const INIT_STATE = {
  dashboard: {
    date: "",
    time: "",
    cards: [],
    scores: [],
  },
  dashboardLoading: false,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_LOAD_SUCCESS:
      return {
        ...state,
        dashboard: action.data,
        dashboardLoading: action.loading,
      };
    case DASHBOARD_LOAD_FAILURE:
      return {
        ...state,
        dashboard: {
          date: "",
          time: "",
          cards: [],
          scores: [],
        },
        dashboardLoading: false
      }
    default:
      return state;
  }
};

export default reducer;
