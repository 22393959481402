import React from "react";
import { Row, Col } from "antd";
import { Card, CardBody } from "reactstrap";
import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./fallback.css";
const FallbackError = (props) => {

  const { message, notFound = false, notFoundMessage } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        top: "142px",
      }}
    >
      <div>
        <Card
          style={{
            width: "525px",
            height: "280px",
            background: "transparent",
            border: "none",
          }}
        >
          <CardBody>
            <Row gutter={[16, 10]}>
              <Col span={24}>
                <span
                  style={{
                    display: "flex",
                    placeContent: "stretch space-evenly",
                    fontFamily: "poppins",
                    color: "black",
                    lineHeight: "36px",
                    fontSize: "24px",
                    fontWeight: "600",
                    position: "relative",
                    top: "20px",
                  }}
                >
                  <svg
                    width="229"
                    height="181"
                    viewBox="0 0 229 181"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M160.174 86.1788H110.346C106.026 86.1788 102.195 88.9526 100.846 93.0563L76.4226 167.361C74.2968 173.829 79.1146 180.484 85.9226 180.484H144.348L169.72 99.157C171.729 92.7187 166.919 86.1788 160.174 86.1788Z"
                      fill="#49B69D"
                    />
                    <path
                      d="M1.8575 96.4127L11.6143 171.768C12.2597 176.753 16.505 180.484 21.5315 180.484H143.561C145.335 180.287 144.728 178.231 142.961 177.981C138.662 177.372 136.249 175.063 133.897 170.364C133.682 169.935 133.544 169.473 133.474 168.998L120.382 79.4962C119.664 74.5847 115.451 70.9435 110.487 70.9435H92.7479C90.1275 70.9435 87.6118 71.9721 85.742 73.8079L77.129 82.2643C75.2592 84.1001 72.7435 85.1287 70.1231 85.1287H11.7747C5.74206 85.1287 1.08288 90.43 1.8575 96.4127Z"
                      fill="#5EEECD"
                    />
                    <path
                      d="M93.1132 113.91L78.791 128.609M93.1132 128.609L78.791 113.91"
                      stroke="#555555"
                      strokeWidth="5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M47.8866 113.91L33.5645 128.609M47.8866 128.609L33.5645 113.91"
                      stroke="#555555"
                      strokeWidth="5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M78.7913 156.123C78.7913 151.127 73.054 147.077 65.9767 147.077C58.8994 147.077 53.1621 151.127 53.1621 156.123"
                      stroke="#555555"
                      strokeWidth="5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M180.947 92.3918C205.61 92.3918 225.604 72.3862 225.604 47.7079C225.604 23.0297 205.61 3.02403 180.947 3.02403C156.284 3.02403 136.29 23.0297 136.29 47.7079C135.701 54.2074 140.104 66.6647 142.38 72.081L136.29 92.3918L158.42 86.2986C165.033 90.1718 172.731 92.3918 180.947 92.3918Z"
                      fill="#D7FFF6"
                      stroke="#5EEECD"
                      strokeWidth="5"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M177.545 57.8216H184.005V57.2421C184.039 53.9182 185.232 52.3671 187.925 50.7478C191.113 48.8557 193.192 46.3501 193.192 42.3444C193.192 36.3785 188.385 32.9012 181.618 32.9012C175.431 32.9012 170.385 36.1398 170.232 42.958H177.187C177.289 40.1796 179.351 38.6966 181.584 38.6966C183.885 38.6966 185.743 40.2307 185.743 42.6001C185.743 44.833 184.124 46.316 182.028 47.6455C179.164 49.4523 177.562 51.2762 177.545 57.2421V57.8216ZM180.903 68.7307C183.084 68.7307 184.976 66.9069 184.993 64.6398C184.976 62.4069 183.084 60.583 180.903 60.583C178.653 60.583 176.795 62.4069 176.812 64.6398C176.795 66.9069 178.653 68.7307 180.903 68.7307Z"
                      fill="#5EEECD"
                    />
                  </svg>
                </span>
              </Col>
              <Col span={24}>
                <span style={{ left: notFound ? '38px' : '13px' }} className="fallback-span">{notFound ? notFoundMessage : message}</span>
                <br />
                <span className="oops">
                  Oops! That's not supposed to happen.
                </span>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  top: "80px",
                  left: "-32px",
                }}
                span={24}
              >
                <Link to="/">
                  <ButtonBase
                    style={{
                      background: "#5EEECD",
                      color: "#475569",
                      borderRadius: "8px",
                      width: "121px",
                      height: "45px",
                      fontSize: "14px",
                      fontWidht: "normal",
                    }}
                    block
                  >
                    Back to home
                  </ButtonBase>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default FallbackError;

FallbackError.defaultProps = {
  message: "Sorry, something went wrong.",
  notFoundMessage: 'This page does not exist'
};
