import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.css";
import { widget } from "../../../src/charting_library/charting_library.esm.js";
import Datafeed, { getUnsub } from "./datafeed";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import history from "./history";
import { useAuth } from "../../contexts/Auth";
import { apiURL, isInternalWS } from "../../appRedux/actions/helpers";
import datafeedDx from "./datafeedDx";
function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
function StockContainer({
  symbols,
  setSymbol,
  securityPrice,
  setLastPrice,
  themeMode,
  userSettings,
}) {
  const { userData } = useAuth();
  const userId = userData?.userId;
  const search = useLocation().search;
  // let resolution = candleDefaults.resolution;
  // const candleType = candleDefaults.candleType;
  let resolution = userSettings?.defaultResolution || "15";
  const indicators = userSettings?.defaultIndicators || [];
  const candleType = userSettings?.candleType || "1";
  const timezone = userSettings?.timezone || 'America/New_York'
  const settingsMemo = useMemo(() => {
    return `${resolution},${indicators},${candleType},${timezone}`;
  }, [resolution, indicators, candleType,timezone]);
  //0 = private 1 = community
  const chartSaveMode = userSettings?.chartSaveMode || 0;
  const { symbol } = useParams();
  const [widgetRef, setWidgetRef] = useState({});
  const windowSize = useWindowSize();
  const location = useLocation();
  const symbolRef = useRef(symbol);
  const unsubRef = useRef(getUnsub());
  // useEffect(() => {
  //   setTimeout(() => {
  //     unsubRef.current = getUnsub();
  //   },2000)
  //   // eslint-disable-next-line
  // }, [symbols.value, getUnsub()]);
  // useEffect(() => {
  //   setLastPrice("");
  //   // eslint-disable-next-line
  //   let unsub = getUnsub();
  //   // eslint-disable-next-line
  // }, [symbols.value]);
  // useEffect(() => {
  //   return () => {
  //     setTimeout(() => {
  //       unsubRef?.current && unsubRef?.current();
  //     }, 2000);
  //   };
  //   // eslint-disable-next-line
  // }, []);
  const disabled_features = useMemo(() => {
    return location.pathname.includes("guest")
      ? [
          "use_localstorage_for_settings",
          "display_market_status",
          "popup_hints",
          "header_screenshot",
          // "widget_logo",
          "header_indicators",
          "header_compare",
          "left_toolbar",
          "header_symbol_search",
          "header_settings",
          "header_fullscreen_button",
        ]
      : [
          "use_localstorage_for_settings",
          "display_market_status",
          "popup_hints",
          "header_screenshot",
          // "widget_logo",
        ];
  }, [location.pathname]);

  const urlResolution = new URLSearchParams(search).get("resolution");
  useEffect(() => {

    return () => {
      setTimeout(() => {
        if (!isInternalWS) {
          datafeedDx.unsubscribeBars('full-unsub')
        }
      }, 1200)
    }
  }, [])
  useEffect(() => {
    setWidgetRef({});
    const timeline = new URLSearchParams(search).get("timeline");
    if (urlResolution) {
      resolution = urlResolution;
    }
    const widgetOptions = {
      // debug: true,
      studies_overrides: {
        "williams alligator.jaw length": 13,
        "williams alligator.teeth length": 8,
        "williams alligator.lips length": 5,
        "williams alligator.jaw offset": 8,
        "williams alligator.teeth offset": 5,
        "williams alligator.lips offset": 3,
      },
      symbol: symbol.replace("-", "/"),
      interval: resolution,
      datafeed: isInternalWS ? Datafeed : datafeedDx,
      symbol_search_request_delay: 1000,
      container: "mm_chart_container",
      library_path: "/charting_library/",
      locale: getLanguageFromURL() || "en",
      time_frames: [
        { text: "5Y", resolution: "1D", description: "5 Years" },
        { text: "1Y", resolution: "1D", description: "1 Year" },
        { text: "6M", resolution: "1D", description: "6 Months" },
        { text: "3M", resolution: "1D", description: "3 Months" },
        { text: "1M", resolution: "1", description: "1 Month" },
        { text: "5D", resolution: "1", description: "5 Days" },
        { text: "1D", resolution: "1", description: "1 Day" },
      ].reverse(),
      header_widget_buttons_mode: "adaptive",
      disabled_features,
      enabled_features: [
        // "use_localstorage_for_settings",
        "side_toolbar_in_fullscreen_mode",
        "chart_style_hilo",
        // "extended_hours",
        "secondary_series_extend_time_scale",
        // "high_density_bars",
        "header_in_fullscreen_mode",
        "hide_last_na_study_output",
        "seconds_resolution",
      ],
      load_last_chart: false,
      fullscreen: false,
      autosize: true,
      width: "100%",
      timezone: "America/New_York",
      client_id: "patternscanner",
      theme: "dark",
      user_id: userId,
      // auto_save_delay: 10,
      // charts_storage_url: apiURL,
      // 1.3 = community , 1.1 = private
      // charts_storage_api_version: !!chartSaveMode ? "1.3" : "1.1",
      overrides: {
        "mainSeriesProperties.showCountdown": true,
      },
      loading_screen: {
        backgroundColor: "#222222",
        foregroundColor: "#229712",
      },
    };
    // if logged in to test account allow saving of chart
    if (userId === "PzhoHfWuv6dxNChrwoCfffjh3of2") {
      widgetOptions.charts_storage_url = apiURL;
      widgetOptions.charts_storage_api_version = "1.1";
    }
    if (symbol) {
      const tvWidget = new widget(widgetOptions);
      tvWidget.onChartReady(() => {
        setWidgetRef(tvWidget);
        // let chartStart = MTZ.utc().subtract(8, "hours").valueOf() / 1000;
        // if (timeline) {
        //   if (timeline === "3M") {
        //     chartStart = moment().subtract(3, "months").utc().valueOf() / 1000;
        //   }
        // }
        // tvWidget
        //   .activeChart()
        //   .onIntervalChanged()
        //   .subscribe(null, function (int) {
        //     tvWidget
        //       .activeChart()
        //       .setVisibleRange(
        //         { from: chartStart, to: now },
        //         { percentRightMargin: 5 }
        //       );
        //   });
        // const now = MTZ.utc().valueOf() / 1000;
        // if (securityPrice?.date) {
        //   tvWidget
        //     .activeChart()
        //     .setVisibleRange(
        //       { from: chartStart, to: now },
        //       { percentRightMargin: 5 }
        //     )
        //     .then(() => { })
        //     .catch((err) => console.log(err));
        // }

        tvWidget
          .chart()
          .onSymbolChanged()
          .subscribe(null, function (symbolData) {
            const type = symbolData.type;
            //please do not remove this
            if (symbols.value !== symbolData.name) {
              history.push(`/company/${symbolData.name.replace("/", "-")}`);
            }
            // sends first data request true on symbol change
            setSymbol({
              type: "company",
              value: symbolData.name.replace("/", "-"),
            });
            symbolRef.current = symbolData.name;
          });
      });
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (Object.keys(widgetRef).length) {
      // widgetRef.activeChart().removeAllStudies();
      // if (urlResolution) {
      //   resolution = urlResolution;
      // }
      widgetRef.activeChart().setChartType(Number(candleType));
      widgetRef.activeChart().setResolution(resolution);
      //doesnt support all timezones supported by moment but supports major ones 
      widgetRef.activeChart().setTimezone(timezone);
      indicators?.forEach((indicator) =>
        widgetRef.activeChart().createStudy(indicator)
      );
    }
  }, [settingsMemo, urlResolution, widgetRef]);

  useEffect(() => {
    if (Object.keys(widgetRef).length && !!symbols.value) {
      if (symbols.type !== "crypto") {
        widgetRef.activeChart().setSymbol(symbols.value.replace(/-/g, "/"));
      }
    }
    // eslint-disable-next-line
  }, [symbols.value, widgetRef]);

  useEffect(() => {
    if (document.getElementById("mm_chart_container")) {
      const currentWidth =
        document.getElementById("mm_chart_container").clientWidth;
      const maxHeight = currentWidth * 0.6;
      document.getElementById(
        "mm_chart_container"
      ).style.maxHeight = `${maxHeight}px`;
    }
    // eslint-disable-next-line
  }, [windowSize]);
  return (
    <>
      {symbol && <div id="mm_chart_container" className={"MMChartContainer"} />}
    </>
  );
}
const stateToProps = (state) => ({
  symbols: state.symbol.symbol,
  marketStatus: state.companyData.companyData.marketState,
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});

export default connect(stateToProps, dispatchToProps)(StockContainer);
