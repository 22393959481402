import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { forexActivityLoad } from "../../appRedux/ducks/forexActivity";
import { Typography } from "@mui/material";
import moment from "moment";
import "./index.scss";
import MarketMakersTable from "../common/Tables/MarketMakersTable";
import InfiniteScroll from "react-infinite-scroll-component";

import { optionsActivityData } from "./optionsActivity-mock";

const sortLookup = {
  "pair": (a, b) => (a.pair.toUpperCase() < b.pair.toUpperCase()) ? -1 : (a.pair.toUpperCase() > b.pair.toUpperCase()) ? 1 : 0,
  "timePeriod": (a, b) => a.timePeriod - b.timePeriod,
  "expiry": (a, b) => a.expiry - b.expiry,
  "strike": (a, b) => a.strike - b.strike,
  "cp": (a, b) => a.cp - b.cp,
  "spot": (a, b) => a.spot - b.spot,
  "details": (a, b) => a.details - b.details,
  "premium": (a, b) => a.premium - b.premium,

  // "timePeriod": (a, b) => a.timePeriod - b.timePeriod,
  // "expiry": (a, b) => a.expiry - b.expiry,
  // "strike": (a, b) => a.strike - b.strike,
  // "cp": (a, b) => a.cp - b.cp,
  // "spot": (a, b) => a.spot - b.spot,
  // "details": (a, b) => a.details - b.details,
  // "premium": (a, b) => a.premium - b.premium,
}

const createData = (
  pair,
  timePeriod,
  expiry,
  strike,
  cp,
  spot,
  details,
  premium
) => {
  return {
    id: "id" + Math.random().toString(16).slice(2),
    pair,
    timePeriod,
    expiry,
    strike,
    cp,
    spot,
    details,
    premium
  };
};

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.innerBackground + ' !important',
  },
  headerTitle: {
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
}));

const OptionsActivity = (props) => {
  const classes = useStyles();
  const { fetchForexActivity, forexActivity, loading } = props;
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0)
  const [sortModel, setSortModel] = useState([{
    field: 'timePeriod',
    sort: 'desc'
  }])
  const columns = [
    {
      field: "pair",
      headerName: "PAIR",
      flex: 1,
      renderCell: (params) => (
        <div className="forex-activity-ticker">
          <Link to={`/company/${params.value.replace('/', '-')}`}>
            <span className={classes.text}>{params.value}</span>
          </Link>
        </div>
      )
    },
    {
      field: "timePeriod",
      headerName: "TIME PERIOD",
      flex: 1,
      renderCell: (params) => (
        <span className={classes.text}>{params.value}</span>
      )
    },
    {
      field: "expiry",
      headerName: "EXPIRY",
      flex: 1,
      renderCell: (params) => (
        <span className={classes.text}>{params.value}</span>
      )
    },
    {
      field: "strike",
      headerName: "STRIKE",
      flex: 1,
      renderCell: (params) => (
        <span className={classes.text}>{params.value}</span>
      )
    },
    {
      field: "cp",
      headerName: "C/P",
      flex: 1,
      renderCell: (params) => (
        <span className={classes.text}>{params.value}</span>
      )
    },
    {
      field: "spot",
      headerName: "SPOT",
      flex: 1,
      renderCell: (params) => (
        <span className={classes.text}>{params.value}</span>
      )
    },
    {
      field: "details",
      headerName: "DETAILS",
      flex: 1,
      renderCell: (params) => (
        <span className={classes.text}>{params.value}</span>
      )
    },
    {
      field: "premium",
      headerName: "PREMIUM",
      flex: 1,
      renderCell: (params) => (
        <div>
          <span
            style={{
              border: "2px solid #40e548",
              marginRight: "5px",
              padding: "5px",
              color: "#40e548",
            }}
          >
            {params.value.value}
          </span>
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   fetchForexActivity();
  // }, []);

  useEffect(() => {
    let processedRows = [];
    optionsActivityData.sort(sortLookup[sortModel[0].field]).map((row) =>
      processedRows.push(
        createData(
          row.pair,
          row.timePeriod,
          row.expiry,
          row.strike,
          row.cp,
          row.spot,
          row.details,
          {
            value: row.premium
          }
        )
      )
    );
    if (sortModel[0].sort === 'desc') {
      processedRows = processedRows.reverse();
    }
    setRows(processedRows);
    const sliced = processedRows.slice(0, 25)
    setData(sliced)
    setIndex(0)
    // }, [forexActivity, sortModel]);
  }, [optionsActivityData, sortModel]);

  useEffect(() => {
    if (index !== 0) {
      const sliced = rows.slice(index * 25, (index * 25) + 25)
      setData([...data, ...sliced])
    }
  }, [index])

  return (
    <Box className={classes.background}>
      <InfiniteScroll
        dataLength={data.length}
        next={() => setIndex((prev) => prev + 1)}
        hasMore={data.length !== rows.length}
        style={{ overflow: 'none' }}
      >
        <Typography
          style={{ padding: '16px' }}
          className={classes.headerTitle}
        >
          Options Unusual Activity
        </Typography>

        <MarketMakersTable loading={false} sortModel={sortModel} setSortModel={setSortModel} rows={data} columns={columns} hasRowClassName={false} />
      </InfiniteScroll>
    </Box>
  );
};

const stateToProps = (state) => ({
  forexActivity: state.forexActivity.data,
  loading: state.forexActivity.forexActivityLoading,
});

const dispatchToProps = (dispatch) => ({
  fetchForexActivity: () => dispatch(forexActivityLoad()),
});

export default connect(stateToProps, dispatchToProps)(OptionsActivity);
