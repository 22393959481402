/* eslint-disable */
import { useMemo } from 'react';
import { connect } from 'react-redux';
import './customProgressBar.scss';
const calcRotateAndDeg = (percent) => {
    let deg = 1;
    let clip = 1;
    if(percent == 100) {
        deg = 184;
        clip = 'hundo'
    }
    else if (percent == 75) {
       deg = 91;
       clip = 'tree-fourth'
    } else if (percent == 50) {
        clip = 'half-ass';
        deg = 1
    } else if (percent == 25) {
        clip = 'one-fourth'
        deg = 303
    } else {
        clip = 'zoro'
    }
    return {deg,clip}
}
const CircularProgressBar = (props) => {
    const {themeMode, callRatio, options} = props
    let closest;
    if(options) {
        closest = [0,25,50,75,100].reduce(function(prev, curr) {
            return (Math.abs(curr - Number(callRatio)) < Math.abs(prev - Number(callRatio)) ? curr : prev);
        });
    }
    const nums = calcRotateAndDeg(closest)
    const percentage = props.percentage
    const title = props.title;
    const value = props.value;
    const color = props.color;
    const deg = percentage ? (360 / 100) * percentage / 2 : 0;
    const up = props.up;
    let propsDep = JSON.stringify(props);
    const Component = useMemo(() => {
      return  (
            <div className={`dash-circle-wrap-${themeMode}`}>
                <div className="dash-circle" >
                    <div className={`dash-mask full ${options && nums.clip} `} style={{ transform: `rotate(${options ? nums.deg : deg}deg)`, animation: `${percentage && nums.clip !== 'one-fourth' ? 'fill ease-in-out 2s' : 'none'}` }}>
                        <div className="fill" style={{ backgroundColor: `${color}`, transform: `rotate(${deg}deg)`, animation: `${percentage ? 'fill ease-in-out 2s' : 'none'}` }} />
                    </div>
                    <div className={`dash-mask half ${options && nums.clip === 'one-fourth' && nums.clip}`}>
                        <div className={`fill ${options && nums.clip === 'zoro' && nums.clip}`}  style={{ backgroundColor: `${color}`, transform: `rotate(${deg}deg)`, animation: `${percentage ? 'fill ease-in-out 2s' : 'none'}` }} />
                    </div>
                    <div className={`inside-dash-circle-${color === '#3EB489' ? 'up': 'down'}`}>
                        {title && <div className={"inside-dash-circle-value"}>{title}</div>}
                        {value && <div style={{color:up ? '#3EB489' : 'rgb(249 27 27)'}} className={"inside-dash-circle-title"}>{value}</div>}
                    </div>
                </div>
            </div>
        )
    },[propsDep, up, nums])
    return Component
}

    const stateToProps = (state) => ({
    themeMode: state.themeMode.themeMode,
  });
  
  export default connect(stateToProps, null )(CircularProgressBar);
  