/* eslint-disable */
import { Helmet } from 'react-helmet';
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { numberWithCommas } from "../utilities";
import { getSub } from "../TradingView/datafeed";
import RobinhoodDigits from "./Util/RobinhoodDigits";
import { Skeleton } from '@material-ui/lab';

const CompanyPrice = (props) => {
  const {
    ticker,
    marketStatus,
    price,
    lastPrice,
    pLastPrice,
    setLastPrice,
    securityPrice,
  } = props
  useEffect(() => {
    setLastPrice("");
  }, [ticker]);
  useEffect(() => {
    setLastPrice(pLastPrice)
  }, [pLastPrice])
  const performance = useMemo(() => {
    let lastPriceUsed = {
      "pre-open": parseFloat(lastPrice || price?.last_price || 0).toFixed(2),
      open: parseFloat(lastPrice || price?.last_price || 0).toFixed(2),
      after: parseFloat(price?.close_price || lastPrice || price?.last_price || 0).toFixed(2),
      closed: parseFloat(price?.close_price || lastPrice || price?.last_price || 0).toFixed(2),
    }[marketStatus];
    let compare = {
      "pre-open": parseFloat(
        price?.pre_open || price?.prev_close_price
      ).toFixed(2),
      open: parseFloat(price.open_price).toFixed(2),
      after: parseFloat(price.open_price).toFixed(2),
      closed: parseFloat(price.open_price).toFixed(2),
    }[marketStatus];

    return {
      present: lastPriceUsed,
      change: lastPriceUsed - compare,
      percent: (((lastPriceUsed - compare) / compare) * 100)?.toFixed(2),
    };
  }, [
    marketStatus,
    price,
    ticker,
    lastPrice,
    securityPrice,
  ]);

  useEffect(() => {
    let interval = setInterval(() => {
      let currentSub = getSub()
      if (currentSub.isLastBar) {
        setLastPrice(currentSub.close)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [getSub(), ticker]);

  if (performance.present == 0) {
    return null;
  }

  return (
    <div style={{ fontSize: "1.6rem", fontWeight: "600" }}>
      <Helmet>
        <title>{ticker} {!!lastPrice ? `| ${lastPrice}` : ''} | TradeAlgo</title>
      </Helmet>
      {performance.present && (
        <div style={{ display: "flex" }} className="col-6">
          {Number(performance.present) == 0 ? <Skeleton animation="wave" /> : (
            <>
              $<RobinhoodDigits ticker={ticker} lastPrice={performance?.present} />
            </>
          )
          }
          <span
            style={{
              color: "lightgrey",
              fontSize: ".95rem",
              position: "relative",
              top: "12px",
              left: "5px",
            }}
          >
            USD
          </span>
        </div>
      )}
      <div style={{ display: 'flex', position: 'relative', left: '-7px', top: '-28px' }}>
        <span style={{
          fontSize: "15px",
          color: String(performance.percent).includes("-")
            ? "red"
            : "rgba(84, 202, 19, 1)",
          paddingTop: "10px",
          paddingLeft: "14px",
          whiteSpace: 'nowrap'
        }}>
          {String(performance.percent).includes("-") ? "" : "+ "}
          {performance.change.toFixed(2)}
        </span>
        <div className="col-6">
          <div
            style={{
              height: "25px",
              width: "75px",
              background: String(performance.percent).includes("-")
                ? "red"
                : "rgba(84, 202, 19, 1)",
              color: "white",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "600",
              borderRadius: "25px",
              marginTop: "8px",
              marginLeft: "-2px",
            }}
          >
            <span style={{ position: "relative", top: "1px" }}>
              {String(performance.percent).includes("-") ? "" : "+ "}
              {numberWithCommas(performance.percent ?? "") + "%"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const stateToProps = (state) => ({
  price: state.companyData.companyData.secPrice,
  pLastPrice: state.companyData.companyData.lastPrice
});

export default connect(stateToProps, null)(CompanyPrice);
