import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, Tooltip } from "@material-ui/core";
import moment from "moment-timezone";
import CardGridViewer from './CardGridViewer';
import { dashboardLoad } from "../../appRedux/ducks/dashboard";
// import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import { DashboardCardSkeleton } from "../common/Skeletons/DashboardCardSkeleton";
import "./dashboard.scss";
import tickerList from "../../helpers/tickerList";
import { equtiesSocketListen } from "../../appRedux/ducks/equitiesSocket";
import { isInternalWS } from "../../appRedux/actions/helpers";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import TrendingArea from "./TrendingArea";
import TimeStamp from "./TimeStamp";
import { Skeleton } from "@mui/material";
import { useAuth } from "../../contexts/Auth";
import MentorshipPromoBanner from "../MentorshipPromoBanner";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    flexGrow: 1,
    padding: "16px",
    background: theme.palette.primary.innerBackground,
    minHeight: "calc(100vh - 90px)",
    fontFamily: "'Circular Std'",
  },
  headerTitle: {
    color: theme.palette.primary.text,
  },
  smallButton: {
    background: theme.palette.primary.smallButton
  },
  activeHoursButton: {
    background: theme.palette.primary.headerButtonBg,
    color: theme.palette.primary.headerButtonText + ' !important'
  }
}));

const HomePage = ({ dashboard, dashLoading, fetchDashboardData, listen, timezone }) => {
  const classes = useStyles();
  const [hours, setHours] = useState('4h');
  const hardHistory = useHistory();
  const { loading, showCTABanner, CTABannerLink } = useAuth();
  const [type, setType] = useState('popular');
  const goHours = (value) => {
    hardHistory.push(`/home/${value}`);
  }
  const { time } = useParams();
  useEffect(() => {
    if (time) {
      setHours(time);
      fetchDashboardData(time, type);
    }
  }, [time, type])

  const trendingUp = useMemo(() => {
    let result = [];
    if (dashboard.scores && dashboard.scores.length > 0) {
      dashboard.scores.forEach(score => {
        if (score.score > 0) {
          result.push(score);
        }
      })
    }

    return result.slice(0, 5);
  }, [dashboard.scores]);

  const trendingDown = useMemo(() => {
    let result = [];
    if (dashboard.scores && dashboard.scores.length > 0) {
      dashboard.scores.forEach(score => {
        if (score.score < 0) {
          result.push(score);
        }
      })
    }

    result = result.sort((a, b) => a.score - b.score);

    return result.slice(0, 5);
  }, [dashboard.scores]);

  const tickers = useMemo(() => {
    const dashTickers = dashboard?.cards?.map((i) => i?.symbol) || []
    const dxTickers = isInternalWS ? dashTickers.map((el) => `forex_1m_dx;${el}`).concat(dashTickers.map((el) => `forex_quote_dx;${el}`)) : tickerList.filter((t) => dashTickers.includes(t.slice(0, 7))).filter((i) => i.includes("COMP") || i.includes("HSFX")).map((i) => i.includes("COMP") ? i + "{=1m,price=mark}" : i)
    return dxTickers
  }, [dashboard])

  useEffect(() => {
    if (!!tickers.length) {
      listen([...new Set(tickers)], false, false)
    }
    return () => listen([], false, true)
  }, [tickers]);
  return (
    <div className={classes.background + ' dashboard-container'}>
      {(showCTABanner && !loading) && <MentorshipPromoBanner link={CTABannerLink} />}
      <div className="dashboard-header">
        <span className={classes.headerTitle + ' dashboard-header-title'}>Trending Currencies</span>

        <TimeStamp hours={hours} goHours={goHours} />
      </div>

      <div className="dashboard-content">
        <div className="mobile-dashboard-date">
          <div>
            {
              loading && <Skeleton variant="text" sx={{ fontSize: '1rem', height: '23px', background: '#7c7c7c', width: '201px' }} />

            }
            <span>{(dashboard.date && !loading) && moment.tz(dashboard.date, timezone).format('MMMM D, YYYY')}</span>
            {
              (dashboard.time && !loading) && <span style={{ marginLeft: 8 }}>{moment.tz(dashboard.time, timezone).format("h:mm A")}</span>
            }
          </div>
          <div>
            <select className="api-type-selector" value={type} onChange={(e) => setType(e.target.value)}>
              <option value='default'>All</option>
              <option value='popular'>Popular</option>
              <option value='g7'>G7 Pairs</option>
            </select>
          </div>
        </div>
        <TrendingArea
          loading={dashLoading}
          trendingUp={trendingUp}
          trendingDown={trendingDown}
        />
        {
          dashLoading ? (
            <DashboardCardSkeleton />
          ) : (
            <CardGridViewer cards={dashboard.cards} timeStamp={hours} />
          )
        }
      </div>
    </div >
  );
};


const stateToProps = (state) => ({
  dashboard: state.dashboard.dashboard,
  dashLoading: state.dashboard.dashboardLoading,
  themeMode: state.themeMode.themeMode,
  timezone: state.userSettings.userSettings.timezone,
});

const dispatchToProps = (dispatch) => ({
  fetchDashboardData: (hours, type) => dispatch(dashboardLoad(hours, type)),
  listen: (symbols, options, disconnect) => dispatch(isInternalWS ? equtiesSocketListen(symbols) : dxFeedSocketListen(symbols, options, disconnect)),
});

export default connect(stateToProps, dispatchToProps)(HomePage);