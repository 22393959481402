import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const POPULAR_STOCKS_LOAD_TOP = "POPS/LOAD/TOP";
export const POPULAR_STOCKS_LOAD_TOP_SUCCESS = "POPS/LOAD/TOP/SUCCESS";
export const POPULAR_STOCKS_LOAD_TOP_FAILURE = "POPS/LOAD/TOP/FAILURE";

// Action Creators
export const popularStocksLoad = () => ({
  type: POPULAR_STOCKS_LOAD_TOP,
});
export const popularStocksLoadSuccess = (data) => ({
  type: POPULAR_STOCKS_LOAD_TOP_SUCCESS,
  data
});
export const popularStocksLoadFailure = (error) => ({
  type: POPULAR_STOCKS_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchPopularStocks(action) {
  try {
    yield put(popularStocksLoadSuccess({ popularStocks: [], loading: true }));
    const response = yield axios.get(`${apiURL}/popular-stocks`
      // 'http://localhost:4000/popular-stocks'
      , { withCredentials: true }
    );

    const popularStocks = response.data.map((row) => ({
      ...row,
      darkPoolActivity: row.darkflow.ats.multiplier || 0
    }));

    yield put(
      popularStocksLoadSuccess({
        popularStocks: popularStocks,
        loading: false,
      })
    );
  } catch (error) {
    yield put(popularStocksLoadFailure(error));
  }
}

function* listenPopularStocksLoad() {
  yield takeEvery(POPULAR_STOCKS_LOAD_TOP, fetchPopularStocks);
}

// Root Saga
export function* saga() {
  yield all([fork(listenPopularStocksLoad)]);
}

const INIT_STATE = {
  popularStocks: [],
  loading: true,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POPULAR_STOCKS_LOAD_TOP_SUCCESS:
      return {
        ...state,
        popularStocks: action.data.popularStocks,
        loading: action.data.loading,
      };
    default:
      return state;
  }
};

export default reducer;
