import React, { useEffect, useState } from "react";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Collapse from '@mui/material/Collapse';
import { IconButton } from '@material-ui/core';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import AuthorAvatar from "../../../assets/images/brian.png";
import FAQImage from "../../../assets/images/faq.png";
import FAQCategoryIcon from "../../../assets/images/faq-guide.png";
import HandPersonIcon from "../../../assets/images/hand-person.gif";

import "./style.css"

const SupportComponent = ({ selectNavigation, isShowFAQ }) => {
  const [isFAQ, setIsFAQ] = useState(false);
  const [openItems, setOpenItems] = useState([]);
  const [categories, setCategories] = useState([
    'General', 'Getting Started', 'Bot Automations', 'Chart', 'Indicators', 'Drawings', 'Trading', 'Alerts', 'Screener'
  ]);

  const toggleItem = (index) => {
    let duplicatedItems = [...openItems];

    if (duplicatedItems.includes(index)) {
      duplicatedItems = duplicatedItems.filter(obj => obj !== index);
    } else {
      duplicatedItems.push(index);
    }

    setOpenItems(duplicatedItems);
  }

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const goToFAQ = () => {
    selectNavigation('faq')
  }

  useEffect(() => {
    setIsFAQ(isShowFAQ);
  }, [isShowFAQ])

  return (
    <>
      {
        isFAQ ? (
          <div className="support-component">
            <div className="support-component-navigator" onClick={() => selectNavigation('support')}>
              <ArrowCircleLeftOutlinedIcon /> Support
            </div>

            <div className="support-faq-header">
              <div className="support-component-header-info">
                <div className="support-component-title">FAQ</div>
                <div className="support-faq-description">How can we help?</div>
              </div>
            </div>

            <div className="support-faq-search-bar">
              <input type="text" className="support-faq-search-box" placeholder="Find an answer" />
              <Button className="support-faq-search-button">Search</Button>
            </div>

            <div className="support-faq-categories">
              {
                categories.map((category, key) => (
                  <div className="support-faq-category" key={key}>
                    <div className="support-faq-category-header">
                      <div className="support-faq-category-title">{category}</div>
                      <div className="support-faq-category-available">3 help available</div>
                    </div>

                    <img src={FAQCategoryIcon} alt="faq-category" className="support-faq-category-icon" />
                  </div>
                ))
              }
            </div>

            <div className="support-faq-section">
              <div className="support-faq-section-title">General</div>

              <div className={`support-faq-section-item ${openItems.includes(1) ? 'active' : ''}`} onClick={toggleItem.bind(this, 1)}>
                <div className="support-faq-section-item-main">What is MarketMakers {openItems.includes(1) ? <RemoveIcon /> : <AddIcon />}</div>
                <Collapse in={openItems.includes(1)}>
                  <div className="support-faq-section-item-more">
                    MarketMakers is a global market data leader on a mission to make investing simple and fair. Modern investors require access to real-time data for independent insights, and MarketMakers provides these insights through cutting-eidge algorithms and AI.
                  </div>
                </Collapse>
              </div>
              <div className={`support-faq-section-item ${openItems.includes(2) ? 'active' : ''}`} onClick={toggleItem.bind(this, 2)}>
                <div className="support-faq-section-item-main">What is MarketMakers {openItems.includes(2) ? <RemoveIcon /> : <AddIcon />}</div>
                <Collapse in={openItems.includes(2)}>
                  <div className="support-faq-section-item-more">
                    MarketMakers is a global market data leader on a mission to make investing simple and fair. Modern investors require access to real-time data for independent insights, and MarketMakers provides these insights through cutting-eidge algorithms and AI.
                  </div>
                </Collapse>
              </div>
              <div className={`support-faq-section-item ${openItems.includes(3) ? 'active' : ''}`} onClick={toggleItem.bind(this, 3)}>
                <div className="support-faq-section-item-main">What is MarketMakers {openItems.includes(3) ? <RemoveIcon /> : <AddIcon />}</div>
                <Collapse in={openItems.includes(3)}>
                  <div className="support-faq-section-item-more">
                    MarketMakers is a global market data leader on a mission to make investing simple and fair. Modern investors require access to real-time data for independent insights, and MarketMakers provides these insights through cutting-eidge algorithms and AI.
                  </div>
                </Collapse>
              </div>
            </div>

            <div className="support-faq-section">
              <div className="support-faq-section-title">Getting Started</div>

              <div className={`support-faq-section-item ${openItems.includes(4) ? 'active' : ''}`} onClick={toggleItem.bind(this, 4)}>
                <div className="support-faq-section-item-main">What is MarketMakers? {openItems.includes(4) ? <RemoveIcon /> : <AddIcon />}</div>
                <Collapse in={openItems.includes(4)}>
                  <div className="support-faq-section-item-more">
                    MarketMakers is a global market data leader on a mission to make investing simple and fair. Modern investors require access to real-time data for independent insights, and MarketMakers provides these insights through cutting-eidge algorithms and AI.
                  </div>
                </Collapse>
              </div>
              <div className={`support-faq-section-item ${openItems.includes(5) ? 'active' : ''}`} onClick={toggleItem.bind(this, 5)}>
                <div className="support-faq-section-item-main">What is MarketMakers? {openItems.includes(5) ? <RemoveIcon /> : <AddIcon />}</div>
                <Collapse in={openItems.includes(5)}>
                  <div className="support-faq-section-item-more">
                    MarketMakers is a global market data leader on a mission to make investing simple and fair. Modern investors require access to real-time data for independent insights, and MarketMakers provides these insights through cutting-eidge algorithms and AI.
                  </div>
                </Collapse>
              </div>
              <div className={`support-faq-section-item ${openItems.includes(6) ? 'active' : ''}`} onClick={toggleItem.bind(this, 6)}>
                <div className="support-faq-section-item-main">What is MarketMakers? {openItems.includes(6) ? <RemoveIcon /> : <AddIcon />}</div>
                <Collapse in={openItems.includes(6)}>
                  <div className="support-faq-section-item-more">
                    MarketMakers is a global market data leader on a mission to make investing simple and fair. Modern investors require access to real-time data for independent insights, and MarketMakers provides these insights through cutting-eidge algorithms and AI.
                  </div>
                </Collapse>
              </div>
            </div>

            <div className="support-faq-section">
              <div className="support-faq-section-title">Bot Automations</div>

              <div className={`support-faq-section-item ${openItems.includes(7) ? 'active' : ''}`} onClick={toggleItem.bind(this, 7)}>
                <div className="support-faq-section-item-main">What is MarketMakers? {openItems.includes(7) ? <RemoveIcon /> : <AddIcon />}</div>
                <Collapse in={openItems.includes(7)}>
                  <div className="support-faq-section-item-more">
                    MarketMakers is a global market data leader on a mission to make investing simple and fair. Modern investors require access to real-time data for independent insights, and MarketMakers provides these insights through cutting-eidge algorithms and AI.
                  </div>
                </Collapse>
              </div>
              <div className={`support-faq-section-item ${openItems.includes(8) ? 'active' : ''}`} onClick={toggleItem.bind(this, 8)}>
                <div className="support-faq-section-item-main">What is MarketMakers? {openItems.includes(8) ? <RemoveIcon /> : <AddIcon />}</div>
                <Collapse in={openItems.includes(8)}>
                  <div className="support-faq-section-item-more">
                    MarketMakers is a global market data leader on a mission to make investing simple and fair. Modern investors require access to real-time data for independent insights, and MarketMakers provides these insights through cutting-eidge algorithms and AI.
                  </div>
                </Collapse>
              </div>
              <div className={`support-faq-section-item ${openItems.includes(9) ? 'active' : ''}`} onClick={toggleItem.bind(this, 9)}>
                <div className="support-faq-section-item-main">What is MarketMakers? {openItems.includes(9) ? <RemoveIcon /> : <AddIcon />}</div>
                <Collapse in={openItems.includes(9)}>
                  <div className="support-faq-section-item-more">
                    MarketMakers is a global market data leader on a mission to make investing simple and fair. Modern investors require access to real-time data for independent insights, and MarketMakers provides these insights through cutting-eidge algorithms and AI.
                  </div>
                </Collapse>
              </div>
            </div>

            <IconButton className="support-faq-scroll-item" onClick={scrollToTop.bind(this)}>
              <span className="up-circle-button">
                <ArrowUpwardIcon />
              </span>
            </IconButton>
          </div>
        ) : (
          <div className="support-component">
            {/* <div className="support-component-navigator" onClick={() => selectNavigation('livestream')}>
              <ArrowCircleLeftOutlinedIcon /> Main
            </div>

            <div className="support-component-header">
              <div className="support-component-header-info">
                <div className="support-component-title">Live Q&A</div>
                <div className="support-component-description">Join us to discuss, learn and excel at trading.</div>
              </div>
              <div className="support-component-header-promo">
                <img src={HandPersonIcon} alt='hand-person-icon' className="support-component-hand-person-icon" />
                <div className="support-component-care">
                  <span style={{ textDecoration: "underline", cursor: "pointer" }}>Click here</span> to buy a private session with us.
                </div>
              </div>
            </div> */}

            <div>
              <div>
                <iframe
                  className="live-stream-event-video"
                  src="https://vimeo.com/event/3009169/embed"
                  allow="autoplay; fullscreen; picture-in-picture"
                  frameBorder="0"
                  allowFullScreen
                  title='Q&A'
                ></iframe>
              </div>
            </div>

            <div className='support-component-info'>
              <div className='support-component-info-header'>
                <img src={AuthorAvatar} alt="Brian" className='live-streams-author' />
                <div className='support-component-author-info'>
                  <div className='support-component-author-name'>Brian Anderson</div>
                  <div className='support-component-author-job'>MarketMakers Option Analyst</div>
                </div>
              </div>

              <div className='support-component-info-content'>
                <div className='support-component-title'>MarketMakers Live Q&A Session</div>
                <div className='support-component-description'>Join us live for an exciting and informative session on profiting from trading!<br />Brian will share his insights and strategies on capitalizing the gold market, including technical analysis, risk management, Q&A and more.</div>
              </div>

              <div className='support-component-schedule'>
                <div className='support-component-schedule-title'>Live Schedule</div>
                <div className='support-component-schedule-list'>
                  <div className='support-component-schedule-day-item'>
                    <div className='support-component-schedule-day'>Monday</div>
                    <div className='support-component-schedule-time'>12:00pm EST</div>
                  </div>

                  <div className='support-component-schedule-day-item'>
                    <div className='support-component-schedule-day'>Tuesday</div>
                    <div className='support-component-schedule-time'>12:00pm EST</div>
                  </div>

                  <div className='support-component-schedule-day-item'>
                    <div className='support-component-schedule-day'>Wednesday</div>
                    <div className='support-component-schedule-time'>12:00pm EST</div>
                  </div>

                  <div className='support-component-schedule-day-item'>
                    <div className='support-component-schedule-day'>Thursday</div>
                    <div className='support-component-schedule-time'>12:00pm EST</div>
                  </div>

                  <div className='support-component-schedule-day-item'>
                    <div className='support-component-schedule-day'>Friday</div>
                    <div className='support-component-schedule-time'>12:00pm EST</div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="support-component-bottom-item">
              <div className="support-component-bottom-item-detail">
                <img src={FAQImage} className="support-component-bottom-faq-img" alt="faq" />
                <div>
                  <div className="support-component-bottom-item-title">FAQ</div>
                  <div className="support-component-bottom-item-info">Answers to your most common questions.</div>
                </div>
              </div>
              <Button className="support-component-bottom-item-button" onClick={goToFAQ.bind(this)}>How can we help?</Button>
            </div> */}
          </div>
        )
      }
    </>
  )
}

export default SupportComponent;