import React, { useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "./AlphaOptionsSignUpResult.scss";
import { useAuth } from "../../../contexts/Auth";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";

const AlphaOptionsSignUpResult = ({ userSettings, setUserSettings }) => {
  const hardHistory = useHistory();
  const { canSeeInternal, loading } = useAuth()
  const goDashboard = () => {
    hardHistory.push('/home');
  }
  useEffect(() => {
    setUserSettings({
      ...userSettings,
      alpha_sign_up: true
    })
  }, [])

  return (
    <div className="alpha-options-sign-up-result">
      {
        !loading ? (
          <div>
            <div className="alpha-options-sign-up-result-icon-wrapper">
              <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_939_11992)">
                  <path d="M99.998 0C44.7705 0 0 44.7705 0 99.998C0 155.226 44.7705 199.996 99.998 199.996C155.226 199.996 199.996 155.226 199.996 99.998C199.996 44.7705 155.226 0 99.998 0ZM99.998 187.46C51.695 187.46 12.5359 148.301 12.5359 99.998C12.5359 51.695 51.695 12.5398 99.998 12.5398C148.301 12.5398 187.46 51.6989 187.46 100.002C187.46 148.305 148.301 187.464 99.998 187.464V187.46Z" fill="#00DC0A" />
                  <path d="M151.728 61.2805C149.524 59.0688 147.238 56.9352 145.136 54.6335C144.104 53.5081 143.331 53.6957 142.443 54.5866C137.406 59.6549 132.381 64.7349 127.356 69.811C125.746 71.2686 124.261 72.863 122.714 74.387C112.999 83.953 103.39 93.6285 93.7496 103.269C85.8522 111.162 77.9274 119.029 70.0885 126.977C68.8498 128.231 68.1659 128.13 66.9897 126.93C60.6084 120.427 54.1373 114.011 47.7169 107.544C47.0565 106.879 46.6853 106.497 45.7357 107.341C43.2661 109.548 40.9253 111.858 38.737 114.343C37.9907 115.191 38.1626 115.535 38.7683 116.352C39.7921 117.723 41.0308 118.853 42.2266 120.029C47.1425 124.847 51.8904 129.829 56.9391 134.523C59.0024 136.441 60.796 138.719 62.8671 140.665C66.7162 144.284 69.6313 145.214 73.4296 141.463C92.1787 122.936 110.744 104.222 129.364 85.563C134.187 80.7292 138.977 75.8602 143.78 71.0068C146.511 68.3222 149.212 65.6103 151.99 62.9726C152.858 62.1519 152.217 61.769 151.732 61.2844L151.728 61.2805Z" fill="#00DC0A" />
                </g>
                <defs>
                  <clipPath id="clip0_939_11992">
                    <rect width="200" height="200" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </div>

            <div className="alpha-options-sign-up-result-success-title">
              Thank You For Signing Up!
            </div>

            <div className="alpha-options-sign-up-result-success-info">
              This is your official confirmation.<br />
              Thanks for joining the <span style={{ color: '#00DC0A', fontWeight: 600 }}>Alpha Options Method.</span> Check back in the next 12 hours to access the features fully.<br />Say goodbye to uncertainty and hello to consistent profits with the <span style={{ color: '#00DC0A', fontWeight: 600 }}>Alpha Options Method.</span>
            </div>

            {
              !!canSeeInternal && <Link to="home/4h"><Button className="back-to-dashboard" onClick={goDashboard.bind(this)}>Back to Dashboard</Button></Link>
            }
          </div>
        ) : (
          <div>
            <div className="alpha-options-sign-up-result-icon-wrapper">
              <CircularProgress size={200} color="success" />
            </div>
            <div className="alpha-options-sign-up-result-loading-text">
              Our algorithms are crunching numbers. Your patience is appreciated.
            </div>
          </div>
        )}
    </div>
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(AlphaOptionsSignUpResult);
