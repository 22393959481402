import React, { useEffect, useState } from 'react';
import { Checkbox, IconButton } from '@material-ui/core';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import crumb from "../../../assets/images/crumb.png";
import PlayIcon from "../../../assets/images/icon-play.png";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VideoPopup from "./VideoPopup";
import { useAuth } from '../../../contexts/Auth';
import { connect } from 'react-redux';
import { addUserSettingsLoad } from '../../../appRedux/ducks/userSettings';
function TutorialVideoGroup({ currentVideoGroup, setCurrentVideoGroup, userSettings, setUserSettings }) {
  const { userData } = useAuth();
  const [open, setOpen] = useState(false);
  const [i, setI] = useState(0)
  const [videoData, setVideoData] = useState([
    {
      id: 1,
      title: '1. Intro to Options',
      src: "https://player.vimeo.com/video/803028980?h=96943a33d0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "Option trading involves buying or selling options contracts, which are rights to buy or sell underlying assets like stocks, commodities, or currencies at a predetermined price and date.",
      period: "08:18",
      checked: userSettings?.viewed_videos?.beginner?.video_1 || false
    },
    {
      id: 2,
      title: '2. Basic Options Strategies',
      src: "https://player.vimeo.com/video/803029080?h=c3e6a63d14&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "Unlike trading stocks directionally, options allow you to profit multiple ways in different market conditions. Learn the advantages and disadvantages of stock trading and options trading.",
      period: "08:50",
      checked: userSettings?.viewed_videos?.beginner?.video_2 || false
    },
    {
      id: 3,
      title: '3. Understanding Options Pricing',
      src: "https://player.vimeo.com/video/803029175?h=bde7e32114&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "3 components that determine an option price: Intrinsic Value (aka Underlying Stock Price),Extrinsic Value (composite of many moving parts) and Bid/Ask Spread.",
      period: "14:53",
      checked: userSettings?.viewed_videos?.beginner?.video_3 || false
    },
    {
      id: 4,
      title: '4. Options Trading Risks',
      src: "https://player.vimeo.com/video/803029412?h=51d342e8bd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "This video provides an understanding of the two options contract types and the details of call and put options basics that you need to know.",
      period: "19:36",
      checked: userSettings?.viewed_videos?.beginner?.video_4 || false
    },
    {
      id: 5,
      title: '5. Basic Technical Analysis',
      src: "https://player.vimeo.com/video/803702141?h=e94638bbad&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "",
      period: "23:44",
      checked: userSettings?.viewed_videos?.beginner?.video_5 || false
    },
    {
      id: 6,
      title: '6. Fundamental Analysis for Options Trading',
      src: "https://player.vimeo.com/video/803029709?h=0332077a1b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "Every publicly traded domestic entity must file financial reports on a regular basis or risk being delisted.",
      period: "06:32",
      checked: userSettings?.viewed_videos?.beginner?.video_6 || false
    },
    {
      id: 7,
      title: '7 Options Trading Platforms & Tools',
      src: "https://player.vimeo.com/video/803702768?h=68f969e08c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "",
      period: "11:32",
      checked: userSettings?.viewed_videos?.beginner?.video_7 || false
    },
    {
      id: 8,
      title: '8 Trading Psychology & Emotions',
      src: "https://player.vimeo.com/video/803702630?h=857d413116&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "",
      period: "14:37",
      checked: userSettings?.viewed_videos?.beginner?.video_8 || false
    },
    {
      id: 9,
      title: '9. Building a Trading Plan',
      src: "https://player.vimeo.com/video/803702322?h=c2cdafc29b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "",
      period: "14:07",
      checked: userSettings?.viewed_videos?.beginner?.video_9 || false
    },
    {
      id: 10,
      title: '10. Putting It All Together',
      src: "https://player.vimeo.com/video/803708608?h=d11e58817f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description: "",
      period: "03:53",
      checked: userSettings?.viewed_videos?.beginner?.video_10 || false
    },
  ]);
  const handleClose = (video) => {
    setOpen(false);
    const checked = videoData.map((copy, index) => ({
      ...copy,
      checked: copy.id === video.id ? true : copy.checked
    }))
    setI((prev) => prev + 1)
    setVideoData(checked)
  }
  useEffect(() => {
    if (!!userData) {
      // set default settings for sidebar, send flag to not send network req
      setUserSettings(userData?.userSettings || userSettings, true);
    }
  }, [userData]);
  useEffect(() => {
    if (i >= 1) {
      const beginner = videoData.reduce((acc, obj, i) => {
        if (i === 1) {
          return { [`video_${acc.id}`]: acc.checked, [`video_${obj.id}`]: obj.checked }
        }
        return { ...acc, [`video_${obj.id}`]: obj.checked }
      })
      setUserSettings({
        ...userSettings,
        viewed_videos: {
          ...userSettings?.viewed_videos || {},
          beginner
        }
      })
    }
  }, [JSON.stringify(videoData)])

  const [selectedVideo, setSelectedVideo] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const selectVideo = (index) => {
    setSelectedVideo(videoData[index]);
    setCurrentPage(index + 1);
    setOpen(true);
  }

  const moveToOtherTrack = (value) => {
    setCurrentVideoGroup(value);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    setSelectedVideo(videoData[currentPage - 1]);
  }, [currentPage, videoData])

  return (
    <div className="videos-container">
      <div className='beginner-videos'>
        <div onClick={() => setCurrentVideoGroup("")} className='current-video-group-navigator'>
          <ArrowCircleLeftOutlinedIcon className="current-video-group-crumb" /> Tutorials
        </div>
        <div className='beginner-track-text'>
          <span style={{ textTransform: "capitalize" }}>{currentVideoGroup}</span> Track
        </div>

        <div className='building-text'>
          Building a solid foundation and understanding of how options really work is critical to your long-term success and ability to trade consistently.
        </div>
      </div>
      <div className='summary-bg'>
        Summary
      </div>
      <div className='completely-text'>
        If you're completely new (or even fairly new) to options trading then this track was created just for you. We've painstakingly outlined this course so that each lesson focuses on the most important topics to help you quickly hack the options trading learning curve. Take your time as you work through each tutorial and don't be shy about asking questions along the way.
      </div>
      <div className='objective-text'>
        Objectives
      </div>

      <ul className='ul-videos'>
        <li className='list-item-videos'> Master foundational option contract basics and terminology.</li>
        <li className='list-item-videos'> Learn how to use option payoff diagrams to build multi-leg strategies.</li>
        <li className='list-item-videos'>Reading and navigating pricing tables and option "moneyness".</li>
        <li className='list-item-videos'>Finding out exactly why our edge trading comes from implied volatility.</li>
        <li className='list-item-videos'> The best strategies for small accounts and how to generate income with options.</li>
      </ul>
      <div className='videos-text'>Videos</div>
      <div className="video-table-container">
        {
          videoData.map((item, index) => {
            return (
              <div className='video-table-row' key={index} onClick={selectVideo.bind(this, index)}>
                <div className='flex-row align-center no-margin'>
                  <IconButton >
                    <img className="w-18" src={PlayIcon} alt="video-play-icon" />
                  </IconButton>
                  <div className='table-video-text'>{item.title}</div>
                </div>

                <div className='flex-row align-center no-margin'>
                  <div className="time-stamp">{item.period}</div>
                  <div className='radio-video' name="radio-buttons">
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon className="checked-icon" />}
                      onClick={(e) => {
                        e.stopPropagation()
                        const checked = videoData.map((copy, index) => ({
                          ...copy,
                          checked: copy.id === item.id ? !item.checked : copy.checked
                        }))
                        setVideoData(checked)
                        setI((prev) => prev + 1)
                      }}
                      checked={item.checked}
                    />
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      {
        currentVideoGroup === 'beginner' && (
          <div className='tutorial-video-group-footer'>
            <div className='tutorial-video-group-footer-item visibility-hidden'>
              <ArrowCircleLeftOutlinedIcon className='arrow-circle-left-icon' />
              View track
            </div>
            <div className='tutorial-video-group-footer-item' onClick={moveToOtherTrack.bind(this, 'intermediate')}>
              Intermediate Track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' />
            </div>
          </div>
        )
      }

      {
        currentVideoGroup === 'intermediate' && (
          <div className='tutorial-video-group-footer'>
            <div className='tutorial-video-group-footer-item' onClick={moveToOtherTrack.bind(this, 'beginner')}>
              <ArrowCircleLeftOutlinedIcon className='arrow-circle-left-icon' />
              Beginner Track
            </div>
            <div className='tutorial-video-group-footer-item' onClick={moveToOtherTrack.bind(this, 'advanced')}>
              Advanced Track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' />
            </div>
          </div>
        )
      }

      {
        currentVideoGroup === 'advanced' && (
          <div className='tutorial-video-group-footer'>
            <div className='tutorial-video-group-footer-item' onClick={moveToOtherTrack.bind(this, 'intermediate')}>
              <ArrowCircleLeftOutlinedIcon className='arrow-circle-left-icon' />
              Intermediate Track
            </div>
            <div className='tutorial-video-group-footer-item visibility-hidden'>
              View Track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' />
            </div>
          </div>
        )
      }

      <VideoPopup
        open={open}
        handleClose={handleClose}
        video={selectedVideo}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totals={videoData.length}
        setVideoData={setVideoData}
        videoData={videoData}
        setI={setI}
      />
    </div>
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(TutorialVideoGroup);
