import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { makeStyles } from "@material-ui/core";
import { TableSkeleton } from "../Skeletons/TableSkeleton";
import React, { useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMedia } from "react-media";
import { connect } from "react-redux";
import { LinearProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
const useStyles = makeStyles((theme) => ({
  dataGrid: {
    // background: theme.palette.primary.tableBackground,
    // borderRadius: 16,
    "& .MuiDataGrid-toolbarContainer svg, .MuiDataGrid-toolbarContainer input":
    {
      color: "#f0f0f0",
    },
    "& .MuiDataGrid-columnHeader,.MuiDataGrid-iconButtonContainer svg, .MuiDataGrid-menuIcon svg":
    {
      backgroundColor: "#171917",
      color: "#9f9fa3",
    },
    "& .MuiIconButton-label": {
      color: theme.palette.primary.text,
    },
    "& .MuiDataGrid-root": {
      border: "none !important",
      position: "relative",
      overflow: "hidden !important",
      fontFamily: "Poppins,sans-serif !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "normal !important",
      borderBottomColor: theme.palette.primary.bb,
      fontSize: 14,
      fontWeight: "bold",
      color: "#141a14",
      backgroundColor: "#fff",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
      color: theme.palette.primary.tableHeaderText,
      fontSize: "14px",
      fontWeight: "600",
      fontFamily: "Poppins,sans-serif !important",
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottomColor: theme.palette.primary.bb,
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      display: "none",
    },
  },
  tableBackground: {
    background: theme.palette.primary.tableBackground,
    color: theme.palette.primary.tableHeaderText,
  },
  tableText: {
    color: theme.palette.primary.tableHeaderText + " !important",
    fontSize: "14px",
    fontFamily: "Poppins,sans-serif !important",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
}));
// const useStyles = makeStyles((theme) => ({
//   "& .MuiDataGrid-toolbarContainer button": {
//     display: "none",
//   },
//   "& .MuiDataGrid-toolbarContainer svg, .MuiDataGrid-toolbarContainer input": {
//     color: "#f0f0f0",
//   },
//   "& .MuiDataGrid-iconSeparator": {
//     display: "none",
//   },
//   "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell, .MuiDataGrid-iconButtonContainer svg, .MuiDataGrid-menuIcon svg":
//     {
//       backgroundColor: "#171917",
//       color: "#9f9fa3",
//     },
//   "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
//     borderBottom: "1px solid #f0f0f0",
//   },
//   "& .MuiDataGrid-rowsContainer, .MuiDataGrid-cell": {
//     fontSize: 14,
//     fontWeight: "bold",
//     color: "#141a14",
//     backgroundColor: "#fff",
//   },
//   "& .MuiDataGrid-rowsContainer, .MuiDataGrid-cell:first-child": {
//     fontSize: 14,
//     fontWeight: "normal",
//     color: "#9f9fa3",
//     backgroundColor: "#fff",
//   },
//   "& .MuiPaginationItem-root": {
//     borderRadius: 0,
//   },
//   "& .MuiTablePagination-root": {
//     color: "#f0f0f0",
//   },
//   "& .MuiTablePagination-root p": {
//     margin: "auto",
//   },
//   "& .MuiTablePagination-select": {
//     border: "1px solid #f0f0f0",
//   },
//   "& .MuiTablePagination-selectIcon": {
//     color: "#f0f0f0",
//   },
// }));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.text,
    borderBottomColor: "rgb(60, 64, 67)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottomColor: "rgb(60, 64, 67)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function MobileTable({
  rows,
  columns,
  loading,
  sortModel,
  upperHeight,
  setConcat,
  setPage,
  page,
  resolution
}) {

  function formatTime(time, date) {
    const twentyHourTime = moment(time, ["h:mm A"]).format("HH:mm");
    const preparedDateTime = date.split("T")[0] + " " + twentyHourTime;
    return moment(preparedDateTime).format();
  }

  function formatTimeGrid(str) {
    const startTime = moment(str);

    let timeToAdd = "1m";
    if (resolution === "1m") {
      timeToAdd = 60;
    }
    if (resolution === "5m") {
      timeToAdd = 60 * 5;
    }
    if (resolution === "15m") {
      timeToAdd = 60 * 15;
    }
    if (resolution === "30m") {
      timeToAdd = 60 * 30;
    }
    if (resolution === "1h") {
      timeToAdd = 60 * 60;
    }
    const endTime = moment(startTime).add(timeToAdd, "seconds");
    return `${startTime.format("h:mm a")} - ${endTime.format("h:mm a")}`;
  }

  function formatDate(str) {
    const datePart = moment(str).format("YYYY-MM-DD");

    return datePart;
  }

  const sortedArray = [...rows];
  sortedArray.sort((a, b) => b[sortModel] - a[sortModel]);
  const classes = useStyles();
  return (
    <InfiniteScroll
      dataLength={sortedArray.length}
      // dataLength={data.length}
      scrollThreshold={0.8}
      next={() => {
        const nextPage = page + 1;
        setConcat(true);
        setPage(nextPage);
      }}
      hasMore={true}
      scrollableTarget="scrollable-target"
      style={{ overflow: "none" }}
    >
      <TableContainer
        elevation={0}
      // style={{
      //   height: `calc(100vh - ${upperHeight + 103}px)`,
      //   width: "100%",
      // }}
      >
        {loading ? (
          <TableSkeleton />
        ) : (
          <Table
            className={classes.tableBackground}
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {columns.map((col, index) => {
                  if (col.hide) return null;
                  return (
                    <StyledTableCell className={classes.tableText} key={index}>
                      {col.headerName}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedArray
                .map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      {columns.map((col, j) => {
                        if (col.hide) return null;
                        if (col.renderCell) {
                          let params = {
                            value: row[columns[j].field],
                            row,
                          };
                          return (
                            <StyledTableCell key={j} component="th" scope="row">
                              {col.renderCell(params)}
                            </StyledTableCell>
                          );
                        }
                        return (
                          <StyledTableCell
                            key={j}
                            component="th"
                            scope="row"
                          >
                            {
                              typeof row[columns[j].field] === "object"
                                ? ""
                                : (
                                  columns[j].field === 'date' || columns[j].field === 'timePeriod' ? (
                                    columns[j].field === 'date' ? (formatDate(formatTime(row.converted_time, row.date))) :
                                      (formatTimeGrid(formatTime(row.converted_time, row.date)))
                                  ) : (
                                    row[columns[j].field]
                                  )
                                )
                            }
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </InfiniteScroll>
  );
}
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 1, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

export const TradeAlgoTable = ({
  rows,
  columns,
  loading = false,
  sortModel,
  setSortModel,
  hasRowClassName = false,
  rowClassName = "",
  themeMode,
  page,
  setPage,
  setConcat,
  concat,
  fetchMore,
  upperHeight,
  resolution,
  timezone
}) => {
  const classes = useStyles();
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const mounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const Table = useMemo(() => {
    return (
      <>
        {loading && !concat ? (
          <TableSkeleton />
        ) : (
          <DataGridPro
            rows={rows}
            columns={columns}
            hideFooterPagination={true}
            // pageSize={rows.length + 1}
            pageSize={30}
            disableColumnMenu={true}
            isRowSelectable={false}
            sortingOrder={["asc", "desc"]}
            hideFooter={true}
            autoHeight={false}
            sortModel={sortModel}
            scrollbarSize={1}
            loading={loading}
            getRowClassName={hasRowClassName ? rowClassName : ""}
            onSortModelChange={(model) => {
              if (
                model[0].field !== sortModel[0].field ||
                model[0].sort !== sortModel[0].sort
              ) {
                setSortModel(model);
              }
            }}
            onRowsScrollEnd={() => fetchMore()}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
            }}
            scrollEndThreshold={80}
          />
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(rows),
    loading,
    columns,
    sortModel,
    rows.length,
    themeMode,
    page,
    timezone
  ]);

  if (isMobile)
    return (
      <MobileTable
        sortModel={sortModel}
        loading={loading}
        rows={rows}
        columns={columns}
        upperHeight={upperHeight}
        setPage={setPage}
        setConcat={setConcat}
        page={page}
        resolution={resolution}
      />
    );
  return (
    <div className={classes.dataGrid}>
      <div
        style={{ height: `calc(100vh - ${upperHeight + 93}px)`, width: "100%" }}
      >
        {Table}
        <div style={{ height: "4px" }}> </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  timezone: state.userSettings.userSettings.timezone,
});

export default connect(stateToProps, null)(TradeAlgoTable);
