import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const USER_SETTINGS_LOAD = "UST/LOAD/TOP";
export const USER_SETTINGS_LOAD_SUCCESS = "UST/LOAD/TOP/SUCCESS";
export const USER_SETTINGS_LOAD_FAILURE = "UST/LOAD/TOP/FAILURE";

// Action Creators
export const addUserSettingsLoad = (userSettings, firstRender = false) => ({
  type: USER_SETTINGS_LOAD,
  userSettings,
  firstRender
});
export const addUserSettingsLoadSuccess = (data) => ({
  type: USER_SETTINGS_LOAD_SUCCESS,
  data,
});
export const addUserSettingsLoadFailure = (error) => ({
  type: USER_SETTINGS_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchAddUserSettings(action) {
  try {
    const { userSettings, firstRender } = action;
    yield put(addUserSettingsLoadSuccess(userSettings));
    // if we are setting the default settings from the first render from the user obj then there is no need to update the user obj, just update state
    const { defaultResolution, candleType, expWithin, minPremium, defaultOptionChainTicker, defaultIndicators, chartSaveMode = 0, timezone = 'Etc/UTC', viewed_videos = {}, alpha_sign_up = false, om_novice_sign_up = false,
      om_novice_renewed = false, om_accelerator_sign_up = false,
      om_accelerator_renewed = false,
      om_master_sign_up = false,
      om_master_renewed } = userSettings;
    if (!firstRender) {
      yield axios.put(
        `${apiURL}/user/settings`,
        {
          userSettings: {
            defaultResolution,
            candleType,
            expWithin,
            minPremium,
            defaultOptionChainTicker,
            defaultIndicators,
            chartSaveMode,
            timezone,
            viewed_videos,
            alpha_sign_up,
            om_novice_sign_up,
            om_novice_renewed,
            om_accelerator_sign_up,
            om_accelerator_renewed,
            om_master_sign_up,
            om_master_renewed
          }
        }, { withCredentials: true }
      );
    }
    // yield put(addUserSettingsLoadSuccess(userSettings));
  } catch (error) {
    yield put(addUserSettingsLoadFailure(error));
  }
}

function* listenFetchAddUserSettingsLoad() {
  yield takeEvery(USER_SETTINGS_LOAD, fetchAddUserSettings);
}

// Root Saga
export function* saga() {
  yield all([fork(listenFetchAddUserSettingsLoad)]);
}

const INIT_STATE = {
  userSettings: {
    defaultResolution: '15',
    candleType: '1',
    minPremium: 35000,
    expWithin: 730,
    defaultOptionChainTicker: "AAPL",
    defaultIndicators: [],
    // community = 1 , private = 0
    chartSaveMode: 0,
    timezone: 'Etc/UTC',
    viewed_videos: {},
    alpha_sign_up: false,
    om_novice_sign_up: false,
    om_novice_renewed: false,
    om_accelerator_sign_up: false,
    om_accelerator_renewed: false,
    om_master_sign_up: false,
    om_master_renewed: false
  }
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_SETTINGS_LOAD_SUCCESS:
      return {
        ...state,
        userSettings: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
