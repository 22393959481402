import React, { useMemo } from "react";

import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import PrivateLayout from "../components/common/Layout/PrivateLayout";

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  name,
  from,
  internal = false,
  ...rest
}) => {
  const { authUser, authToken, isUserBasic, canSeeInternal, loading } = useAuth();
  const location = useLocation();
  return useMemo(() => {
    // if it is an interal page and the user CANT see internal pages and user data is not loading - kick em out
    if (internal && !canSeeInternal && !loading) {
      return <Route
        {...rest}
        render={(props) => <Redirect
          to={{
            pathname: "/signout",
            state: { from: props.location },
          }}
        />}
      />
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          authUser && authToken && isUserBasic ? (
            name !== "signOut" ? (
              <></>
            ) : (
              <>
                {rest.customLayout ? (
                  <Layout {...rest} name={name} from={from}>
                    <Component {...props} {...rest} />
                  </Layout>
                ) : (
                  <PrivateLayout {...rest} name={name} from={from}>
                    <Component {...props} {...rest} />
                  </PrivateLayout>
                )}
              </>
            )
          ) : authUser && authToken ? (
            <>
              {rest.customLayout ? (
                <Layout {...rest} name={name} from={from}>
                  <Component {...props} {...rest} />
                </Layout>
              ) : (
                <PrivateLayout {...rest} name={name} from={from}>
                  <Component {...props} {...rest} />
                </PrivateLayout>
              )}
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
    // eslint-disable-next-line
  }, [authUser, Component, location.pathname, loading, canSeeInternal]);
};

export default PrivateRoute;
