import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loader = (props) => {
  const {
    className,
    style,
    variant,
    size,
    children,
  } = props;
  const element = (
    <div className="loader-view">
      <Spinner animation="border" role="status" variant={variant} size={size}>
        <span className="sr-only">Loading...</span>
      </Spinner>
      {children}
    </div>
  );
  return (
    <div className={`${className}`} style={style}>
      {element}
    </div>
  );
};

Loader.defaultProps = {
  style: null,
  className: "",
  type: "multi",
  variant: "dark",
};

export default Loader;
