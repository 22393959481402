import { Typography } from "@mui/material";
import React from "react";

const MinuteCard = ({ data }) => {
  return (
    <div
      className="minute-card"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          className="minute-card-title"
        >
          {data.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Circular Std'",
            fontStyle: "normal",
            fontWeight: "450",
            fontSize: "16px",
            lineHeight: "23px",
            letterSpacing: "-0.04em",
            color: "#1F1F21",
          }}
        >
          {data.value}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40%",
        }}
      >
        <CustomPercentageBar percentage={data.percentage} />
      </div>
    </div>
  );
};

export default MinuteCard;

const CustomPercentageBar = ({ percentage }) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#ecfbed",
        height: "20px",
      }}
    >
      <div
        style={{
          width: `${percentage}%`,
          backgroundColor: "#00DC0A",
          height: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          sx={{
            fontFamily: "'Circular Std'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "-0.04em",
          }}
        >
          {`${percentage}%`}
        </Typography>
      </div>
    </div>
  );
};
