/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import "./tabs.scss";
import { connect } from "react-redux";
import { darkflowTableLoad } from "../../../appRedux/ducks/darkflowTable";
import { makeStyles, Tooltip } from "@material-ui/core";
import Zoom from "@mui/material/Zoom";
import { popularStocksLoad } from "../../../appRedux/ducks/popularStocks";
import moment from "moment";
import { Select as SelectUnstyled } from '@mui/base';
import { Option as OptionUnstyled } from '@mui/base';
import { Popper as PopperUnstyled } from '@mui/base';
import { styled } from "@mui/system";
import { useMedia } from "react-media";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const useStyles = makeStyles((theme) => ({
  text: {
    position: "relative", top: '9px', left: '1px', fontWeight: 400, fontSize: '16px',
    color: theme.palette.primary.tabHeaderText,
  },
  normalText: {
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
  },
  customWidth: {
    "& > *": {
      width: '250px !important'
    }
  },
  customWidth2: {
    "& > *": {
      width: '155px !important'
    }
  }
}));

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 194px;
  background: #F1F5F9;
  border: 1px solid #F1F5F9;
  border-radius: 24px;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: black;
  
  &::after {
    content: '▾';
    float: right;
  }
  `
  );

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 194px;
  background: #F1F5F9;
  border-radius: 24px;
  color: black;
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }
  `
  );

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

function MobileTabs({ value, setValue, popular = false }) {
  return (
    <CustomSelect value={value} onChange={setValue} >
      <StyledOption value={'1'}>Auto</StyledOption>
      <StyledOption value={'2'}>Large Caps</StyledOption>
      <StyledOption value={'3'}>Medium Caps</StyledOption>
      <StyledOption value={'4'}>Small Caps</StyledOption>
    </CustomSelect>
  );
}
function MobileHeaderTabs({ value, setValue }) {
  return (
    <CustomSelect value={value} onChange={setValue} >
      <StyledOption value={'1'}>Intraday Activity</StyledOption>
      <StyledOption value={'2'}>Weekly Activity</StyledOption>
      <StyledOption value={'3'}>Popular Stocks</StyledOption>
    </CustomSelect>
  );
}
const lookupTable = {
  aht: {
    '1': 'Intraday',
    '2': 'Weekly',
    '3': 'Popular',
  },
  at: {
    '1': "Auto",
    '2': "Large",
    '3': "Medium",
    '4': "Small",
  }
}
const lookupTableReversed = {
  aht: {
    'Intraday': '1',
    'Weekly': '2',
    'Popular': '3',
  },
  at: {
    'Auto': '1',
    'Large': '2',
    'Medium': '3',
    'Small': '4',
  }
}
const DarkflowTabs = ({
  getDarkFlow,
  up,
  setUp,
  history,
  setCap,
  notifications,
  getPopularStocks,
  popular = false,
  setIsPopularTab,
  darkflowTable,
  themeMode
}) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const classes = useStyles()
  const params = useParams()

  const home = !history && !notifications
  const all = params['0']?.split('/')
  const allParams = useMemo(() => {
    if (home) {
      const [aht, at, t] = all
      return { aht, at, t }
    }
    else {
      const [at, t] = all
      return { at, t }
    }
  }, [history, notifications, all])
  const rrd = useHistory()
  const [activeHeaderTab, setActiveHeaderTab] = useState(!!allParams.aht ? lookupTableReversed.aht[allParams.aht] : '1');
  const [activeTab, setActiveTab] = useState(!!allParams?.at ? lookupTableReversed.at[allParams.at] : '1');

  useEffect(() => {
    return rrd.listen(location => {
      if (rrd.action === 'POP') {
        // Handle events
        const home = !history && !notifications
        const all = location.pathname.slice(1).split('/')
        all.shift()
        home ? setActiveTab(lookupTableReversed?.at[all[1]] || '1') : setActiveTab(lookupTableReversed.at[all[0]]);
        home && setActiveHeaderTab(lookupTableReversed?.aht[all[0]] || '1');
        home ? setUp(!!all[2] ? all[2] === "Up" : true) : setUp(all[1] === "Up");
      }
    })
  }, [])
  useEffect(() => {
    let basePath = ''
    const home = !history && !notifications
    const atChanged = (lookupTableReversed?.at[allParams?.at] || '1') != activeTab
    const ahtChanged = (lookupTableReversed?.aht[allParams?.aht] || '1') != activeHeaderTab
    if (home) {
      // if we are on the home page
      basePath = '/home'
    }
    else if (history) {
      // if we are on the history page
      basePath = '/historical'
    }
    else {
      // we assume we are on the notifications page
      basePath = '/alerts'
    }
    // trending status / cap filter included / weekly vs intraday
    let dir = up ? 'Up' : 'Down'
    // active tab changed boolean 
    if (home && (dir !== allParams.t || atChanged || ahtChanged)) {
      // /home/[daily|weekly|popular]/[auto|large|medium|small]/[up|down]
      rrd.push(`/home/${lookupTable.aht[activeHeaderTab]}/${lookupTable.at[activeTab]}/${dir}`)
    } else {
      if (dir !== allParams?.t || atChanged) {
        rrd.push(`${basePath}/${lookupTable.at[activeTab]}/${dir}`)
      }
    }
    // }
  }, [activeHeaderTab, activeTab, history, notifications, up])
  const [i, setI] = useState(0)
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const headerToggle = (tab) => {
    if (activeHeaderTab !== tab) {
      setActiveHeaderTab(tab);
    }
  };
  const weekly = useMemo(() => {
    return activeHeaderTab === '2'
  }, [activeHeaderTab])
  const cap = useMemo(() => {
    if (activeHeaderTab === '3') return "popular"
    else if (activeTab === "1") return "";
    else if (activeTab === "2") return "large";
    else if (activeTab === "3") return "medium";
    else if (activeTab === "4") return "small";
  }, [activeTab, activeHeaderTab]);
  useEffect(() => {
    if (history || notifications) setCap(cap);
    else {
      if (cap !== 'popular') {
        i > 0 && getDarkFlow(cap, weekly);
        setI(i + 1)
        const interval = setInterval(() => {
          getDarkFlow(cap, weekly);
          //15 minutes
        }, 900000);
        return () => clearInterval(interval);
      }
    }
  }, [cap, history, notifications, weekly]);
  useEffect(() => {
    if (popular) {
      if (activeHeaderTab === "3") {
        getPopularStocks();
        setIsPopularTab(true);
      } else {
        setIsPopularTab(false);
      }
    }
  }, [activeHeaderTab, popular]);
  const title = useMemo(() => {
    if (history) return "Historic ATS Gainers & Losers";
    else if (notifications) return "ATS Irregular Activity Alerts";
    else return "Top 10 Daily Unusual Activity";
  }, [history, notifications]);
  const darkflowDate = useMemo(() => {
    const dataDate = !!darkflowTable?.trending_up && darkflowTable?.trending_up[0]?.ats?.date || "";
    const dataMoment = moment.tz(dataDate, "America/New_York");
    return dataMoment.format("MMMM Do, YYYY");
  }, [darkflowTable, weekly]);
  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode])
  const notActiveColor = useMemo(() => {
    return themeMode !== "dark" ? "#CCCCCC" : "#161718";
  }, [themeMode])
  return (
    <div className="tabs tabs--justify tabs--bordered-bottom">
      <div className={clsx(classes.normalText, "unusual")}>
        {(notifications || history) && title}
        {!notifications && !history && (
          <>
            {
              isMobile ? <MobileHeaderTabs value={activeHeaderTab} setValue={setActiveHeaderTab} /> : (

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: themeMode === "dark" ? "1px solid rgb(41 72 55)" : "1px solid #dee2e6",
                  }}
                  className="tabs__wrap"
                >
                  <Nav className="nav-tabs-dark" tabs>
                    <NavItem className={clsx(classes.customWidth, "nav-item-dark")}>
                      <NavLink
                        className={clsx("nav-link-dark", classnames({ active: activeHeaderTab === "1" }))}
                        onClick={() => headerToggle("1")}
                        style={{
                          color: activeHeaderTab === "1" ? color : "#000000",
                          fontFamily: "inter",
                          fontSize: "16px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Intraday Activity
                      </NavLink>
                    </NavItem>
                    <NavItem className={clsx(classes.customWidth, "nav-item-dark")}>
                      <NavLink
                        className={clsx("nav-link-dark", classnames({ active: activeHeaderTab === "2" }))}
                        onClick={() => headerToggle("2")}
                        style={{
                          color: activeHeaderTab === "2" ? color : "#000000",
                          fontFamily: "inter",
                          fontSize: "16px",
                          whiteSpace: "nowrap",
                          paddingLeft: '9px'
                        }}
                      >
                        Weekly Activity
                      </NavLink>
                    </NavItem>
                    <NavItem className={clsx(classes.customWidth2, "nav-item-dark")}>
                      <NavLink
                        className={clsx("nav-link-dark", classnames({ active: activeHeaderTab === "3" }))}
                        onClick={() => headerToggle("3")}
                        style={{
                          color: activeHeaderTab === "3" ? color : "#000000",
                          fontFamily: "inter",
                          fontSize: "16px",
                          whiteSpace: "nowrap",
                          paddingLeft: '19px'
                        }}
                      >
                        Popular Stocks
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              )
            }
          </>
        )}

        {
          (popular && darkflowDate != 'Invalid date') && (
            <>
              <br />
              <span className={classes.text} >{darkflowDate}</span>
            </>
          )
        }
      </div>


      <br />
      <div>
        {
          activeHeaderTab !== "3" && (

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: themeMode === "dark" ? "1px solid rgb(41 72 55)" : "1px solid #dee2e6",
              }}
              className="tabs__wrap"
            >
              {
                isMobile ? <MobileTabs popular={popular} value={activeTab} setValue={setActiveTab} /> : (
                  <>

                    <Nav className="nav-tabs-dark" tabs>
                      <NavItem className={clsx(classes.customWidth2, "nav-item-dark")}>
                        <NavLink
                          className={clsx("nav-link-dark", classnames({ active: activeTab === "1" }))}
                          onClick={() => toggle("1")}
                          style={{
                            color,
                            fontFamily: "inter",
                            fontSize: "16px",
                          }}
                        >
                          Auto
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={clsx("nav-link-dark", classnames({ active: activeTab === "2" }))}
                          onClick={() => toggle("2")}
                          style={{
                            color,
                            fontFamily: "inter",
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Large Caps
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={clsx("nav-link-dark", classnames({ active: activeTab === "3" }))}
                          onClick={() => toggle("3")}
                          style={{
                            color,
                            fontFamily: "inter",
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Medium Caps
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={clsx("nav-link-dark", classnames({ active: activeTab === "4" }))}
                          onClick={() => toggle("4")}
                          style={{
                            color,
                            fontFamily: "inter",
                            fontWeight: "normal",
                            fontSize: "16px",
                          }}
                        >
                          Small Caps
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </>
                )}
              {activeHeaderTab !== "3" && (
                <span style={{ whiteSpace: 'nowrap' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    placement="bottom"
                    title={"Trending Up"}
                  >
                    <svg
                      className="up-icon"
                      onClick={() => setUp(true)}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="36"
                        height="36"
                        rx="12"
                        fill={up ? "#00b208" : notActiveColor}
                      />
                      <path
                        d="M9 23L15 17L19 21L26.405 13.595"
                        stroke="#FFFFFF"
                        strokeWidth="2"
                        strokeLinecap="square"
                      />
                      <path
                        d="M26.4062 13.5945L27.0008 13"
                        stroke="#FFFFFF"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M27 16V13H24"
                        stroke="#FFFFFF"
                        strokeWidth="2"
                        strokeLinecap="square"
                      />
                    </svg>
                  </Tooltip>
                  <Tooltip
                    TransitionComponent={Zoom}
                    placement="bottom"
                    title={"Trending Down"}
                  >
                    <svg
                      className="down-icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => setUp(false)}
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="36"
                        height="36"
                        rx="12"
                        fill={!up ? "#00b208" : notActiveColor}
                      />
                      <path
                        d="M12.9993 9L18.9993 15L14.9993 19L22.4043 26.405"
                        stroke="#FFFFFF"
                        strokeWidth="2"
                        strokeLinecap="square"
                      />
                      <path
                        d="M22.4055 26.4053L23 26.9999"
                        stroke="#FFFFFF"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M20 27L23 27L23 24"
                        stroke="#FFFFFF"
                        strokeWidth="2"
                        strokeLinecap="square"
                      />
                    </svg>
                  </Tooltip>
                </span>
              )}

            </div>
          )
        }
      </div>

    </div>
  );
};
const stateToProps = (state) => ({
  darkflowTable: state.darkflowTable,
  themeMode: state.themeMode.themeMode,
});
const dispatchToProps = (dispatch) => ({
  getDarkFlow: (risk, weekly) => dispatch(darkflowTableLoad(risk, weekly)),
  getPopularStocks: () => dispatch(popularStocksLoad()),
});

export default connect(stateToProps, dispatchToProps)(DarkflowTabs);
