import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PopularBadge from "../../../assets/images/most-popular-badge.png";

import "./index.scss";
import { useAuth } from "../../../contexts/Auth";
import { connect } from "react-redux";

const OptionsMentorshipPlan = ({ type = 'novice', userSettings }) => {
  const { hasForexMentorshipNovice, hasForexMentorshipAccelerator, hasForexMentorshipMaster, loading } = useAuth()
  const checkout = (href, processing) => {
    if(!processing) {
      window.location.href = href
    }
  }
  const NoviceProcessing = userSettings.om_novice_sign_up || !!userSettings?.om_novice_renewed;
  const AcceleratorProcessing = userSettings.om_accelerator_sign_up || !!userSettings?.om_accelerator_renewed;
  const MasterProcessing = userSettings.om_master_sign_up || !!userSettings?.om_master_renewed;
  if(type === 'novice' && hasForexMentorshipNovice && !NoviceProcessing) return null 
  if(type === 'accelerator' && hasForexMentorshipAccelerator && !AcceleratorProcessing) return null 
  if(type === 'master' && hasForexMentorshipMaster && !MasterProcessing) return null 
  return (
    <div className={`options-metorship-plan`}>
      {
        type === 'master' && (
          <img src={PopularBadge} alt='most popular badge' className="most-popular-badge" />
        )
      }
      <div className={`options-metorship-plan-badge ${type}-badge`}>{type}</div>
      <div className="options-mentorship-plan-info">
        {
          type === 'novice' && ("The Novice Mentorship Program curriculum offers an interactive learning experience with an expert analyst, who will provide extensive resources and give you a boost in navigating the challenges of launching your trading journey.")
        }

        {
          type === 'accelerator' && ("The Accelerator Mentorship Program provides personalised and exclusive guidance from our trading experts on technical analysis techniques and risk management strategies, so you can develop an effective risk-adjusted trading plan.")
        }

        {
          type === 'master' && ("The Master Mentorship Program curriculum is meticulously designed to elevate your trading skills to the apex level. Our experts offers in-depth market analysis reports and foolproof trading strategies, so you can multiply your profits exponentially.")
        }
      </div>

      <div className="options-mentorship-plan-features">
        {
          type === 'novice' && (
            <div className="options-mentorship-plan-feature">
              <CheckCircleIcon /> <span>3x 1-on-1 training over 3 months</span>
            </div>
          )
        }

        {
          type === 'accelerator' && (
            <div className="options-mentorship-plan-feature">
              <CheckCircleIcon /> <span>12x 1-on-1 training over 12 months</span>
            </div>
          )
        }

        {
          type === 'master' && (
            <div className="options-mentorship-plan-feature">
              <CheckCircleIcon /> <span>40x 1-on-1 training over 12 months</span>
            </div>
          )
        }

        <div className="options-mentorship-plan-feature">
          <CheckCircleIcon /> <span>Comprehensive resources on market analysis</span>
        </div>
        <div className="options-mentorship-plan-feature">
          <CheckCircleIcon /> <span>Structured Curriculum</span>
        </div>
        <div className="options-mentorship-plan-feature">
          <CheckCircleIcon /> <span>Practical Approach with hands-on session</span>
        </div>
        <div className="options-mentorship-plan-feature">
          <CheckCircleIcon /> <span>Risk Management with tools & strategies</span>
        </div>
        <div className="options-mentorship-plan-feature">
          <CheckCircleIcon /> <span>Experienced Mentors</span>
        </div>
        <div className="options-mentorship-plan-feature">
          <CheckCircleIcon /> <span>Results-Oriented</span>
        </div>
        {/* {
          (type === 'accelerator' || type === 'master') && (
            <div className="options-mentorship-plan-feature">
              <CheckCircleIcon /> <span>Cutting-Edge tools and technologies</span>
            </div>
          )
        } */}

        {/* {
          type === 'master' && (
            <div className="options-mentorship-plan-feature">
              <CheckCircleIcon /> <span>Community Support & Networking</span>
            </div>
          )
        } */}
      </div>

      {
        type === 'novice' && (
          <div className="options-mentorship-plan-price">
            <span className="small-text">$</span>599<span className="small-usd-text">USD</span>
          </div>
        )
      }

      {
        type === 'accelerator' && (
          <div className="options-mentorship-plan-price">
            <span className="small-text">$</span>2,199<span className="small-usd-text">USD</span>
          </div>
        )
      }

      {
        type === 'master' && (
          <div className="options-mentorship-plan-price">
            <span className="small-text">$</span>6,599<span className="small-usd-text">USD</span>
          </div>
        )
      }
      {
        type === 'novice' && (
          <Button onClick={() => checkout('https://checkout.marketmakers.com/ICS_aB3GB4v_SrY6CQ2HHwMza2OeuN1ZnFAqPZ-S_vo57Ge9lNh82-ayLY2aZBIJlqy1Xpbz4Q3IedpMaXrj9ZRZXy7dEn2Z68Yng14=', NoviceProcessing)} className="options-mentorship-plan-buy-button" sx={{ width: NoviceProcessing ? '215px !important' : '' }}>{loading ? <CircularProgress color="success" size={24} /> : NoviceProcessing ? "Payment Processing" : "Sign Me Up"}</Button>
        )
      }

      {
        type === 'accelerator' && (
          <Button onClick={() => checkout('https://checkout.marketmakers.com/ICS_aB3GB4v_SrY6CQ-VciEycX_W3vREi01yULeZ5OEk7Xy8jYgS9fO4K4TfPiAYlbjGE7fg6EvdbsdXLB7E9JNWEAvOBTyk2_hg3k6IH8Jd26hk', AcceleratorProcessing)} className="options-mentorship-plan-buy-button" sx={{ width: AcceleratorProcessing ? '215px !important' : '' }}>{loading ? <CircularProgress color="success" size={24} /> : AcceleratorProcessing ? "Payment Processing" : "Sign Me Up"}</Button>
        )
      }

      {
        type === 'master' &&  (
          <Button onClick={() => checkout('https://checkout.marketmakers.com/ICS_aB3GB4v_SrY6CQ-VciEycX_W3vREi01yULeZ5OEk7Xy8jYgS-fGoOo3IbGk_n6aAaIH38kiuTcdOJB7G549TUiDOBDDv68ZNjFLueQ==', MasterProcessing)} className="options-mentorship-plan-buy-button" sx={{ width: MasterProcessing ? '215px !important' : '' }}>{loading ? <CircularProgress color="success" size={24} /> : MasterProcessing ? "Payment Processing" : "Sign Me Up"}</Button>
        )
      }
    </div >
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(OptionsMentorshipPlan);