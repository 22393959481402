import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const STREAMS_LOAD = "STR/LOAD/TOP";
export const STREAMS_LOAD_SUCCESS = "STR/LOAD/TOP/SUCCESS";
export const STREAMS_LOAD_FAILURE = "STR/LOAD/TOP/FAILURE";
const loadingArr = [
  {
    name: "Loading...",
  },
  {
    name: "Loading...",
  },
  {
    name: "Loading...",
  },
  {
    name: "Loading...",
  },
  {
    name: "Loading...",
  },
  {
    name: "Loading...",
  },
  {
    name: "Loading...",
  },
  {
    name: "Loading...",
  },
]
// Action Creators
export const streamsLoad = () => ({
  type: STREAMS_LOAD,
});
export const streamsLoadSuccess = (data) => ({
  type: STREAMS_LOAD_SUCCESS,
  data,
});
export const streamsLoadFailure = (error) => ({
  type: STREAMS_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchPastStreams(action) {
  try {
    const final = {
      streams: loadingArr,
      loading: true,
    };
    put(streamsLoadSuccess(final));
    const response = yield axios.get(`${apiURL}/vimeo-past-streams`, { withCredentials: true }
      // "http://localhost:4000/vimeo-past-streams"
    );
    final.streams = response?.data?.data || loadingArr;
    final.loading = false;
    yield put(streamsLoadSuccess(final));
  } catch (error) {
    yield put(streamsLoadFailure(error));
  }
}

function* listenFetchStreamsLoad() {
  yield takeEvery(STREAMS_LOAD, fetchPastStreams);
}

// Root Saga
export function* saga() {
  yield all([fork(listenFetchStreamsLoad)]);
}

const INIT_STATE = {
  streams: loadingArr,
  loading: true,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STREAMS_LOAD_SUCCESS:
      return {
        ...state,
        streams: action.data.streams,
        loading: action.data.loading,
      };
    default:
      return state;
  }
};

export default reducer;
