import MTZ from "moment-timezone";
import axios from "axios";
import { apiURL } from "../../appRedux/actions/helpers";
import moment from "moment";
import { generateBars } from "./candleControl";
const supportedResolutions = ["1", "5", "15", "30", "60", "D", "W"];
const config = {
  supported_resolutions: supportedResolutions,
};
let allBars = [];
const history = {};
var sub;
let pricescale = 10000
let subbedTicker
const socket_url = process.env.REACT_APP_WEBSOCKET
const socket = new WebSocket(socket_url);
var sub;
// Trading View JS Datafeed
const datafeed = {
  onReady: (cb) => {
    setTimeout(() => cb(config), 0);
  },

  searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
    try {
      const results = [];
      const { data } = await axios.get(`${apiURL}/search/${userInput.replace("/", "")}`, { withCredentials: true })
      const availableSymbols = data;
      if (availableSymbols.length) {
        for (let i = 0; i <= availableSymbols.length; i++) {
          const data = availableSymbols[i];
          const symbol = data?.type ? data?.ticker : data?.symbol || '';
          const full_name = data?.name || '';
          const description = data?.type ? data?.name : data?.description || '';
          const ticker = data?.ticker || '';
          let type = "Forex";
          if (!!data?.type) {
            if (data?.type?.includes("fund")) {
              type = "Fund";
            } else {
              if (data?.type === "etf") {
                type = "Etf";
              } else {
                if (data?.type === "common_stock") {
                  type = "Stock";
                }
              }
            }
          }
          const formatted = {
            symbol,
            full_name,
            description,
            ticker,
            exchange: symbol,
            type,
          };
          if (!!symbol.length) {
            results.push(formatted);
          }
        }
        onResultReadyCallback(results);
      }
    } catch (error) {
      console.log(error, "err searching symbols")
    }
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    const isForex = symbolName.includes('/') || symbolName.includes('-')
    let symbolExchange = '{mm=COMP}';
    if (isForex) {
      pricescale = 100000
    } else {
      pricescale = 100
    }
    var symbol_stub = {
      isForex,
      symbolExchange,
      isOption: false,
      name: symbolName,
      description: symbolName,
      type: isForex ? 'forex' : 'stock',
      timezone: "Etc/UTC",
      ticker: symbolName,
      // exchange: 'NYSE',
      minmov: 1,
      pricescale,
      has_weekly_and_monthly: false,
      has_seconds: true,
      seconds_multipliers: ["1", "5", "15", "30"],
      has_intraday: true,
      intraday_multipliers: ["1", "5", "15", "30", "60", "120", "240", "360"],
      supported_resolution: supportedResolutions,
      volume_precision: 1,
      data_status: "streaming",
    };
    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);
  },

  async getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    try {
      let { from, to, firstDataRequest } = periodParams;
      let qs = {
        // firstDataRequest,
        symbol: symbolInfo.name,
        resolution: resolution.replace("1D", "D"),
        fromTime: moment(from * 1000).subtract(resolution.includes("S") ? firstDataRequest ? 5 : 12 : 5, resolution.includes("S") ? "hours" : 'days').valueOf(),
        toTime: to * 1000,
        timespan: "m"
      };
      const sunday = moment().startOf('week').format("YYYY-MM-DD");
      let fromTime = moment(qs.fromTime).format("YYYY-MM-DD");
      if (moment(sunday).isSame(fromTime, 'day') && resolution.includes('S')) {
        qs.fromTime = moment(qs.fromTime).subtract(2, 'days').startOf("day").valueOf();
      }
      if (qs.resolution === "D") {
        // day
        qs.timespan = "d";
        qs.resolution = 1;
      } else if (resolution.includes("S")) {
        qs.timespan = ''
        // qs.resolution = resolution.replace("S", "")
      } else {
        //minute || hour
        qs.timespan = 'm';
        qs.resolution = qs.resolution;
        if (qs.resolution >= 60) {
          qs.timespan = 'h'
          qs.resolution = qs.resolution / 60;
        }
      }
      const symbol = symbolInfo.isForex ? qs.symbol + symbolInfo.symbolExchange : qs.symbol;
      const timespanLookup = {
        'm': 'minute',
        "d": 'day',
        'h': 'hour'
      }
      const timespan = timespanLookup[qs.timespan]
      let uri;
      const bars = await generateBars(symbolInfo.isOption, symbol, qs.resolution, qs.timespan, qs.fromTime, qs.toTime)
      if (bars.length) {
        if (firstDataRequest) {
          var lastBar = bars[bars.length - 1];
          history[symbolInfo.name] = { lastBar: lastBar };
        }
        allBars = allBars.concat(bars).sort((a, b) => a.time - b.time);
        onHistoryCallback(bars, { noData: false });
      } else {
        onHistoryCallback([], { noData: true });
      }
    } catch (error) {
      onErrorCallback(error);
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    let symbol = symbolInfo.name
    const { isForex } = symbolInfo
    // forex_1m_dx
    // forex_5m_dx
    // forex_15m_dx
    // forex_30m_dx
    // forex_1h_dx
    // forex_1d_dx
    const res = resolution >= 60 ? resolution / 60 + 'h' : resolution.includes('D') ? resolution.replace('D', 'd') : resolution + 'm'
    socket.send(
      JSON.stringify([isForex ? `forex_${res}_dx;${symbol}` : `equities_candle_dx;${symbol}`])
    );
    if (history[symbolInfo.name]) {
      subbedTicker = symbol;
      const newSub = {
        symbol,
        subscribeUID,
        resolution,
        symbolInfo,
        lastBar: history[symbolInfo.name].lastBar,
        listener: onRealtimeCallback,
      };
      sub = newSub;
    }
    onResetCacheNeededCallback()
  },
  unsubscribeBars: (subscriberUID) => {
    allBars = [];
  },
};
socket.onopen = function (event) {
  console.log("socket opened");
};
socket.addEventListener("message", function (event) {
  if (event.data !== "pong") {
    const message = JSON.parse(event.data);
    if (message) {
      const data = message?.data;
      if (!!data) {
        const trades = JSON.parse(data)
        trades.forEach((el) => {
          let barData
          if (message.channel.includes('options')) {
            barData = {
              symbol: el.symbol,
              price: Number(el.price),
              volume: Number(el.size),
              time: (el.time),
            };
          } else {
            if (!!el.eventSymbol) {
              barData = {
                symbol: el.eventSymbol,
                price: Number(el.close),
                volume: Number(el.volume),
                time: (el.time),
              };
            }
          }
          var _lastBar = updateBar(barData, sub);
          // send the most recent bar back to TV's realtimeUpdate callback
          if (allBars[allBars.length - 1]) {
            allBars[allBars.length - 1].close = Number(_lastBar.close);
          }
          _lastBar.close = Number(_lastBar.close);
          sub.listener(_lastBar);
          // update our own record of lastBar
          sub.lastBar = _lastBar;
        });
      }
    }
  }
});

// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub) {
  const isForex = sub.symbolInfo.isForex
  var lastBar = sub?.lastBar;
  let resolution = sub?.resolution;
  var coeff = resolution * 60;
  var lastBarSec = lastBar?.time;
  var _lastBar;
  const lastBarEndMinute = MTZ(lastBarSec).valueOf() / 1000;
  const dataTime = MTZ(data.time).valueOf() / 1000;
  const diff = dataTime - lastBarEndMinute;
  if (diff >= coeff) {
    // create a new candle, use last close as open **PERSONAL CHOICE**
    _lastBar = {
      time: data.time,
      open: Number(lastBar.close),
      high: Number(lastBar.close),
      low: Number(lastBar.close),
      close: Number(data.price),
      volume: Number(data.volume),
    };
  } else {
    if (Number(data.price) < Number(lastBar.low)) {
      lastBar.low = Number(data.price);
    } else if (data.price > lastBar.high) {
      lastBar.high = Number(data.price);
    }
    lastBar.volume = isForex ? Number(data.volume) : lastBar.volume + Number(data.volume);
    lastBar.close = Number(data.price);
    _lastBar = lastBar;
  }

  return _lastBar;
}

export function getSub() {
  if (!!sub?.lastBar) {
    return sub.lastBar
  } else {
    return {
      isLastBar: false,
      close: '',
      symbol: "",
    }
  }
}
export function getUnsub() {
  return datafeed.unsubscribeBars;
}
export function closeSocket() {
  if (socket.readyState === socket.OPEN) {
    socket.send(JSON.stringify([]))
  }
}
export default datafeed;
