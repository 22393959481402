import React from "react";
import Button from "@mui/material/Button";

import "./style.css"
import FAQImage from "../../../assets/images/faq.png";
import QAImage from "../../../assets/images/qa.png";

const Supports = ({ selectNavigation }) => {
  const goToFAQ = () => {
    selectNavigation('faq');
  }

  const goToSupport = () => {
    selectNavigation('support');
  }
  return (
    <div className="supports-widget">
      <div className="supports-widget-title">Supports</div>
      <div className="supports-actions-wrapper">
        <div className="supports-widget-item">
          <div className="supports-widget-item-detail">
            <img src={FAQImage} className="supports-widget-faq-img" alt="faq" />
            <div>
              <div className="supports-widget-item-title">FAQ</div>
              <div className="supports-widget-item-info">Answers to your most common questions.</div>
            </div>
          </div>
          <Button className="supports-widget-item-button" onClick={goToFAQ.bind(this)}>How can we help?</Button>
        </div>

        <div className="supports-widget-item">
          <div className="supports-widget-item-detail">
            <img src={QAImage} className="supports-widget-faq-img" alt="faq" />
            <div>
              <div className="supports-widget-item-title">Live Q&A</div>
              <div className="supports-widget-item-info">Bronco Brian answers your questions live.</div>
            </div>
          </div>
          <Button className="supports-widget-item-button" onClick={goToSupport.bind(this)}>Q&A Schedule</Button>
        </div>
      </div>
    </div>
  )
}

export default Supports;