import { Helmet } from "react-helmet";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/Auth";
import {
  OpenChannel,
  OpenChannelSettings,
  SendBirdProvider,
} from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import "./ChatContainer.scss";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChatSkeleton } from "../common/Skeletons/ChatSkeleton";

function ChatContainer({ themeMode }) {
  const [showSettings, setShowSettings] = useState(false);
  const { authUser, userData, loading } = useAuth();
  const history = useHistory();
  const appId = process.env.REACT_APP_SENDBIRD_APP_ID; // TA: 'ABE805E6-EEA2-4FAE-9606-0481DD2CFD70'
  const channelUrl = process.env.REACT_APP_SENDBIRD_OPEN_CHANNEL_URL; // TA: 'sendbird_open_channel_12232_c4f329285fa22fe2280c1a08ebde56b0baeb42c9'
  useEffect(() => {
    if (!!userData && !userData.display_name && !loading) {
      history.push("/modify-username");
    }
  }, [userData, loading])
  const nickName = useMemo(() => {
    return userData ? userData?.display_name || "" : "";
  }, [JSON.stringify(userData)]);
  const [stringSet] = useState({
    PLACE_HOLDER__NO_CHANNEL: "",
  });
  const Component = useMemo(() => {
    return !!nickName.length && (
      <div
        className={`sendbird-theme--${themeMode} sendbird-openchannel-app container`}
      >
        <Helmet>
          <title> Live Chat | MarketMakers </title>
        </Helmet>
        <SendBirdProvider
          appId={appId}
          userId={authUser}
          nickname={nickName}
          stringSet={stringSet}
        >
          <div className="sendbird-openchannel-app__channel">
            <span
              style={{
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "rgba(148, 163, 184, 1)",
                  flexGrow: 1,
                  textAlign: "center",
                }}
              >
                To sign up for real time alerts, download the free telegram chat
                app and join the
                <a
                  style={{
                    color: "#5EEECD",
                    fontSize: "12px",
                    marginLeft: "3px",
                  }}
                  href={`https://t.me/+xfGSH41PdlMwMjJh`}
                  target="_blank"
                  rel="noreferrer"
                >
                  MarketMakers Private Channel.{" "}
                </a>
              </span>
            </span>
            <span
              style={{
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "rgba(148, 163, 184, 1)",
                  flexGrow: 1,
                  textAlign: "center",
                }}
              >
                If you have any questions please contact us at (332) 895-1869
                and or email
                <a
                  style={{
                    color: "#5EEECD",
                    fontSize: "12px",
                    marginLeft: "3px",
                  }}
                  href={`mailto:support@marketmakers.com`}
                >
                  support@marketmakers.com.
                </a>
              </span>
            </span>

            <OpenChannel
              channelUrl={channelUrl}
              disableUserProfile
              onChatHeaderActionClick={() => {
                setShowSettings(true);
              }}
              renderCustomMessage={(message, channel) => {
                if ((message._sender.userId == "Py7jBgYDxVTgiP6jVOHb38aDL4F3" || message._sender.userId === "5o6pwQ3h37RBXd9bgyJEn94isjf1") && message.type !== 'image/png') {
                  return () => (
                    <div className="sendbird-msg-hoc sendbird-msg--scroll-ref">
                      <div className="sendbird-openchannel-user-message">
                        <div className="sendbird-openchannel-user-message__left">
                          <div className="sendbird-context-menu" style={{ display: 'inline' }}>
                            <div className="sendbird-openchannel-user-message__left__avatar sendbird-avatar" style={{ height: '28px', width: '28px' }}>
                              <div className="sendbird-avatar-img sendbird-image-renderer" style={{ width: '100%', minWidth: '28px', maxWidth: '400px', height: '28px' }}>
                                <div className="sendbird-avatar-img--default" style={{ width: '28px', height: '28px' }}>
                                  <div className=" sendbird-icon sendbird-icon-user sendbird-icon-color--content" style={{ width: '16.1px', minWidth: '16.1px', height: '16.1px', minHeight: '16.1px' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{ height: '100%', width: '100%' }}><path className="icon-user_svg__fill" d="M42.667 37.333c7.17 0 13.018 5.66 13.32 12.755l.013.579V56a2.667 2.667 0 01-5.315.311L50.667 56v-5.333c0-4.26-3.33-7.743-7.53-7.987l-.47-.013H21.333a8 8 0 00-7.986 7.53l-.014.47V56a2.667 2.667 0 01-5.316.311L8 56v-5.333c0-7.17 5.66-13.019 12.755-13.321l.578-.013h21.334zM32 5.333c7.364 0 13.333 5.97 13.333 13.334C45.333 26.03 39.363 32 32 32c-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.334 13.333-13.334zm0 5.334a8 8 0 100 16 8 8 0 000-16z" fill="#000" fillRule="evenodd"></path></svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sendbird-openchannel-user-message__right">
                          <div className="sendbird-openchannel-user-message__right__top">
                            <span className="brian sendbird-openchannel-user-message__right__top__sender-name sendbird-label sendbird-label--caption-2 ">
                              {message._sender.nickname}
                            </span>
                          </div>
                          <div className="sendbird-openchannel-user-message__right__bottom">
                            <span className="sendbird-openchannel-user-message__right__bottom__message sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-1">
                              {message.message}
                            </span>
                          </div>
                        </div>
                        <div className="sendbird-openchannel-user-message__context-menu">

                        </div>
                      </div>
                    </div>
                  )
                }
              }}
            ></OpenChannel>
          </div>
          {showSettings && (
            <div className="sendbird-openchannel-app__settings">
              <OpenChannelSettings
                channelUrl={channelUrl}
                onCloseClick={() => {
                  setShowSettings(false);
                }}
              />
            </div>
          )}
        </SendBirdProvider>
      </div>
    )
  }, [
    nickName,
    appId,
    authUser,
    stringSet,
    showSettings,
    themeMode,
    loading
  ]);
  if (loading) return <ChatSkeleton />
  return Component;
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  change: state.nameChange.change,
});

export default connect(stateToProps, null)(ChatContainer);
