import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import SearchBar from "../common/SearchBar";
import { makeStyles, Tooltip } from "@material-ui/core";
import { ExpandOutlined, UserOutlined } from "@ant-design/icons";
import clsx from "clsx";
import Logo from "../common/Logo";
import { LogoDarkIcon, LogoWhiteIcon } from "../common/Actions";
import "./topbar.scss";
import "../common/Layout/PrivateLayout/sidebarStyles.scss";
import { sidebarSetter } from "../../appRedux/ducks/sidebarOpen";
import { useMedia } from "react-media";
import InfoIcon from "@mui/icons-material/Info";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const useStyles = makeStyles((theme) => ({
  background: {
    zIndex: 100,
    width: "100%",
    backgroundColor: theme.palette.primary.appHeader,
    // backgroundColor: "#2C302E",
  },
  backgroundWithBanner: {
    zIndex: 100,
    width: "100%",
    backgroundColor: theme.palette.primary.appHeader,
    // backgroundColor: "#2C302E",
  },
  text: {
    color: theme.palette.primary.text,
    // color: "#FFF",
  },
  appBanner: {
    fontSize: "16px",
    backgroundColor: "#000000 !important",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    position: "sticky",
    fontWeight: 500,
    top: 0,
  },
}));

const HomeHeader = ({ setSidebar, name, themeMode }) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const hardHistory = useHistory();
  const classes = useStyles();
  const [isClick, setClick] = useState(true);
  const headerDiv = useRef(null);
  const loc =
    window.location.pathname.includes("home") ||
    window.location.pathname.includes("historical") ||
    window.location.pathname.includes("movement") ||
    window.location.pathname.includes("alerts");
  useEffect(() => {
    setSidebar(isClick);
    // eslint-disable-next-line
  }, [isClick]);

  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      if (!isMobile) document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }
  useEffect(() => {
    if (!isMobile) {
      window.onscroll = () => {
        if (headerDiv != null && headerDiv.current != null) {
          if (window.pageYOffset > 30) {
            headerDiv.current.classList.add("header-bg-white");
          } else {
            headerDiv.current.classList.remove("header-bg-white");
          }
        }
      };
    }
  }, [isMobile]);
  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode]);

  if (isMobile)
    return (
      <>
        {/* <div className={classes.appBanner}>
          Own a piece of TradeAlgo! Invest before it’s too late => <a target="_blank" href="https://www.startengine.com/market-maker/">StartEngine.com</a>
          </div> */}
        <div
          className={clsx(
            classes.background,
            "d-flex w-100 align-items-center"
          )}
          ref={headerDiv}
        >
          <button
            onClick={() => {
              tToggle();
            }}
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item"
            id="vertical-menu-btn"
          >
            <svg
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.95508 1.45428H15.046M1.95508 6.90883H12.3187M1.95508 12.3634H15.046"
                stroke={"#808080"}
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>
          </button>
          <div className="row w-100">
            <div className="col-5">
              <span
                style={{
                  position: "relative",
                  top: isMobile ? "12px" : "9px",
                  left: isMobile ? "0" : "14px",
                  fontSize: isMobile ? "17px" : "24px",
                }}
                className={clsx(classes.text, "home-title")}
              >
                {name}
                {name === "SwingTrade AI" && (
                  <>
                    <Tooltip
                      arrow={true}
                      title="Our algorithms have identified these companies with great momentum and great fundamentals for potential swing trades. We recommend holding these assets for a minimum of 1 month and ideally between 6-12 months for best results. New picks are added every week as they are identified."
                    >
                      <InfoIcon style={{ marginLeft: "10px" }} />
                    </Tooltip>
                  </>
                )}
              </span>
            </div>
            <div className="col-7">
              <div
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
                className="d-flex"
              />
              <div
                className="d-flex"
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  alignItems: "flex-end",
                  position: "relative",
                  right: loc ? "14px" : "",
                  left: isMobile ? "-16px" : "",
                  width: isMobile ? "202px" : "aljhsda",
                }}
              >
                <SearchBar popupContainerId="header-search-bar" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <div
      className={clsx(
        hardHistory.location &&
          hardHistory.location.pathname &&
          hardHistory.location.pathname === "/real-time-options-flow"
          ? classes.backgroundWithBanner
          : classes.background,
        "home-font-family py-3 header-height"
      )}
    >
      <div className="container sidebar-content-container">
        <header id="page-topbar" className={`${themeMode}-mode`}>
          {/* <div className={classes.appBanner}>
          Own a piece of TradeAlgo! Invest before it’s too late => <a target="_blank" href="https://www.startengine.com/market-maker/">StartEngine.com</a>
          </div> */}
          <div className="navbar-header">
            <div className="d-flex">
              <div
                className={`navbar-brand-box ${themeMode === "dark" ? "dark-brandbox" : ""
                  }`}
              >
                <div style={{ width: "100%" }}>
                  <Logo
                    url="/home/4h"
                    type="h1"
                    icon={
                      themeMode === "dark" ? (
                        <LogoWhiteIcon
                          style={{ width: "30px", height: "20px" }}
                        />
                      ) : (
                        <LogoDarkIcon
                          style={{ width: "30px", height: "20px" }}
                        />
                      )
                    }
                    from={"dashboard"}
                    header={true}
                    hide={!isClick && !isMobile}
                  />
                </div>
              </div>
            </div>

            <div className="search-box-wrapper">
              <SearchBar popupContainerId="header-search-bar" />
              {/* <span className="top-bar-action-icons">
                <UserOutlined />
              </span> */}
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  sidebarOpen: state.sidebarOpen.open,
  themeMode: state.themeMode.themeMode,
});
const dispatchToProps = (dispatch) => ({
  setSidebar: (open) => dispatch(sidebarSetter(open)),
});
export default connect(stateToProps, dispatchToProps)(HomeHeader);