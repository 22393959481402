import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import React, { useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { useMedia } from "react-media";
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
const useStyles = makeStyles((theme) => ({
  datePicker: {
    cursor: 'pointer',
    "& ": {
      border: "none !important",
    },
  },
}));

const NDatePicker = (props) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { availableDates } = props;
  const expDates = availableDates.map((d) => moment.utc(d).format("MM/DD/YY"));
  return (
    < >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        className={classes.datePicker}
        disabled={!expDates.length}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={props?.value}
        disablePast
        inputFormat={'MMMM do, yyyy'}
        onChange={props?.onChange}
        shouldDisableDate={(date) => {
          let calDate = moment(date).format("MM/DD/YY")
          return !expDates.includes(calDate);
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="ndatepicker" >
            <input
              style={{
                // letterSpacing: "3px",
                fontSize: '14px',
                position: "relative",
                cursor: 'pointer',
                left: '-3px'
              }}
              ref={inputRef}
              {...inputProps}
              onClick={() => setOpen(true)}
              />
            <svg
              onClick={() => setOpen(true)}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              cursor="pointer"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#555"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ float: "right", position: 'relative', top: isMobile ? '-24px': '' }}
              className="feather feather-calendar"
              >
              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
              <line x1="16" y1="2" x2="16" y2="6"/>
              <line x1="8" y1="2" x2="8" y2="6"/>
              <line x1="3" y1="10" x2="21" y2="10"/>
            </svg>
          </div>
        )}
        {...props}
        />
    </LocalizationProvider>
</>        
  );
};

export default NDatePicker;
