import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import RealTimeTradingAlertsWidget from './RealTimeTradingAlertsWidget';
import SupportsWidget from './SupportsWidget';
import PastVideos from './PastVideos';
import RealTimeTradingAlertsComponent from './RealTimeTradingAlertsComponent';
import SupportComponent from "./SupportComponent";
import Collapse from "@mui/material/Collapse";
import AuthorAvatar from "../../assets/images/brian.png";
import "./index.scss";
import TutorialVideoGroup from './TutorialVideoGroup';

const AlphaOptions = () => {
  const [currentNav, setCurrentNav] = useState('livestream');
  const [isTutorialsToggle, setIsTutorialsToggle] = useState(false);
  const [currentVideoGroup, setCurrentVideoGroup] = useState('');
  const [isShowFAQ, setIsShowFAQ] = useState(false);

  const hardHistory = useHistory();
  const { main, sub } = useParams();

  const selectNavigation = (nav, extraField = false) => {

    if (nav === 'tutorials' && currentNav === 'tutorials') {
      setIsTutorialsToggle(!isTutorialsToggle)
    } else {
      if (nav === 'support' && extraField) {
        hardHistory.push(`/alpha-options/faq`);
      } else {
        hardHistory.push(`/alpha-options/${nav}`);
      }

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const doNavigation = (main, sub) => {
    let invalidRoute = false;

    if (main && sub) {
      if (main !== 'tutorials') {
        invalidRoute = true;
      } else {
        if (!['beginner', 'intermediate', 'advanced'].includes(sub)) {
          invalidRoute = true;
        } else {
          setCurrentNav(main);
          setIsTutorialsToggle(true);
          setCurrentVideoGroup(sub)
        }
      }
    } else {
      if (!['tutorials', 'livestream', 'real-time-trading-alerts', 'support', 'faq'].includes(sub)) {
        invalidRoute = true;
      } else {
        if (sub === 'tutorials') {
          setCurrentNav(sub);
          setIsTutorialsToggle(true);
          setCurrentVideoGroup('');
        } else {
          if (sub !== 'faq') {
            setCurrentNav(sub);
            setIsTutorialsToggle(false);
            setIsShowFAQ(false);
            setCurrentVideoGroup('');
          } else {
            setCurrentNav('support');
            setIsTutorialsToggle(false);
            setIsShowFAQ(true);
            setCurrentVideoGroup('');
          }
        }
      }
    }

    if (invalidRoute) {
      hardHistory.push(`/alpha-options/livestream`);
    }
  }

  useEffect(() => {
    doNavigation(main, sub);
  }, [main, sub]);

  return (
    <div className='alpha-options-page'>
      <div className='alpha-options-page-content-wrapper'>
        <div className='alpha-options-page-title'>The Alpha Options Method</div>
        <div className='alpha-options-page-short-description'>Guided learning at your own pace to help you reach your goals.</div>
        <div className='alpha-option-page-body'>
          <div className='alpha-option-page-navigator'>
            <div className={`alpha-option-page-navItem ${currentNav === 'tutorials' ? 'active' : ''}`} onClick={() => selectNavigation('tutorials')}>Tutorials
              {isTutorialsToggle ? <ExpandMoreIcon className='expand-icon' /> : <ExpandLessIcon className='expand-icon' />}
            </div>
            <div className={`alpha-option-page-navItem ${currentNav === 'livestream' ? 'active' : ''}`} onClick={() => selectNavigation('livestream')}>Livestream Learning</div>
            <div className={`alpha-option-page-navItem ${currentNav === 'real-time-trading-alerts' ? 'active' : ''}`} onClick={() => selectNavigation('real-time-trading-alerts')}>Real-time Trading Alerts</div>
            <div className={`alpha-option-page-navItem ${currentNav === 'support' ? 'active' : ''}`} onClick={() => selectNavigation('support')}>Support</div>
          </div>
          <div className='alpha-option-page-main-view'>
            {
              currentVideoGroup && <TutorialVideoGroup setCurrentVideoGroup={setCurrentVideoGroup} currentVideoGroup={currentVideoGroup} />
            }
            {
              !currentVideoGroup.length && (currentNav === 'tutorials' || currentNav === 'livestream') && (
                <>
                  <div className='alpha-options-page-content'>
                    <Collapse in={(currentNav === 'tutorials' && isTutorialsToggle)}>
                      {(currentNav === 'tutorials') && (
                        <>
                          {/* Tutorials section */}
                          <div className='alpha-options-page-tutorials-section'>
                            <div className='alpha-options-page-tutorial-section' onClick={() => setCurrentVideoGroup('beginner')}>
                              <div>
                                <div className='alpha-options-page-tutorial-section-header'>
                                  <PlayCircleIcon className='play-circle-icon' />
                                  <div className='alpha-options-page-tutorial-section-header-info'>
                                    <div className='alpha-options-page-tutorial-section-title'>Beginner Track</div>
                                    <div className='alpha-options-page-tutorial-section-video-info'>10 Videos • 2 hours 6 mins</div>
                                  </div>
                                </div>

                                <div className='alpha-options-page-tutorial-section-body'>Understanding the basics is crucial for success. This course will cover all the essentials and give you the knowledge and skills you need to trade with confidence.</div>
                              </div>

                              <div className='alpha-options-page-tutorial-section-view-video'>View track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' /></div>
                            </div>

                            <div className='alpha-options-page-tutorial-section' onClick={() => setCurrentVideoGroup('intermediate')}>
                              <div>
                                <div className='alpha-options-page-tutorial-section-header'>
                                  <PlayCircleIcon className='play-circle-icon' />
                                  <div className='alpha-options-page-tutorial-section-header-info'>
                                    <div className='alpha-options-page-tutorial-section-title'>Intermediate Track</div>
                                    <div className='alpha-options-page-tutorial-section-video-info'>12 Videos • 4 hours</div>
                                  </div>
                                </div>

                                <div className='alpha-options-page-tutorial-section-body'>If you've already learned the basics, this course is for you. We'll cover advanced strategies for finding and trading high probability options.</div>
                              </div>
                              <div className='alpha-options-page-tutorial-section-view-video'>View track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' /></div>
                            </div>

                            <div className='alpha-options-page-tutorial-section' onClick={() => setCurrentVideoGroup('advanced')}>
                              <div>
                                <div className='alpha-options-page-tutorial-section-header'>
                                  <PlayCircleIcon className='play-circle-icon' />
                                  <div className='alpha-options-page-tutorial-section-header-info'>
                                    <div className='alpha-options-page-tutorial-section-title'>Advanced Track</div>
                                    <div className='alpha-options-page-tutorial-section-video-info'>16 Videos • 4 hours 45 mins</div>
                                  </div>
                                </div>

                                <div className='alpha-options-page-tutorial-section-body'>The advanced course is designed to help you master the mechanics behind trading to build a robust trading system that consistently generates excess returns.</div>
                              </div>
                              <div className='alpha-options-page-tutorial-section-view-video'>View track <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' /></div>
                            </div>

                            <div className='alpha-options-page-tutorial-section' onClick={() => selectNavigation('support', true)}>
                              <div>
                                <div className='alpha-options-page-tutorial-section-header'>
                                  <PlayCircleIcon className='play-circle-icon' />
                                  <div className='alpha-options-page-tutorial-section-header-info'>
                                    <div className='alpha-options-page-tutorial-section-title'>FAQ</div>
                                    <div className='alpha-options-page-tutorial-section-video-info'>12 Videos • 4 hours</div>
                                  </div>
                                </div>

                                <div className='alpha-options-page-tutorial-section-body'>The FAQ section is a helpful resource for anyone seeking answers to common questions about the Alpha Options Method.</div>
                              </div>
                              <div className='alpha-options-page-tutorial-section-view-video'>View FAQ <ArrowCircleRightOutlinedIcon className='arrow-circle-right-icon' /></div>
                            </div>
                          </div>
                        </>
                      )}
                    </Collapse>

                    {/* Live Streams section */}
                    <div className='alpha-options-page-live-streams-content' id="live-stream-section">
                      <div>
                        <iframe
                          className="live-stream-event-video"
                          src="https://vimeo.com/event/2973944/embed/"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowFullScreen
                          title='Livestream Learning'
                          frameBorder="0"
                        ></iframe>
                      </div>
                      <div className='alpha-options-page-live-streams-info'>
                        <div className='alpha-options-page-live-streams-info-header'>
                          <img src={AuthorAvatar} alt="Brian" className='live-streams-author' />
                          <div className='alpha-options-page-live-streams-author-info'>
                            <div className='alpha-options-page-live-streams-author-name'>Brian Anderson</div>
                            <div className='alpha-options-page-live-streams-author-job'>MarketMakers Option Analyst</div>
                          </div>
                        </div>

                        <div className='alpha-options-page-live-streams-info-content'>
                          <div className='alpha-options-page-live-streams-title'>The Alpha Options Daily Livestreams</div>
                          <div className='alpha-options-page-live-streams-description'>This is a live trading experience where viewers can learn from a professional trader, Bronco, as he shares his desktop platform and provides analysis, trading tactics, and risk management strategies. The show offers insights into price action on charts and empirical reasons for projected profits.</div>
                        </div>

                        <div className='alpha-options-page-live-streams-schedule'>
                          <div className='alpha-options-page-live-streams-schedule-title'>Live Schedule</div>
                          <div className='alpha-options-page-live-streams-schedule-list'>
                            <div className='alpha-options-page-live-streams-schedule-day-item'>
                              <div className='alpha-options-page-live-streams-schedule-day'>Monday</div>
                              <div className='alpha-options-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>

                            <div className='alpha-options-page-live-streams-schedule-day-item'>
                              <div className='alpha-options-page-live-streams-schedule-day'>Tuesday</div>
                              <div className='alpha-options-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>

                            <div className='alpha-options-page-live-streams-schedule-day-item'>
                              <div className='alpha-options-page-live-streams-schedule-day'>Wednesday</div>
                              <div className='alpha-options-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>

                            <div className='alpha-options-page-live-streams-schedule-day-item'>
                              <div className='alpha-options-page-live-streams-schedule-day'>Thursday</div>
                              <div className='alpha-options-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>

                            <div className='alpha-options-page-live-streams-schedule-day-item'>
                              <div className='alpha-options-page-live-streams-schedule-day'>Friday</div>
                              <div className='alpha-options-page-live-streams-schedule-time'>3:00pm EST</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <RealTimeTradingAlertsWidget selectNavigation={selectNavigation} />

                    <SupportsWidget selectNavigation={selectNavigation} />
                  </div>
                </>

              )
            }

            {
              currentNav === 'real-time-trading-alerts' && (
                <RealTimeTradingAlertsComponent selectNavigation={selectNavigation} />
              )
            }

            {
              currentNav === 'support' && (
                <SupportComponent selectNavigation={selectNavigation} isShowFAQ={isShowFAQ} />
              )
            }
          </div>
        </div>
      </div>

      <div className={`past-videos-content ${currentNav === 'support' && !isShowFAQ ? 'qa-chat-wrapper' : ''}`}>
        {
          currentNav === 'support' && !isShowFAQ ? (
            <div className='alpha-options-qa-chat-widget'>
              <iframe
                src="https://vimeo.com/event/3009169/chat/"
                width="100%"
                height="100%"
                frameBorder="0"
                title='Q&A Chat'
              ></iframe>
            </div>
          ) : (
            <PastVideos selectNavigation={selectNavigation} />
          )
        }

      </div>
    </div>
  )
}

export default AlphaOptions;