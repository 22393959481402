// Action Types
export const SYMBOL_SET = "SYMBOL/SET";

// Action Creators
export const symbolSetter = (symbol) => {
  return {
    type: SYMBOL_SET,
    symbol
  };
};


const INIT_STATE = {
  symbol: {
    type: 'Company',
    value: ''
  }
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SYMBOL_SET:
      return {
        ...state,
        symbol: action.symbol,
      };
    default:
      return state;
  }
};

export default reducer;
