
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const NEWS_FILTERS_LOAD = "NF/LOAD/TOP";
export const NEWS_FILTERS_LOAD_SUCCESS = "NF/LOAD/TOP/SUCCESS";
export const NEWS_FILTERS_LOAD_FAILURE = "NF/LOAD/TOP/FAILURE";

// Action Creators
export const newsFiltersLoad = () => ({
    type: NEWS_FILTERS_LOAD,
});
export const newsFiltersLoadSuccess = (data, loading) => ({
    type: NEWS_FILTERS_LOAD_SUCCESS,
    data,
    loading
});
export const newsFiltersLoadFailure = (error) => ({
    type: NEWS_FILTERS_LOAD_FAILURE,
    error,
    loading: false
});

// Sagas
function* fetchNewsFilters(action) {
    try {
        yield put(newsFiltersLoadSuccess([], true));

        const url = `${apiURL}/forex/general-news-filters`;
        const response = yield axios.get(url, { withCredentials: true });
        yield put(newsFiltersLoadSuccess(response.data, false));

    } catch (error) {
        yield put(newsFiltersLoadFailure(error));
    }
}

function* listenNewsFiltersLoad() {
    yield takeEvery(NEWS_FILTERS_LOAD, fetchNewsFilters);
}

// Root Saga
export function* saga() {
    yield all([fork(listenNewsFiltersLoad)]);
}

const INIT_STATE = {
    newsFilters: {
        topics: [],
        sentiment: [],
        source_name: [],
    },
    newsFiltersLoading: false,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NEWS_FILTERS_LOAD_SUCCESS:
            return {
                ...state,
                newsFilters: action.data,
                newsFiltersLoading: action.loading,
            };
        case NEWS_FILTERS_LOAD_FAILURE:
            return {
                ...state,
                newsFilters: {
                    topics: [],
                    sentiment: [],
                    source_name: [],
                },
                newsFiltersLoading: false
            }
        default:
            return state;
    }
};

export default reducer;
