import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useParams } from "react-router-dom";
import "./index.scss";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import { connect } from "react-redux";
import { updateFieldsLoad } from "../../../appRedux/ducks/updateFields";
import { useAuth } from "../../../contexts/Auth";
import { Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
const copyLookup = {
  novice: "Novice Mentorship Program curriculum offers an interactive learning experience with an expert analyst, who will provide extensive resources and give you a boost in navigating the challenges of launching your trading journey.",
  accelerator: "Accelerator Mentorship Program provides personalised and exclusive guidance from our trading experts on technical analysis techniques and risk management strategies, so you can develop an effective risk-adjusted trading plan.",
  master: "Master Mentorship Program curriculum is meticulously designed to elevate your trading skills to the apex level. Our experts offers in-depth market analysis reports and foolproof trading strategies, so you can multiply your profits exponentially."
}
const buttonLinks = {
  novice: "https://t.me/+qcPejaRMgiJkYTM5",
  accelerator: "https://t.me/+4XQzTWUSnY04ODIx",
  master: "https://t.me/+DytrgmUjt3YxYTlh"
}
const useStyles = makeStyles((theme) => ({
  telegramButton: {
    "& .MuiButton-root": {
      width: "277px",
      height: "36px",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121416 !important",
      background: "#00DC0A !important",
      borderRadius: "4px",
      textTransform: 'none'
    }
  }
}));

const OptionsMentorshipStarter = ({ setUserFields, setUserSettings, userSettings }) => {
  const classes = useStyles()
  const { loading, mentorshipSessionsNovice, mentorshipSessionsAccelerator, mentorshipSessionsMaster, mentorshipSessionsUsed, userData } = useAuth()
  const { tier } = useParams()
  const capitalized = tier.charAt(0).toUpperCase() + tier.slice(1)
  const [subtract, setSubtract] = useState(0)
  const [mentor, setMentor] = useState('');
  const [toggleON, setToggleON] = useState(false);

  const mentorshipSessionsAvail = useMemo(() => {
    if (tier === 'novice') return mentorshipSessionsNovice
    if (tier === 'accelerator') return mentorshipSessionsAccelerator
    if (tier === 'master') return mentorshipSessionsMaster
    return 0
  }, [loading, mentorshipSessionsNovice, mentorshipSessionsAccelerator, mentorshipSessionsMaster, tier])

  useEffect(() => {
    if (!loading) {
      setUserSettings({
        ...userSettings,
        [`om_${tier}_sign_up`]: false,
        [`om_${tier}_renewed`]: false
      });
    }
  }, [loading])

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setSubtract((prev) => prev + 1)
      setUserFields({
        training_sessions_used: {
          ...mentorshipSessionsUsed,
          [`forex_mentorship_${tier}`]: mentorshipSessionsUsed[`forex_mentorship_${tier}`] + 1
        }
      })
    },
  })

  const sessionsLeft = useMemo(() => {
    return (mentorshipSessionsAvail - mentorshipSessionsUsed[`forex_mentorship_${tier}`] - subtract) <= 0 ? 0 : (mentorshipSessionsAvail - mentorshipSessionsUsed[`forex_mentorship_${tier}`] - subtract)
  }, [loading, mentorshipSessionsUsed, subtract, tier]);

  const renewMentorship = () => {
    let paymentLink = '';
    if (tier === 'novice') {
      paymentLink = 'https://checkout.marketmakers.com/ICS_aB3GB4v_SrY6CQ2HHwMza2OeuN1ZnFAqPZ-S_vo57Ge9lNh82-ayLY2aZBIJlqy1Xpbz4Q3IedpMaXrj9ZRZXy7dEn2Z68Yng14='
    }

    if (tier === 'accelerator') {
      paymentLink = 'https://checkout.marketmakers.com/ICS_aB3GB4v_SrY6CQ-VciEycX_W3vREi01yULeZ5OEk7Xy8jYgS9fO4K4TfPiAYlbjGE7fg6EvdbsdXLB7E9JNWEAvOBTyk2_hg3k6IH8Jd26hk'
    }

    if (tier === 'master') {
      paymentLink = 'https://checkout.marketmakers.com/ICS_aB3GB4v_SrY6CQ-VciEycX_W3vREi01yULeZ5OEk7Xy8jYgS-fGoOo3IbGk_n6aAaIH38kiuTcdOJB7G549TUiDOBDDv68ZNjFLueQ=='
    }

    window.location.href = paymentLink;
  }

  const calendlyUrl = useMemo(() => {
    if (mentor) {
      if (mentor === 'anthony') {
        if (tier === 'novice') return "https://calendly.com/analysts-6/marketmakers-novice-program-anthony-clone"
        if (tier === 'accelerator') return "https://calendly.com/analysts-6/marketmakers-accelerator-program-anthony-clone"
        if (tier === 'master') return "https://calendly.com/analysts-6/marketmakers-master-mentorship-program"
      }
      if (mentor === 'joe') {
        if (tier === 'novice') return "https://calendly.com/analysts-6/marketmakers-novice-mentorship-program"
        if (tier === 'accelerator') return "https://calendly.com/analysts-6/marketmakers-accelerator-mentorship-program"
        if (tier === 'master') return "https://calendly.com/analysts-6/market-makers-master-program-joe"
      }
      if (mentor === 'kevin') {
        if (tier === 'novice') return "https://calendly.com/analysts-6/marketmakers-novice-program-kevin"
        if (tier === 'accelerator') return "https://calendly.com/analysts-6/marketmakers-accelerator-program-kevin"
        if (tier === 'master') return "https://calendly.com/analysts-6/marketmakers-master-program-kevin"
      }
    } else {
      return '';
    }
  }, [tier, mentor])

  return (
    <div className="options-mentorship-starter">
      <div className="options-mentorship-starter-title">
        <span>Forex Mentorship:</span>
        <div className={`options-mentorship-mentors-selector ${toggleON ? 'active' : ''}`} onClick={(e) => setToggleON(!toggleON)}>
          <div className="options-mentorship-current-mentor">
            <span className="options-mentorship-current-mentor-name">{mentor === '' ? 'select your mentor' : mentor}</span>
            {toggleON ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
          <div className="options-mentorship-mentor-candidate" onClick={(e) => setMentor('anthony')}>Anthony</div>
          <div className="options-mentorship-mentor-candidate" onClick={(e) => setMentor('joe')}>Joe</div>
          <div className="options-mentorship-mentor-candidate" onClick={(e) => setMentor('kevin')}>Kevin</div>
        </div>
      </div>
      <div className="options-mentorship-starter-type">{capitalized}</div>
      <div className="options-mentorship-starter-info">
        {copyLookup[tier]}
      </div>
      <div className={clsx(classes.telegramButton, "telegram-join")}>Join our Telegram groupchat: <Button href={buttonLinks[tier]} target="_blank">MarketMakers {tier.charAt(0).toUpperCase() + tier.slice(1)} Alerts</Button> </div>
      <span className="options-mentorship-starter-available-sessions">

        Number of Sessions Left: {loading ? <Skeleton style={{
          marginLeft: "6px",
          width: "25px",
          background: "#5EEECD"
        }} /> : sessionsLeft}

      </span>

      <div className={`options-mentorship-starter-content ${sessionsLeft !== 0 ? 'no-padding-border' : ''}`}>
        {
          loading && (
            <div style={{ display: 'grid' }}>
              <Skeleton style={{
                width: "80%",
                height: '700px',
                display: "flex",
                justifySelf: "center",
                background: "#5EEECD",
                borderRadius: 7,
                position: 'relative',
                top: '-150px'
              }} />
            </div>
          )
        }
        {
          sessionsLeft !== 0 && !loading && calendlyUrl && <InlineWidget prefill={{
            email: userData?.email || '',
            name: (userData?.firstName || '') + ' ' + (userData?.lastName || ''),
          }} isLoading={(e) => console.log(e)} url={calendlyUrl} />
        }
        {
          (
            (sessionsLeft === 0 && !loading) && (
              <>
                <div className="options-mentorship-starter-no-sessions-title">
                  You Have no More<br />Sessions left
                </div>
                <div className="options-mentorship-starter-no-sessions-info">
                  Renew your mentorship with a simple click below
                </div>
                <Button className="options-mentorship-starter-renew-button" onClick={renewMentorship}>Renew Mentorship</Button>
              </>
            )
          )
        }
      </div >

      <div className="options-mentorship-starter-return-home">
        <Link to="/home/4h">
          <KeyboardBackspaceIcon /> Return Home
        </Link>
      </div>
    </div >
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserFields: (fields) => dispatch(updateFieldsLoad(fields)),
  setUserSettings: (settings, firstRender) => dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(OptionsMentorshipStarter);
