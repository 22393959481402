// Action Types
export const BREAD_CRUMB_SET = "BC/SET";

// Action Creators
export const breadCrumbChange = (breadCrumb) => {
  return {
    type: BREAD_CRUMB_SET,
    breadCrumb
  };
};


const INIT_STATE = {
  breadCrumb: {
    name: 'Dashboard',
    path: '/home/4h'
  }
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BREAD_CRUMB_SET:
      return {
        ...state,
        breadCrumb: action.breadCrumb,
      };
    default:
      return state;
  }
};

export default reducer;
