import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { Typeahead } from 'react-bootstrap-typeahead';
import { makeStyles } from "@material-ui/core";

import NewsCard from "./news-card/NewsCard";
import { newsLoad } from "../../appRedux/ducks/news";
import { newsFiltersLoad } from "../../appRedux/ducks/newsFilters";
import { NewsFilterSkeleton } from "../common/Skeletons/NewsFilterSkeleton";
import { CardSkeleton } from "../common/Skeletons/CardSkeleton";

import "./index.scss"

const useStyles = makeStyles((theme) => ({
  wholebackground: {
    width: "100%",
    minHeight: "calc(100vh - 90px)",
    flexGrow: 1,
    backgroundColor: theme.palette.primary.innerBackground,
  },
  background: {
    height: "100%",
    flexGrow: 1,
    padding: "16px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerTitle: {
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
    marginBottom: "16px!important",
  },
  backgroundInner: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexGrow: 1,
    rowGap: "10px",
  }
}));

const News = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { fetchFilters, fetchNews, loading, filtersLoading, topics, sentiments, sourceNames, news } = props;

  const [topic, setTopic] = useState(['']);
  const [sentiment, setSentiment] = useState(['']);
  const [sourceName, setSourceName] = useState(['']);

  const { tp, st, sn } = useParams();

  useEffect(() => {
    if (tp) {
      setTopic([tp === 'all' ? 'All' : tp]);
    }

    if (st) {
      setSentiment([st === 'all' ? 'All' : st]);
    }

    if (sn) {
      setSourceName([sn === 'all' ? 'All' : sn]);
    }

    fetchNews(tp, st, sn);
  }, [tp, st, sn, fetchNews]);

  const setSelectedTopic = (item) => {
    setTopic(item)
    if (item[0]) {
      history.push(`/news/${item[0]}/${sentiment[0]}/${sourceName[0]}`);
    }
  }

  const setSelectedSentiment = (item) => {
    setSentiment(item)
    if (item[0]) {
      history.push(`/news/${topic[0]}/${item[0]}/${sourceName[0]}`);
    }
  }

  const setSelectedSourceName = (item) => {
    setSourceName(item)
    if (item[0]) {
      history.push(`/news/${topic[0]}/${sentiment[0]}/${item[0]}`);
    }
  }

  useEffect(() => {
    fetchFilters();
  }, []);

  const allTopics = useMemo(() => {
    if (Array.isArray(topics)) {
      return ['All', ...topics];
    }
  }, [topics]);

  const allSentiments = useMemo(() => {
    if (Array.isArray(sentiments)) {
      return ['All', ...sentiments];
    }
  }, [sentiments]);

  const allSourceNames = useMemo(() => {
    if (Array.isArray(sourceNames)) {
      return ['All', ...sourceNames];
    }
  }, [sourceNames]);

  return (
    <div
      className={classes.wholebackground}
    >
      <div
        className={classes.background}
      >
        <Typography
          className={classes.headerTitle}
        >
          News
        </Typography>
        {
          filtersLoading ? (
            <NewsFilterSkeleton />
          ) : (
            <div className="news-filters-wrapper">
              <Typeahead
                id="typehead-topic-selctor"
                labelKey="topic"
                onChange={setSelectedTopic}
                options={allTopics}
                placeholder="Choose a topic..."
                selected={topic}
                className="news-filter-dropdown"
              />

              <Typeahead
                id="typehead-sentiment-selctor"
                labelKey="sentiment"
                onChange={setSelectedSentiment}
                options={allSentiments}
                placeholder="Choose a sentiment..."
                selected={sentiment}
                className="news-filter-dropdown"
              />

              <Typeahead
                id="typehead-source-name-selctor"
                labelKey="sourceName"
                onChange={setSelectedSourceName}
                options={allSourceNames}
                placeholder="Choose a source name..."
                selected={sourceName}
                className="news-filter-dropdown"
              />
            </div>
          )
        }

        {
          loading ? (
            <CardSkeleton width="100%" margin="0px" borderRadius="0px" />
          ) : (
            news.length === 0 ? (
              <div className="news-notification text-msg">No news available</div>
            ) : (
              <div
                className={classes.backgroundInner}
              >
                <div className="news-wrapper">
                  <div className="news-big-one">
                    {news[0] && <NewsCard data={news[0]} bigOne />}
                  </div>
                  <div className="news-small-one">
                    {news[1] && <NewsCard data={news[1]} />}
                    {news[2] && <NewsCard data={news[2]} />}
                  </div>
                </div>
                <Grid
                  container
                  style={{
                    display: "flex",
                    width: "100%",

                    flexGrow: 1,
                  }}
                >
                  {news.slice(3).map((newsItem, idx) => (
                    <Grid
                      style={{ padding: 5 }}
                      id={idx}
                      key={idx}
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                    >
                      <NewsCard data={newsItem} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )
          )
        }
      </div>
    </div>
  );
}

const stateToProps = (state) => ({
  news: state.news.news,
  loading: state.news.newsLoading,
  topics: state.newsFilters.newsFilters.topics,
  sentiments: state.newsFilters.newsFilters.sentiment,
  sourceNames: state.newsFilters.newsFilters.source_name,
  filtersLoading: state.newsFilters.newsFiltersLoading,

});

const dispatchToProps = (dispatch) => ({
  fetchNews: (topic, sentiment, sourceName) => dispatch(newsLoad(topic, sentiment, sourceName)),
  fetchFilters: () => dispatch(newsFiltersLoad()),
});

export default connect(stateToProps, dispatchToProps)(News);