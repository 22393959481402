import { Box, Typography } from "@mui/material";
import React from "react";

import { timeDifference } from "../../utilities/date";

const NewsCard = ({ data, bigOne }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#fff",
        cursor: "pointer"
      }}
      onClick={() => window.open(data?.news_url, '_blank')}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: "transparent",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            background: `url(${data?.image_url})`,
            height: bigOne ? "90%" : "250px",
            position: "relative",
            display: "flex",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: bigOne ? '600px' : 'auto'
          }}
        >
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              backgroundColor: "transparent",
              margin: "5px",
            }}
          >
            {
              data?.topics.map((topic, key) => (
                <button
                  key={key}
                  style={{
                    padding: "4px 8px",

                    background: "#2F2E31",
                    fontFamily: "'SF Pro Text'",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "14px",
                    textAlign: "center",
                    letterSpacing: "-0.4px",
                    color: "#E5E5EA",
                  }}
                >
                  {topic}
                </button>
              ))
            }
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Circular Std'",
              fontStyle: "normal",
              fontWeight: "450",
              fontSize: "14px",
              lineHeight: "18px",
              letterSpacing: "-0.02em",
              color: "#9E9F9F",
              marginTop: "16px",
              marginBottom: "16px"
            }}
          >
            {timeDifference(data?.source_date)} &#8226; {data?.source_name}
          </Typography>
          <Typography
            sx={{
              fontFamily: "'Circular Std'",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "30px",
              letterSpacing: "-0.04em",
              color: "#1C1C1E",
            }}
          >
            {data?.title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsCard;