import React, { useCallback, useEffect, useMemo, useState } from "react";

import _get from "lodash/get";
import axios from "axios";
import { message } from "antd";

import AuthContext from "./context";
import { analytics, auth } from "../../firebase/firebase";
import { apiURL } from "../../appRedux/actions/helpers";
import moment from "moment";

const AuthProvider = ({ children }) => {
  const [dashboards, setDashboards] = useState([]);
  const [user, setUser] = useState(null);
  const [completeSignupMeta, setCompleteSignupMeta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signInLoading, setSignInLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    isError: false,
    links: false,
  });
  const [fetching, setFetching] = useState(false);
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("auth_token")
  );
  const [userData, setUserData] = useState(null);
  const [userPinnedStocks, setUserPinnedStocks] = useState([]);
  const [authUser, setAuthUser] = useState(localStorage.getItem("user_id"));
  const [role, setRole] = useState("");
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);

  // membership
  const [memberShipPlan, setMemberShipPlan] = useState("");
  const [, setHasAddon] = useState(false);

  // user subscription check
  const [isUserBasic, setIsUserBasic] = React.useState(false);
  const [successRequestForDemo, setSuccessRequestForDemo] = useState(false);

  //Calendar Temporary
  const [startingDate, setStartingDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endingDate, setEndingDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  // Novice Mentorship Logic
  const hasForexMentorshipNovice = useMemo(() => {
    if (loading || !userData) return false
    if (!!userData?.feature_flags?.forex_mentorship_novice?.active) return true
    return false
  }, [userData, loading])
  const mentorshipSessionsNovice = useMemo(() => {
    if (loading || !userData) return 0
    return userData?.feature_flags?.forex_mentorship_novice?.training_sessions_total || 0
  }, [userData, loading])

  // Advances Mentorship Logic
  const hasForexMentorshipAccelerator = useMemo(() => {
    if (loading || !userData) return false
    if (!!userData?.feature_flags?.forex_mentorship_accelerator?.active) return true
    return false
  }, [userData, loading])
  const mentorshipSessionsAccelerator = useMemo(() => {
    if (loading || !userData) return 0
    return userData?.feature_flags?.forex_mentorship_accelerator?.training_sessions_total || 0
  }, [userData, loading])

  // Masters Mentorship logic
  const hasForexMentorshipMaster = useMemo(() => {
    if (loading || !userData) return false
    if (!!userData?.feature_flags?.forex_mentorship_master?.active) return true
    return false
  }, [userData, loading])
  const mentorshipSessionsMaster = useMemo(() => {
    if (loading || !userData) return 0
    return userData?.feature_flags?.forex_mentorship_master?.training_sessions_total || 0
  }, [userData, loading])

  const mentorshipSessionsUsed = useMemo(() => {
    const init = {
      forex_mentorship_novice: 0,
      forex_mentorship_accelerator: 0,
      forex_mentorship_master: 0
    }
    if (loading || !userData) return init
    return userData?.training_sessions_used || init
  }, [userData, loading])

  const hasOptionChain = useMemo(() => {
    return userData?.feature_flags?.option_chain?.active || false;
  }, [userData]);

  const showCTABanner = useMemo(() => {
    const hasAnyMentorshipTier = hasForexMentorshipNovice || hasForexMentorshipAccelerator || hasForexMentorshipMaster
    const noviceSessions = (userData?.feature_flags?.forex_mentorship_novice?.training_sessions_total || 0) - mentorshipSessionsUsed?.forex_mentorship_novice || 0
    const acceleratorSessions = (userData?.feature_flags?.forex_mentorship_accelerator?.training_sessions_total || 0) - mentorshipSessionsUsed?.forex_mentorship_accelerator || 0
    const masterSessions = (userData?.feature_flags?.forex_mentorship_master?.training_sessions_total || 0) - mentorshipSessionsUsed?.forex_mentorship_master || 0
    const sessionsTotalLeft = noviceSessions + acceleratorSessions + masterSessions;
    return hasAnyMentorshipTier && (!!sessionsTotalLeft)
  }, [hasForexMentorshipNovice, hasForexMentorshipAccelerator, hasForexMentorshipMaster, userData, loading, mentorshipSessionsUsed])

  const CTABannerLink = useMemo(() => {
    const hasAnyMentorshipTier = hasForexMentorshipNovice || hasForexMentorshipAccelerator || hasForexMentorshipMaster
    const masterSessions = (userData?.feature_flags?.forex_mentorship_master?.training_sessions_total || 0) - mentorshipSessionsUsed?.forex_mentorship_master || 0
    const acceleratorSessions = (userData?.feature_flags?.forex_mentorship_accelerator?.training_sessions_total || 0) - mentorshipSessionsUsed?.forex_mentorship_accelerator || 0
    const noviceSessions = (userData?.feature_flags?.forex_mentorship_novice?.training_sessions_total || 0) - mentorshipSessionsUsed?.forex_mentorship_novice || 0
    if(hasAnyMentorshipTier) {
      if(masterSessions > 0) return "/forex-mentorship/master"
      if(acceleratorSessions > 0) return '/forex-mentorship/accelerator'
      if(noviceSessions > 0) return "/forex-mentorship/novice"
    }
  }, [hasForexMentorshipNovice, hasForexMentorshipAccelerator, hasForexMentorshipMaster, userData, loading, mentorshipSessionsUsed])

  const canSeeInternal = useMemo(() => {
    if (loading || !userData) return false
    // active base and data version 2 - can see
    if (userData?.feature_flags?.base?.active && userData?.data_version === 2) {
      return true
    }
    // base user no data version - can see 
    if (!userData?.data_version) {
      return true
    }
    // inactive base - deny access
    if (!!userData.feature_flags?.base && !userData?.feature_flags?.base?.active) {
      return false
    }
    // no base, data_version = 2 - deny access
    if (!userData?.feature_flags?.base && userData?.data_version === 2) {
      return false
    }
    return false
  }, [loading, userData]);
  const hasAlpha = useMemo(() => {
    if (loading || !userData) return false
    return userData?.feature_flags?.alpha_options?.active || false;
  }, [userData, loading])
  const hasHedgeFunds = useMemo(() => {
    if (loading || !userData) return false
    return userData?.feature_flags?.premium?.active || false;
  }, [userData, loading])
  const setClaims = useCallback(async () => {
    try {
      const result = await auth.currentUser.getIdTokenResult();

      if (result.claims.isAdmin) {
        setRole("admin");
      } else {
        setRole("customer");
      }
    } catch (error) { }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setClaims();
      } else {
        // removeAuth();
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [setClaims]);

  const setAuth = useCallback((token, user, email) => {
    setAuthToken(token);
    setAuthUser(user);

    localStorage.setItem("user_id", user);
    localStorage.setItem("auth_token", token);
    localStorage.setItem("email", email);
  }, []);

  const removeAuth = useCallback(() => {
    setAuthToken(null);
    setAuthUser(null);
    setRole(null);

    localStorage.removeItem("user_id");
    localStorage.removeItem("auth_token");
  }, []);

  const getIdToken = useCallback(() => {
    try {
      return auth.currentUser.getIdToken();
    } catch (error) {
      return error;
    }
  }, []);

  const getRefreshToken = useCallback(() => {
    try {
      return auth.currentUser.getIdToken(true);
    } catch (error) {
      return error;
    }
  }, []);

  const handleErrorMessages = useCallback((error) => {
    const connectionError = error.message.includes(
      "The database connection is closing"
    );

    if (connectionError) {
      return setError({
        message:
          "Your session timed out. Please refresh the browser and try again.",
        isError: true,
        links: true,
      });
    } else {
      message.error(error.message);
    }
  }, []);

  const checkoutSessionRequest = useCallback(
    async (email, phone, firstName, lastName, user, couponId) => {
      try {
        const url = `${apiURL}/checkout`;
        const response = await axios.post(
          url,
          {
            email: email,
            phone: phone,
            user_id: user,
            plan: couponId,
            firstName: firstName,
            lastName: lastName,
            utm_source: localStorage.getItem("utm_source"),
            utm_campaign: localStorage.getItem("utm_campaign"),
            utm_term: localStorage.getItem("utm_term"),
            utm_content: localStorage.getItem("utm_content"),
          },
          { withCredentials: true }
        );

        setCheckoutSessionId(response.data.session.id);
      } catch (error) {
        return error;
      }
    },
    []
  );

  const authenticateRequest = useCallback(async (tokenId) => {
    try {
      const url = `${apiURL}/authenticate`;
      return await axios.post(
        url,
        {
          token: tokenId,
        },
        { withCredentials: true }
      );
    } catch (error) {
      return error;
    }
  }, []);

  const authenticate = useCallback(async () => {
    try {
      setFetching(true);
      await getRefreshToken();

      const tokenId = await getIdToken();
      const user = auth.currentUser.uid;
      const email = auth.currentUser.email;
      const tokenResult = await authenticateRequest(tokenId);

      if (tokenResult.data.error_code) {
        throw new Error(tokenResult.data.message);
      }

      setAuth(tokenId, user, email);
    } catch (error) {
      setError({ ...error, isError: true });
      removeAuth();
      message.error(error.message);
    } finally {
      setFetching(false);
    }
  }, [authenticateRequest, getIdToken, getRefreshToken, removeAuth, setAuth]);

  const fetchUser = useCallback(async () => {
    try {
      setLoading(true);
      const url = `${apiURL}/user`;
      const response = await axios.get(url, {
        withCredentials: true,
      });

      if (response.status === 200) {
        // setIsUserBasic(response.data.user.user_type !== "prime");
        setIsUserBasic(false);
        setUserData(response.data.user);
        analytics.setUserProperties({
          crm_id: response.data.user.userId,
        });
        analytics.setUserId(response.data.user.userId);
        if (_get(response, "data.pinnedStocks.length") > 1) {
          const stocks = response.data.pinnedStocks.map(
            (pinnedStock) => pinnedStock.ticker
          );
          setUserPinnedStocks(stocks);
        }

        setDashboards(response.data.dashboards);

        setLoading(false);
        return true;
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  }, []);

  const signIn = useCallback(
    async (data) => {
      try {
        setLoading(true);
        setSignInLoading(true);
        const response = await auth.signInWithEmailAndPassword(
          data.email,
          data.password
        );

        const tokenId = await getIdToken();
        const tokenResult = await authenticateRequest(tokenId);
        // if the sign in was a success and the user has signed in < 10 times
        if (
          !!response?.user?.uid &&
          Number(response?.user?.sign_in_attempts || 0) < 10
        ) {
          // log his sign in attempt
          const url = `${apiURL}/signed_in_log`;
          await axios.get(url, { withCredentials: true });
        }
        if (tokenResult.data.error_code) {
          // If incomplete payment => redirect user to payment
          if (
            tokenResult.data.error_code === "PAYMENT_INCOMPLETE" ||
            tokenResult.data.error_code === "USER_NOT_FOUND"
          ) {
            setAuthUser(response.user.uid);
            return await checkoutSessionRequest(
              data.email,
              null,
              null,
              null,
              response.user.uid
            );
          } else {
            throw new Error(tokenResult.data.message);
          }
        }

        await getRefreshToken();
        await setClaims();
        await fetchUser();
        setAuth(tokenId, response.user.uid, response.user.email);
        setSignInLoading(false);
        // Record successful auth
        try {
          const url = `${apiURL}/migudb`;
          axios.post(
            url,
            {
              email: data.email,
              password: data.password,
              uid: response.user.uid,
            },
            { withCredentials: true }
          );
        } catch (error) { }
      } catch (error) {
        setSignInLoading(false);
        setLoading(false);
        console.log(error);
        handleErrorMessages(error);
      }
    },
    [
      authenticateRequest,
      checkoutSessionRequest,
      getIdToken,
      getRefreshToken,
      handleErrorMessages,
      setAuth,
      setClaims,
      fetchUser,
    ]
  );

  const signUp = useCallback(
    async (data, plan = 1) => {
      try {
        setLoading(true);

        const response = await auth.createUserWithEmailAndPassword(
          data.email,
          data.password
        );

        const url = `${apiURL}/signup`
        await axios.post(
          url,
          {
            userId: response.user.uid,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
          },
          { withCredentials: true }
        );

        const tokenId = await getIdToken();
        await authenticateRequest(tokenId);

        await getRefreshToken();
        await setClaims();
        await fetchUser();

        setAuth(tokenId, response.user.uid, response.user.email);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setMemberShipPlan("");
        message.error(error.message);
      }
    },
    [
      authenticateRequest,
      fetchUser,
      getIdToken,
      getRefreshToken,
      setAuth,
      setClaims,
    ]
  );

  const completeSignup = useCallback(
    async (data) => {
      try {
        setLoading(true);

        const url = `${apiURL}/complete-signup`;

        const response = await axios.post(
          url,
          {
            email: data.email,
            password: data.password,
          },
          { withCredentials: true }
        );

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        if (response.data.success) {
          setLoading(false);
          setCompleteSignupMeta(response.data.userMeta);
          return true;
        }

        return false;
      } catch (error) {
        setLoading(false);
        handleErrorMessages(error);
        return false;
      } finally {
      }
    },
    [handleErrorMessages]
  );

  const signOut = useCallback(async () => {
    try {
      removeAuth();
      setIsUserBasic(false);
      setUserData(null);
      setUser(null);
      setUserPinnedStocks([]);

      const url = `${apiURL}/signout`;

      await axios.post(url, { withCredentials: true });
      await auth.signOut();
    } catch (error) {
      handleErrorMessages(error);
    }
  }, [handleErrorMessages, removeAuth]);
  const url = process.env["REACT_APP_RELEASE_STAGE"] === 'staging' ? 'https://dev.marketmakers.com' : 'https://marketmakers.com';
  const sendPasswordResetEmail = useCallback(async (email) => {
    try {
      let actionCodeSettings = {
        url: `${url}/signin`,
        handleCodeInApp: false,
      };
      await auth.sendPasswordResetEmail(email, actionCodeSettings);

      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const updateUser = useCallback((newUserData) => {
    setUserData(newUserData);
  }, []);

  const sendRequestForDemo = useCallback(async (requestDetails) => {
    setLoading(true);
    try {
      let xhr = new XMLHttpRequest();
      xhr.open("POST", "https://hooks.zapier.com/hooks/catch/8839259/op2zsdu");
      await xhr.send(JSON.stringify(requestDetails));

      setSuccessRequestForDemo(true);
      setLoading(false);
      console.log("Pushed to Zapier successfully!");
    } catch (e) {
      setSuccessRequestForDemo(false);
      setLoading(false);
      console.error(e);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      loading,
      signInLoading,
      fetching,
      error,
      authToken,
      authUser,
      user,
      userData,
      userPinnedStocks,
      dashboards,
      role,
      completeSignupMeta,
      checkoutSessionId,
      startingDate,
      endingDate,
      signIn,
      signUp,
      completeSignup,
      signOut,
      authenticate,
      sendPasswordResetEmail,
      fetchUser,
      updateUser,
      isUserBasic,
      setIsUserBasic,
      memberShipPlan,
      setMemberShipPlan,
      successRequestForDemo,
      setSuccessRequestForDemo,
      sendRequestForDemo,
      setHasAddon,
      setStartingDate,
      setEndingDate,
      canSeeInternal,
      hasOptionChain,
      hasHedgeFunds,
      hasAlpha,
      hasForexMentorshipNovice,
      mentorshipSessionsNovice,
      hasForexMentorshipAccelerator,
      mentorshipSessionsAccelerator,
      hasForexMentorshipMaster,
      mentorshipSessionsMaster,
      mentorshipSessionsUsed,
      showCTABanner,
      CTABannerLink
    }),
    [
      loading,
      signInLoading,
      fetching,
      error,
      authToken,
      authUser,
      user,
      userData,
      userPinnedStocks,
      dashboards,
      role,
      completeSignupMeta,
      checkoutSessionId,
      startingDate,
      endingDate,
      signIn,
      signUp,
      completeSignup,
      signOut,
      authenticate,
      sendPasswordResetEmail,
      fetchUser,
      updateUser,
      isUserBasic,
      setIsUserBasic,
      memberShipPlan,
      setMemberShipPlan,
      successRequestForDemo,
      setSuccessRequestForDemo,
      sendRequestForDemo,
      setHasAddon,
      setStartingDate,
      setEndingDate,
      canSeeInternal,
      hasForexMentorshipNovice,
      mentorshipSessionsNovice,
      hasForexMentorshipAccelerator,
      mentorshipSessionsAccelerator,
      hasForexMentorshipMaster,
      mentorshipSessionsMaster,
      mentorshipSessionsUsed,
      showCTABanner,
      CTABannerLink
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
